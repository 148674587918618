import { magicNumber } from '../../utils/magicNumber'
import { PREFIX } from '../config'

export const offerConstants = {
    offerCode: 'offerCode',
    maxRewardsCount: magicNumber.ONETHOUSAND,
    currentPageInitialValue: magicNumber.ONE,
    view: 'view',
    click: 'click',
    loyaltyOffersAppearedTimes: 'loyaltyOffersAppearedTimes',
    loyaltyOffersWereExpanded: 'loyaltyOffersWereExpanded',
    loyaltyOffersPanelDismiss: 'loyaltyOffersPanelDismiss',
    flatrate: 'FLATRATE',
    zeroMultiplier: '0X',
    percent: '%',
    activated: 'ACTIVATED',
    activate: 'ACTIVATE',
    redeemed: 'REDEEMED',
    swap: 'SWAP',
    offersCardImgComponentName: 'offers-card',
    bannerLogoImgComponentName: 'banner-logo',
    yes: 'Y',
    ctLogo: 'https://media-www.canadiantire.ca/logo/ct-brandmark-standard-primary-rgw-pos-hex-5f6ce5ad-aadc-44da-9d55-a06d4c6273bb.svg',
    coachmark: 'coachmark',
    search: 'Search',
}

export enum offerComponentLocation {
    accountOffers = 'accountOffers',
    pdpWidget = 'pdpWidget',
    loyaltyOffer = 'loyaltyOffer',
    pdpOffers = 'pdpOffers',
    carouselOffers = 'carouselOffers',
}

// props required for learn more modal on weekly offers page
export const learnMoreModalData = {
    learnMorePopupId: 'learnMoreModal',
    learnMorePopupCloseButtonClass: `${PREFIX}-informative-modal__close-container`,
    trianglePopupOpenedClass: `${PREFIX}-react-modal--opened`,
}
