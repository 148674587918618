import { Dispatch } from 'redux'
import {
    setIsStickyBuyBarInViewAction,
    setStickyBuyBarATCSpinnerAction,
    setStickyBuyBuyBarHeightAction,
    setStickyBuyBarBuyNowSpinnerAction,
} from '../actionCreators/stickyBuyBar.actionCreators'
import { IS_STICKY_BUYBAR_IN_VIEW, SET_STICKY_BUYBAR_HEIGHT } from '../types/products/stickyBuyBar.actionTypes.constant'

export const setIsStickyBuyBarInView =
    (isInView: boolean) =>
    (dispatch: Dispatch<{ type: typeof IS_STICKY_BUYBAR_IN_VIEW; payload: boolean }>): void => {
        dispatch(setIsStickyBuyBarInViewAction(isInView))
    }

export const setStickyBuyBuyBarHeight =
    (height: number) =>
    (dispatch: Dispatch<{ type: typeof SET_STICKY_BUYBAR_HEIGHT; payload: number }>): void => {
        dispatch(setStickyBuyBuyBarHeightAction(height))
    }

export const setStickyBuyBarATCSpinner =
    (showSpinner: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setStickyBuyBarATCSpinnerAction(showSpinner))
    }

export const setStickyBuyBarBuyNowSpinner =
    (showSpinner: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setStickyBuyBarBuyNowSpinnerAction(showSpinner))
    }
