/**
 * This file is designed to read global props from AEM/HTML
 * and make available at application level with Redux
 */
import { Configs, CriteoConfig, ISEOmetadata } from './globalProps.types'

/**
 * This file is designed to read global props from AEM/HTML
 * and make available at application level with Redux
 */
export class GlobalPropsHelper {
    // eslint-disable-next-line no-warning-comments
    // TODO: improvement required [vinay.mavi] need to work
    /**
     * This function read data from config
     * @return {Configs} configs
     */
    readDataConfig(): Configs {
        return this.dataset<Configs>('configs')
    }

    /**
     * This function return property by name from dataset
     * @template ObjectReturnType
     * @param {string} name of property which need to find
     * @param {boolean} isObject
     * @return {ObjectReturnType | string} property
     */
    dataset<ObjectReturnType>(name: string, isObject?: true): ObjectReturnType
    dataset(name: string, isObject: false): string
    // it was disabled because the function should return two types string or object
    // eslint-disable-next-line sonar/function-return-type
    dataset<ObjectReturnType>(name: string, isObject = true): ObjectReturnType | string {
        const dataset = document.querySelector('body')?.dataset

        try {
            return isObject ? (JSON.parse(dataset?.[name] as string) as ObjectReturnType) : (dataset?.[name] as string)
        } catch (error) {
            return {} as ObjectReturnType
        }
    }
    /**
     * Generate function to read alternateLanguagesDetails data-attribute from body tag
     * @return {ISEOmetadata} returns alternate language details JSON
     */
    readDataAlternateLangDetails(): ISEOmetadata {
        return this.dataset<ISEOmetadata>('seometadata')
    }

    /**
     * Function to get SeoImage configuration
     * @return {string}
     */
    readSeoImageConfig(): string {
        return this.dataset('seoimage', false)
    }

    /**
     * function to get digitalFlyer Iframe url
     * @return {string}
     */
    getDigitalFlyerIFrameURL(): string {
        return this.dataset('digitalflyeriframeurl', false)
    }

    /**
     * This function return digiCertLogoUrl
     * @return {string} digiCertLogoUrl
     */
    digiCertLogoUrl() {
        return this.dataset('digicertlogourl', false)
    }

    /**
     * This function get canadapost configs
     * @return {string} canadapost
     */
    getCanadaPostConfig(): Record<string, string> {
        return this.dataset<Record<string, string>>('data-canadapost')
    }

    /**
     * This function get criteo configs
     * @returns {CriteoConfig} criteo configuration
     */
    getCriteoConfig(): CriteoConfig {
        return this.dataset<CriteoConfig>('criteo', true)
    }

    /**
     * This function read data config
     * @return {Record<string, unknown>} dataConfig
     */
    init() {
        return this.readDataConfig()
    }

    /**
     * This function push shared cart link in history
     */
    pushSharedCartLinkInHistory(): void {
        const languageData = this.dataset<ISEOmetadata>('seometadata')
        const path = languageData?.currentPageDetails?.path || window.location.pathname
        window.history.pushState(null, document.title, path)
    }

    /**
     * Function to get allowedDomainsWithHeaders configuration
     * @return {Record<string, unknown>}
     */
    readAllowedDomainsWithHeadersConfig(): Record<string, unknown> {
        return this.dataset<Record<string, unknown>>('alloweddomainswithheaders')
    }
}

export default GlobalPropsHelper
