import spaAnalytics from '../analytics/components/spaAnalytics'
import { analyticsAction, analyticsLinkOption, analyticsStep } from '../globalConstants'

/**
 * SSO login analytics
 * @param  {boolean} isSuccess - true if user becomes authorized
 * @returns {void}
 */
export const ssoLoginAnalyticsEvent = (isSuccess: boolean): void => {
    spaAnalytics.setSSOLoginAnalytics(isSuccess)
}

/**
 * User Login From CLP analytics
 * @param  {boolean} isSuccess - true if user becomes authorized
 * @returns {void}
 */
export const setLoginFromCLPAnalytics = (isSuccess: boolean): void => {
    spaAnalytics.setLoginFromCLPAnalytics(isSuccess)
}

/**
 * Analytics for registration new rewards card initiated from OB
 * @param  {boolean} isSuccess - true if registration was successful, false if has error, undefined if has no result
 * @returns {void}
 */
export const registrationNewCardFromOBAnalytics = (isSuccess?: boolean): void => {
    spaAnalytics.setRewardsRegistrationData(
        analyticsStep.registerLoyaltyCartFromOb,
        analyticsLinkOption.registerNew,
        analyticsAction.triangleRewardsRegistration,
        isSuccess,
    )
}
