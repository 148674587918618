import { iPrice, iPriceRange } from '../PriceOld/PriceOld.types'
import { Price, PriceMessage, FeeMessage, SaleMessagesRule, SaleCut } from '../Price/Price.types'
import { ProductBrand } from '../ProductInfo/product.types'
import {
    ProductImage,
    FeatureBullets,
    Specification,
    ProductCardClickHandler,
    AddToCartClickHandler,
} from '../ProductReusableCard/product.types'
import { ProductSku } from '../VariantsWrapper/VariantsWrapper.type'
import { RebateData } from '../Rebate/Rebate.type'
import { BannerType } from '../VehicleBanner/VehicleBanner.type'
import { ExpressDeliveryType, ProductDataTypeObj, StorePickUpInterface, WishlistResponse } from '../../types'
import { VideoListType } from '../VideoGalleryViewer/VideoGalleryViewer.type'

export type RoadRatingSkeletonMap = Map<number, { height: number }>

/**
 * Interface for ProductCardType
 */
export interface ProductCardType {
    fitmentCompatibilityKey?: string
    fitmentMarkKey?: string
    type: string
    title: string
    code: string
    pCode?: string
    brand: ProductBrand
    onTime: number
    offTime: number
    productPageUrls: string[]
    rating: number
    ratingsCount: number
    timeTestedPrice?: iPrice
    priceShortMessage: string
    priceLongMessage: string
    longDescription: string
    specifications?: []
    productVariants?: []
    assemblyRequired: boolean
    inStock: boolean
    permanentMarkdown: boolean
    onlineOnly: boolean
    onFloor: boolean
    keepOnSiteWithZeroInventory: boolean
    bopisOnly: boolean
    promoMessages: PromoMessage[]
    restrictedPromoMessages: PromoMessage[]
    availability: string[]
    productSizeChart?: []
    availabilityDate: number
    mdmSpecifications?: []
    productPageShortUrls: string[]
    pagePath: string
    packagePrice?: boolean
    salePrice?: iPrice
    discount?: Price
    badges?: string[]
    price: iPrice
    url: string
    coreCharge: iPrice
    coreChargeIncluded?: boolean
    images: ProductImage[] | null
    featureBullets?: FeatureBullets[]
    options?: ProductOption[]
    skus?: ProductSku[]
    originalPrice: Price
    currentPrice: Price
    displayWasLabel?: boolean
    language?: string
    feeMessages?: FeeMessage[]
    priceMessage?: PriceMessage[]
    rebate?: RebateData
    iumapp?: boolean
    isTire?: boolean
    tireCategory?: string
    categoryLevel?: string
    roadRating?: RoadRating
    specification?: Specification[]
    partNumber?: string
    fitmentNotes: FitmentNote[] | null
    fitmentTypeCode?: string
    productWheelType?: string
    scrollToFooter?: (event) => void
    feeDisclaimerType?: string
    vehicleInformationPresent?: boolean
    fulfillment: iProductFulfillment
    sellable?: boolean
    orderable?: boolean
    rearVariant: {
        code: string
        displayWasLabel: string | null
    }
    exclude?: string[]
    isUrgentLowStock?: boolean
    totalCurrentPrice?: Price
    salePriceEndDate?: string
    saleCut?: SaleCut
    isMultiSku?: boolean
    skuId?: string
    saleEndDate?: string
    isOnSale?: boolean
    showATCButton?: boolean
    isAutomotiveRecommendation?: boolean
    isSponsored?: boolean
    productType?: string
    hasAtcQuickLookFlow?: boolean
    lowStockThreshold?: number
    videoList: VideoListType[]
    comparisonEnabled?: boolean
}

/**
 * Interface for PromoMessage
 * @interface
 */

export interface PromoMessage {
    label?: string
    tooltip?: string
}

/**
 * Interface for RoadRating
 * @interface
 */

export interface RoadRating {
    aggregateValue: number
    ratings: RoadRatingDetail[]
}

/**
 * Interface for RoadRatingDetails
 * @interface
 */

export interface RoadRatingDetail {
    roadRatedID: roadRateSpecification
    value: number
}

/**
 * Interface for ProductProps
 */
export interface ProductProps {
    ratingsAndReview: boolean
    featureHeaderLabel: string
    variantAvailableMsg?: string
    moreLabel: string
    unitPriceLabel?: string
    clearancePriceLabel?: string
    promotionalPriceLabel?: string
    thresholdValue?: number
    productCardListView: boolean
    listViewLabel: string
    gridViewLabel: string
    a11yPriceRangeFrom: string
    a11yPriceRangeTo: string
    coreCharge?: iPrice
    coreChargeIncluded?: boolean
    fitmentCriticalNotesLabel?: string
    fitmentUniversalLabel?: string
    fitmentVehicleSpecificLabel?: string
    fulfillment?: iProductFulfillment
    sellable?: boolean
    orderable?: boolean
    enableAddToCartOptions?: boolean
    addButton?: string
    optionsButton?: string
    vehicleSpecificFitLabel?: string
}

/**
 * Interface for Product
 * @interface
 */

export interface Product {
    fulfillment: iProductFulfillment
    sellable: boolean
    orderable: boolean
}

/**
 * Interface for ProductFulfillment
 * @interface
 */

export interface iProductFulfillment {
    code: string
    storePickUp: StorePickUpInterface
    shipToHome: {
        enabled: boolean
        messageCode?: string
        quantity: number // This is not present in new CDS interface
        etaEarliest: string
        etaLatest: string
        shippingEstimate?: string
    }
    expressDelivery: ExpressDeliveryType
    availability: iItemAvailability
    originalPrice: Price | null
    currentPrice: Price | null
    maxPurchasableQty: number
}

/**
 * Interface for ItemAvailability
 * @interface
 */

export interface iItemAvailability {
    Corporate?: {
        Quantity: number
        MinOrderQty: number
        orderableFromDC: boolean
        MinETA?: string
        MaxETA?: string
        bopisETA: ETAType
        sthETA: ETAType
    }
    quantity: number
    storeShelfLocation: string | null
}

/**
 * Interface for ETAType
 * @interface
 */

export interface ETAType {
    MinETA: string
    MaxETA: string
}

/**
 * Interface for BadgePriority
 */
export interface BadgePriority {
    maxNumberToDisplay: number
    priorityAndColour: IBadgePriorityCustomization[]
}

/**
 * Interface for InImageBadgePriority
 */
export interface inImageBadgePriority {
    maxNumberToDisplay: number
    priorityAndName: IBadgePriorityCustomization[]
}

interface IBadgePriorityCustomization {
    badgeId: string
    priority: number
    badgeIconUrl?: string
    badgeLabel?: string
    colorId?: string
    disclaimer?: string | null
}

/**
 * Interface for productCardList
 */
export interface propTypes {
    ProductCardData: ProductCardType[]
    productProps: ProductProps
    path?: string
    viewType: 'list' | 'grid' | string
    productCardClick?: ProductCardClickHandler
    redirectAfterProductCardClick?: (url: string, isReviewClicked: boolean) => string
    children?: React.ReactNode
    badgePriority?: BadgePriority
    inImageBadgePriority?: inImageBadgePriority
    a11yStrikeOutPrice: string
    a11yStrikeOutPriceRange: string
    inclCoreChargesLbl?: string
    plusCoreChargesLbl?: string
    coreCharge?: iPrice | iPriceRange
    coreChargeIncluded?: boolean
    coreChargesTooltipTitle?: string
    coreChargesTooltipParagraph?: string
    a11yTooltipIcon?: string
    language?: string
    a11yCloseIconLabel?: string
    isProductCardLoading?: boolean
    skeletonItems?: number
    rebateIcon?: string
    isFitmentRequired?: boolean
    setOfFourLabel?: string
    perTireLabel?: string
    vehicleFrontLabel?: string
    vehicleRearLabel?: string
    roadRatedLabel?: string
    vehicleFitType?: BannerType
    eachLabel?: string
    isAutoPartPlp?: boolean
    fitmentTypeCode?: fitmentTypeCodeValue
    vehicleList?: Vehicle[]
    productWheelType?: string
    nowFromLabel?: string
    saveFromLabel?: string
    wasFromLabel?: string
    fromLabel?: string
    promotionalPriceLabel?: string
    unitPriceLabel?: string
    clearancePriceLabel?: string
    isSRPPage?: boolean
    vehicleInformationPresent?: boolean
    tireInformationPresent?: boolean
    scrollToFooter?: (event) => void
    isWheelOrTirePDP?: boolean
    inStockLabel?: string
    inStockOnlineLabel?: string
    outOfStockLabel?: string
    inStorePurchaseLabel?: string
    inStockAisleLabel?: string
    checkAvailabilityLabel?: string
    checkOtherStoresLabel?: string
    isUseLegacyLogicOfVehicleCheckAvailability?: boolean
    partNumberLabel?: string
    isPLPPage?: boolean
    exclude?: string[]
    thresholdValue?: number
    overridePriceHeight?: boolean
    a11yReviewRating?: string
    reviewsContainerId?: string
    productCardSpecifications?: string
    isUrgentLowStockLabel?: string
    isWheelOrTireFunc?: (productWheelType?: string) => boolean
    isAutomotiveProduct?: (fitmentTypeCode?: string, productWheelType?: string) => boolean
    imageDataComponentName?: string
    returnPolicy: (policyType: string) => string
    attributePositionLabel?: string
    suggestedQuantityLabel?: string
    attributeUsageLabel?: string
    isLazyRequire?: boolean
    saleEndDaySoonMessage?: string
    isColourSwatchesActive?: boolean
    saleMessageRules?: SaleMessagesRule[]
    saleEndDisableShift?: boolean
    a11yVariantSelected?: string
    a11yVariantUnSelected?: string
    a11yClickToReadFootnote?: string
    pageType?: string
    plusMinusSymbol?: string
    enableBoldedBrandName?: boolean
    enableMiniPdpFlyoutSupport?: boolean
    enableATCForSingleSkuMiniPdp?: boolean
    observeProducts?: (ref: HTMLElement) => void
    isTireDataExist?: boolean
    fitmentMessageOnProductLevel?: string
    fitmentIcon?: string
    fitmentMessageToggle?: boolean
    fitmentMessageToggleUniversal?: boolean
    fitMessageOnCard?: string
    partialFitMessageOnCard?: string
    confirmFitMessageUniversalOnCard?: string
    fitMessageUniversalOnCard?: string
    vehicleString?: string
    addToCartBtnClick?: AddToCartClickHandler
    showAtcBtnSpinner?: boolean
    clickedATCCardIdx?: number
    addToCartLabel?: string
    searchResultsType?: string
    sponsoredLabel?: string
    addProductItemToWishlist?: (
        event?: React.MouseEvent<Element> | React.KeyboardEvent<HTMLElement>,
        product?: ProductCardType | ProductDataTypeObj,
        isWishlstActive?: boolean,
        idx?: number,
    ) => void
    wishlistItems?: WishlistResponse
    isWishlistToShow?: boolean
    isWishlitEligible?: boolean
    clickedWishlistIdx?: number
    showWishlistBtnSpinner?: boolean
    a11yWishlistIcon?: string
    quickLookLabel?: string
    addProductItemToCompare?: (
        event?: React.MouseEvent<Element> | React.KeyboardEvent<HTMLElement>,
        product?: ProductCardType | ProductDataTypeObj,
        idx?: number,
    ) => void
    compareProductsList?: ProductDataTypeObj[]
    enableComparisonOfProducts?: boolean
    enableComparisonTool?: boolean
    compareProductsLabel?: string
    isBrowseOnlyMode?: boolean
    isWestPriceLazyLoadEnabled?: boolean
    isPriceRangeViewEnabled?: boolean
}

export interface ProductOption {
    descriptor: string
    display: string // Colour/Couleur
    showDisclaimer?: boolean
    values: ProductOptionValue[]
}

export interface HybrisMedia {
    altText: string
    mediaType: string
    isListingThumbnailImage: string
    url: string
}

export interface ProductOptionValue {
    value: string // Red, Blue, Green etc.
    swatchImage?: string
    id: string // "100"
    skuCodes: string[] // ["1872500", "1872504"]
    url?: string // To be removed after swatches change
    // Below attributes are not present in new CDS interface
    quantity: number
    sequence: number
    isLeaf: boolean
    mediaSet?: HybrisMedia[] // images, video, pdf, etc.
    saleEndDate?: string
    promoMessages: PromoMessage[]
    restrictedPromoMessages: PromoMessage[]
    currentPrice: Price
    originalPrice: Price
    displayWasLabel: boolean
    defaultSelect: boolean
    badges: string[]
    priceMessage: PriceMessage[]
    saleCut?: SaleCut
    isOnSale?: boolean
}

export interface CurrentPrice {
    value?: number | null
    maxPrice?: number | null
    minPrice?: number | null
}

export interface OriginalPrice {
    value?: number | null
    maxPrice?: number | null
    minPrice?: number | null
}

export interface ImageColorType {
    color: string
    imageUrl: string
    colorValue: string
    images: string[]
}

export enum descriptorType {
    color = 'COLOR',
}

export enum fitmentTypeCodeValue {
    universalFit = '01_UNIVERSAL_FIT',
    directFit = '02_DIRECT_FIT',
    vehCondFit = '03_VEH_COND_FIT',
    prodVehCondFit = '04_PROD_VEH_COND_FIT',
    partCondFit = '05_PART_COND_FIT',
}

export const enum autoPartSkuFitmentTypes {
    FIT = 'directFit',
    PARTIAL = 'partialFit',
    UNIVERSAL = 'universal',
}

export interface Vehicle {
    vehicleType?: string
    autoAttributes?: AutoAttributes
    selected?: boolean
    baseVehicleId?: string
    vehicleID?: string
    id?: number
    selectedImage?: VehicleImage
    autoImagesList?: VehicleImage[]
    criticalFitment?: CriticalFitment[]
    lang?: string
    option?: string
    fullTireSize?: string
    year?: string
    make?: string
    model?: string
    [key: string]: unknown
}

export interface AutoAttributes {
    year?: string
    make?: string
    model?: string
    body?: string
    option?: string
    subModel?: string
    bodyStyleConfigId?: string
    driveTypeId?: string
    engineConfigId?: string
    boltPattern?: string
}

export interface VehicleImage {
    colorId: string
    colorName: string
    fileName: string
    hexNumber: string
    url: string
}

export interface FitmentNote {
    sku?: string | null
    title?: string | null
    note: string
}

export interface CriticalFitment {
    autoPartsFit?: string
    sku: string
    position: ShortFitmentNote[] | null // Obtained from positionId lookup
    usage: ShortFitmentNote[] | null // Obtained from positionId (optional) & partTerminologyGroupId lookup (required)
    fitmentNotes: FitmentNote[] | null // Obtained from PIESACESApplicationNotes lookup
    suggestedQty: number | null
    tireWheelFitment: FitmentNote[] | FitmentNote | null
    isAvailable?: boolean | null
}

export interface ShortFitmentNote {
    note: string
}

export interface ProductCardWishlistProps {
    isWishlistEligible: boolean
    a11yWishlistIcon: string
    showWishlistBtnSpinner: boolean
    product: ProductCardType
    isWishlistItemActive: boolean
    idx: number
    isWishlistToShow?: boolean
    addProductItemToWishlist?: (
        event?: React.MouseEvent<Element> | React.KeyboardEvent<HTMLElement>,
        product?: ProductCardType | ProductDataTypeObj,
        isWishlistItemActive?: boolean,
        idx?: number,
    ) => void
}

export interface ProductCardCompareProps {
    addProductItemToCompare?: (
        event?: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLElement>,
        product?: ProductCardType | ProductDataTypeObj,
        idx?: number,
        isCompareProductChecked?: boolean,
    ) => void
    product?: ProductCardType
    idx?: number
    isCompareProductChecked?: boolean
    compareProductsLabel?: string
    showCompareCheckbox?: boolean
}

export enum roadRateSpecification {
    ROAD_RATED_ICE_TRACTION = 'ROAD_RATED_ICE_TRACTION',
    ROAD_RATED_SNOW_TRACTION = 'ROAD_RATED_SNOW_TRACTION',
    ROAD_RATED_WET_TRACTION = 'ROAD_RATED_WET_TRACTION',
    ROAD_RATED_DRY_TRACTION = 'ROAD_RATED_DRY_TRACTION',
    ROAD_RATED_COMFORT_ROAD_NOISE = 'ROAD_RATED_COMFORT_ROAD_NOISE',
    ROAD_RATED_FUEL_ECONOMY = 'ROAD_RATED_FUEL_ECONOMY',
    ROAD_RATED_OFF_ROAD_MUD_WET = 'ROAD_RATED_OFF_ROAD_MUD_WET',
    ROAD_RATED_OFF_ROAD_DRY = 'ROAD_RATED_OFF_ROAD_DRY',
    ROAD_RATED_OFF_ROAD_AGGREGATE = 'ROAD_RATED_OFF_ROAD_AGGREGATE',
}
