import { tostErrorTypeConst } from '../../redux/types/checkout/checkout.actionTypes.constant'
import { AddressAutoSuggestions, AutoCompleteSuggestions } from '../../redux/models/checkout.interface'
// eslint-disable-next-line no-warning-comments
// TODO: This is not a service need to moved outside of this folder.
/**
 * returns itemId for CanadaPost validation.
 * @param  {AddressAutoSuggestions} addressData
 * @return {string}
 */
export const getRetrieveItemId = (addressData: AddressAutoSuggestions): string => {
    const itemId = addressData.Items.find(
        (elem: AutoCompleteSuggestions) => elem.Next?.toString().toLowerCase() === tostErrorTypeConst.retrieve,
    )
    return itemId?.Id ?? ''
}
