import React from 'react'
import AnimatedNumbers from 'react-animated-numbers'
import { PREFIX } from '../components/config'
import { libUtils } from '..'
import { multiLanguageLabel } from '../globalConstants/global.constant'
import { getCaLocale } from '../utils/getCaLocale'

/**
 * function to return total savings amount
 * @param {number} totalSavings totalSavings
 * @returns {JSX.Element} JSX.Element
 */
export const useAnimatedNumber = (totalSavings: number | null): JSX.Element => {
    return (
        <>
            <span className="sr-only">
                {libUtils.getLanguage() === multiLanguageLabel.englishLangLabel.toLowerCase()
                    ? `$${totalSavings}`
                    : `${totalSavings}$`}
            </span>
            {libUtils.getLanguage() === multiLanguageLabel.englishLangLabel.toLowerCase() && (
                <span aria-hidden="true" className={`${PREFIX}-currency`}>
                    $
                </span>
            )}
            <span aria-hidden="true">
                <AnimatedNumbers
                    className={`${PREFIX}-amount`}
                    animateToNumber={totalSavings}
                    locale={getCaLocale()}
                    includeComma={true}
                />
            </span>
            {libUtils.getLanguage() === multiLanguageLabel.frenchLangLabel.toLowerCase() && (
                <span aria-hidden="true" className={`${PREFIX}-currency`}>
                    $
                </span>
            )}
        </>
    )
}
