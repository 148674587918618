import { isArrayNotEmpty, areAllParamsValid } from '@nl/lib'
import analyticsLayer from '../../utils/analyticsService'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { CartAnalytics, CartOrderEntries, eEntryType } from '../../redux/models/cart.interface'
import getPageType from '../../utils/getPageType'
import { getProvider } from '../providers/index'
import { ProductHelper } from '../helpers/product.helper'
import { getPdpType } from '../../components/BuyBox/BuyBox.helper'
import { getDaysDifference } from '../../utils/getDaysDifference.utils'
import { checkValueOrNull, changeNullToUndefined } from '../../utils/checkNotNullAndUndefined'
import { AddToCartData, AnalyticsEntryData, ProductCartDataForAnalytics } from '../analytics.type'

/**
 * this function gives value for guranteedFitment key
 * @param {boolean} fitted
 * @param {boolean} shopWithVehicle
 * @returns {string} returns value for guranteedFitment key
 */
const isGuaranteedFitment = (fitted: boolean, shopWithVehicle: boolean): string => {
    return fitted ? `${String(shopWithVehicle)}` : 'not applicable'
}

/**
 * this function extracts analytics data for entry object
 * @template T - the type of the ProductCartDataForAnalytics value
 * @param {T | CartOrderEntries} entry
 * @returns { AnalyticsEntryData}
 */
const extractAnalyticsData = <T extends ProductCartDataForAnalytics>(
    entry: T | CartOrderEntries,
): AnalyticsEntryData => {
    const isService = entry?.entryType === eEntryType.SERVICE_STANDALONE
    const isFitted = (entry?.vehicleInformation && entry?.fitmentTypeCode) !== null
    const merchCategories = entry?.merchCategories || []
    const feeMessage = isArrayNotEmpty(entry?.feeMessages) ? entry?.feeMessages?.[0] : {}
    const fee = ProductHelper.transformFee(feeMessage)
    const rebate = ProductHelper.transformRebate(entry?.rebate)

    return {
        isService,
        isFitted,
        merchCategories,
        fee,
        rebate,
    }
}

/**
 * this function gives one entry of product key for analytics
 * @template T - the type of the ProductCartDataForAnalytics value
 * @param {T} cartData
 * @param {string} list
 * @param {boolean} isMergeCart
 * @param {boolean} financingEligible
 * @param {boolean} financingShown
 * @param {boolean} isAutomotive
 * @param {boolean} shopWithVehicle
 * @param {boolean} isPackagedItem
 * @param {string} flyerDealId
 * @param {string} flyerPublicationId
 * @param {string} fulfillmentType 'sth' 'bopis' 'express'
 * @param {string} atcLocation 'certona'
 * @returns {CartAnalytics} returns product list value
 */
const getProductList = <T extends ProductCartDataForAnalytics>(
    cartData: T,
    list: string,
    isMergeCart: boolean,
    financingEligible?: boolean,
    financingShown?: boolean,
    isAutomotive?: boolean,
    shopWithVehicle?: boolean,
    isPackagedItem?: boolean,
    // eslint-disable-next-line default-param-last
    flyerDealId: string | null = '',
    // eslint-disable-next-line default-param-last
    flyerPublicationId: string | null = '',
    fulfillmentType?: string,
    atcLocation?: string,
    // eslint-disable-next-line max-params
): CartAnalytics => {
    const entry = cartData?.entry || cartData
    const quantityAdded = cartData?.quantityAdded
    const { isService, isFitted, merchCategories, fee, rebate } = extractAnalyticsData(entry)
    const { variant1, variant2, variant3 } = ProductHelper.transFormVariantData(entry)
    const [level1, level2, level3, level4, level5] = merchCategories
    return {
        availability: ProductHelper.transformAvailability(entry),
        corporateStatus: entry?.productStatus?.toLowerCase(),
        badge: entry?.badges,
        brand: entry?.brand?.label,
        category: entry?.merchCategories?.join('/'),
        level1,
        level2,
        level3,
        level4,
        level5,
        fitted: String(isFitted) as unknown as boolean,
        package: String(entry?.isPackagedItem || !!isPackagedItem),
        fullfillmentDaysAway: getDaysDifference(entry?.fulfillment?.sourceTypes?.[0]?.etaEarliest as string),
        fee: changeNullToUndefined(fee as string, isMergeCart),
        financingEligible: String(areAllParamsValid(financingEligible as boolean, financingShown as boolean)),
        financingShown: String(financingShown),
        installationAvailable: ProductHelper.transformInstallation(entry),
        inventory: String(entry?.fulfillment?.stockItemAvailability?.storeQuantity),
        list,
        name: entry?.name as string,
        pcode: entry?.baseProduct,
        price: ProductHelper.transformPrice(entry),
        rebate: changeNullToUndefined(rebate as string, isMergeCart),
        quantity: quantityAdded || entry?.quantity,
        rating: String(entry?.Rating),
        numberOfReviews: String(entry?.numberOfReviews),
        shipFrom: entry?.shipFrom,
        sku: entry?.code as string,
        variant1,
        variant2,
        variant3,
        fulfillmentOptionAvailable: entry?.fulfillmentOptionAvailable,
        bulk: 'false' as unknown as boolean,
        vehicle: {
            staggeredFitment: String(!!entry?.staggeredGroup),
            type: entry?.vehicleInformation?.type,
            make: entry?.vehicleInformation?.make,
            model: entry?.vehicleInformation?.model,
            year: entry?.vehicleInformation?.year,
            body: entry?.vehicleInformation?.body,
            option: entry?.vehicleInformation?.options,
        },
        guaranteedFitment: isGuaranteedFitment(isFitted, shopWithVehicle as boolean),
        pdpType: getPdpType(isAutomotive as boolean, isService),
        isExpress: String(entry?.fulfillment?.isEligibleForExpressDelivery),
        // if present the add them to the final object
        ...(flyerDealId && { flyerDealId }),
        ...(flyerPublicationId && { flyerPublicationId }),
        ...(fulfillmentType && { fulfillment: fulfillmentType }),
        ...(atcLocation && { atc_location: atcLocation }),
    }
}
/**
 * Analytics block to be sent when service is added to cart successfully
 * @template T - the type of the AddToCartData value
 * @param {T} cartData - the data needs to be sent to analytics layer
 * @param {string} cartGUID - cart id
 * @param {string} location - location
 * @param {string} list - list
 * @param {boolean} financingEligible - true/ false
 * @param {boolean} financingShown - true/ false
 * @param {boolean} isAutomotive - true/ false
 * @param {boolean} shopWithVehicle - true/ false
 * @param {boolean} isPackagedItem - true/ false
 * @param {number} shippingFee
 * @param {string} fulfillmentType - 'sth' 'bopis' 'express'
 * @param {string} atcLocation 'certona'
 */

const { eventParameters } = analyticsAttributes
export const addToCartAnalytics = <T extends AddToCartData>(
    cartData: T,
    cartGUID: string | null,
    // eslint-disable-next-line default-param-last
    location = eventParameters.location.servicesLocation,
    // eslint-disable-next-line default-param-last
    list = eventParameters.labels.serviceCartList,
    financingEligible?: boolean,
    financingShown?: boolean,
    isAutomotive?: boolean,
    shopWithVehicle?: boolean,
    isPackagedItem?: boolean,
    shippingFee?: number,
    fulfillmentType?: string,
    atcLocation?: string,
    // eslint-disable-next-line max-params
): void => {
    const productList: CartAnalytics[] = []
    const urlParams = new URLSearchParams(window.location.search)
    const flyerDealId = urlParams.get('flyerDealId')
    const flyerPublicationId = urlParams.get('flyerPublicationId')
    const ifHasFlyerParams = !!flyerDealId && !!flyerPublicationId
    const listValue = ifHasFlyerParams ? analyticsAttributes.list.digitalFlyer : list
    const mergeCartAnalytics = eventParameters.location.mergeCart
    const isMergeCart = mergeCartAnalytics === list
    const shippingCost = checkValueOrNull(shippingFee?.toString())
    if (Array.isArray(cartData) || isArrayNotEmpty(cartData)) {
        const analyticsCartData = (
            isArrayNotEmpty(cartData) ? cartData : Object.keys(cartData)
        ) as ProductCartDataForAnalytics[]

        analyticsCartData.forEach(singleCartData => {
            productList.push(
                getProductList(
                    singleCartData,
                    listValue,
                    isMergeCart,
                    financingEligible,
                    financingShown,
                    isAutomotive,
                    shopWithVehicle,
                    isPackagedItem,
                    flyerDealId,
                    flyerPublicationId,
                    fulfillmentType,
                    atcLocation,
                ),
            )
        })
    } else {
        productList.push(
            getProductList(
                cartData,
                listValue,
                isMergeCart,
                financingEligible,
                financingShown,
                isAutomotive,
                shopWithVehicle,
                isPackagedItem,
                flyerDealId,
                flyerPublicationId,
                fulfillmentType,
                atcLocation,
            ),
        )
    }
    analyticsLayer.pushData({
        event: analyticsAttributes.event.addToCart,
        cart: {
            id: cartGUID,
            shippingCost: changeNullToUndefined(shippingCost, isMergeCart),
        },
        eventParameters: {
            location: location,
        },

        product: productList,
    })
}

/**
 * Analytics block to be sent when warning modal appears
 * @param {string} modalTitle - modal title
 *
 */

export const addToCartAnalyticsError = (modalTitle: string, eventName?: string, actionName?: string): void => {
    const { event } = analyticsAttributes

    getProvider().push({
        event: eventName || event.warning,
        eventParameters: {
            action: actionName || eventParameters.action.viewWarningMessage,
            category: getPageType() || undefined,
            label: modalTitle,
            value: '',
        },
    })
}
