import { useMemo } from 'react'
import { PaymentFlowType } from '../components/config'

/**
 * BA stands for Billing Address
 * Below hook is used to check if the flow is Payment Billing Address flow
 * @param {string} flowType
 * @returns {boolean} isBillingAddressFlow
 */
export const useIsPaymentBAFlow = (flowType: string): boolean => {
    return useMemo(() => {
        return flowType === PaymentFlowType.BILLING_ADDRESS
    }, [flowType])
}

/**
 * PO stands for Place Order
 * Below hook is used to check if the flow is Payment Place Order flow
 * @param {string} flowType
 * @returns {boolean} isPlaceOrderFlow
 */
export const useIsPaymentPOFlow = (flowType: string): boolean => {
    return useMemo((): boolean => {
        return flowType === PaymentFlowType.PLACE_ORDER
    }, [flowType])
}

/**
 * Below hook is used to check if the flow is Payment Normal flow
 * @param {string} flowType
 * @returns {boolean} isNormalFlow
 */
export const useIsPaymentNormalFlow = (flowType: string): boolean => {
    return useMemo((): boolean => {
        return flowType === PaymentFlowType.NORMAL
    }, [flowType])
}

/**
 * Below hook is used to check if the flow is not Payment Billing Address flow
 * @param {string} flowType
 * @returns {boolean} isNotBillingAddressFlow
 */
export const useIsNotPaymentBAFlow = (flowType: string): boolean => {
    return useMemo(() => {
        return flowType !== PaymentFlowType.BILLING_ADDRESS
    }, [flowType])
}
