/* eslint-disable max-lines */
import React, { useState, useEffect, useRef, useMemo } from 'react'

import { pageTypes, PREFIX, previousElementName } from '../config'
import {
    Inventory,
    StoreSelectorModalProps,
    StoreWithAvailability,
    StoreAddress,
    StoreTime,
    Filter,
} from './StoreSelectorModal.type'
import Icon from '../Icon'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'
import { getNextDayStoreTimings, getStoreTimings } from './StoreSelectorHelper'
import Tag from '../Tag/Tag'
import { BREAKPOINTS } from '../../components/config'
import {
    replaceStrWithDynamicVal,
    generateGoogleMapOnAddress,
    getFormattedPhoneNumber,
    formatedPhoneNumberAccessibility,
} from '../../utils'
import Dropdown from '../Dropdown'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { titleDataTetsId, unavailableIndex, allItemsInStock } from './StoreSelectorModal.constant'
// eslint-disable-next-line sonar/no-built-in-override
import Map from '../Map/Map'
import StoreDetailsModal from '../StoreDetailsModal/StoreDetailsModal'
import GoogleAutocomplete from '../GoogleAutocomplete'
import Button from '../Button'
import { magicNumber } from '../../utils/magicNumber'
import { ProductSku } from '../VariantsWrapper/VariantsWrapper.type'
import Switch from '../Switch'
import { MapPosition, MapProps } from '../Map/Map.type'
import { isArrayEmpty } from '../../utils/isArrayEmpty'
import Spinner from '../Spinner'
import { checkNotNullAndUndefined } from '../../utils/checkNotNullAndUndefined'
import { disableFocusLock, enableFocusLock } from '../../utils/focusLock'
import ToggleTabs from '../ToggleTabs/ToggleTabs'
import Cart from '../Cart'
import { afternoonHours } from '@nl/app/src/components/Header/HeaderWrapper/HeaderWrapper.constants'
/**
 * StoreSelectorModal component
 * @param {StoreSelectorModalProps} props
 * @returns {JSX.Element} returns FlyoutModalComponent,CartServicesComponent,Toast,Icon
 */
const StoreSelectorModal: React.FC<StoreSelectorModalProps> = props => {
    const {
        path,
        closeFlyout,
        closeBtnLabel = '',
        storeList,
        allStores,
        isShowAvailability,
        closeLabel,
        closesAtLabel,
        openLabel,
        openingAtLabel,
        title,
        wishlistCartOnlyTitle,
        pdpOnlyTitle,
        headerOnlyTitle,
        searchInputPH,
        a11ySearchIconAriaLabel,
        a11yCurrentLocationCTAAriaLabel,
        closeIconLabel,
        mapViewLabel,
        listViewLabel,
        preferredStoreLabel,
        setPreferredStoreCTALabel,
        storeDetailsCTALabel,
        inStockLabel,
        outOfStockLabel,
        showFilter,
        filterByLabel,
        setPreferredStore,
        preferredStoreId,
        setPreferredStoreOnMapIconClicked,
        indentPreferredStart,
        showClose,
        isInMiniPDPFlyout,
        backBtnLabel,
        analyticMiniPDPLocation,
        setStoreDisplay,
        setCurrentPage,
        searchInputErrorMsg,
        storeFilters,
        selectedFilter,
        filterStoreList,
        addressMaxLength,
        onStoreViewChangesAnalytics,
        newMapProps,
        setHasMoreStores,
        setMarkerClickHandler,
        markerClickHandler,
        pageType,
        searchStoreAnalyticsHandler,
        fetchNearbyStoreListOnSearch,
        onlineOrdersNotAcceptedMsg,
        sku,
        renderAutoAppointmentButton,
        baseStoreId,
        isStaggeredProduct,
        staggeredSkus,
        isHeaderStoreLocator,
        seeMoreCTAURL,
        seeMoreCTALabel,
        viewMoreAnalytics,
        noResultForPostalCodeTitle = '',
        noResultsForInStoreInventoryDescription,
        noResultForPostalCodeDescription = '',
        getStoreSelectorAnalyticsLocation,
        forcePreferredStoreMsg,
        isStoresApiFailed,
        storeErrorResponse,
        maxRadiusErrorCode,
        isShowEmptyStoresToggle,
        showInStockFilterLabel,
        a11yTelephoneAriaLabel,
        inStockOnlineLabel,
        inStockDCOnlyEnabled,
        a11ySuggestionResultAvailable,
        a11yMapLocateMeButtonLabel,
        isOpen,
        a11yListViewAriaLabel,
        a11yMapViewAriaLabel,
        enableInStockOnlineInFlyout,
        a11yStoresAvailableLabel,
        noResultFoundTitle,
        noResultForCartInStoreInventoryTitle,
        noResultForCartInStoreInventoryDescription,
        noResultForCartTitle,
        noResultForCartDescription,
        fetchNearbyStoresWithAvailability,
        allItemsInStockMsg,
        isStoreLocator,
    } = props

    const componentClassName = `${PREFIX}-store-selector-flyout`
    const storeClassName = `${componentClassName}__stores-list`
    const toggleButtonClassName = !showFilter ? `${PREFIX}-toggle-tab-btn` : ''
    const isMobile = window.innerWidth <= BREAKPOINTS.mobileMaxWidth
    const isTablet = window.innerWidth > BREAKPOINTS.mobileMaxWidth && window.innerWidth <= BREAKPOINTS.tabletMaxWidth
    const [isMapView, setIsMapView] = useState(false)
    const selectedstoreClassName = `${PREFIX}-store-selector-flyout__stores-list--selected`
    const storesRefArray = useRef([])
    const preferredStore = storeList?.find((item: StoreWithAvailability) => item?.id === preferredStoreId)
    const isTabletOnStoreLocatorPage = () => isTablet && pageType === pageTypes.storeLocator
    const isTabletNotOnStoreLocatorPage = () => isTablet && pageType !== pageTypes.storeLocator
    const [showInStock, setShowInStock] = useState(false)
    const storesWithInventoryOrPreferred: StoreWithAvailability[] = []
    const storesLocationWithInventoryOrPreferred: MapPosition[] = []
    const storesIdsWithInventoryOrPreferred: number[] = []
    const allStoresLocations: MapPosition[] = newMapProps.locations
    const storeSelectorModalRef = useRef(null)
    const focusLockEventRef = useRef<EventListener>()
    const storeCloseByRef = useRef<HTMLDivElement>(null)
    const isPdpPageType = pageType === pageTypes.pdpPage
    const isCartPage = pageType === pageTypes.shoppingCart
    const inFlyoutLinkTarget = useMemo(() => (isInMiniPDPFlyout ? '_blank' : '_self'), [isInMiniPDPFlyout])
    const visibleTitle = useMemo(() => {
        if (isHeaderStoreLocator || isStoresApiFailed) {
            return headerOnlyTitle
        } else if (isInMiniPDPFlyout || isPdpPageType) {
            return pdpOnlyTitle
        } else if (pageType === pageTypes.wishlist || pageType === pageTypes.shoppingCart) {
            return wishlistCartOnlyTitle
        } else {
            return title
        }
    }, [
        isInMiniPDPFlyout,
        isHeaderStoreLocator,
        isStoresApiFailed,
        isPdpPageType,
        pageType,
        headerOnlyTitle,
        pdpOnlyTitle,
        wishlistCartOnlyTitle,
        title,
    ])

    /**
     * Function to return view store details link location
     * @returns {string}
     */
    const viewStoreDetailsLinkLocation = (): string => {
        // This condition can be removed if data-pageType for store locator page is changed to correct value
        if (pageType === pageTypes.storeLocator) return pageType
        else return getStoreSelectorAnalyticsLocation()
    }
    /**
     * Function to set selected store
     */
    useEffect(() => {
        if (!isMobile && pageType) {
            storeList.forEach((store: StoreWithAvailability) => {
                store.isSelected = markerClickHandler?.id === store.id
            })

            const selStore =
                isArrayNotEmpty(storesRefArray.current) &&
                (storesRefArray.current.find(
                    (ref: HTMLLIElement) => ref?.id === markerClickHandler?.id?.toString(),
                ) as HTMLLIElement)
            selStore && selStore?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
    }, [markerClickHandler, isMobile, pageType, storeList])

    /**
     * useEffect for focusLock Event
     */
    useEffect(() => {
        if (isOpen) {
            focusLockEventRef.current = enableFocusLock(storeSelectorModalRef)
        } else {
            disableFocusLock(focusLockEventRef.current)
        }
    }, [isOpen])

    /**
     * function to render next set of store list
     */
    const loadMoreStores = (): void => {
        const storeListParent = document.querySelector(`.${PREFIX}-store-selector-flyout__body`)
        const storeListElement = storeListParent?.firstElementChild

        if (
            storeListElement?.lastElementChild?.getBoundingClientRect().y < storeListParent?.clientHeight &&
            !isMapView
        ) {
            setCurrentPage(storeListElement.children.length)
            setHasMoreStores(true)
        }
    }

    /**
     * Onselect Handler for dropdown.
     * @param {object} selectedValue - Dropdown value.
     * @returns {void}
     */
    const onSelectHandler = (selectedValue: { label: string; id: string }): void => {
        filterStoreList?.(selectedValue.id)
    }

    /**
     * Below use effect to stop background scrolling when the flyout is open
     */
    useEffect(() => {
        if (isHeaderStoreLocator || isStoresApiFailed) {
            const overFlowHiddenClass = 'modal-open-not-scroll'
            document.body.classList.add(overFlowHiddenClass)
            return () => {
                document.body.classList.remove(overFlowHiddenClass)
            }
        }
        return undefined
    }, [isHeaderStoreLocator, isStoresApiFailed])

    /**
     * Below use effect to stop flyout scrolling when the other store flyout on the mini pdp flyout is open
     */
    useEffect(() => {
        if (isInMiniPDPFlyout) {
            const overFlowHiddenClass = 'modal-open-store-selector-not-scroll'
            const miniPDPFlyoutClass = `${PREFIX}-store-selector-flyout__container`
            const miniPDPFlyout = document.querySelector(`.${miniPDPFlyoutClass}`)?.parentElement
            miniPDPFlyout?.classList.add(overFlowHiddenClass)
            return () => {
                miniPDPFlyout?.classList.remove(overFlowHiddenClass)
            }
        }
        return undefined
    }, [isInMiniPDPFlyout])

    /**
     * return item with selected property
     * @return {Filter[]}
     */

    const setFilters = (): Filter[] => {
        if (storeFilters) {
            return storeFilters.map((filter: Filter) => {
                if (filter.id === selectedFilter) {
                    filter.selected = true
                } else {
                    filter.selected = false
                }
                return filter
            })
        } else {
            return []
        }
    }

    /**
     * const for dropdownProps
     */
    const dropdownProps = {
        dropdownTitle: filterByLabel,
        dropdownList: setFilters(),
        dropdownIcon: 'ct-chevron-down',
        size: 'default',
        dropdownId: 'Filter',
        selectedItem: onSelectHandler,
        filterTitle: filterByLabel,
        isFilter: true,
        type: 'primary',
    }

    // Below code is for handling the accessibility when clicked on close button
    const modalClassName = `${PREFIX}-flyout-modal-overlay`
    const previousElement: HTMLElement = document.querySelector(`button[${previousElementName}`)

    /**
     * function to handle Accessibility needs
     */

    const accessibilityHandler = (): void => {
        if (previousElement && previousElement.hasAttribute(previousElementName)) {
            modalAccessibilityHandler({ modalOpen: false, modalClassName })

            previousElement.focus()
            previousElement.removeAttribute(previousElementName)
        }
    }

    /**
     * function to handle modal accessibility close handler
     */
    const modifiedCloseFlyout = (): void => {
        closeFlyout?.()
        accessibilityHandler()
    }

    /**
     * redirect User to google map of click of store address
     * @param {StoreAddress} address store address
     * @returns {string}
     */
    const redirectToGoogleMaps = (address: StoreAddress): string => {
        return generateGoogleMapOnAddress(`${address.line1 ?? ''} ${address.line2 ?? ''},${address.postalCode ?? ''}`)
    }

    /**
     * function to handle different address formats
     * @param {string} line1
     * @param {string} line2
     * @returns {string} returns address string
     */
    const formatAddress = (line1: string, line2: string): string => {
        const comma = isHeaderStoreLocator || isStoresApiFailed ? ',' : ''
        return `${line1 ?? ''} ${line2 ?? ''}${comma}`
    }

    /**
     * Switches Store's List view
     */

    const switchStoreViewlist = (): void => {
        setIsMapView(false)
        const selectedViewLabel = listViewLabel
        setStoreDisplay?.(selectedViewLabel)
        onStoreViewChangesAnalytics?.(isMapView)
        const updatedClickHandler = {
            ...markerClickHandler,
            isSelected: false,
            selectedIndex: null,
        }
        markerClickHandler.isSelected = false
        setMarkerClickHandler(updatedClickHandler)
    }

    /**
     * Switches Store's Map view
     */

    const switchStoreViewMap = (): void => {
        setIsMapView(true)
        const selectedViewLabel = mapViewLabel
        setStoreDisplay?.(selectedViewLabel)
        onStoreViewChangesAnalytics?.(isMapView)
        const updatedClickHandler = {
            ...markerClickHandler,
            isSelected: false,
            selectedIndex: null,
        }
        markerClickHandler.isSelected = false
        setMarkerClickHandler(updatedClickHandler)
    }

    /**
     * Renders list and map button
     * @return {JSX.Element} returns jsx list and map button element
     */

    const renderListAndMapComponent = (): JSX.Element => {
        return (
            <ToggleTabs
                firstBtnLabel={listViewLabel}
                secondBtnLabel={mapViewLabel}
                firstBtnAriaLabel={a11yListViewAriaLabel}
                secondBtnAriaLabel={a11yMapViewAriaLabel}
                onFirstBtnClick={switchStoreViewlist}
                onSecondBtnClick={switchStoreViewMap}
                firstBtnIconType={'ct-list-view-dark'}
                secondBtnIconType={'ct-map-marker-black'}
                showIcon={!isMobile}
            />
        )
    }

    /**
     * Renders Inventory Stock Values
     * @param {StoreWithAvailability} store Store Details
     * @param {Number} inStockQty Stock Quantity
     * @returns {JSX.Element} returns Stock Quantity or Out of Stock
     */
    const renderInventoryStock = (store: StoreWithAvailability, inStockQty: number): JSX.Element => {
        const dcQuantity = getDCQuantity(store, sku)
        const isEnoughQty = dcQuantity && dcQuantity > magicNumber.ZERO
        const isInStockOnline = (inStockDCOnlyEnabled && isEnoughQty) || (enableInStockOnlineInFlyout && isEnoughQty)
        const bulletType =
            inStockQty > magicNumber.ZERO || isInStockOnline
                ? 'availability-bullet--in-stock'
                : 'availability-bullet--out-of-stock'

        const stockLabel = isInStockOnline ? inStockOnlineLabel : outOfStockLabel
        const label = inStockQty > magicNumber.ZERO ? replaceStrWithDynamicVal(inStockLabel, inStockQty) : stockLabel

        return (
            <Tag type="availability-bullet" subType={bulletType}>
                {label}
            </Tag>
        )
    }

    /**
     * Renders Indented Preferred Star
     * @param {number} index Store index
     * @return {JSX.Element} returns store index
     */

    const renderIndentedText = (store: StoreWithAvailability, index: number): JSX.Element => {
        return !isMobile ? (
            <div className={`${storeClassName}__item__number`}>
                {preferredStore ? (
                    // eslint-disable-next-line sonar/no-nested-conditional
                    isPreferredStore(store) ? (
                        <Icon type="ct-star" size="md" path={path} />
                    ) : (
                        `${index}.`
                    )
                ) : (
                    `${index + magicNumber.ONE}.`
                )}
            </div>
        ) : null
    }

    /**
     * Renders UnIndented Preferred Star
     * @param {StoreWithAvailability} store Store Details
     * @param {number} index Store index
     * @returns {JSX.Element} returns store index
     */
    const renderUnIndentedPreferredStar = (store: StoreWithAvailability, index: number): JSX.Element => {
        return (
            isPreferredStore(store) && (
                <div className={`${storeClassName}__item__preferred-store-label`}>
                    {!index && (
                        <>
                            {(!indentPreferredStart || isMobile) && <Icon type="ct-star" size="md" path={path} />}
                            {preferredStoreLabel}
                        </>
                    )}
                </div>
            )
        )
    }

    /**
     * close store selected from map
     */
    const closeStoreDetailsModal = (): void => {
        const updatedClickHandler = {
            ...markerClickHandler,
            isSelected: false,
            selectedIndex: null,
        }
        setMarkerClickHandler(updatedClickHandler)
    }

    /**
     * Renders UnIndented Preferred Star
     * @param {StoreWithAvailability} store Store Details
     * @param {number} index Store index
     * @returns {JSX.Element} returns store index
     */
    const renderUnIndentedPreferredStarMapView = (store: StoreWithAvailability, index: number): JSX.Element => {
        const showPreferedStoreLabel = isPreferredStore(store) && index > unavailableIndex
        return (
            <div className={`${storeClassName}__item__preferred-store-map-view`}>
                {showPreferedStoreLabel && (
                    <>
                        {(!indentPreferredStart || isMobile) && <Icon type="ct-star" size="md" path={path} />}
                        {preferredStoreLabel}
                    </>
                )}
                {markerClickHandler.isSelected && (
                    <button
                        className={`${componentClassName}__close-btn-map-view`}
                        onClick={closeStoreDetailsModal}
                        aria-label={closeIconLabel}
                        title={closeBtnLabel}>
                        <Icon type="ct-close" size="lg" path={path} />
                    </button>
                )}
            </div>
        )
    }

    /**
     * sets preferred store from map view
     * @param {React.MouseEvent} event
     * @param {StoreWithAvailability} store Store Details
     * @param {boolean} isClosed
     */
    const setPreferredStoreButton = (
        event: React.MouseEvent,
        store: StoreWithAvailability,
        isClosed: boolean,
    ): void => {
        event.stopPropagation()
        event.preventDefault()

        setPreferredStore(store, isClosed)
        accessibilityHandler()

        const currentStore = document.querySelector(`.${selectedstoreClassName}`)
        if (currentStore) currentStore.classList.remove(selectedstoreClassName)
        document.getElementById(store.id.toString()).classList.add(selectedstoreClassName)
    }

    /**
     * sets preferred store from list view using index
     * @param {StoreWithAvailability} store
     * @param {number} index
     * @return {JSX.Element} returns store Name store locator
     */

    const renderStoreName = (store: StoreWithAvailability, index: number): JSX.Element => {
        return !isHeaderStoreLocator && !checkNotNullAndUndefined(showFilter) ? (
            <button
                onClick={() => storeDetailClickHandler(store)}
                className={`${storeClassName}__item__name main-store-link main-link-${index}`}>
                {store.name}
            </button>
        ) : (
            <h3 className={`${storeClassName}__item__name`} id={`store-${index}`}>
                {store.name}
            </h3>
        )
    }

    /**
     * Renders individul store's details
     * @param {StoreWithAvailabilit} store List of all near by store
     * @param {string} timeText store open/close time
     * @param {StoreAddress} address store address details
     * @param {boolean} isClosed store status
     * @param {string} closeOrOpenTime store time
     * @param {number} index
     * @returns {JSX.Element} returns nearby store component text
     */
    const renderIndividualStoreDetails = (
        store: StoreWithAvailability,
        timeText: string,
        address: StoreAddress,
        isClosed: boolean,
        closeOrOpenTime: string,
        index: number,
    ): JSX.Element => {
        return (
            <div className={`${storeClassName}__item__label card-${index}`} data-component="card">
                {!!closeOrOpenTime && (
                    <div className={`${storeClassName}__item__time`}>
                        <span>{isClosed ? closeLabel : openLabel}</span> . {timeText}
                    </div>
                )}
                <div className={`${storeClassName}__item__address`}>
                    <a
                        className={`${storeClassName}__item__address__line`}
                        href={redirectToGoogleMaps(address)}
                        target={inFlyoutLinkTarget}
                        data-link-value={address.line1}>
                        <span className="sr-only" aria-labelledby={`store-${index}`}></span>
                        {formatAddress(address.line1, address.line2)}
                        {!isHeaderStoreLocator && !isStoresApiFailed && <br />}
                        {address.town}, {address.region?.name}, {address.postalCode}
                    </a>
                </div>
                {address.phone && (
                    <a
                        href={`tel://${address.phone}`}
                        aria-label={`${a11yTelephoneAriaLabel} ${formatedPhoneNumberAccessibility(address.phone)}`}
                        className={`${storeClassName}__item__phone-no`}
                        target={inFlyoutLinkTarget}
                        data-link-value={address.phone}>
                        {getFormattedPhoneNumber(address?.phone)}
                    </a>
                )}
                <a
                    href={`${store.url}`}
                    className={`${storeClassName}__item__view-stores ${storeClassName}__item__view-stores-link`}
                    data-link-value={storeDetailsCTALabel}
                    dap-wac-link="true"
                    dap-wac-loc={viewStoreDetailsLinkLocation()}
                    dap-wac-value="View Store Details"
                    target={inFlyoutLinkTarget}
                    aria-label={`${storeDetailsCTALabel}, ${store.name}`}>
                    {storeDetailsCTALabel}
                </a>
            </div>
        )
    }

    /**
     * Renders store times
     * @param {boolean} closed Store is closed
     * @param {boolean} closingTime Store Closing time
     * @param {boolean} openingTime Store Opening time
     * @returns {string} returns Open or Close label with time
     */
    const showStoreTime = (closed: boolean, closingTime: StoreTime, openingTime: StoreTime): string => {
        return closed
            ? replaceStrWithDynamicVal(openingAtLabel, openingTime?.formattedHour)
            : replaceStrWithDynamicVal(closesAtLabel, closingTime?.formattedHour)
    }

    /**
     * Renders if store is preferd store
     * @param {StoreWithAvailability} store Store details
     * @returns {boolean} returns true if its preferred store
     */
    const isPreferredStore = (store: StoreWithAvailability): boolean => {
        return Boolean(preferredStoreId === store.id)
    }

    /**
     * Renders store details
     * @param {StoreWithAvailabilit} store List of all near by store
     * @param {number} index store index
     * @param {string} timeText store open/close time
     * @param {StoreAddress} address store address details
     * @param {number} inStockQty Stock quantity
     * @param {boolean} isClosed store status
     * @param {string} closeOrOpenTime store time
     * @returns {JSX.Element} returns nearby store component text
     */
    const renderStoreDetails = (
        store: StoreWithAvailability,
        index: number,
        timeText: string,
        address: StoreAddress,
        inStockQty: number,
        isClosed: boolean,
        closeOrOpenTime: string,
    ): JSX.Element => {
        const showPreferredStoreButton = baseStoreId ? baseStoreId === store.baseStoreId : true
        const showPreferedStoreLabel = isPreferredStore(store) && indentPreferredStart && !isMobile && !index
        return (
            <div className={`${storeClassName}__item__content`}>
                <>
                    {showPreferedStoreLabel && (
                        <div
                            className={`${storeClassName}__item__preferred-store-label`}
                            role="alert"
                            aria-live="assertive">
                            {`${preferredStoreLabel}`}
                        </div>
                    )}
                    <div className={`${storeClassName}__availability`}>
                        {renderStoreName(store, index)}
                        {isShowAvailability && renderInventoryStock(store, inStockQty)}
                    </div>
                    <div className={`${storeClassName}__store-details`}>
                        {renderAllItemInStock(store)}
                        {renderIndividualStoreDetails(store, timeText, address, isClosed, closeOrOpenTime, index)}
                    </div>
                    {!store.onlineOrdering && (
                        <div className={`${componentClassName}__store-status`}>
                            <div>
                                <Icon type="ct-notification-caution-stroked" size="md" />
                            </div>
                            <span className={`${componentClassName}__store-status-message`}>
                                {onlineOrdersNotAcceptedMsg}
                            </span>
                        </div>
                    )}
                    {isPreferredStore(store)
                        ? renderAutoAppointmentButton?.(store.id as number)
                        : showPreferredStoreButton && (
                              <div className={`${storeClassName}__item__set-preferred-store`}>
                                  <Button
                                      size="mini"
                                      type="primary"
                                      onClick={(e: React.MouseEvent) => setPreferredStoreButton(e, store, isClosed)}>
                                      {setPreferredStoreCTALabel}
                                  </Button>
                              </div>
                          )}
                </>
            </div>
        )
    }

    /**
     * Renders on click of store card
     * @param {StoreWithAvailability} store Store
     */

    const storeDetailClickHandler = (store: StoreWithAvailability) => {
        store.isSelected = markerClickHandler.isSelected
        store.selectedIndex = markerClickHandler.selectedIndex
        setMarkerClickHandler(store)
    }

    /**
     * Renders store list
     * @param {StoreWithAvailability} store Store
     * @param {number} index Store index
     * @param {string} timeText Store timings
     * @param {StoreAddress} address Store address
     * @param {number} inStockQty Stock quantity
     * @param {boolean} isClosed store status
     * @param {string} closeOrOpenTime store time
     * @returns {JSX.Element}
     */
    const renderStoreListItem = (
        store: StoreWithAvailability,
        index: number,
        timeText: string,
        address: StoreAddress,
        inStockQty: number,
        isClosed: boolean,
        closeOrOpenTime: string,
    ): JSX.Element => {
        const selStoreClass = store.isSelected ? `${PREFIX}-store-selector-flyout__stores-list--selected` : ''
        return (
            <li
                id={store.id.toString()}
                ref={ref => {
                    storesRefArray.current[index] = ref
                }}
                key={store.id}
                className={`${storeClassName}__item ${selStoreClass}`}>
                <div className={`${storeClassName}__item__wrapper`}>
                    {indentPreferredStart && renderIndentedText(store, index)}
                    {isMapView
                        ? renderUnIndentedPreferredStarMapView(store, index)
                        : (!indentPreferredStart || isMobile) && renderUnIndentedPreferredStar(store, index)}
                    {renderStoreDetails(store, index, timeText, address, inStockQty, isClosed, closeOrOpenTime)}
                </div>
            </li>
        )
    }

    /**
     * Takes store object and returns in store inventory available for this product's SKU
     * @param {StoreWithAvailability | undefined} store
     * @param {string | undefined} selectedSku
     * @returns {Inventory | undefined } returns instore inventory available in store passed as param
     */
    const getInventory = (
        store: StoreWithAvailability | undefined,
        selectedSku: string | undefined,
    ): Inventory | undefined => {
        if (isStaggeredProduct) {
            const staggeredSkusList = staggeredSkus?.map((strgSku: ProductSku) => {
                return strgSku.code
            })
            // eslint-disable-next-line consistent-return
            const inventoryStaggeredSkus = store?.inventory?.map((storeInv: Inventory) => {
                if (storeInv?.Quantity) return storeInv.sku
            })
            if (!!staggeredSkusList?.every((skuValue: string) => inventoryStaggeredSkus?.includes(skuValue))) {
                return store?.inventory?.find((inv: Inventory) => inv.sku === staggeredSkusList[0] && inv.Quantity)
            }
        }
        return store?.inventory?.find(inv => inv.sku === selectedSku)
    }

    /**
     * This function returns if product quantity
     * @param {StoreWithAvailability | undefined} store
     * @param {string} selectedSku
     * @returns { boolean } returns true or false
     */
    const getInStockQty = (store: StoreWithAvailability | undefined, selectedSku: string | undefined): number => {
        const storeInventory = getInventory(store, selectedSku)
        return storeInventory?.Quantity
    }

    /**
     * This function returns instock quantity at DC
     * @param {StoreWithAvailability | undefined} store
     * @param {string} selectedSku
     * @returns { number } returns DC quantity
     */
    const getDCQuantity = (store: StoreWithAvailability | undefined, selectedSku: string | undefined): number => {
        const storeInventory = getInventory(store, selectedSku)
        return storeInventory?.orderable ? storeInventory?.Corporate?.Quantity : magicNumber.ZERO
    }

    /**
     * Renders Max Number Of Stores
     * @param {StoreWithAvailability} store Store
     * @param {number} index Store index
     * @returns {JSX.Element}
     */
    const renderMaxNumberOfStores = (store: StoreWithAvailability, index: number): JSX.Element => {
        const inStockQty = getInStockQty(store, sku)
        const { address } = store || {}
        const weekdayHours = getStoreTimings(store)
        const { closingTime, closed } = weekdayHours

        const nextWeekDayHours = getNextDayStoreTimings(store)
        const { openingTime } = new Date().getHours() < afternoonHours ? weekdayHours : nextWeekDayHours

        const storeOpenOrCloseLabel = closed ? openingTime?.formattedHour : closingTime?.formattedHour

        const timeText = showStoreTime(closed, closingTime, openingTime)
        return store && renderStoreListItem(store, index, timeText, address, inStockQty, closed, storeOpenOrCloseLabel)
    }

    /**
     * Renders nearby store component text
     * @param {StoreWithAvailability[]} stores List of all near by store
     * @returns {JSX.Element} returns nearby store component text
     */
    const rendersNearbyStoreComp = (stores: StoreWithAvailability[]): JSX.Element => {
        return (
            <ul className={`${storeClassName}`}>
                {
                    // eslint-disable-next-line sonar/function-return-type
                    stores.map((store: StoreWithAvailability, index: number) => {
                        return store && Object.keys(store).length && renderMaxNumberOfStores(store, index)
                    })
                }
            </ul>
        )
    }

    /**
     * Invalid postal code or city warning message component
     * @returns {JSX.Element} returns invalid postal code or city warning message component
     */
    const renderInvalidAddressWarningMessage = (): JSX.Element | null => {
        return isInputInValid ? (
            <div className={`${componentClassName}__error-message`}>
                <Icon type="ct-warning" size="lg"></Icon>
                <p
                    className={`${PREFIX}-body-sm ${componentClassName}__error-message__text`}
                    role="alert"
                    aria-atomic="true">
                    {searchInputErrorMsg}
                </p>
            </div>
        ) : null
    }

    /**
     * Renders switch store view component
     * @returns {JSX.Element} returns switch store view component
     */
    const renderStoreView = (): JSX.Element => {
        return (
            !isHeaderStoreLocator &&
            (showFilter !== undefined ? (
                <div className={`${componentClassName}__header__toggle-btn-show-filter`}>
                    <div className={`${componentClassName}__header__toggle-options ${toggleButtonClassName}`}>
                        {showFilter && <Dropdown {...dropdownProps} />}
                        {isMobile && renderListAndMapComponent()}
                    </div>
                </div>
            ) : (
                <div
                    className={
                        isMapView || !isShowEmptyStoresToggle
                            ? `${componentClassName}__header__toggle-btn ${componentClassName}__header__toggle-btn_mapview`
                            : `${componentClassName}__header__toggle-btn`
                    }>
                    <div className={`${componentClassName}__header__toggle-options`}>{renderListAndMapComponent()}</div>
                </div>
            ))
        )
    }

    /**
     * Renders view more stores link
     * @returns {JSX.Element} returns view more stores link
     */
    const renderViewMoreStores = (): JSX.Element => {
        return (
            (isHeaderStoreLocator || isInMiniPDPFlyout) && (
                <div className={`${storeClassName}__item__wrapper ${storeClassName}__item__wrapper__see-more`}>
                    <a
                        className={`${storeClassName}__item__view-stores ${storeClassName}__item__view-stores-link`}
                        href={seeMoreCTAURL}
                        onClick={() => viewMoreAnalytics?.(seeMoreCTALabel)}
                        data-link-value={seeMoreCTALabel}
                        target={inFlyoutLinkTarget}
                        dap-wac-link="true"
                        dap-wap-loc="header"
                        dap-wac-value="View More Stores">
                        {seeMoreCTALabel}
                    </a>
                    <Icon path={path} size="md" type="ct-chevron-right" />
                </div>
            )
        )
    }

    /**
     * function to call near by stores on click of the button
     */

    const onChangeButtonAction = (): void => {
        if (showInStock) {
            setShowInStock(!showInStock)
            fetchNearbyStoresWithAvailability()
        }
    }

    const cartInStoreInventoryErrorDescription = (): JSX.Element => {
        const cartErrorDescription = noResultForCartInStoreInventoryDescription.split('[0]')
        return (
            <>
                <p>
                    {cartErrorDescription[0]}
                    <Button
                        modifierClass={`${PREFIX}-check-nearby-stores-btn`}
                        onClick={onChangeButtonAction}
                        type="tertiary">
                        {wishlistCartOnlyTitle}
                    </Button>
                    {cartErrorDescription[1]}
                </p>
            </>
        )
    }

    const inStoreInventoryErrorDescriptionMsg = (): JSX.Element => {
        return isCartPage ? (
            <>
                <div className="no-result-error">{noResultForCartInStoreInventoryTitle}</div>
                {cartInStoreInventoryErrorDescription()}
            </>
        ) : (
            <>
                <div className="no-result-error">{noResultFoundTitle}</div>
                <p>{noResultsForInStoreInventoryDescription}</p>
            </>
        )
    }

    /**
     * Renders no results found component text or no result for postal code error message
     * @returns {JSX.Element} returns Renders no results found component text or no result for postal code error message
     */
    // eslint-disable-next-line consistent-return
    const errorMessage = (): JSX.Element | null => {
        const errorCode = storeErrorResponse?.data?.errCode
        if (showInStock) {
            return <>{inStoreInventoryErrorDescriptionMsg()}</>
        }
        // check any non maxmind error first
        if (errorCode === maxRadiusErrorCode) {
            // check if header store locator first
            return (
                <>
                    {isCartPage ? (
                        <>
                            <div className="no-result-error">{noResultForCartTitle}</div>
                            <p>{noResultForCartDescription}</p>
                        </>
                    ) : (
                        <>
                            <div className="no-result-error">{noResultForPostalCodeTitle}</div>
                            <p>{noResultForPostalCodeDescription}</p>
                        </>
                    )}
                </>
            )
        } else {
            if (isStoresApiFailed) {
                return null
            }
        }
    }

    /**
     * @param {StoreWithAvailability} store
     * @returns {JSX.Element} returns all item instock messaging
     */
    const renderAllItemInStock = (store: StoreWithAvailability): JSX.Element => {
        return (
            isCartPage &&
            store.allItemsInStock && (
                <div className={`${storeClassName}__item__all-in-stock`}>
                    <Cart isCartStoreSelectorModal={true} />
                    <span className={`${storeClassName}__item__all-in-stock__text`}>{allItemsInStockMsg}</span>
                </div>
            )
        )
    }

    /**
     * function returns stores with inventory with quantity more
     * than zero or preferred store
     * @returns {StoreWithAvailability[]} stores
     */
    const getStoresWithInventoryOrPreferred = (): StoreWithAvailability[] => {
        if (isArrayEmpty(storesWithInventoryOrPreferred)) {
            allStores.forEach((store: StoreWithAvailability) => {
                if (store.hasOwnProperty(allItemsInStock)) {
                    store.allItemsInStock && storesWithInventoryOrPreferred.push(store)
                } else {
                    if (!!getInStockQty(store, sku)) {
                        storesWithInventoryOrPreferred.push(store)
                    }
                }
            })
        }
        return storesWithInventoryOrPreferred
    }

    /**
     * function returns stores id with inventory with quantity more
     * than zero or preferred store location
     * @returns {number[]} stores id
     */
    const getStoresIdsWithInventoryOrPreferred = (): number[] => {
        if (isArrayEmpty(storesIdsWithInventoryOrPreferred)) {
            getStoresWithInventoryOrPreferred().forEach((store: StoreWithAvailability) => {
                storesIdsWithInventoryOrPreferred.push(store.id as number)
            })
        }
        return storesIdsWithInventoryOrPreferred
    }

    /**
     * function returns stores location with inventory with quantity more
     * than zero or preferred store location
     * @returns {MapPosition[]} stores location
     */
    const getStoresLocationWithInventoryOrPreferred = (): MapPosition[] => {
        if (isArrayEmpty(storesLocationWithInventoryOrPreferred)) {
            const storesId = getStoresIdsWithInventoryOrPreferred()
            newMapProps?.locations.forEach((location: MapPosition) => {
                if (storesId.includes(location.id)) {
                    storesLocationWithInventoryOrPreferred.push(location)
                }
            })
        }
        return storesLocationWithInventoryOrPreferred
    }

    /**
     * function filters stores by inStock toggle state
     * @param {StoreWithAvailability[]} stores
     * @returns {StoreWithAvailability[]} filtered stores
     */
    const filterStores = (stores: StoreWithAvailability[]): StoreWithAvailability[] => {
        if (showInStock) {
            return getStoresWithInventoryOrPreferred()
        }
        return stores
    }

    /**
     * function filters stores locations in properties
     * @param {mapProps} mapProps
     * @returns {StoreWithAvailability[]} filtered stores
     */
    const filterMapLocations = (mapProps: MapProps): MapProps => {
        mapProps.locations =
            isShowEmptyStoresToggle && showInStock ? getStoresLocationWithInventoryOrPreferred() : allStoresLocations
        return mapProps
    }

    /**
     * Renders store details modal
     * @returns {JSX.Element}
     */
    const renderStoreDetailsModal = (): JSX.Element => {
        const storeLocatorComp = document.querySelector<HTMLElement>(`.${PREFIX}-store-locator-comp`)
        if (storeLocatorComp) {
            window.scroll({
                top: storeLocatorComp.offsetTop,
                behavior: 'smooth',
            })
        }

        return (
            <StoreDetailsModal
                renderMaxNumberOfStores={renderMaxNumberOfStores}
                storeList={allStores}
                markerClickHandler={markerClickHandler}
            />
        )
    }

    const shouldShowSpinner = () => {
        return !storeErrorResponse?.data?.errCode && !isHeaderStoreLocator && isPdpPageType
    }

    /**
     * useEffect to set announcement message after list update for SR users
     * Below useEffect is dedicated for accessibility purpose in order to announce number of stores available when search happens
     */
    useEffect(() => {
        if (storeCloseByRef.current) {
            storeCloseByRef.current.innerHTML = ' '
            setTimeout(() => {
                if (storeCloseByRef.current) {
                    storeCloseByRef.current.innerHTML = replaceStrWithDynamicVal(
                        a11yStoresAvailableLabel,
                        storeList.length,
                    )
                }
            }, magicNumber.ONEHUNDREDFIFTY)
        }
    }, [storeList, a11yStoresAvailableLabel])

    /**
     * returns no result error message
     * @returns {JSX.Element}
     */
    const noResultErrorMessage = (): JSX.Element | null => {
        return <div className={`${componentClassName}__no-results`}>{inStoreInventoryErrorDescriptionMsg()}</div>
    }

    /**
     * Renders nearby stores list or no results found component
     * @returns {JSX.Element} returns nearby stores list or no results found component
     */
    const renderNearByStoreListOrNoResultsComp = (): JSX.Element => {
        if (isArrayNotEmpty(storeList)) {
            if (!isMapView) {
                return showInStock && isArrayEmpty(getStoresWithInventoryOrPreferred()) ? (
                    noResultErrorMessage()
                ) : (
                    <>
                        <div
                            className={`${componentClassName}__list-view`}
                            onScroll={loadMoreStores}
                            data-testid={'contentModal'}>
                            {rendersNearbyStoreComp(filterStores(storeList))}
                            {renderViewMoreStores()}
                        </div>
                    </>
                )
            } else {
                return showInStock && isArrayEmpty(getStoresWithInventoryOrPreferred()) ? (
                    noResultErrorMessage()
                ) : (
                    <div className={`${componentClassName}__body`} onScroll={loadMoreStores}>
                        <Map
                            {...filterMapLocations(newMapProps)}
                            setMarkerClickHandler={setMarkerClickHandler}
                            setPreferredStoreOnMapIconClicked={setPreferredStoreOnMapIconClicked}
                            a11yMapLocateMeButtonLabel={a11yMapLocateMeButtonLabel}
                        />
                        {markerClickHandler.isSelected && markerClickHandler.selectedIndex > unavailableIndex
                            ? renderStoreDetailsModal()
                            : null}
                    </div>
                )
            }
        } else {
            return (
                <div className={`${componentClassName}__no-results`}>
                    {shouldShowSpinner() ? <Spinner /> : null}
                    {errorMessage()}
                </div>
            )
        }
    }

    /**
     * returns header title within h1 tag on store-locator page and in h2 tag on other pages
     * @returns {JSX.Element} returns h1 or h2 tag
     */
    const renderHeaderText = (): JSX.Element => {
        return isStoreLocator ? (
            <h1 data-testId={titleDataTetsId} className={`${componentClassName}__header__title`}>
                {visibleTitle}
            </h1>
        ) : (
            <h2 data-testId={titleDataTetsId} className={`${componentClassName}__header__title`}>
                {visibleTitle}
            </h2>
        )
    }

    const isSelected = markerClickHandler?.isSelected

    /**
     * Function to resize map on display of store details modal
     */
    useEffect(() => {
        if (isMobile && isMapView) {
            const locator = document.querySelector(`.${PREFIX}-store-selector-flyout__body`)
            const locatorHeader = document.querySelector(`.${PREFIX}-store-selector-flyout__header`)
            const locatorHeaderHeight = locatorHeader?.clientHeight || 0
            const detailsModal = document.querySelector(`.${PREFIX}-store-details-modal`)
            const detailsModalHeight = detailsModal ? detailsModal.clientHeight : 0
            const mapHeight = window.innerHeight - locatorHeaderHeight - detailsModalHeight
            const map = document.querySelector(`.${PREFIX}-map`)
            map?.setAttribute('style', `height:${mapHeight}px`)
            locator?.setAttribute('style', `height:${mapHeight + detailsModalHeight}px`)
        }
    }, [isMobile, isMapView, isSelected])

    const [isInputInValid, setIsInputInValid] = useState(false)
    const googleAutoCompleteProps = {
        a11ySearchIconAriaLabel,
        a11ySuggestionResultAvailable,
        searchInputPH,
        fetchNearbyStoreListOnSearch,
        inputMaxLength: addressMaxLength,
        setIsInputInValid,
        windowRef: window,
        searchStoreAnalyticsHandler,
    }

    /**
     * @override function to toggle class
     * To align search bar and current location button horizontally/vertically
     */
    const getSearchBarStyle = () => {
        if (pageType === pageTypes.storeLocator) {
            return `${componentClassName}__header__search-bar ${PREFIX}-search-column`
        } else {
            return `${componentClassName}__header__search-bar`
        }
    }

    const toggleStoreRef = useRef(false)

    /**
     * function to render in-stock filter at my store component on Pdp page
     * @returns {JSX.Element | null}
     */
    const renderToggleStore = (): JSX.Element | null => {
        const toggleHandler = (): void => {
            setShowInStock(prevState => !prevState)
            // Accessibility work
            // We need this operation as in accessibility we need to announce updated stores number
            toggleStoreRef.current = !toggleStoreRef.current
            let storesLength
            if (getStoresWithInventoryOrPreferred()?.length > magicNumber.ZERO) {
                if (toggleStoreRef.current) {
                    storesLength = getStoresWithInventoryOrPreferred()?.length
                } else {
                    storesLength = storeList?.length
                }
                storeCloseByRef.current.innerHTML = replaceStrWithDynamicVal(a11yStoresAvailableLabel, storesLength)
            } else {
                if (toggleStoreRef.current) {
                    storeCloseByRef.current.innerHTML = noResultForCartTitle
                } else {
                    storesLength = storeList?.length
                    if (storesLength) {
                        storeCloseByRef.current.innerHTML = replaceStrWithDynamicVal(
                            a11yStoresAvailableLabel,
                            storesLength,
                        )
                    }
                }
            }
        }
        return isShowEmptyStoresToggle && !isMapView ? (
            <div className={`${componentClassName}__header__in-store-toggle`}>
                <Switch handleInputChange={toggleHandler} label={showInStockFilterLabel} checked={showInStock} />
            </div>
        ) : null
    }

    const errorClass = isStoresApiFailed ? `${PREFIX}-search-error` : `${PREFIX}-search`

    /**
     * Render store flyout title.
     * @returns {JSX.Element} returns store flyout title.
     */
    const showPreferredStoreErrorMsg = (): JSX.Element | null => {
        return !!isStoresApiFailed ? (
            <div className={`${componentClassName}__error-block`}>
                <Icon type="ct-warning" size="md" path={path}></Icon>
                <span className={`${componentClassName}__error-block__description`}>{forcePreferredStoreMsg}</span>
            </div>
        ) : null
    }

    /**
     * Render current location icon.
     * @returns {JSX.Element} current location icon.
     */
    const renderCurrentLocationIcon = (): JSX.Element | null => {
        return (
            !isStoresApiFailed && (
                <button
                    className={`${componentClassName}__map-icon`}
                    onClick={setPreferredStoreOnMapIconClicked}
                    aria-label={a11yCurrentLocationCTAAriaLabel}>
                    <Icon type="ct-geo-location" size="lg" path={path} />
                </button>
            )
        )
    }

    /**
     * Render current location link.
     * @returns {JSX.Element} current location link.
     */
    const renderCurrentLocationLink = (): JSX.Element | null => {
        return (
            !isStoresApiFailed && (
                <Button type="tertiary" onClick={setPreferredStoreOnMapIconClicked}>
                    {a11yCurrentLocationCTAAriaLabel}
                </Button>
            )
        )
    }

    return (
        <div className={`${componentClassName}__container`} ref={storeSelectorModalRef}>
            <div className={`${componentClassName}__header`}>
                {isInMiniPDPFlyout && (
                    <div className={`${componentClassName}__back-btn-container`}>
                        <button
                            className={`${componentClassName}__back-btn`}
                            onClick={closeFlyout}
                            aria-label={backBtnLabel}
                            dap-wac-link="true"
                            dap-wac-loc={analyticMiniPDPLocation}
                            dap-wac-value={backBtnLabel}>
                            <Icon type="ct-chevron-left" size="lg" />
                            {backBtnLabel}
                        </button>
                    </div>
                )}
                <div className={`${componentClassName}__header__label`}>
                    {renderHeaderText()}
                    {showClose && (
                        <button
                            data-testid="close-flyout-btn"
                            className={`${componentClassName}__close-btn`}
                            onClick={modifiedCloseFlyout}
                            aria-label={closeIconLabel}
                            title={closeBtnLabel}>
                            <Icon type="ct-close" size="lg" path={path} />
                        </button>
                    )}
                </div>
                <div className={`${getSearchBarStyle()}`}>
                    <div className={`${componentClassName}__header__autocomplete ${errorClass}`}>
                        <GoogleAutocomplete {...googleAutoCompleteProps} />
                    </div>
                    {(!isTablet || isTabletNotOnStoreLocatorPage()) && renderCurrentLocationIcon()}
                    {isTabletOnStoreLocatorPage() && renderCurrentLocationLink()}
                </div>
                {renderInvalidAddressWarningMessage()}
                {showPreferredStoreErrorMsg()}
                <div className={`${componentClassName}__header__selector-container`}>
                    {renderToggleStore()}
                    {!isStoresApiFailed && renderStoreView()}
                </div>
            </div>
            <span className="sr-only" ref={storeCloseByRef} aria-live="polite" aria-atomic="true" role="status"></span>
            {renderNearByStoreListOrNoResultsComp()}
        </div>
    )
}

StoreSelectorModal.defaultProps = {
    showClose: true,
}

export default StoreSelectorModal
