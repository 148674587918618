import React from 'react'

import { NotificationBadgeProps } from './NotificationBadge.type'
import { PREFIX } from '../config'
import { Size, Role, Radius, State, Position } from '../../types'

/**
 * NotificationBadge value
 * @param {NotificationBadgeProps} props
 * @returns {JSX.Element} returns notification badge value
 */
const NotificationBadge: React.FC<NotificationBadgeProps> = ({ ...props }) => {
    const {
        value,
        notificationClass,
        a11y,
        color = State.ERROR,
        size = Size.SMALL,
        radius = {
            size: Radius.CIRCLE,
            position: Position.ALL,
        },
    } = props
    const customClass = notificationClass ? `${notificationClass} ` : ''
    const componentClass = `${PREFIX}-notification-badge`
    const radiusPosition = radius?.position === Position.ALL ? '' : `-${radius?.position}`
    const showRadius = radius?.size === Radius.NONE ? '' : ` ${componentClass}--${radius?.size}${radiusPosition}`
    const defaultA11yLabel = 'notification status'

    return (
        <div
            role={Role.STATUS}
            aria-label={a11y?.label ? a11y.label : defaultA11yLabel}
            className={`${customClass}${componentClass}${showRadius} ${componentClass}__${color} ${componentClass}--${size}`}>
            {a11y?.label && <span className="sr-only">{a11y?.label}</span>}
            <span aria-hidden={a11y?.hidden ? a11y.hidden : false}>{value}</span>
        </div>
    )
}

export default NotificationBadge
