import { MagicNumber } from '../../analytics/analytics.type'

/**
 * Function allows to get token status depending on `timeToExpire` param value.
 * For example: `timeToExpire = 60000` (one minute) means that left less the one minute to token expiration
 * @param {number} expTime - token expiration time
 * @param {number} timeToExpire - time in ms that left to token expiration
 * @return {boolean}
 */
export const getGigyaJwtTokenExpirationStatus = (expTime: number, timeToExpire = 0): boolean => {
    return new Date(Date.now()) > new Date(expTime * MagicNumber.ONETHOUSAND - timeToExpire)
}

/**
 * Function returns the decoded JWT token
 * @param {string} gigyaJWTToken
 * @return {Record<string, unknown>}
 */
export const parseGigyaJwtToken = (gigyaJWTToken: string): Record<string, unknown> => {
    if (!!gigyaJWTToken) {
        try {
            const base64Url = gigyaJWTToken.split('.')[MagicNumber.ONE]
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        // eslint-disable-next-line prefer-template
                        return '%' + ('00' + c.charCodeAt(0).toString(MagicNumber.SIXTEEN)).slice(MagicNumber.MINUS_TWO)
                    })
                    .join(''),
            )
            return JSON.parse(jsonPayload) as Record<string, unknown>
        } catch (error) {
            console.error('Parse token', error)
        }
    }
    return {}
}
