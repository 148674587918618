import { CertonaInitialization } from '../certona/certona.service'

/**
 * Trigger resonance call with customize param
 * @param {string} event
 * @param {boolean} isCertonaRecItemAdded
 * @param {string | undefined} itemId
 * @param {string | undefined} categoryId
 * @returns {void}
 */
export function customizeCertonaInitialization(
    event: string,
    // eslint-disable-next-line default-param-last
    isCertonaRecItemAdded = false,
    itemId?: string,
    categoryId?: string,
): void {
    CertonaInitialization.triggerCertona({
        event: event,
        recommendations: true,
        isCertonaRecItemAdded: isCertonaRecItemAdded,
        filter: {
            fitmentproducts: false,
        },
        ...(itemId && { itemid: itemId }),
        ...(categoryId && { categoryid: categoryId }),
    })
}

export default customizeCertonaInitialization
