import { PREFIX } from '../../../config'

/**
 * function to calculate menu positon top and height
 * @param {string} elementClassName
 * @return {void}
 */
export const setMenuPosition = (elementClassName: string): void => {
    const sideMenRef = document.querySelector(elementClassName) as HTMLElement
    const headerElement = document.getElementsByClassName(`${PREFIX}-primary-navigation`)[0]
    const headersHeight = Number(headerElement?.clientHeight || 0)
    const infoBannerHeight =
        isInformationBannerVisible() || !getStickyStoreLocatorHeight()
            ? Number(document.getElementsByClassName(`${PREFIX}-information-banner`)[0]?.clientHeight || 0)
            : 0
    const storeLocatorHeight = Number(
        document.getElementsByClassName('store-locator-pencil-banner')[0]?.clientHeight || 0,
    )
    const stickyStoreLocatorHeight = getStickyStoreLocatorHeight()
    const browseOnlyBannerHeight = getBrowseOnlyBannerHeight()
    const headerContainerHeight = stickyStoreLocatorHeight
        ? headersHeight
        : infoBannerHeight + headersHeight + storeLocatorHeight
    const offsetTop = browseOnlyBannerHeight
        ? headerContainerHeight + browseOnlyBannerHeight
        : headerContainerHeight + getHeadBandBannerHeight()

    if (sideMenRef) {
        getMenuPosition(sideMenRef, headersHeight, offsetTop, stickyStoreLocatorHeight)
    }
}

/**
 * Returns height of Sticky Store Locator bar.
 * @return { number }
 */
export const getStickyStoreLocatorHeight = (): number => {
    return Number(
        document.getElementsByClassName(`${PREFIX}-primary-navigation__sticky_store-locator`)[0]?.clientHeight || 0,
    )
}

/**
 * Returns height of HeadBandBanner component.
 * @return { number }
 */
export const getHeadBandBannerHeight = (): number => {
    return Number(document.getElementsByClassName(`${PREFIX}-headband-banner`)[0]?.clientHeight || 0)
}

/**
 * Returns height of Browse Only Banner.
 * @return { number }
 */
const getBrowseOnlyBannerHeight = (): number => {
    return Number(document.getElementsByClassName(`${PREFIX}-browse-only-information-banner`)[0]?.clientHeight || 0)
}

/**
 * Returns true if information banner exist on view.
 * @return { boolean }
 */
export const isInformationBannerVisible = (): boolean => {
    const infoBannerElement = document.getElementsByClassName(`${PREFIX}-information-banner`)
    if (!!infoBannerElement && !!infoBannerElement[0]) {
        const rect = infoBannerElement[0].getBoundingClientRect()
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
    }
    return false
}

/**
 * @param {HTMLElement} sideMenRef
 * @param {number} headersHeight
 * @param {number} offsetTop
 * @param {number} stickyStoreLocatorHeight
 * @returns { void }
 */
const getMenuPosition = (
    sideMenRef: HTMLElement,
    headersHeight: number,
    offsetTop: number,
    stickyStoreLocatorHeight: number,
): void => {
    const stickyHeader = document.getElementsByClassName(`${PREFIX}-primary-navigation__fixed`)

    const windowInnerHeight = window.innerHeight
    sideMenRef.style.top = stickyHeader?.length ? `${headersHeight}px` : `${offsetTop}px`
    sideMenRef.style.height = stickyHeader?.length
        ? `${windowInnerHeight - headersHeight}px`
        : `${windowInnerHeight - offsetTop - stickyStoreLocatorHeight}px`
}
