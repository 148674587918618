/**
 * function to convert object into string value
 * @param {string} str - string value to parse to object
 * @return {Object}
 */
const convertStrToObj = <ReturnType = Record<string, unknown>>(str: string): ReturnType => {
    let obj = {} as ReturnType
    if (str && typeof str === 'string') {
        const objStr: RegExpMatchArray | null = str.match(/\{(.)+\}/g)
        try {
            obj = (objStr ? JSON.parse(objStr[0]) : {}) as ReturnType
        } catch (e) {
            obj = {} as ReturnType
        }
    }
    return obj
}
export default convertStrToObj
