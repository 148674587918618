import React from 'react'
import { ContentVariation, CustomContentType } from './CustomContentType.type'
import { PREFIX } from '../config'
import HeaderBlock from '../HeaderBlock/HeaderBlock'
import ButtonV2 from '../ButtonV2'
import { Size, Variation } from '../../types'
import { ButtonColors } from '../ButtonV2/Button.types'
import { useIsMobile } from '../../hooks/useIsMobile.hook'

/**
 * Braze Custom Content UI
 * @param {CustomContentType} props custom card props
 * @returns {JSX.Element} braze custom component
 */
const CustomContentCard: React.FC<CustomContentType> = ({ ...props }): JSX.Element | null => {
    const { type, cardInfo, contentPosition, triggerEvent } = props
    const { imageUrl, title, description, url, linkText } = cardInfo
    const componentClass = `${PREFIX}-custom-card__container`
    const isCardPositionRight = Boolean(contentPosition === ContentVariation.ImageRight)
    const modifierClass = type === ContentVariation.CaptionImage ? `${PREFIX}-col-md-5 ${PREFIX}-col-sm-6` : ''
    const isMobile = useIsMobile()

    /**
     * Display image only UI
     * @param {string} link - link to redirect
     * @returns { JSX.Element } return only image ui
     */
    const renderImageContentCard = (link?: string): JSX.Element => (
        <ButtonV2
            variant={Variation.TERTIARY}
            customClass={`${componentClass}__image ${modifierClass}`}
            onClick={() => triggerContentCardEvent(link)}>
            <picture>
                <source srcSet={imageUrl} />
                <img className={`${componentClass}__image--img`} alt={title ?? ''} src={imageUrl} />
            </picture>
        </ButtonV2>
    )

    /**
     * function to trigger content card event
     * @param {string} link - link to redirect
     * @returns {void} return nothing
     */
    const triggerContentCardEvent = (link?: string): void => {
        triggerEvent && triggerEvent(cardInfo)
        if (link) {
            window.location.href = encodeURI(link)
        }
    }

    /**
     * Display image with text
     * @returns { JSX.Element } return image with text Ui
     */
    const renderCaptionedImageContentCard = (): JSX.Element => (
        <div className={`${componentClass} ${PREFIX}-row`}>
            {(!isCardPositionRight || isMobile) && renderImageContentCard(url)}
            <div className={`${PREFIX}-col-md-7 ${PREFIX}-col-sm-6 ${componentClass}__content`}>
                {title && <HeaderBlock title={title} subtitle={description} />}
                {linkText && (
                    <ButtonV2
                        variant={Variation.PRIMARY}
                        size={Size.MINI}
                        color={ButtonColors.DARK}
                        a11y={{ label: linkText }}
                        onClick={() => triggerContentCardEvent(url)}>
                        {linkText}
                    </ButtonV2>
                )}
            </div>
            {isCardPositionRight && !isMobile && renderImageContentCard(url)}
        </div>
    )

    /**
     * Display braze custom card UI based on response from braze console
     */
    switch (type) {
        case ContentVariation.Image:
            return renderImageContentCard()
        case ContentVariation.CaptionImage:
            return renderCaptionedImageContentCard()
        default:
            return null
    }
}

export default CustomContentCard
