import { createAction } from 'typesafe-actions'
import {
    SUCCESS_UPDATE_PASSWORD,
    FAIL_UPDATE_PASSWORD,
    FAIL_UPDATE_TFA_PHONE_NUMBER,
    SUCCESS_UPDATE_TFA_PHONE_NUMBER,
    INIT_PHONE_VERIFICATION_FLOW,
    FAIL_PHONE_VERIFICATION_FLOW,
    RESET_PHONE_VERIFICATION_FLOW,
    SUCCESS_PHONE_VERIFICATION_FLOW,
    CLEAR_UPDATE_TFA_PHONE_NUMBER_DATA,
    SET_PHONE_VERIFICATION_ERR_CODE,
    CLEAR_PHONE_VERIFICATION_DATA,
    SET_TFA_NUMBER,
} from '../types/security/security.actionTypes.constant'
import { GetTfaPhoneNumbersData, InitPhoneVerificationData } from '../models/security.interface'

export const successUpdatePassword = createAction(SUCCESS_UPDATE_PASSWORD)()
export const failUpdatePassword = createAction(FAIL_UPDATE_PASSWORD)<string>()
export const successUpdateTfaPhoneNumber = createAction(SUCCESS_UPDATE_TFA_PHONE_NUMBER)()
export const clearUpdateTfaPhoneNumberStatus = createAction(CLEAR_UPDATE_TFA_PHONE_NUMBER_DATA)()
export const failUpdateTfaPhoneNumber = createAction(FAIL_UPDATE_TFA_PHONE_NUMBER)<string>()
export const initPhoneVerificationFlow = createAction(INIT_PHONE_VERIFICATION_FLOW)<InitPhoneVerificationData>()
export const resetPhoneVerificationFlow = createAction(RESET_PHONE_VERIFICATION_FLOW)()
export const failPhoneVerificationFlow = createAction(FAIL_PHONE_VERIFICATION_FLOW)()
export const successPhoneVerificationFlow = createAction(SUCCESS_PHONE_VERIFICATION_FLOW)()
export const setPhoneVerificationErrCode = createAction(SET_PHONE_VERIFICATION_ERR_CODE)<string>()
export const clearPhoneVerificationData = createAction(CLEAR_PHONE_VERIFICATION_DATA)()
export const setTFANumber = createAction(SET_TFA_NUMBER)<GetTfaPhoneNumbersData>()
