import { magicNumber } from './magicNumber'

/**
 * used to render Text Input fields
 * @param {string} inputString , string received on input field change
 *
 * @return {string}
 */
const validatePostalCode = (inputString: string): string => {
    let isCheckNumber = false
    const stringArr = inputString.split('')
    let postalCodeStr = ''
    stringArr.length &&
        stringArr.forEach((value: string, index: number) => {
            if (isCheckNumber) {
                if (!/^\d+$/.test(value)) {
                    stringArr[index] = ''
                    return
                }
                isCheckNumber = false
                postalCodeStr = postalCodeStr + value
            } else {
                if (!/^[A-Za-z]+$/.test(value)) {
                    stringArr[index] = ''
                    return
                }
                isCheckNumber = true
                postalCodeStr = postalCodeStr + value
            }
        })
    if (postalCodeStr.length >= magicNumber.FOUR && postalCodeStr.charAt(magicNumber.THREE) !== ' ') {
        const postalCodePrefix = postalCodeStr.substring(magicNumber.ZERO, magicNumber.THREE)
        const postalCodeSuffix = postalCodeStr.substring(magicNumber.THREE, magicNumber.SIX)
        const postalCode = `${postalCodePrefix} ${postalCodeSuffix}`

        return postalCode.toUpperCase()
    }
    return postalCodeStr.toUpperCase()
}

export { validatePostalCode }
export default validatePostalCode
