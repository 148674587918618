import { FETCH_PRODUCT_SUCCESS } from '../types/products/productData.actionTypes.constant'
import { ProductDataType } from '../models/productData.interface'
import { setSponsoredAds } from '../actionCreators/sponsoredAds.actionCreators'
import CustomMiddleware from '../types/customMiddleware/customMiddleware'

/**
 * Middleware for updating the sponsored ads in the `sponsoredAds` reducer.
 * This middleware triggers when `sponsoredProducts` data is present in the search response.
 * Note: This does not rely on the `enableCriteoDirectServerOnCDS` or `enableCriteoDirectServer` feature flags being enabled.
 * @param {object} store - The Redux store instance.
 * @returns {Function} - The next middleware function.
 */
export const setSponsoredProductsMiddleware: CustomMiddleware = store => next => action => {
    const productData = action.payload as ProductDataType

    // Skip this middleware if there are no sponsoredProducts in the search response
    if (action.type !== FETCH_PRODUCT_SUCCESS || !productData.sponsoredProducts) {
        return next(action)
    }

    const { onLoadBeacon, carousel, inGrid } = productData.sponsoredProducts

    const modifiedAction = {
        ...action,
        payload: {
            ...productData,
            // To avoid duplicating data across different locations,
            // sponsored products are removed here and managed separately in a dedicated reducer.
            sponsoredProducts: {},
        } as ProductDataType,
    }

    // Trigger the root level OnLoadBeacon
    navigator.sendBeacon(onLoadBeacon)

    // store sponsored ads to the dedicated reducer
    store.dispatch(
        setSponsoredAds({
            onLoadBeacon,
            placements: {
                inGrid,
                carousel,
            },
        }),
    )

    return next(modifiedAction)
}
