import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { OrderExpiredErrorProps } from './OrderExpiredError.type'
import { errorAnalytics } from '../../../analytics/components/errorAnalytics'
import { ButtonColors, ErrorScreen, ErrorScreenData } from '@nl/lib'
import { analyticsAttributes } from '../../../globalConstants/analyticsParams.constant'
import { PREFIX } from '../../../config'
import { orderExpiredSelector } from '../../../redux/selectors/orderDetails.selectors'
import { useAppSelector } from '../../../hooks/react-redux.hook'

const orderExpiredErrorClassName = `${PREFIX}-order-expired`

/**
 * returns Loading Skeleton for order header
 * @return {JSX.Element}
 */
const OrderExpiredError: React.FC<OrderExpiredErrorProps> = ({ ...props }): JSX.Element => {
    const { oeImage, oeTitle, oeMessage, oectaLabel } = props
    const orderExpired = useAppSelector(orderExpiredSelector)

    const continueClick = (): void => {
        window.location.href = encodeURI(window.ODP.globalLinks.homePageLink || '')
    }

    const errorData: ErrorScreenData = {
        headerLogo: {
            image: oeImage,
            imageAlt: oeMessage,
        },
        errorMessageHeader: oeTitle,
        errorMessageSubHeader: oeMessage,
        ctaList: [
            {
                children: oectaLabel,
                onClick: continueClick,
                color: ButtonColors.DARK,
            },
        ],
    }

    useEffect(() => {
        if (orderExpired) {
            errorAnalytics(analyticsAttributes.eventParameters.labels.orderExpired)
        }
    }, [orderExpired, oeMessage])

    const accordionDisplay = document.getElementsByClassName('accordion-wrapper')[0]
    if (accordionDisplay && orderExpired) {
        accordionDisplay.classList.add(`${PREFIX}-xs-none`)
    }

    return (
        <>
            {orderExpired && (
                <div className={orderExpiredErrorClassName}>
                    <ErrorScreen data={errorData}></ErrorScreen>
                </div>
            )}
        </>
    )
}

OrderExpiredError.propTypes = {
    oeImage: PropTypes.string.isRequired,
    oeTitle: PropTypes.string.isRequired,
    oeMessage: PropTypes.string.isRequired,
    oectaLabel: PropTypes.string.isRequired,
}

export default OrderExpiredError
