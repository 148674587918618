import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import { SaveForLaterParams } from '../../components/ShoppingCart/SaveForLater.type'

import { safeForLaterListService } from '../../services/saveForLater/saveForLater.service'
import {
    saveForLaterListSuccess,
    saveForLaterListFailure,
    removeSaveForLaterFailure,
    getNewWishListState,
} from '../actionCreators/saveForLater.actionCreators'
import {
    setSflToastVariables,
    addToCartGuestFailureAction,
    setShoppingCartLimitItemsFailure,
} from '../../redux/actionCreators'
import { setShowSpinner } from '../actionCreators/spinner.actionCreators'
import { AddProductToCartGuestRequestDTO, AxiosCartResponseErrorDTO } from '../models/cart.interface'
import { RootState } from '../reducers'
import appCacheService from '../../utils/appCacheService'
import { getCartItemsData, logError, removeCartItem } from './cart.action'
import { cartService } from '../../services'
import { iSaveForLaterEntry, iSaveForLater } from '../models/SaveForLater.interface'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import { cartLimitItemsErrorCode } from '../../globalConstants/cdsErrorCodes'

export const getSaveForLaterListAction =
    (
        getSFLParams: SaveForLaterParams,
        showSpinner: boolean,
        // eslint-disable-next-line default-param-last
        isnotClickedSfl = false,
        entries?: iSaveForLaterEntry[],
        setShowMoreSpinner?: React.Dispatch<React.SetStateAction<boolean>>,
    ) =>
    (dispatch: Dispatch): void => {
        showSpinner && !isnotClickedSfl && dispatch(setShowSpinner(true))
        safeForLaterListService
            .getSaveForLaterListService(getSFLParams)
            .then((response: AxiosResponse<iSaveForLater>) => {
                replaceEmptyImagesWithDefault(response?.data?.entries, 'images')
                if (entries) {
                    response.data.entries = [...entries, ...response.data.entries]
                }
                dispatch(saveForLaterListSuccess(response.data || []))
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                if (err?.response && err?.response?.status) {
                    dispatch(
                        setSflToastVariables({
                            getSflFailure: true,
                        }),
                    )
                }

                dispatch(saveForLaterListFailure(err))
            })
            .finally(() => {
                dispatch(
                    setSflToastVariables({
                        addedToSflSuccess: false,
                    }),
                )
                !isnotClickedSfl && dispatch(setShowSpinner(false))
                setShowMoreSpinner && setShowMoreSpinner(false)
            })
    }

export const addToCartForSfl =
    (
        requestPayload: AddProductToCartGuestRequestDTO,
        // eslint-disable-next-line default-param-last
        showSpinner = false,
        postalCodeVal?: string,
        isBulk?: boolean,
        // eslint-disable-next-line default-param-last
        ismovetoCart = false,
        isStaggered?: boolean,
        callBackFunction?: () => void,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { selectedPreferredStoreId, preferredStoreDetails } = getState().storeDetails
        const postalCode = postalCodeVal || preferredStoreDetails?.address?.postalCode
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required

        return cartService
            .addItemToCart(
                requestPayload?.saveForLaterId as string,
                requestPayload.entries.orderEntries,
                selectedPreferredStoreId,
                postalCode,
                isStaggered,
                isBulk,
            )
            .then((data: AxiosResponse<AddProductToCartGuestRequestDTO>) => {
                if (ismovetoCart) {
                    getCartItemsData('', '')(dispatch, getState)
                    dispatch(
                        setSflToastVariables({
                            moveToSflSuccess: true,
                        }),
                    )
                    callBackFunction && callBackFunction()
                } else {
                    const slfgid = requestPayload?.saveForLaterId || data.data.saveForLaterId
                    !!slfgid && appCacheService.setSflGuid(slfgid)
                    removeCartItem(
                        '',
                        '',
                        true,
                        requestPayload.entryNumber,
                        [],
                        true,
                    )(dispatch, getState).catch(err => console.error(err))
                }
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                if (!err.response) {
                    dispatch(setShowSpinner(false))
                    return
                }

                logError(err?.response?.data, 'Reason for add to SFL api failure')
                const errorResponse = err.response
                const errCode = errorResponse?.data?.errCode

                if (errorResponse && errorResponse?.status && ismovetoCart) {
                    dispatch(
                        setSflToastVariables({
                            moveToSflFailure: true,
                        }),
                    )
                    // eslint-disable-next-line sonarjs/elseif-without-else
                } else if (errorResponse && errorResponse?.status) {
                    dispatch(
                        setSflToastVariables({
                            addedToSflFailure: true,
                        }),
                    )
                }

                if (errCode === cartLimitItemsErrorCode) {
                    dispatch(setShoppingCartLimitItemsFailure(errorResponse))
                } else {
                    dispatch(addToCartGuestFailureAction(errorResponse))
                }

                dispatch(setShowSpinner(false))
            })
            .finally(() => {
                ismovetoCart && dispatch(setShowSpinner(false))
                !ismovetoCart && callBackFunction && callBackFunction()
            })
    }
export const removeFromSaveForLater =
    (
        removeSFLParams: SaveForLaterParams,
        entryNumbers: number[],
        setShowSuccessRemoveSFL: React.Dispatch<React.SetStateAction<boolean>>,
        showSpinner = false,
    ) =>
    (dispatch: Dispatch): void => {
        showSpinner && dispatch(setShowSpinner(true))
        safeForLaterListService
            .removeFromSaveForLater(removeSFLParams, entryNumbers)
            .then(response => {
                replaceEmptyImagesWithDefault((response?.data as iSaveForLater)?.entries, 'images')
                dispatch(saveForLaterListSuccess(response.data || []))
                setShowSuccessRemoveSFL(true)
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                if (err?.response && err?.response?.status) {
                    dispatch(
                        setSflToastVariables({
                            removeSflFailure: true,
                        }),
                    )
                }
                dispatch(removeSaveForLaterFailure(err))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }
export const getUpdatedWishListSFl =
    (newWishListState: iSaveForLaterEntry[] | undefined) =>
    (dispatch: Dispatch): void => {
        newWishListState && dispatch(getNewWishListState(newWishListState))
    }
