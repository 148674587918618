import { checkDataLength } from '../checkDataLength'
import { convertEpsilonDate } from '../date.util'
import { isTriangleSelectMember, wasTriangleSelectMember } from '../isTriangleSelectMember'
import { GetUserProfileStatusUserRequiredFields, UserProfileStatus } from './GetUserProfileStatus.type'
import { userLoginStatus } from '../../globalConstants/global.constant'
import { isAtleastOneParamValid } from '../validParams'

const subscriptionStartDateDeadline = new Date(Date.UTC(Number('2022'), Number('2'), Number('22')))
/**
 * Path user profile data to get user statuses (guest, auth, loyalty, subscription, login)
 * @param {Boolean} isAuthFlowExecuted
 * @param {UserProfileData} userProfileData - user profile data
 * @param {UserProfileData} isLoyaltyUser - loyalty guest user
 * @returns {UserProfileStatus} - user statuses object
 */
const getUserProfileStatus = <UserProfileDataType extends GetUserProfileStatusUserRequiredFields>(
    isAuthFlowExecuted: boolean,
    userProfileData: UserProfileDataType | null,
    isLoyaltyUser?: boolean,
): UserProfileStatus => {
    const isAuthenticated = isAuthFlowExecuted && checkDataLength(userProfileData)
    const isWelcomeGiftEligible1 =
        !!userProfileData?.loyalty?.tsWelcomeGift &&
        wasTriangleSelectMember(userProfileData?.loyalty?.tsSubscriptionStatus) &&
        !!userProfileData?.loyalty?.tsSubscriptionStartDate &&
        convertEpsilonDate(userProfileData?.loyalty?.tsSubscriptionStartDate).getTime() <
            subscriptionStartDateDeadline.getTime()
    const isWelcomeGiftEligible2 =
        !userProfileData?.loyalty?.tsWelcomeGift && !userProfileData?.loyalty?.tsSubscriptionStatus

    return {
        isGuest: isAuthFlowExecuted && !checkDataLength(userProfileData),
        isAuthenticated,
        hasLoyalty: isAtleastOneParamValid(!!userProfileData?.loyalty?.cardNumber, !!userProfileData?.loyalty?.id),
        hasSubscription: isTriangleSelectMember(userProfileData?.loyalty?.tsSubscriptionStatus),
        hadSubscription: wasTriangleSelectMember(userProfileData?.loyalty?.tsSubscriptionStatus),
        isWelcomeGiftEligible: isAuthenticated && (isWelcomeGiftEligible1 || isWelcomeGiftEligible2),
        partnerCIRLinkStatus: userProfileData?.linkedPartnerCards?.CIR?.partnerLinkStatus,
        partiallyAuthenticated: userProfileData?.role === userLoginStatus.partiallyAuthenticatedUser,
        hasLoyaltyAccount: isAtleastOneParamValid(!!userProfileData?.loyalty?.cardNumber, isLoyaltyUser),
    }
}

export default getUserProfileStatus
