import React from 'react'
import { ButtonGroupProps } from './ButtonGroup.types'
import { PREFIX } from '../config'
import { Direction } from '../../types'

/**
 * ButtonGroup component
 * @param {ButtonGroupProps} props ButtonV2 props
 * @param {React.ForwardedRef<HTMLElement>} ButtonV2 with ref
 * @returns {JSX.Element} returns ButtonV2 component
 */
const ButtonGroup: React.FC<ButtonGroupProps> = React.forwardRef<
    HTMLElement,
    React.PropsWithChildren<ButtonGroupProps>
>(({ ...props }, ref): JSX.Element => {
    const {
        children,
        direction = Direction.HORIZONTAL,
        customClass,
        wrap = true,
        a11y,
        reverse = false,
        gap = 'none',
    } = props
    const customClassName = customClass ? ` ${customClass}` : ''
    const isVertical = Boolean(direction === Direction.VERTICAL)
    const componentClass = `${PREFIX}-button-group`
    const buttonAlignment = isVertical ? ` ${PREFIX}-xs-align-items-start` : ''
    const commonClass = `${componentClass} ${PREFIX}-xs-flex${buttonAlignment}`
    const buttonDirection = ` ${PREFIX}-xs${isVertical ? '-column' : '-row'}${reverse ? '-reverse' : ''}`
    const buttonWrap = ` ${PREFIX}-xs-flex-${wrap ? 'wrap' : 'nowrap'}`
    const buttonGap = gap !== 'none' ? ` ${componentClass}--gap-${gap}` : ''

    return (
        <section
            ref={ref}
            role="group"
            className={`${commonClass}${customClassName}${buttonDirection}${buttonWrap}${buttonGap}`}
            aria-label={a11y?.label ? a11y?.label : `${direction}-group`}>
            {children}
        </section>
    )
})

ButtonGroup.displayName = 'ButtonGroup'

export default ButtonGroup
