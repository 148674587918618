import { GlobalPropsHelper } from '../../../analytics/helpers/globalProps/globalProps.helper'
import { checkDataLength } from '../../../components/Accounts/Addresses/checkDataLength'
import { SeoLink } from './Seo.types'
import { pageTypes } from '../../../config'
import getPageType from '../../../utils/getPageType'
import store from '../../../store'
import { SeoHelper } from '../../helpers/Seo.helper'
import { sanitizeStringContent } from '@nl/lib/src/utils/sanitizeStringContent'
import { ISEOmetadata } from '../../../analytics/helpers/globalProps/globalProps.types'

/**
  function to inject meta tags and links for static pages in the head tag
 */
const StaticSEO = (): void => {
    /**
     * function to inject  alternate and cannonical links for static pages
     * @param {SeoLink} linkObj
     * @param {string} type
     */
    const addHrefLangLinks = (linkObj: SeoLink, type: string): void => {
        const { path, hrefLang } = linkObj
        if (path) {
            const link = document.createElement('link')
            link.rel = type
            link.href = window.location.origin + path
            if (hrefLang) {
                link.setAttribute('hrefLang', hrefLang)
            }
            document.getElementsByTagName('head')[0].appendChild(link)
        }
    }

    // open graph meta tag
    if (!document.querySelector("meta[property='og:url']")) {
        const ogUrlTag = document.createElement('meta')
        ogUrlTag.setAttribute('property', 'og:url')
        ogUrlTag.content = sanitizeStringContent(document.location.href)
        document.getElementsByTagName('head')[0].appendChild(ogUrlTag)
    }

    const pageType = getPageType()

    if (pageType !== pageTypes.pdpPage) {
        store.subscribe(() => {
            const imageLink = store.getState().ogImageTag.renderedComponents.imageLink
            if (imageLink && !document.querySelector("meta[property='og:image']")) {
                const ogImageTag = document.createElement('meta')
                ogImageTag.setAttribute('property', 'og:image')
                ogImageTag.content = imageLink
                document.getElementsByTagName('head')[0].appendChild(ogImageTag)
            }
        })
    }

    const globalProps = new GlobalPropsHelper()
    const seometadata = globalProps.readDataAlternateLangDetails()

    if (checkDataLength(seometadata)) {
        const {
            alternatePageDetails,
            currentPageDetails,
            isDynamic,
            canonical,
            pageDetails,
            isAlternateTagRenderEnabled,
        } = seometadata

        if (isDynamic !== 'true') {
            const metaTypes = ['description', 'robots', 'title'] as Array<keyof ISEOmetadata>
            metaTypes.forEach(metaType => {
                if (seometadata[metaType]) {
                    if (metaType === 'title' && pageType === pageTypes.brandPage) {
                        document.title = `${seometadata[metaType]} |
                            ${seometadata['siteBannerTitle']}`
                    }
                    const metaTag = document.createElement('meta')
                    metaTag.setAttribute('name', metaType)
                    metaTag.content = seometadata[metaType] as string
                    document.getElementsByTagName('head')[0].appendChild(metaTag)
                }
            })
            // links
            if (pageType === pageTypes.brandPage) {
                SeoHelper.updateCanonicalLinks()
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (pageType !== pageTypes.searchPage) {
                addHrefLangLinks({ path: canonical } as SeoLink, 'canonical')
            }

            if (isAlternateTagRenderEnabled && pageType !== pageTypes.searchPage) {
                addHrefLangLinks(pageDetails as SeoLink, 'alternate')
                addHrefLangLinks(alternatePageDetails as SeoLink, 'alternate')
                addHrefLangLinks(currentPageDetails as SeoLink, 'alternate')
            }
        }
    }
}

export { StaticSEO }
