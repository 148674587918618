export const fiveSecondsToTokenExpiration = 5000
export const oneMinuteToTokenExpiration = 60000
export const tokenExpirationCheckInterval = 5000
export const retryMaxAttemptsNumber = 5
export const oneSecondForDelayInterval = 1000
export const defaultRetryAttemptValue = 0
export const retryAttemptIncrement = 1
export const baseRetryAttemptDelay = 2
export const retryAttemptsOffset = 1
export const visibilityState = {
    visible: 'visible',
    hidden: 'hidden',
}
