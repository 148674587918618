import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { pageTypes, PREFIX, previousElementName } from '../config'
import {
    CartFlyoutProps,
    CartModificationsDTO,
    IHeliumInflationStoreServiceType,
    ProductOptionsType,
    TireWheelDetails,
} from './CartFlyout.type'
import Icon from '../Icon'
import Button from '../Button'
import { replaceStrWithDynamicVal, getSortVariants } from '../../utils'
import FlyoutModalComponent from '../FlyoutModalComponent'
import CartServicesComponent from '../CartServices'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import ServiceInstallationMessage from '../ServiceInstallationMessage'
import { productWheelTypes } from '../VehicleBanner/VehicleBanner.type'
import { getPageType } from '../../utils/getPageType'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'
import SnippetCard from '../SnippetCard'
import { checkDataLength } from '../../utils/checkDataLength'
import { inflationData } from '../../globalConstants/global.constant'

/**
 * CartFlyout component
 * @param {CartFlyoutProps} props
 * @returns {JSX.Element} returns FlyoutModalComponent,CartServicesComponent,Toast,Icon
 */
// eslint-disable-next-line max-lines-per-function
const CartFlyout: React.FC<CartFlyoutProps> = props => {
    const {
        isOpen,
        path,
        closeFlyout,
        addToCartSingularLabel,
        addToCartPluralLabel,
        cartButtonLabel,
        continueLinkLabel,
        cartModifications,
        finalCartModifications,
        closeBtnLabel,
        cartServices,
        serviceAddOnLabel,
        serviceAddOnAvailableLabel,
        addServiceToCartLabel,
        serviceAddOnNextSteps,
        serviceAddToCartHandler,
        addedServiceToCart,
        serviceDescription,
        recommendationsCarousel,
        serviceInstallationProps,
        isAutoInstallationReq,
        autoPackagesDiffTireLabel,
        autoPackageChooseWheelCTA,
        autoPackageChooseTireCTA,
        autoPackagesDiffWheelLabel,
        currentSelectedContainer,
        nextSelectedPackage,
        changeSelectedLink,
        autoPackagesUpdatedTiresTitle,
        autoPackagesUpdatedWheelsTitle,
        chooseCTAHandler,
        selectDiffTireWheel,
        tireData,
        wheelData,
        continueToCart,
        isWheelOrTire,
        isPackageFlow,
        cartCount,
        renderPackageUpselling,
        renderPackageCompleteMessage,
        createPackageAndNavigate,
        enableCustomOrder,
        heliumInflationAddToCartEnabled,
        isHeliumInflationOptionEnabled,
        heliumFilledButtonLabel,
        uninflatedButtonLabel,
        inflationButtonLabel,
        balloonAddonOptionsComponent,
        triggerAddToCartBundleApi,
        setShouldCloseFlyout,
        isSendBundleApi,
    } = props

    const numberOfItems = useRef(cartModifications.numberOfItems)
    const images = useRef(cartModifications.entry.images)
    const name = useRef(cartModifications.entry.name)
    const options = useRef(cartModifications.entry.options)

    const {
        installLabel,
        shoppingAtText,
        storeName,
        phone,
        serviceAvailabiity,
        availabilityLabel,
        enableAOAFlowMessaging,
    } = serviceInstallationProps

    const serviceInstallationMessageProps = {
        installLabel: installLabel,
        serviceAvailabiity: serviceAvailabiity,
        isCartFlyout: true,
    }

    const componentClassName = `${PREFIX}-cart-flyout`
    const singleItemNumber = 1

    const totalProductCount = numberOfItems.current + Number(cartCount)

    const itemsQuantityAdded = addedServiceToCart?.success ? totalProductCount + singleItemNumber : totalProductCount

    const labelToReplace = itemsQuantityAdded === singleItemNumber ? addToCartSingularLabel : addToCartPluralLabel
    const addedToCartTitle = replaceStrWithDynamicVal(labelToReplace, itemsQuantityAdded)
    const { iconType, title, description, CTA, CTALink } = renderPackageUpselling || {}

    const goToCartPage = (buttonName?: string) => {
        setShowSpinner(true)
        if (isSendBundleApi) {
            triggerAddToCartBundleApi()
        } else {
            continueToCart(buttonName)
        }
    }

    // Below code is for handling the accessibility when clicked on close button
    const modalClassName = `${PREFIX}-flyout-modal-overlay`

    const addToCartBtnElem: HTMLElement = document.getElementById('add-to-cart')

    /**
     * function to handle modal accessibility close handler
     */
    const modifiedCloseFlyout = (): void => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName })

        if (isSendBundleApi) {
            triggerAddToCartBundleApi()
            setShouldCloseFlyout(true)
        } else {
            closeFlyout()
        }

        if (addToCartBtnElem) {
            addToCartBtnElem.focus() // Highlight the initiated button
            addToCartBtnElem.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }

    /**
     * Returns Balloon addon options component
     * @returns {JSX.Element}
     */
    const renderballoonAddonOptionsComponent = (): JSX.Element => {
        return <div className={`${componentClassName}__recommendations_carousel`}>{balloonAddonOptionsComponent}</div>
    }

    const [showSpinner, setShowSpinner] = useState(false)
    const renderRecommendationsComponent = (): JSX.Element => {
        return <div className={`${componentClassName}__recommendations_carousel`}>{recommendationsCarousel}</div>
    }
    /**
     * function to send jsx element as children props
     * @return {JSX.Element} returns ServiceInstallationMessage children props
     */
    const renderServiceComponentChildren = (): JSX.Element => {
        const isChildrenVisible =
            !enableAOAFlowMessaging || !serviceAvailabiity || (!isWheelOrTire && serviceAvailabiity)
        return (
            <>
                {/* 
                 tire and wheel
                 if AEMaoatoggle on - for no service availability this block should show
                 if AEMaoatoggle off - always this block should show
                 Auto part - always this block should show irrespective of AOA flow
                */}
                {isChildrenVisible && (
                    <>
                        <div className={`${PREFIX}-installation-msg__shopping-at`}>
                            {shoppingAtText}
                            <span className={`${PREFIX}-installation-msg__shopping-at__store`}>{storeName}</span>
                        </div>
                        <div className={`${PREFIX}-installation-msg__phone`}>
                            <a href={`tel:${phone}`}>{phone}</a>
                        </div>
                    </>
                )}
                {availabilityLabel && <div className={`${PREFIX}-installation-msg__desc`}>{availabilityLabel}</div>}
            </>
        )
    }

    /**
     * function to render ServiceInstallationMessage or CartServicesComponent based on isAutomotive
     * @return {JSX.Element} returns ServiceInstallationMessage or CartServicesComponent
     */
    const renderServiceComponent = (): JSX.Element => {
        return isAutoInstallationReq ? (
            <ServiceInstallationMessage {...serviceInstallationMessageProps}>
                {renderServiceComponentChildren()}
            </ServiceInstallationMessage>
        ) : (
            (addedServiceToCart?.success || isArrayNotEmpty(cartServices)) && (
                <CartServicesComponent
                    cartServices={cartServices}
                    serviceAddOnLabel={serviceAddOnLabel}
                    serviceAddOnAvailableLabel={serviceAddOnAvailableLabel}
                    addServiceToCartLabel={addServiceToCartLabel}
                    serviceAddOnIcon="ct-tools"
                    serviceAddOnNextSteps={serviceAddOnNextSteps}
                    serviceAddToCartHandler={serviceAddToCartHandler}
                    addedServiceToCart={addedServiceToCart}
                    serviceDescription={serviceDescription}
                    parentProduct={cartModifications.entry}
                />
            )
        )
    }

    /**
     * This function is used to render footer button based on the provided arguments
     * @param {string} buttonName
     * @param  {Function} handleClickMethod
     * @return {JSX.Element} return button component
     */
    const renderFooterButton = (buttonName: string, handleClickMethod: (btnLabel?: string) => void): JSX.Element => {
        return (
            <Button
                id="footer-btn"
                onClick={() => {
                    handleClickMethod(buttonName)
                }}
                size="large"
                ariaLabel={buttonName}
                label={buttonName}
                showSpinner={showSpinner}></Button>
        )
    }

    /**
     * This function is used to display the button based on the currentSelectedContainer & nextSelectedPackage
     * @return {JSX.Element}
     */
    const handleFooterButton = (): JSX.Element => {
        if (!nextSelectedPackage) {
            if (currentSelectedContainer === productWheelTypes.Tire) {
                return renderFooterButton(autoPackageChooseWheelCTA, chooseCTAHandler)
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (currentSelectedContainer === productWheelTypes.Wheel) {
                return renderFooterButton(autoPackageChooseTireCTA, chooseCTAHandler)
            }
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (nextSelectedPackage) {
            return renderFooterButton(cartButtonLabel, goToCartPage)
        }
        return null
    }

    /**
     * This function is used to rener the label based on selected package
     * @param {string} selectedPackage
     * @return {JSX.Element}
     */
    const renderTireWheelLabel = (selectedPackage: string): JSX.Element => {
        const selectDiffLabel =
            selectedPackage === productWheelTypes.Tire ? autoPackagesDiffTireLabel : autoPackagesDiffWheelLabel
        return (
            <Button
                id="diff-wheel-btn"
                type="tertiary"
                size="mini"
                onClick={() => selectDiffTireWheel(selectedPackage, selectDiffLabel)}>
                {selectDiffLabel}
            </Button>
        )
    }

    /**
     * This function is used to tireData and wheelData
     * @param {TireWheelDetails} tireContainerData
     * @param {TireWheelDetails} wheelContainerData
     *  @return {boolean}
     */
    const checkTireWheelData = (tireContainerData: TireWheelDetails, wheelContainerData: TireWheelDetails): boolean => {
        return (
            (tireContainerData && Object.values(tireContainerData).length > 0) ||
            (wheelContainerData && Object.values(wheelContainerData).length > 0)
        )
    }

    /**
     * This function is used to render the updated tire or wheel lable based on the selected package
     * @param {string} selectedPackage
     * @return {JSX.Element}
     */
    const renderChangeLabel = (selectedPackage: string): JSX.Element => {
        if (
            selectedPackage === productWheelTypes.Tire &&
            changeSelectedLink &&
            checkTireWheelData(tireData, wheelData)
        ) {
            return <>{autoPackagesUpdatedTiresTitle}</>
        } else if (
            selectedPackage === productWheelTypes.Wheel &&
            changeSelectedLink &&
            checkTireWheelData(tireData, wheelData)
        ) {
            return <>{autoPackagesUpdatedWheelsTitle}</>
        } else {
            return (
                <>
                    <Icon type="ct-checkmark" size="md" />
                    {addedToCartTitle}
                </>
            )
        }
    }

    /**
     * This function handle updated tire or data lable
     * @return {JSX.Element}
     */
    const handleChangeLabel = (): JSX.Element =>
        renderChangeLabel(!nextSelectedPackage ? currentSelectedContainer : nextSelectedPackage)

    /**
     * This function checks if package flow conditions are matched or not
     * @return {boolean}
     */
    const checkPackageFlowConditions = (): boolean => {
        return isPackageFlow && isWheelOrTire && getPageType() === pageTypes.pdpPage
    }

    /**
     * This function is used to render label based on the currentSelectedContainer & nextSelectedPackage
     * @param {CartModificationsDTO} item
     * @return {JSX.Element}
     */
    const handlePlpLabel = (item?: CartModificationsDTO): JSX.Element => {
        if (currentSelectedContainer && !nextSelectedPackage && checkPackageFlowConditions()) {
            return renderTireWheelLabel(currentSelectedContainer)
        } else if (nextSelectedPackage && checkPackageFlowConditions()) {
            return renderTireWheelLabel(nextSelectedPackage)
        } else {
            const filteredOptions = (item ? item.entry.options : options.current)?.filter(
                (option: ProductOptionsType) => !props.hideVariants.includes(option.descriptor),
            )
            const optionsForRender = enableCustomOrder ? getSortVariants(filteredOptions) : filteredOptions
            return (
                isArrayNotEmpty(optionsForRender) && (
                    <>
                        {optionsForRender.map((option: ProductOptionsType, index: number) => {
                            return (
                                <p className={`${componentClassName}__product-variant`} key={index}>
                                    {option.display}: {option.value}
                                </p>
                            )
                        })}
                    </>
                )
            )
        }
    }

    const getHeliumInflationLabel = (
        selectedStoreServices?: IHeliumInflationStoreServiceType[],
        supportedStoreServices?: IHeliumInflationStoreServiceType[],
    ): JSX.Element | undefined => {
        const isHeliumAddonSupported = supportedStoreServices?.some(
            service => service.value === inflationData.heliumAddOn,
        )
        if (heliumInflationAddToCartEnabled && selectedStoreServices && isHeliumAddonSupported) {
            let label = ''
            const isHeliumAddonSelected = selectedStoreServices?.some(item => item.value === inflationData.heliumAddOn)

            if (isHeliumAddonSelected && isHeliumInflationOptionEnabled) label = heliumFilledButtonLabel
            else label = uninflatedButtonLabel

            return (
                <p className={`${componentClassName}__product-variant`}>
                    {inflationButtonLabel}: {label}
                </p>
            )
        }
        return null
    }

    const getFBTProductAddedToCart = (): JSX.Element => {
        return (
            <>
                {finalCartModifications.map((item: CartModificationsDTO) => {
                    const { images: productImages, name: productName } = item.entry
                    return (
                        <>
                            <div className={`${componentClassName}__product-details`}>
                                <div className={`${componentClassName}__image-wrap`}>
                                    <SanitizeStringContentWrapper stringContent={productImages?.[0]?.url}>
                                        {memoizedStringContent => (
                                            <img
                                                src={memoizedStringContent}
                                                alt={productImages?.[0]?.altText}
                                                className={`${componentClassName}__image`}></img>
                                        )}
                                    </SanitizeStringContentWrapper>
                                </div>
                                <div className={`${componentClassName}__details-wrap`}>
                                    <p className={`${componentClassName}__product-title`}>{productName}</p>
                                    {handlePlpLabel(item)}
                                </div>
                            </div>
                            <hr className={`${componentClassName}__hr`} />
                        </>
                    )
                })}
            </>
        )
    }

    /**
     * Function to render package completion message
     * @return {JSX.Element}
     */
    const renderPackageCompletionMessage = (): JSX.Element => {
        return (
            <SnippetCard imageOffSet={false} iconType={renderPackageCompleteMessage.iconType}>
                <h4
                    aria-label={renderPackageCompleteMessage.title}
                    className={`${PREFIX}-snippet-card__completion-title`}>
                    {renderPackageCompleteMessage.title}
                </h4>
                <p className={`${PREFIX}-snippet-card__completion-description`}>
                    {renderPackageCompleteMessage.description}
                </p>
            </SnippetCard>
        )
    }

    /**
     * Function to render product summary section
     * @return {JSX.Element}
     */
    const renderProductSummarySection = (): JSX.Element => {
        return finalCartModifications.length > 1 ? (
            getFBTProductAddedToCart()
        ) : (
            <>
                <div className={`${componentClassName}__product-details`}>
                    <div className={`${componentClassName}__image-wrap`}>
                        <img
                            src={images.current?.[0]?.url}
                            alt={images.current?.[0]?.altText}
                            className={`${componentClassName}__image`}></img>
                    </div>
                    <div className={`${componentClassName}__details-wrap`}>
                        <p className={`${componentClassName}__product-title`}>{name.current}</p>
                        {handlePlpLabel()}
                        {getHeliumInflationLabel(
                            cartModifications.entry.selectedStoreServices,
                            cartModifications.entry.supportedStoreServices,
                        )}
                    </div>
                </div>
                <hr className={`${componentClassName}__hr`} />
            </>
        )
    }

    const isNotGoToCartButton = !!((nextSelectedPackage || currentSelectedContainer) && isWheelOrTire && isPackageFlow)

    return (
        <FlyoutModalComponent
            isOpen={isOpen}
            closeFlyout={modifiedCloseFlyout}
            isStoreSelectorModalUsed={false}
            title={addedToCartTitle}>
            <div className={`${componentClassName}__container`}>
                <div className={`${componentClassName}__header`}>
                    <h2 className={`${componentClassName}__title`}>{handleChangeLabel()}</h2>
                    <button
                        data-testid="close-btn"
                        className={`${componentClassName}__close-btn`}
                        onClick={modifiedCloseFlyout}
                        aria-label={closeBtnLabel}
                        title={closeBtnLabel}>
                        <Icon type="ct-close" size="lg" path={path} />
                    </button>
                </div>
                <div className={`${componentClassName}__body`}>
                    {renderProductSummarySection()}
                    {CTA && (
                        <SnippetCard imageOffSet={false} iconType={iconType}>
                            <h3 aria-label={CTA} className={`${PREFIX}-snippet-card__title`}>
                                {title}
                            </h3>
                            <p className={`${PREFIX}-snippet-card__label`}>{description}</p>
                            <a
                                className={`${componentClassName}__compatible-button ${PREFIX}-button--mini`}
                                data-testid="package-button"
                                href={CTALink}
                                onClick={event => createPackageAndNavigate(event, CTALink)}
                                aria-label={CTA}>
                                {CTA}
                            </a>
                        </SnippetCard>
                    )}
                    {checkDataLength(renderPackageCompleteMessage) && renderPackageCompletionMessage()}
                    {renderServiceComponent()}
                    {renderballoonAddonOptionsComponent()}
                    {renderRecommendationsComponent()}
                </div>
                <div className={`${componentClassName}__footer`}>
                    {isNotGoToCartButton ? handleFooterButton() : renderFooterButton(cartButtonLabel, goToCartPage)}
                    <Button
                        id="continue-btn"
                        type="tertiary"
                        onClick={() => {
                            modifiedCloseFlyout()
                            closeFlyout(continueLinkLabel)
                        }}
                        ariaLabel={continueLinkLabel}>
                        {continueLinkLabel}
                    </Button>
                </div>
            </div>
        </FlyoutModalComponent>
    )
}

CartFlyout.propTypes = {
    addToCartSingularLabel: PropTypes.string.isRequired,
    addToCartPluralLabel: PropTypes.string.isRequired,
    cartButtonLabel: PropTypes.string.isRequired,
    continueLinkLabel: PropTypes.string.isRequired,
    colorLabel: PropTypes.string.isRequired,
    sizeLabel: PropTypes.string.isRequired,
    closeBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeFlyout: PropTypes.func.isRequired,
    cartModifications: PropTypes.any,
    path: PropTypes.string,
    cartServices: PropTypes.array,
    addServiceToCartLabel: PropTypes.string,
    serviceAddOnAvailableLabel: PropTypes.string,
    serviceAddOnErrorMessage: PropTypes.string,
    serviceAddOnLabel: PropTypes.string,
    serviceAddOnMultipleAvailableLabel: PropTypes.string,
    serviceAddOnNextSteps: PropTypes.string,
    serviceAddToCartHandler: PropTypes.func,
    addedServiceToCart: PropTypes.any,
    serviceDescription: PropTypes.string,
    resetPageVariables: PropTypes.func,
    recommendationsCarousel: PropTypes.node,
    serviceInstallationProps: PropTypes.object,
    isAutoInstallationReq: PropTypes.bool,
    autoPackagesDiffTireLabel: PropTypes.string,
    autoPackageChooseWheelCTA: PropTypes.string,
    autoPackageChooseTireCTA: PropTypes.string,
    autoPackagesDiffWheelLabel: PropTypes.string,
    changeSelectedLink: PropTypes.bool,
    currentSelectedContainer: PropTypes.string,
    nextSelectedPackage: PropTypes.string,
    autoPackagesUpdatedTiresTitle: PropTypes.string,
    chooseCTAHandler: PropTypes.func,
    selectDiffTireWheel: PropTypes.func,
    autoPackagesUpdatedWheelsTitle: PropTypes.string,
    tireData: PropTypes.any,
    wheelData: PropTypes.any,
    continueToCart: PropTypes.func,
    isWheelOrTire: PropTypes.bool,
    isPackageFlow: PropTypes.bool,
    hideVariants: PropTypes.array,
}

export default CartFlyout
