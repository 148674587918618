import { AxiosPromise } from 'axios'

import BaseService from '../base.service'
import { getHttpClient } from '../../httpClient'
import { CriteoParams, CriteoResponse } from './criteo.interface'
import { appendCustomCriteoParams } from './criteoService.helper'

const httpClient = getHttpClient()

/**
 * Criteo Service
 */
class CriteoService extends BaseService {
    private readonly cache: Map<string, CriteoResponse> = new Map()

    /**
     * @param {CriteoParams} params - The criteo API parameters
     * @param {CriteoEventType} params.eventType - Indicates what type of request we want to use
     * @param {CriteoEventType} params.pageId - Criteo page id for the the Criteo request
     * @param {string} params.criteoBaseUrl - The base URL for the Criteo request.
     * @param {string} params.partnerId - The partner ID for Criteo.
     * @param {string} params.noLog - Flag to determine if logging should be disabled.
     * @param {string} params.retailerVisitorId - The retailer visitor ID.
     * @param {string} params.storeId - The store ID or region ID.
     * @param {boolean} params.isMobile - Indicates if the environment is mobile.
     * @param {number} params.pageNumber - The page number for pagination.
     * @param {string} params.keywords -  Keywords for the search query. Provide only if eventType = viewSearchResult
     * @param {string} params.categoryId - The current CategoryID. Provide only if eventType = viewCategory
     * @param {string} [params.customerId] - The optional customer ID.
     * @param {boolean} [params.isNullSearch] - Flag to indicate if a NullSearch should be triggered
     * @returns {AxiosPromise<CriteoResponse>} - A promise that resolves to the Criteo response
     */
    fetchCriteoData(params: CriteoParams): AxiosPromise<CriteoResponse> {
        const { criteoBaseUrl, partnerId, noLog, retailerVisitorId, storeId, isMobile, customerId } = params

        const baseUrl = this.getCriteoUrl(
            criteoBaseUrl,
            partnerId,
            noLog,
            retailerVisitorId,
            storeId,
            isMobile,
            customerId,
        )

        const customParams = appendCustomCriteoParams(params)
        const url: URL = new URL(baseUrl)
        url.search += (url.search ? '&' : '') + customParams

        // Generate a unique cache key based on the URL
        const cacheKey = url.toString()

        // Return cached response as a resolved promise if available
        const cachedResponse = this.cache.get(cacheKey)
        if (cachedResponse) {
            return Promise.resolve({
                data: cachedResponse,
                status: 200,
                statusText: 'OK',
                headers: {},
                config: {},
            }) as AxiosPromise<CriteoResponse>
        }

        // Fetch data from the API and store it in the cache
        const request = httpClient.apiGet<CriteoResponse>(url.toString())
        request
            .then(response => {
                this.cache.set(cacheKey, response.data)
            })
            .catch(() => {
                console.error('Error fetching Criteo data')
            })

        return request
    }

    /**
     * Compose a Criteo URL based on the Universal parameters.
     * @param {string} criteoBaseUrl - The base URL for the Criteo request.
     * @param {string} partnerId - The partner ID for Criteo.
     * @param {string} noLog - Flag to determine if logging should be disabled.
     * @param {string} retailerVisitorId - The retailer visitor ID.
     * @param {string} storeId - The store ID or region ID.
     * @param {boolean} isMobile - Indicates if the environment is mobile.
     * @param {string} [customerId] - The optional customer ID.
     * @returns {string} The composed Criteo URL.
     */
    getCriteoUrl = (
        criteoBaseUrl: string,
        partnerId: string,
        noLog: string,
        retailerVisitorId: string,
        storeId: string,
        isMobile: boolean,
        customerId?: string,
    ): string => {
        const params = new URLSearchParams({
            'criteo-partner-id': partnerId,
            'retailer-visitor-id': retailerVisitorId,
            regionId: storeId,
            environment: isMobile ? 'm' : 'd',
        })

        if (noLog) {
            params.append('nolog', '1')
        }

        if (customerId) {
            params.append('customer-id', customerId)
        }

        return `${criteoBaseUrl}?${params.toString()}`
    }
}

const criteoService = new CriteoService()
export default criteoService
