import { VariantDetails, ProductOptionValue, FilteredVariantType } from './BuyBox.type'
import { isArrayNotEmpty } from '@nl/lib'
import { customSort, descriptorSort } from './getVariantData.helper'
import { ProductOption } from '../../redux/models/product.interface'
import { COLOR } from './BuyBox.constant'

/**
 * Variants component
 * @param {ProductOption[]} options - Product options
 * @returns {VariantDetails} returns arrays with Variant Details like colorVariant,sizes,dynamicName,dynamicVariantLabel
 */
const getVariantData = (options: ProductOption[]): VariantDetails => {
    const filteredVariants: FilteredVariantType[] = []

    if (isArrayNotEmpty(options)) {
        options.forEach((item: ProductOption) => {
            const { display, descriptor } = item
            const filteredOption = item.values?.map(variant => {
                const {
                    id,
                    value,
                    skuCodes,
                    mediaSet,
                    isDefaultColour,
                    colourSwatchImageUrl,
                    displayNameForVariants,
                    isUnavailableDueToStock,
                } = variant as ProductOptionValue
                if (descriptor === COLOR) {
                    return {
                        name: value,
                        skuCodes,
                        id,
                        mediaSet,
                        isDefaultColour,
                        colourSwatchImageUrl,
                    }
                }
                return {
                    name: value,
                    skuCodes,
                    id,
                    mediaSet,
                    displayNameForVariants,
                    isUnavailableDueToStock,
                }
            })

            filteredVariants.push({
                values: filteredOption || [],
                display,
                descriptor,
            } as unknown as FilteredVariantType)
        })

        /* Filtered variant result : -
            [
                [{name: black, id: 1, skuCodes: []}, {color: red, id: 1, skuCodes: []}],
                [{name: 7, id: 11, skuCodes: []}, {name: 8, id: 12, skuCodes: []}],
                [{name: lemon mint, id: 101, skuCodes: []}, {name: mint, id: 102, skuCodes: []}]
            ]
        */
    }
    const sortBy = ['COLOUR', 'SIZE', 'SIZE_CD']
    const sortedFilteredVariants = filteredVariants.map(item => ({
        ...item,
        sortStatus: sortBy.includes(item?.descriptor) ? item?.descriptor : 'other',
    }))
    sortedFilteredVariants.sort(descriptorSort)
    customSort({ data: sortedFilteredVariants, sortBy: [...sortBy, 'other'], sortField: 'sortStatus' })
    const [firstVariant, secondVariant, thirdVariant] = sortedFilteredVariants

    return {
        firstVariant,
        secondVariant,
        thirdVariant,
    }
}

export default getVariantData
