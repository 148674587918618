import { RootState } from '../reducers'
import { profilePaymentState } from '../reducers/profile.payment.reducer'
import { PaymentUnitType } from '../models/profile.payment.interface'

export const profilePaymentSelector = (state: RootState): profilePaymentState => state.profilePayment

export const profilePaymentDataSelector = (state: RootState): PaymentUnitType[] | null =>
    state.profilePayment.profilePaymentData

export const hideRecommendationSelector = (state: RootState): boolean => state.profilePayment.hideRecommendation
