import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import { PREFIX } from '../config'
import { TextInputProps } from './TextInput.type'
import { getTextInputClassNames } from '../../utils/getTextInputClassNames'

/**
 * TextInput component
 * @return {JSX.Element} returns TextInput, Label and  Icon
 */

const TextInput: React.FC<TextInputProps> = props => {
    const {
        id,
        label,
        disabled,
        assistiveText,
        isAssistiveTextAlwaysVisible,
        icon,
        error,
        success,
        successMessage,
        size,
        placeholder,
        path,
        value,
        onChange,
        errorIcon,
        successIcon,
        displaySuccessIcon,
        maxLength,
        type,
        onBlur,
        onFocus,
        onKeyDown,
        customOnChange,
        isErrorFocus,
        ariaRequired,
        quantumMetricAttribute,
        autoComplete,
        tabIndex,
        inputMode,
        pattern,
        textInputRef,
        a11yRequiredLabel,
        isAllReqFieldFilled,
        noTextError,
        ariaDescribedBy,
        ariaLabelledBy,
        isErrorForSrOnly,
    } = props

    const [isFocused, setIsFocused] = useState(false)

    const componentClassName = `${PREFIX}-textinput`
    const quantumMetricDataAttribute = quantumMetricAttribute?.type
        ? { [`data-qm-${quantumMetricAttribute?.type}`]: quantumMetricAttribute?.value }
        : {}

    const reqFieldsFilled = isAllReqFieldFilled ? `${componentClassName}__container--req` : ''

    const errorFocusClass = getTextInputClassNames(
        error,
        isFocused,
        isErrorFocus,
        componentClassName,
        disabled,
        success,
        reqFieldsFilled,
    )

    /**
     * function executes when an element gets focus out
     * @param {React.FocusEvent<HTMLInputElement>} ev
     *
     * @return {void}
     */
    const onBlurEvent = (ev: React.FocusEvent<HTMLInputElement>): void => {
        setIsFocused(false)
        onBlur && onBlur(ev)
    }

    const onFocusEvent = (ev: React.FocusEvent<HTMLInputElement>): void => {
        setIsFocused(true)
        onFocus && onFocus(ev)
    }

    const onChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        customOnChange ? onChange(event) : onChange(event.target.value)
    }

    /**
     * @return { string }
     */
    const returnAccessibilityFieldErrorClass = () => (error ? `${PREFIX}-accessibility-field--error` : '')
    const renderInputContainer = (): JSX.Element => {
        return (
            <div className={`${componentClassName}__input-container`}>
                <input
                    id={id}
                    data-testid={id}
                    {...quantumMetricDataAttribute}
                    type={type || 'text'}
                    className={`${componentClassName}__input ${returnAccessibilityFieldErrorClass()} ${
                        value ? '' : `${PREFIX}-accessibility-field-empty-class--error`
                    }`}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChangeEvent}
                    onFocus={(event: React.FocusEvent<HTMLInputElement>) => onFocusEvent(event)}
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) => onBlurEvent(event)}
                    maxLength={maxLength}
                    onKeyDown={val => (onKeyDown ? onKeyDown(val) : null)}
                    aria-required={ariaRequired}
                    tabIndex={tabIndex}
                    aria-labelledby={`${id}_id ${ariaLabelledBy ? ariaLabelledBy : ''}`}
                    aria-describedby={`${id}_textError ${ariaDescribedBy ? ariaDescribedBy : ''}`}
                    inputMode={inputMode}
                    pattern={pattern}
                    ref={textInputRef}
                />
                <label id={`${id}_id`} className={`${componentClassName}__label`} htmlFor={id}>
                    {label} {a11yRequiredLabel}
                </label>
            </div>
        )
    }

    /**
     * function to render error scenario in text input
     *
     * @return {JSX.Element | null}
     */

    // noTextError is added to hide error text for KP initiative form.
    const renderErrorField = (): JSX.Element | null => {
        return (
            error &&
            !noTextError && (
                <div className={`${componentClassName}__msg ${isErrorForSrOnly ? 'sr-only' : ''}`} role="alert">
                    <div>
                        <Icon type={errorIcon} size="sm" path={path} />
                    </div>
                    <span className={`${componentClassName}__msg-text`} id={`${id}_textError`}>
                        {error}
                    </span>
                </div>
            )
        )
    }

    /**
     * function execute success scenario for text input field
     *
     * @return {JSX.Element | null}
     */
    const renderSuccessField = (): JSX.Element | null => {
        return (
            success &&
            successMessage && (
                <div className={`${componentClassName}__msg`} role="alert">
                    <div>
                        <Icon type="ct-checkmark" size="sm" path={path} />
                    </div>
                    <span className={`${componentClassName}__msg-text`}>{successMessage}</span>
                </div>
            )
        )
    }

    // eslint-disable-next-line sonar/no-nested-conditional
    const stateClass = error ? `${componentClassName}--error` : success ? `${componentClassName}--success` : ''
    const isRenderAssistiveText = () => isAssistiveTextAlwaysVisible || (!error && assistiveText)

    return (
        <div className={`${componentClassName} ${componentClassName}--${size} ${stateClass}`}>
            <div className={`${componentClassName}__container ${errorFocusClass}`} data-testid={'input-container'}>
                {renderInputContainer()}
                {icon && (
                    <button className={`${componentClassName}__icon`} aria-label="textinput-icon">
                        <Icon type={icon} size="md" path={path} />
                    </button>
                )}
            </div>
            {isRenderAssistiveText() && (
                <div className={`${componentClassName}__assistive-text`}>
                    {displaySuccessIcon && <Icon type={successIcon} size="sm" path={path} />}
                    {assistiveText}
                </div>
            )}
            {renderErrorField()}
            {renderSuccessField()}
        </div>
    )
}

TextInput.defaultProps = {
    disabled: false,
    size: 'default',
    placeholder: ' ',
    errorIcon: 'ct-warning-triangle-sm',
    successIcon: 'ct-checkmark',
    displaySuccessIcon: false,
}

TextInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    assistiveText: PropTypes.string,
    icon: PropTypes.string,
    error: PropTypes.string,
    success: PropTypes.bool,
    successMessage: PropTypes.string,
    size: PropTypes.oneOf(['mini', 'small', 'medium', 'large', 'default']),
    placeholder: PropTypes.string,
    path: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errorIcon: PropTypes.string,
    successIcon: PropTypes.string,
    displaySuccessIcon: PropTypes.bool,
    maxLength: PropTypes.number,
    type: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    customOnChange: PropTypes.bool,
    isErrorFocus: PropTypes.bool,
    ariaRequired: PropTypes.bool,
    inputMode: PropTypes.oneOf(['search', 'text', 'email', 'tel', 'url', 'none', 'numeric', 'decimal']),
    pattern: PropTypes.string,
    noTextError: PropTypes.bool,
    ariaDescribedBy: PropTypes.string,
}

export default TextInput
