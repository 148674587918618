import { PREFIX } from '../config'

export const mediaGalleryClassName = `${PREFIX}-media-gallery`
export const mediaGalleryViewerClassName = `${mediaGalleryClassName}-viewer`
export const staticImageContainerClassName = `${PREFIX}-static_image_container`
export const hideAkamaiViewerImageClassName = 'hide_akamai-viewer-image'
export const mediaGalleryNoPaddingClassName = 'media-gallery-no-padding'
export const thumbnailImageCountMobile = 3
export const thumbnailNavWidthMobile = 245
export const thumbnailNavWidthTablet = 346
export const hideVideoPlayButton = 'is-hidden'

export const ELEMENT_NOT_FOUND = -1
export const ONE_ELEMENT_LENGTH = 1
export const ALL_MEDIA_SELECTOR = 'img, video'
export const THUMBNAIL_MEDIA_SELECTOR = `.snapper_nav ${ALL_MEDIA_SELECTOR}`
export const AKAMAI_IMAGE_LOAD_EVENT = 'akamai_image_load_event'

export const mediaGalleryConstants = {
    minimumImageLength: 1, // minimum number of images length
    thumbnailPrevArrowClass: `${mediaGalleryViewerClassName}__thumb-prev`,
    thumbnailNextArrowClass: `${mediaGalleryViewerClassName}__thumb-next`,
    thumbnailPlayButtonClass: `${mediaGalleryViewerClassName}__play-icon`,
    playButtonIcon: 'ct-play',
    closeIconClass: `${mediaGalleryViewerClassName}__close`,
    prevArrowIcon: 'ct-chevron-left',
    nextArrowIcon: 'ct-chevron-right',
    closeIcon: 'ct-close',
    minimumThumbnailCount: 2,
    maximumThumbnailCount: 4,
    thumbnailNavWidthMobile: 346,
    defaultStaticImageSize: 1244,
    defaultAkamaiTagname: 'akamai-untagged',
    mediaGalleryThumbnailImageWidth: 68,
    limitThreshold: 1000000,
    mediaGalleryParentElement: '[data-akamai-viewer-tag][style*=display\\:block]',
    videoGalleryParentElement: '[data-akamai-viewer-tag="akamai-untagged"]',
    mediaGalleryViewerClassNameFullscreen: `${PREFIX}-media-gallery-viewer--fullscreen`,
}
