import { useCallback } from 'react'
import { productSelector } from '../redux/selectors/product.selectors'
import { useAppSelector } from './react-redux.hook'
import { loyaltyBadge } from '../components/BuyBox/BuyBox.type'
import { getFormattedPriceWithLocale } from '../utils/formatPrice'

/**
 * This function used to calculate loyalty value
 * @param {loyaltyBadge} loyaltyObj - loyalty object
 * @param {boolean} isStaggered - flag that indicates if product is staggered
 * @param {number} qtySelectorInput - quantity selector input
 * @returns {loyaltyBadge | undefined} return calculated loyalty value
 */
export const useCalculatedLoyaltyValue = (
    loyaltyObj: loyaltyBadge,
    isStaggered?: boolean,
    qtySelectorInput = 0,
): loyaltyBadge | undefined => {
    const { frontQuantitySelected, rearQuantitySelected, quantitySelected } = useAppSelector(productSelector)
    const { OfferRewardValue = '', RewardPercentage, OfferRule } = loyaltyObj?.Bonus || {}

    /**
     * Calculates the loyalty value based on the provided quantities and loyalty object.
     * @returns {loyaltyBadge | undefined} The calculated loyalty badge object or undefined.
     */
    const calculatedLoyaltyValue = useCallback((): loyaltyBadge | undefined => {
        let quantity: number
        if (isStaggered) {
            if (qtySelectorInput === frontQuantitySelected) {
                quantity = frontQuantitySelected
            } else {
                quantity = rearQuantitySelected ?? quantitySelected
            }
        } else {
            quantity = qtySelectorInput
        }

        return {
            ...loyaltyObj,
            Bonus: {
                OfferRewardValue: getFormattedPriceWithLocale(Number(OfferRewardValue) * quantity),
                OfferRule: OfferRule || '',
                RewardPercentage: RewardPercentage || '',
            },
        }
    }, [
        OfferRewardValue,
        OfferRule,
        RewardPercentage,
        frontQuantitySelected,
        isStaggered,
        loyaltyObj,
        qtySelectorInput,
        quantitySelected,
        rearQuantitySelected,
    ])

    return calculatedLoyaltyValue()
}
