import React from 'react'
import PropTypes from 'prop-types'
import { RecommendationsProps, ProductCardType } from './Recommendations.type'
import { ProductCardType as ProductCardGrid } from '../ProductGridView/ProductGrid.types'
import ProductCard from '../ProductReusableCard'
import { PREFIX } from '../config'
import { addDynamicToken, replaceStrWithDynamicVal } from '../../utils/replaceStrWithDynamicVal'
import { getFormattedPriceValue } from '../ProductReusableCard/instanceCheckingUtil'
import { appendQueryParamsToUrl, magicNumber } from '../../utils'
import { getAccessibilityId } from '../../utils/getAccessibilityId'
import { CarouselSwiper, getPageType } from '../..'
import { plaCarouselSchemaId } from './Recommendations.constant'
import ProductListingAd from './ProductListingAd'
import { renderClearanceBadge } from '../../utils/renderClearanceBadge'

/**
 * Recommendations component
 * @param {RecommendationsProps} props -  title, productList, path, priceProps, productCardClick, maximumNumberOfRecommendations, a11yCarouselPreviousLabel, a11yCarouselNextLabel, a11yStrikeOutPrice
 * @return {JSX.Element} returns Recommendations component
 */
const Recommendations: React.FC<RecommendationsProps> = props => {
    const {
        title,
        productList,
        path,
        priceProps,
        productCardClick,
        carouselCallbackHandler,
        maximumNumberOfRecommendations,
        a11yCarouselPreviousLabel,
        a11yCarouselNextLabel,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        badgePriorities,
        language,
        imageDataComponentName,
        enableBadges,
        enableSaveStory,
        returnPolicy,
        a11yClickToReadFootnote,
        selectedSchemaId,
        sliderRef,
        a11yCloseIconLabel,
        a11yTooltipIcon,
        enableBoldedBrandName = false,
        plaBreakpointSettings,
        plaInfiniteScrolling,
        plaCenterInsufficientSlides,
        plaDynamicBullet,
        breakpointSettings,
        centerInsufficientSlides,
        infiniteScrolling,
        dynamicBullet,
        enableAddToCartOptions,
        addButton,
        optionsButton,
        clickedATCCardIdx,
        addToCartBtnClick,
        showAtcBtnSpinner,
        addToCartLabel,
        quickLookLabel,
        enableATCForSingleSkuMiniPdp,
        isPriceRangeViewEnabled = false,
    } = props

    const {
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        threshold,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
    } = priceProps

    const productProps = {
        ratingsAndReview: true,
        featureHeaderLabel: '',
        variantAvailableMsg: '',
        moreLabel: '',
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        thresholdValue: threshold,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
        enableAddToCartOptions,
        addButton,
        optionsButton,
    }

    /**
     * gets the ProductCarousel List
     *
     * @return {JSX.Element} returns array of JSX.Element
     */
    const getProductCarouselList = (): JSX.Element[] => {
        return productList.slice(0, maximumNumberOfRecommendations).map((product: ProductCardType, index: number) => {
            const {
                type,
                title: productTitle,
                brand,
                images,
                rating,
                ratingsCount,
                timeTestedPrice,
                priceShortMessage,
                priceLongMessage,
                longDescription,
                featureBullets,
                onlineOnly,
                discount,
                badges,
                currentPrice,
                feeMessages,
                url,
                code,
                isOnSale,
            } = product

            const pageType = getPageType()
            const urlParams = {
                rrecName: title,
                rrecReferrer: pageType,
                rrecProductId: code,
                rrecProductSlot: index + magicNumber.ONE,
                rrecSchemeId: selectedSchemaId,
                rrec: true,
            }

            /**
             * Function returns true when we need to show spinner on ATC/Option btn
             * @param { number } idx index of product card
             * @returns {boolean} returns boolean
             */
            const showUpdatedATCSpinner = (idx: number): boolean => {
                return clickedATCCardIdx === idx ? showAtcBtnSpinner : false
            }

            return (
                <ProductCard
                    isPriceRangeViewEnabled={isPriceRangeViewEnabled}
                    product={product as unknown as ProductCardGrid}
                    url={appendQueryParamsToUrl(url, urlParams)}
                    key={index}
                    productProps={productProps}
                    path={path}
                    idx={index}
                    cardType="grid"
                    title={productTitle}
                    type={type}
                    code={null}
                    brand={brand}
                    images={images}
                    rating={rating}
                    ratingsCount={ratingsCount || 0}
                    timeTestedPrice={timeTestedPrice}
                    discount={discount}
                    priceShortMessage={priceShortMessage}
                    priceLongMessage={priceLongMessage}
                    longDescription={longDescription}
                    featureBullets={featureBullets}
                    onlineOnly={onlineOnly}
                    productCardClick={(event, productdata, idx) =>
                        productCardClick(event, productdata as ProductCardType, idx)
                    }
                    a11yStrikeOutPrice={a11yStrikeOutPrice}
                    a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                    showRatingSection={true}
                    badges={renderClearanceBadge(badges, enableBadges)}
                    badgePriorities={badgePriorities}
                    language={language}
                    feeTitle={
                        feeMessages?.[0]?.value
                            ? replaceStrWithDynamicVal(
                                  addDynamicToken(feeMessages?.[0]?.feeTitle, '$x'),
                                  getFormattedPriceValue(language, feeMessages?.[0]?.value),
                              )
                            : ''
                    }
                    feeDisclaimerType={feeMessages?.[0]?.type}
                    feeDisclaimerMessage={feeMessages?.[0]?.feeDisclaimerMessage}
                    feeDisclaimerTitle={feeMessages?.[0]?.feeDisclaimerTitle}
                    currentPrice={currentPrice}
                    nowFromLabel={nowFromLabel}
                    saveFromLabel={saveFromLabel}
                    wasFromLabel={wasFromLabel}
                    fromLabel={fromLabel}
                    imageDataComponentName={imageDataComponentName}
                    returnPolicy={returnPolicy}
                    unitPriceLabel={unitPriceLabel}
                    promotionalPriceLabel={promotionalPriceLabel}
                    clearancePriceLabel={clearancePriceLabel}
                    thresholdValue={threshold}
                    enableSaveStory={enableSaveStory}
                    productDataId={code}
                    a11yClickToReadFootnote={a11yClickToReadFootnote}
                    accessibilityId={getAccessibilityId(
                        selectedSchemaId,
                        product?.skus?.[0]?.code ? product?.skus?.[0]?.code : code,
                    )}
                    a11yCloseIconLabel={a11yCloseIconLabel}
                    a11yTooltipIcon={a11yTooltipIcon}
                    boldBrand={enableBoldedBrandName}
                    isOnSale={isOnSale}
                    enableBadges={enableBadges}
                    showSaleClearanceBadge={true}
                    swiperLazyLoading={true}
                    addToCartBtnClick={(event, productdata, idx, isTireWheel, selectedSchemaID) =>
                        addToCartBtnClick(event, productdata, idx, isTireWheel, selectedSchemaID)
                    }
                    showAtcBtnSpinner={showUpdatedATCSpinner(index)}
                    addToCartLabel={addToCartLabel}
                    selectedSchemaId={selectedSchemaId}
                    quickLookLabel={quickLookLabel}
                    enableATCForSingleSkuMiniPdp={enableATCForSingleSkuMiniPdp}
                />
            )
        })
    }

    return (
        <div className={`${PREFIX}-recommendations`}>
            <div className={`${PREFIX}-recommendations__title`} data-testid="recommendations-title">
                <h2>{title}</h2>
            </div>
            {productList &&
                productList.length > 0 &&
                (plaCarouselSchemaId.includes(selectedSchemaId) ? (
                    <ProductListingAd
                        carouselList={getProductCarouselList()}
                        breakpointSettings={plaBreakpointSettings}
                        infiniteScrolling={plaInfiniteScrolling}
                        centerInsufficientSlides={plaCenterInsufficientSlides}
                        dynamicBullet={plaDynamicBullet}
                        sliderRef={sliderRef}
                    />
                ) : (
                    <div className={`${PREFIX}-product__grid-view`}>
                        <CarouselSwiper
                            carouselList={getProductCarouselList()}
                            carouselCallbackHandler={carouselCallbackHandler}
                            sliderRef={sliderRef}
                            a11yCarouselPreviousLabel={a11yCarouselPreviousLabel}
                            a11yCarouselNextLabel={a11yCarouselNextLabel}
                            breakpointSettings={breakpointSettings}
                            centerInsufficientSlides={centerInsufficientSlides}
                            infiniteScrolling={infiniteScrolling}
                            dynamicBullet={dynamicBullet}
                        />
                    </div>
                ))}
        </div>
    )
}

Recommendations.propTypes = {
    title: PropTypes.string,
    productList: PropTypes.array,
    path: PropTypes.string,
    priceProps: PropTypes.object,
    productCardClick: PropTypes.func,
    maximumNumberOfRecommendations: PropTypes.number,
    a11yCarouselPreviousLabel: PropTypes.string,
    a11yCarouselNextLabel: PropTypes.string,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    language: PropTypes.string.isRequired,
    imageDataComponentName: PropTypes.string,
    enableBadges: PropTypes.bool,
    enableSaveStory: PropTypes.bool,
    returnPolicy: PropTypes.func,
    a11yClickToReadFootnote: PropTypes.string,
    selectedSchemaId: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
}

Recommendations.displayName = 'Recommendations'

export default Recommendations
