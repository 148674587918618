const getQueryString = (params: string, partBy: string): string => {
    const urlParams = new URLSearchParams(params)
    const queryString = urlParams.get(partBy)
    return queryString ? queryString : ''
}

export default getQueryString

/**
 * get query string params seperated by semi colon
 * @function
 * @param {string} path - url path.
 * @param {string} name - name of the query params.
 * @return {string} match - matched word
 */
export const getQueryStringParams = (path: string, name: string): string => {
    // eslint-disable-next-line prefer-template
    const match = RegExp('[?&;]' + name + '=([^&;]*)').exec(path)
    const index = 1
    return match ? decodeURIComponent(match[index]) : ''
}
