import React, { useEffect, useRef } from 'react'

import { PREFIX } from '../config'
import {
    AccountsDropdownPropType,
    AccountOptions,
    MobileNavigationListItemType,
    CTATypes,
} from './AccountDropdown.type'
import { MenuAccessibility } from '../../utils'
import Button from '../Button'
import Icon from '../Icon'
import { maximumAccountManagementLinks } from '../../globalConstants/global.constant'
import SkeletonComponent from '../Skeleton/SkeletonComponent'
import { externalNavigation } from '../../utils/getExternalLinks'
import NotificationBadge from '../NotificationBadge'
import { areAllParamsValid } from '../../utils/validParams'
import { checkDataLength } from '../../utils/checkDataLength'
import ButtonV2 from '../ButtonV2'
import { Size, State, Variation } from '../../types'

/**
 * Accounts Drop Down
 *
 * @interface AccountsDropdownPropType
 * @returns {JSX.Element}
 */
// eslint-disable-next-line max-lines-per-function
const AccountsDropdown: React.FC<AccountsDropdownPropType> = ({
    a11yAlertBadgeValue,
    accountButton,
    accountOptions,
    accountsLabel,
    featureType,
    isAccountDashboard,
    isDashboard,
    isFlyout,
    isGigyaLoaded,
    isLoggedIn,
    isShowTriangle,
    menuEscapeHandler,
    mobileNavigation,
    offersBadgeCountThreshold,
    onSignInButtonClickHandler,
    orText = '',
    registerLabel = '',
    registerLink = '',
    selectLastMenuItem,
    signInLabel = '',
    signOutButtonClick,
    signOutLabel = '',
    unActivatedOffersCount,
    weeklyOffersLink,
    ctaList,
}: AccountsDropdownPropType): JSX.Element => {
    const className = `${PREFIX}-pencil-banner`
    const runOnce = useRef(0)
    const cleanUpRef = useRef<() => void>(null)

    /**
     * useEffect for adding event listeners and creating menuAccessibility
     */
    useEffect(() => {
        if (!isAccountDashboard && runOnce.current === 0) {
            runOnce.current++
            const menuDropDownParent = isShowTriangle ? 'accountsMenuDropDown' : 'accountMenuDropDown'
            const menuList = new MenuAccessibility(menuDropDownParent)
            menuList.init(selectLastMenuItem)
            document.addEventListener('keydown', menuEscapeHandler)
            cleanUpRef.current = () => {
                document.removeEventListener('keydown', menuEscapeHandler)
                menuList.destroy()
            }
            accountButton?.current?.focus()
        }
    }, [isAccountDashboard, selectLastMenuItem, menuEscapeHandler, isShowTriangle, accountButton])

    useEffect(() => {
        return () => {
            const cleanUp = cleanUpRef.current
            if (cleanUp !== null) {
                cleanUp()
            }
        }
    }, [])

    /**
     * renderHorizontalLine for account Dashboard
     * @returns {JSX.Element | null}
     */
    const renderHorizontalLine = (): JSX.Element | null => (isAccountDashboard ? <hr /> : null)

    /**
     * accountOptionsAfterRemovingExtraRecords is for removing additional records from Account Management Links
     * @returns {AccountOptions[]}
     */
    const accountOptionsAfterRemovingExtraRecords = (): AccountOptions[] => {
        const isAccountOptions = accountOptions ? accountOptions.length : 0
        return !isShowTriangle && isAccountOptions > maximumAccountManagementLinks
            ? accountOptions.slice(0, -(accountOptions.length - maximumAccountManagementLinks))
            : accountOptions
    }

    /**
     * render skeleton or sign-in text
     * @returns {JSX.Element}
     */
    const showSignIn = (): JSX.Element => {
        return (
            !isLoggedIn &&
            (isGigyaLoaded ? (
                <>
                    <div className={`${className}__authentication`}>
                        <button
                            role="menuitem"
                            className={`${className}__authentication--sign-in`}
                            onClick={onSignInButtonClickHandler}
                            data-link-value={signInLabel}
                            data-feature-type={featureType}>
                            {signInLabel}
                        </button>
                        <span className={`${className}__middle-text`}>{orText}</span>
                        <a
                            role="menuitem"
                            href={registerLink}
                            className={`${className}__authentication--register`}
                            data-link-value={registerLabel}
                            data-feature-type={featureType}>
                            {registerLabel}
                        </a>
                    </div>
                    {renderHorizontalLine()}
                </>
            ) : (
                <div className={`${className}__sigin-in-skeleton`}>
                    <SkeletonComponent />
                </div>
            ))
        )
    }

    /**
     * render Accounts Dropdown
     * @returns {JSX.Element}
     */
    const showRewardsSection = (): JSX.Element => {
        return (
            !isShowTriangle && (
                <>
                    {showSignIn()}
                    {renderHorizontalLine()}
                </>
            )
        )
    }

    /**
     * render Accounts Dropdown
     * @param {boolean} isWeeklyOffer
     * @returns {JSX.Element}
     */
    const renderBadge = (isWeeklyOffer: boolean): JSX.Element => {
        const showBadge =
            unActivatedOffersCount &&
            unActivatedOffersCount !== 0 &&
            isWeeklyOffer &&
            unActivatedOffersCount <= offersBadgeCountThreshold
        if (showBadge) {
            return (
                <NotificationBadge
                    value={unActivatedOffersCount}
                    a11y={{ label: a11yAlertBadgeValue }}
                    notificationClass={`${PREFIX}-account-settings__warning`}
                    color={State.ERROR}
                    size={Size.SMALL}
                />
            )
        }
        return null
    }

    /**
     * render Menu Items on Primary navigation mobile view
     * @param {MobileNavigationListItemType[]} items
     * @returns {JSX.Element[]}
     */
    const renderMobileNavigation = (items: MobileNavigationListItemType[]): JSX.Element[] => {
        return items.map((item: MobileNavigationListItemType, i: number) => (
            <li
                key={i}
                className={`${className}__account-options-item${externalNavigation(item.linkUrl) ? '__external' : ''}`}>
                <a
                    className={`${PREFIX}-xs-inline-block`}
                    href={item.linkUrl}
                    title={item.linkLabel}
                    data-link-value={item.linkLabel}
                    target={`${externalNavigation(item.linkUrl) ? '_blank' : '_self'}`}>
                    {item.linkLabel}
                    {externalNavigation(item.linkUrl) && <Icon type="ct-north-east" size="lg" />}
                </a>
            </li>
        ))
    }

    /**
     * render Menu Items on Primary navigation mobile view with title
     * @param {string} title
     * @param {MobileNavigationListItemType[]} items
     * @returns {JSX.Element}
     */
    const renderMobileNavigationItems = (): JSX.Element | null => {
        if (isFlyout && !!mobileNavigation) {
            return (
                <>
                    <h4 className={`${className}__header ${PREFIX}-body-lg`}>{mobileNavigation.categoryTitle}</h4>
                    <ul className={`${className}__account-options`}>
                        {renderMobileNavigation(mobileNavigation.categoryItems)}
                    </ul>
                </>
            )
        }
        return null
    }

    const accountsDropDownSection = `${className}__dropdown${isShowTriangle ? '--triangle' : '--dashboard'}`
    const linkActive = (showDashboad, link: AccountOptions) =>
        showDashboad && link.isActive ? `${className}__account-options-item--active` : ''

    // Simplified till MTE-178 will be redesigned
    // const accountOptionsData = accountOptionsAfterRemovingExtraRecords()
    const buttonType = isShowTriangle ? 'secondary_reverse' : 'secondary'

    /**
     * renders ctaList based on button version
     * @returns {JSX.Element[]}
     */
    const renderCTA = (): JSX.Element[] => {
        return (
            checkDataLength(ctaList) &&
            ctaList?.map((cta: CTATypes, i: number): JSX.Element => {
                return renderV2Cta(cta, i)
            })
        )
    }

    /**
     * renders cta as Button version 2
     * @param {CTATypes} cta // cta as per button version 2
     * @param {number} i // index
     * @returns {JSX.Element}
     */
    const renderV2Cta = (cta: CTATypes, i: number): JSX.Element => {
        return (
            <div className={`${className}__cta`}>
                {cta.url ? (
                    <a
                        href={cta.url}
                        key={i}
                        id={cta.id}
                        className={`${PREFIX}-button2
                     ${PREFIX}-button2--${cta.variant || 'primary'}
                     ${PREFIX}-button2--${cta.variant || 'primary'}-${cta.color || 'default'}
                     ${PREFIX}-xs-justify-center
                     ${cta.customClass ?? ''}`}>
                        {cta.children}
                        {cta.icon && <Icon type={'ct-right-arrow-black'} size={cta.size} />}
                    </a>
                ) : (
                    <ButtonV2
                        variant={Variation.PRIMARY}
                        customClass={
                            cta.customClass ? `${cta.customClass} ${PREFIX}-xs-justify-center ${PREFIX}-xs-flex` : ''
                        }
                        color={cta?.color}
                        size={Size.MEDIUM}
                        onClick={cta.onClick}
                        key={i}
                        reverse={true}
                        icon={cta.icon ? { type: 'ct-right-arrow-black', size: 'lg' } : null}
                        a11y={cta.a11y}>
                        {cta.children}
                    </ButtonV2>
                )}
            </div>
        )
    }

    return (
        <div id="accountsMenuDropDown" aria-labelledby="accountButton" className={accountsDropDownSection}>
            {showRewardsSection()}
            <div className={`${className}__container${isShowTriangle ? '--triangle' : ''}`}>
                {areAllParamsValid(isFlyout, !!accountsLabel) && (
                    <h4
                        className={`${
                            isShowTriangle ? `${className}__header` : `${className}__section-header`
                        } ${PREFIX}-body-lg`}>
                        {accountsLabel}
                    </h4>
                )}
                {Array.isArray(accountOptionsAfterRemovingExtraRecords()) && (
                    <ul className={`${className}__account-options`}>
                        {accountOptionsAfterRemovingExtraRecords().map(link => {
                            const isWeeklyOffer = Boolean(link.navigationRedirectionLink === weeklyOffersLink)
                            return (
                                <li
                                    key={link.navigationOptionsLabel}
                                    className={`${linkActive(isDashboard, link)} ${className}__account-options-item${
                                        isShowTriangle && externalNavigation(link.navigationRedirectionLink)
                                            ? '__external'
                                            : ''
                                    }`}>
                                    <a
                                        aria-current={linkActive(isDashboard, link) ? 'page' : null}
                                        href={link.navigationRedirectionLink}
                                        target={link.navigationOptionsTarget}
                                        data-link-value={link.navigationOptionsLabel}
                                        data-feature-type={featureType}
                                        className={`${PREFIX}-xs-inline-block`}>
                                        {link.navigationOptionsLabel}
                                        {isShowTriangle && externalNavigation(link.navigationRedirectionLink) && (
                                            <Icon type="ct-north-east" size="lg" />
                                        )}
                                        {renderBadge(isWeeklyOffer)}
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                )}
                {renderMobileNavigationItems()}
                {isLoggedIn && (
                    <>
                        {renderCTA()}
                        <div className={`${className}__sign-out`}>
                            <Button type={buttonType} size="mini" onClick={signOutButtonClick}>
                                {signOutLabel}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default AccountsDropdown
