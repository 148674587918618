import React from 'react'
import Icon from '../Icon/Icon'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'
import { CategoryIconsProps } from './Icon.types'

/**
 * CategoryIcons component
 * @param {CategoryIconsProps} props
 * @returns {JSX.Element} returns side nav icon
 */
const CategoryIcons: React.FC<CategoryIconsProps> = (props: CategoryIconsProps): JSX.Element => {
    const { name, url, size, type, onClick, index } = props
    return (
        <SanitizeStringContentWrapper stringContent={url}>
            {memoizedStringContent => (
                <a href={memoizedStringContent} onClick={() => onClick(name, index)}>
                    <Icon size={size} type={type} />
                    {name}
                </a>
            )}
        </SanitizeStringContentWrapper>
    )
}

export default CategoryIcons
