import React, { createContext, PropsWithChildren, ReactElement, useContext } from 'react'

type ContextType = {
    aoaConfirmationFlowEnabledToggle: boolean
}

const Context = createContext<ContextType>({ aoaConfirmationFlowEnabledToggle: false })

export const useAemTogglesContext = (): ContextType => {
    return useContext(Context)
}

/**
 * AEMToggleContext is a Context Provider that provides the value of AEM feature toggles to its child components.
 *
 * This allows child components to consume the AEM Toggle values and adjust their behaviour accordingly.
 *
 * @component
 * @example
 * <AEMToggleContext aoaConfirmationFlowEnabledToggle={true}>
 *     <ChildComponent />
 * </AEMToggleContext>
 *
 * @param {object} props - The properties that define the feature toggles' states.
 * @param {ReactNode} props.children - The child components that AEMToggleContext will provide its value to.
 * @param {boolean} props.aoaConfirmationFlowEnabledToggle - The state of the aoaConfirmationFlowEnabled toggle.
 *
 * @returns {ReactElement} A Context Provider that supplies the AEM feature toggle values to its children.
 */
function AEMToggleContext({ children, ...context }: PropsWithChildren<ContextType>): ReactElement {
    return <Context.Provider value={context}>{children}</Context.Provider>
}

export default AEMToggleContext
