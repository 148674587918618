import { ProductSku } from '../VariantsWrapper/VariantsWrapper.type'

/**
 * Interface for Price
 * @interface
 */
export interface Price {
    value: number | null
    maxPrice: number | null
    minPrice: number | null
}

/**
 * Interface for PriceMessage
 * @interface
 */
export interface PriceMessage {
    label?: string | null
    tooltip?: string | null
}

/**
 * Interface for SaleMessagesRules
 * @interface
 */
export interface SaleMessagesRule {
    minPercent: number
    absoluteValueThreshold: number
    maxPercent: number
    upToSaveMessage: string
    absoluteCutType: string
    saveMessage: string
}

/**
 * Interface for PriceType
 * @interface
 */
export interface PriceType {
    isRangeView?: boolean
    priceStyle?: string
    promotionalPriceLabel?: string
    thresholdValue?: number
    unitPriceLabel?: string
    clearancePriceLabel?: string
    a11yPriceRangeFrom?: string
    a11yPriceRangeTo?: string
    hideSaveLabel?: boolean
    a11yStrikeOutPrice?: string
    a11yStrikeOutPriceRange?: string
    a11yTooltipIcon?: string
    a11yCloseIconLabel?: string
    feeTitle?: string
    feeDisclaimerMessage?: string
    feeDisclaimerTitle?: string
    originalPrice?: Price
    currentPrice?: Price
    displayWasLabel?: boolean
    discountValue?: Price
    language?: string
    eachLabel?: string
    isMoreQuantityCartPage?: boolean
    priceValue?: Price
    priceMessage?: PriceMessage[]
    isAutomotiveEachLabel?: boolean
    scrollToFooter?: (event) => void
    a11yRecycleFeesTriangleIcon?: string
    nowFromLabel?: string
    nowToLabel?: string
    saveFromLabel?: string
    wasFromLabel?: string
    wasToLabel?: string
    fromLabel?: string
    toLabel?: string
    feeDisclaimerType?: string
    isVehicleInformationSaved?: boolean
    isWheelOrTirePDP?: boolean
    totalCurrentPrice?: Price
    totalOriginalPrice?: Price
    setOfFourLabel?: string
    setOfFourNowLabel?: string
    setOfTwoLabel?: string
    setOfTwoNowLabel?: string
    isWheelOrTire?: boolean
    productSkus?: ProductSku[]
    isWishList?: boolean
    isStaggered?: boolean
    isStaggeredFront?: boolean
    showFromNow?: boolean
    showToNow?: boolean
    isRebate?: boolean
    isShoppingCartPage?: boolean
    showEachTextForSaveLabel?: boolean
    overridePriceHeight?: boolean
    showSaveMessage?: boolean
    saveMessage?: string
    showDiscountedPrice?: boolean
    highlightDiscountedPrice?: boolean
    totalPrice?: Price
    isAppliedPromoExist?: boolean
    enablePdpPricingEnhancement?: boolean
    a11yClickToReadFootnote?: string
    accessibilityPriceId?: string
    ariaHidden?: boolean
    isOnSaleOrClearance?: boolean
    fromAutomotiveSpecification?: boolean
    plusMinusSymbol?: string
    isProductLevelPDP?: boolean
    isModalPresent?: boolean
    staggeredfulfillmentData?: ProductSku[]
    isOnSale?: boolean
}

export enum FeeType {
    ECO_FEE = 'ECO_FEE',
    CORE_CHARGE = 'CORE_CHARGE',
    TIRE_RECYCLE_FEE = 'TIRE_RECYCLE_FEE',
}

/**
 * Interface for FeeMessage
 * @interface
 */
export interface FeeMessage {
    feeDisclaimerTitle?: string
    feeDisclaimerMessage?: string
    feeTitle?: string
    type?: string
    value?: number
}

/**
 * Interface for SaleCut
 * @interface
 */
export interface SaleCut {
    percentage?: number | null
    value?: number | null
}

export interface RangePriceOrFromPriceProps {
    price: Price
    language: string
    isRangeView?: boolean
    fromLabel?: string | JSX.Element
    toLabel?: string | JSX.Element
    a11yFromLabel?: string
    a11yFToLabel?: string
    showToNow?: boolean
}

export interface TotalRangePriceOrFromPriceProps {
    isMoreQuantityCartPage?: boolean
    priceValue?: Price
    currentPrice?: Price
    originalPrice?: Price
    language?: string
    showDiscountedPrice?: boolean
    totalPrice?: Price
    isAppliedPromoExist?: boolean
    isRangeView?: boolean
    fromLabel?: string | JSX.Element
    toLabel?: string | JSX.Element
    showToNow?: boolean
    a11yFromLabel?: string
    a11yFToLabel?: string
}

export interface WrapByLabelProps {
    inputPrice: string
    label: string | JSX.Element
    language: string
}
