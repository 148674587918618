import React, { useEffect, useMemo, useState } from 'react'
import {
    CustomContentCard,
    BrazeCardType,
    getUserProfileStatus,
    BrazeContentPropsType,
    ContentCardType,
    isArrayNotEmpty,
    CarouselSwiper,
} from '@nl/lib'
import brazeService from '../../services/brazeService/braze.service'
import { isAuthFlowExecutedSelector, userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { PREFIX } from '../../config'
import { useAppSelector } from '../../hooks/react-redux.hook'
import { breakpointSettings } from '../MiniPDP/components/ImageGallery/ImageGallery.constant'
import { accessibilitySelector } from '../../redux/selectors/commonContent.selectors'

/**
 * BrazeContent card Component
 * @param {BrazeContentPropsType} props - The props
 * @returns {JSX.Element} Renders BrazeContentCard
 */
const BrazeContentCard: React.FC<BrazeContentPropsType> = ({ ...props }): JSX.Element => {
    const { position } = props
    const [cards, setCards] = useState<BrazeCardType[]>([])
    const isAuthFlowExecuted = useAppSelector(isAuthFlowExecutedSelector)
    const userProfileData = useAppSelector(userProfileDataSelector)
    const { a11yCarouselNextLabel, a11yCarouselPreviousLabel } = useAppSelector(accessibilitySelector)
    const { hasLoyalty } = useMemo(
        () => getUserProfileStatus(isAuthFlowExecuted, userProfileData),
        [isAuthFlowExecuted, userProfileData],
    )
    const componentClassName = `${PREFIX}-content-card`

    // useeffect to render content card detail and set
    useEffect(() => {
        if (hasLoyalty) {
            // To render custom content card trigger subscribeToContentCardsUpdates event
            brazeService.subscribeToContentCardsUpdates(((event: ContentCardType): void => {
                setCards(event.cards)
            }) as unknown as ContentCardType)
        }
        // To manual refresh of Braze Content trigger requestContentCardsRefresh
        brazeService.requestContentCardsRefresh()
    }, [hasLoyalty])

    const visibleCards: BrazeCardType[] =
        (isArrayNotEmpty(cards) &&
            cards.filter((card: BrazeCardType) => card.extras['web_card_location'] === position)) ||
        []

    // useEffect to log content card impressions
    useEffect(() => {
        if (isArrayNotEmpty(visibleCards) && hasLoyalty) {
            brazeService.logContentCardImpressions(visibleCards)
        }
    }, [hasLoyalty, visibleCards])

    /**
     * Function to close the PTE Flyout.
     * @param {BrazeCardType} customCard
     * @returns {void}
     */
    const triggerEvent = (customCard: BrazeCardType): void => {
        customCard && brazeService.logContentCardClick(customCard)
    }

    /**
     * Function to render content cards
     * @returns {JSX.Element[]} - Returns content cards
     */
    const renderContentCards = (): JSX.Element[] => {
        return visibleCards?.map((card: BrazeCardType) => (
            <div className={componentClassName} key={card.id}>
                <CustomContentCard
                    cardInfo={card}
                    contentPosition={card.extras['web_position'] as string}
                    type={card.extras['web_type'] as string}
                    triggerEvent={triggerEvent}
                />
            </div>
        ))
    }

    return (
        <>
            {isArrayNotEmpty(visibleCards) && visibleCards.length > 1 ? (
                <div className={`${componentClassName}__carousel`}>
                    <CarouselSwiper
                        carouselList={renderContentCards()}
                        a11yCarouselPreviousLabel={a11yCarouselPreviousLabel}
                        a11yCarouselNextLabel={a11yCarouselNextLabel}
                        breakpointSettings={breakpointSettings}
                        dynamicBullet={{ show: true, count: 3 }}
                        arrowBottom={true}
                        centerInsufficientSlides={true}
                        infiniteScrolling={true}
                    />
                </div>
            ) : (
                renderContentCards()
            )}
        </>
    )
}

export default BrazeContentCard
