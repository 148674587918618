import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { iconProptypes } from './Icon.types'
import { PREFIX } from '../config'
import { magicNumber } from '../../utils/magicNumber'

/**
 * function to generate random ID
 * @return { string }
 */
const generateId = (): string => {
    return `-${Math.random().toString(magicNumber.THIRTYSIX).substr(magicNumber.TWO, magicNumber.NINE)}`
}

/**
 * Icon component
 * @param {iconProptypes} props
 * @return {JSX.Element} returns icon and a link attached to icon as a constant named showcase
 */
const Icon: React.FC<iconProptypes> = ({
    type = 'ct-accounts',
    size = 'lg',
    title,
    path,
    decorative = true,
    iconSpritePath = '/icon/global-icons.svg', // this needs to be passed from the parent component, putting a default value here for now
}): JSX.Element => {
    const elementRef = useRef<SVGSVGElement>(null)
    const divElement = elementRef.current
    let parentElementhasLink = false
    if (divElement) {
        const parentElement = divElement.parentElement
        const parentElementhasAnchor = divElement.parentNode.nodeName.toLowerCase() === 'a'
        if (parentElementhasAnchor) {
            parentElementhasLink = parentElement.hasAttribute('href')
        }
    }

    const hrefLink = !path ? `${iconSpritePath}#${type}` : `${path}#${type}`

    const id = generateId()
    const useElement = title ? (
        <use xlinkHref={hrefLink}>
            <title>{title}</title>
        </use>
    ) : (
        <use xlinkHref={hrefLink}></use>
    )
    const renderParentLink = (): JSX.Element => {
        return !parentElementhasLink ? (
            <svg
                className={`${PREFIX}-icon  ${PREFIX}-icon-${type} ${PREFIX}-icon--${size} `}
                ref={elementRef}
                aria-labelledby={`${type}-Title${id}`}
                role="img">
                {useElement}
            </svg>
        ) : (
            <svg
                className={`${PREFIX}-icon  ${PREFIX}-icon-${type} ${PREFIX}-icon--${size} `}
                ref={elementRef}
                role="img">
                {useElement}
            </svg>
        )
    }
    const icon = !decorative ? (
        renderParentLink()
    ) : (
        <svg
            aria-hidden="true"
            data-testid={`icon-${type}-hidden`}
            className={`${PREFIX}-icon  ${PREFIX}-icon-${type} ${PREFIX}-icon--${size} `}
            ref={elementRef}>
            {useElement}
        </svg>
    )
    const showcase = icon
    return <> {showcase}</>
}

Icon.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.string,
    path: PropTypes.string,
    decorative: PropTypes.bool,
    iconSpritePath: PropTypes.string,
}
Icon.displayName = 'Icon'

export default Icon
