import { ActionType, getType } from 'typesafe-actions'
import { setBuyNowAEMProps } from '../actionCreators'
import * as actions from '../actionCreators/buyNowModal.actionCreators'
import { AEMPropsAndFlags } from '../models/buyNowModal.interface'

type Action = ActionType<typeof actions>
export interface buyNowModalDataState {
    aemPropsAndFlags: AEMPropsAndFlags
}

export const initialState: buyNowModalDataState = {
    aemPropsAndFlags: {} as AEMPropsAndFlags,
}

/**
 * @param state
 * @param action
 */
export const buyNowModalDataReducer = (
    state: buyNowModalDataState = initialState,
    action: Action,
): buyNowModalDataState => {
    switch (action.type) {
        case getType(setBuyNowAEMProps): {
            return {
                ...state,
                aemPropsAndFlags: action.payload,
            }
        }
        default:
            return state
    }
}
