import { simpleHeaderCheckoutConst } from './SimpleHeaderCheckout.constant'
import analyticsLayer from '../../utils/analyticsService'

const SimpleHeaderCheckout = (): void => {
    const logoComp = document.getElementById(simpleHeaderCheckoutConst.logo)
    logoComp?.addEventListener('click', function () {
        analyticsLayer.pushData({
            event: 'interaction',
            eventParameters: {
                action: 'click on internal link',
                category: 'interaction',
                label: logoComp.getAttribute('alt') || simpleHeaderCheckoutConst.logoText,
                value: null,
                location: 'simple header',
            },
        })
    })
}

export { SimpleHeaderCheckout }
