/**
 * Function to create accessibility id - combination of component name/ schema id + skucode/skuid
 * @param {string} componentId component name or schema id
 * @param {string} skuId sku code or skuId
 * @return {string}
 */

export const getAccessibilityId = (componentId: string, skuId?: string | null): string =>
    `${componentId}-${skuId || ''}`

/**
 * Function to create unique accessibility id for title-> title__category-N-768956
 * @param {string} id accessibility id
 * @return {string}
 */
export const getAccessibilityTitleId = (id: string): string => `title__${id}`

/**
 * Function to create unique accessibility id for wishlist Product title-> wishlist__546738
 * @param {string} id accessibility id
 * @return {string}
 */
export const wishlistPrefixId = (id: string): string => `wishlist__${id}`

/**
 * Function to create unique accessibility id for price-> price__category-N-768956
 * @param {string} id accessibility id
 * @return {string}
 */
export const getAccessibilityPriceId = (id: string): string => `price__${id}`

/**
 * Function to create unique accessibility id for availability-> availability__category-N-768956
 * @param {string} id accessibility id
 * @return {string}
 */
export const getAccessibilityAvailabilityId = (id: string): string => `availability__${id}`

/**
 * Function to create unique accessibility id for promo-> promo__category-N-768956
 * @param {string} id accessibility id
 * @returns {string}
 */
export const getAccessibilityPromoId = (id: string): string => `promo__${id}`
