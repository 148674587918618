/* eslint-disable max-len */
import { ProductCardType } from '../Recommendations/Recommendations.type'

const MaxETA = '2021-01-24'
const MinETA = '2021-01-19'

export const featuredProductCardData = {
    products: [
        {
            type: 'product',
            title: "The North Face Women's Venture 2 Shell 2.5L Jacket",
            code: '332236141',
            brand: { label: 'THE NORTH FACE' },
            url: '/en/pdp/yardworks-kids-electronic-lawn-mower-toy-332236144.html',
            images: [
                {
                    url: 'https://cdn.pixabay.com/photo/2014/02/27/16/10/tree-276014__340.jpg',
                    isListingThumbnailImage: 'N',
                    mediaType: 'LIFESTYLE_IMAGE',
                    altText: "Altra Connor Men's Wheat CSA Boot, 8-in",
                },
            ],
            fulfillment: {
                availability: {
                    Corporate: {
                        MaxETA,
                        MinETA,
                        MinOrderQty: 1,
                        Quantity: 15,
                        orderableFromDC: true,
                        bopisETA: {
                            MaxETA,
                            MinETA,
                        },
                        sthETA: {
                            MaxETA,
                            MinETA,
                        },
                    },
                    quantity: 15,
                    storeShelfLocation: 'Aisle 24',
                },
            },
            productPageUrls: [
                '/categories/women/womens-jackets-coats-vests/rain-jackets/product/the-north-face-womens-venture-2-shell-25l-jacket-332236141',
                '/categories/women/womens-jackets-coats-vests/hiking-jackets/lightweight/product/the-north-face-womens-venture-2-shell-25l-jacket-332236141',
                '/categories/women/womens-jackets-coats-vests/hiking-jackets/performance/product/the-north-face-womens-venture-2-shell-25l-jacket-332236141',
                '/categories/women/womens-jackets-coats-vests/rain-jackets/shell-rain-jackets/short/product/the-north-face-womens-venture-2-shell-25l-jacket-332236141',
                '/categories/women/womens-jackets-coats-vests/rain-jackets/short-rain-jackets/product/the-north-face-womens-venture-2-shell-25l-jacket-332236141',
            ],
            rating: 4.59,
            ratingsCount: 39.0,
            formattedTimeTestedPrice: null,
            wasPrice: null,
            formattedWasPrice: null,
            originalPrice: {
                formattedValue: '$50',
                value: 50,
            },
            price: {
                formattedValue: '$69.88',
                value: 69.88,
            },
            currentPrice: {
                formattedValue: '$50',
                value: 50,
                minPrice: null,
                maxPrice: null,
            },
            displayWasLabel: true,
            discount: {
                value: 50.98,
                minPrice: 50.98,
                maxPrice: 100.9,
            },
            salePrice: {
                maxPrice: {
                    formattedValue: '$299.99',
                    value: 299.99,
                },
                minPrice: {
                    formattedValue: '$199.99',
                    value: 199.99,
                },
            },
            badges: ['HOTDEAL'],
            priceShortMessage: '',
            priceLongMessage: '',
            hiddenPrice: null,
            longDescription:
                '<p>You only need a handful of extra space in your pack to stash this Ultralight, weatherproof rain jacket for extra weather protection when storms are on the horizon. A Relaxed Fit and an adjustable hood leave room for extra layers.</p>',
            featureBullets: [
                {
                    description: 'Unlined, weatherproof rain jacket for year-round use</li><li>Relaxed Fit',
                },
                {
                    description: 'Waterproof, breathable, fully seam-sealed DryVent™ 2.5L shell',
                },
                {
                    description: '100% windproof fabric</li><li>Adjustable hood',
                },
                {
                    description: 'Stormflap with Velcro® closure covers front zipper',
                },
                {
                    description: 'Covered, secure-zip hand pockets',
                },
                {
                    description: 'Pit-zip venting for added breathability',
                },
                {
                    description: 'Hem cinch-cord',
                },
                {
                    description: 'Stowable in hand pocket',
                },
            ],
            specifications: null,
            clearancePrice: false,
            permanentMarkdown: false,
            sellable: false,
            assemblyRequired: false,
            inStock: false,
            onlineOnly: false,
            onFloor: true,
            bopisOnly: false,
        },
    ] as ProductCardType[],
    notFoundIds: [
        '0821011P',
        '1423944P',
        '1421282P',
        '0825228P',
        '0420544P',
        '1424954P',
        '0820083P',
        '0825203P',
        '0825299P',
    ],
}

export const priceType = 'CURRENT' // constant for price type
export const featureProductListConst = 'featured-product-lists' // constant for accessibility id
