import { ReactNode } from 'react'

import { HybrisMedia, MediaImages } from '@nl/lib'
import { FeeMessage, PriceMessage, Price } from '../../redux/models/cart.interface'
import { FulfillmentDTOP, ProductResponseData, TireWheelSpecifications } from '../../redux/models/product.interface'
import { StoreGeoPoint } from '../../redux/models/storeDetails.interface'
import { SchemaIdListObject } from '../Recommendations/Recommendations.type'
import { PDPOfferBenefitsWrapperProps } from '../PDPOfferBenefits/PDPOfferBenefits.type'
import { SelectRewardsCardType } from '../ProductTriangleRewards/ProductTriangleRewards.type'
import { VehicleTypeIds } from '../Vehicles/Vehicles.constant'
import { VehicleSelectionExclusionProps } from './VehicleSelectionExclusionProps.type'
import { HowToImageSectionProps } from './HowToImageSection/HowToImageSection.type'
// eslint-disable-next-line max-len
import { BuyBoxWeatherTechVehicleSelectorProps } from '../AutomotivePDP/AutomotiveBuyBox/BuyBoxWeatherTechVehicleSelector/BuyBoxWeatherTechVehicleSelector.type'

export interface PromoRestrictedBrands {
    brand?: string
}

export interface ShippingExclusion {
    messageToExclude?: string
    messageToPresent?: string
}

/**
 * Interface for BuyboxProps
 */
export interface BuyboxProps
    extends PDPOfferBenefitsWrapperProps,
        SelectRewardsCardType,
        VehicleSelectionExclusionProps,
        HowToReadTireSizesProps {
    a11yVariantsSelectedCount?: string
    addToPackageCTA?: string
    buyboxButtonLabel?: string
    chooseAnotherStoreLabel?: string
    visitPreferredStoreLabel?: string
    nearbyStoresNotAcceptOrdersLabel?: string
    addToWishListLabel?: string
    addedToWishListLabel?: string
    variantWarningLabel?: string
    availabilityLabel?: string
    installLabel?: string
    toolTipTitle?: string
    children?: ReactNode
    addToWishlistMessage?: string
    addToWishlistMessageGuest?: string
    maximumQuantityError?: string
    minQuantity: number
    staggeredFitmentDefaultQuantity: number
    maxQuantity: number
    wishlistMaxLimitMessage?: string
    variantNotSelectedMessage?: string
    a11yWishlistIconSelectedLabel?: string
    a11yWishlistIconDeselectedLabel?: string
    failureCloseLabel?: string
    successUndoLabel?: string
    viewWishlistLink?: string
    viewWishlistLinkLabel?: string
    warningOverlayHeaderLabel?: string
    warningOverlayContinueLabel?: string
    setOf4Label?: string
    warningOverlayCancelLabel?: string
    getItInStoreLabel?: string
    urgencyMessageLabel?: string
    inStockLabel?: string
    checkNearByCTALabel?: string
    inStorePurchase?: string
    storePickUpIcon?: string
    preferredStoreText?: string
    checkAllStoreAvailabilityLabel?: string
    outOfStockLabel?: string
    outOfStockTitle?: string
    outOfStockText?: string
    recomendationCarouselTitle?: string
    minimumNumberOfRecommendationsPC: number
    maximumNumberOfRecommendationsPC?: number
    recommendationsSchemaIdList?: SchemaIdListObject[]
    recommendationsSchemaId: string
    recommendationsIsForRecentlyView?: boolean
    visitNearbyStoreLabel?: string
    shippingToLabel?: string
    freePickUpInStoreLabel?: string
    freePickUpInStoreOverLabel?: string
    thresholdTooltipHeaderText?: string
    thresholdTooltipDescription?: string
    thresholdTooltipThresholdConditionText?: string
    a11yThresholdAndHandlingFeeTooltipIcon?: string
    shipToHomeLabel?: string
    changeLabel?: string
    getItBetweenLabel?: string
    selectNearbyStorePickUpLabel?: string
    selectNearbyStoreShipLabel?: string
    isAddToCartClicked?: boolean
    postalCodeTitle?: string
    postalCodeUpdateCTA?: string
    postalCodeLabel?: string
    a11yReviewsAndRating: string
    installationHelp?: string
    serviceTooltipMsg?: string
    autoServiceAvailable?: string
    autoServiceNotAvailable?: string
    shoppingAt?: string
    enableBadges?: boolean
    enableSaveStory?: boolean
    linkTarget?: string
    wishlistRemoveItemMessage: string
    displayFitmentMessage?: boolean
    // eslint-disable-next-line no-warning-comments
    // TODO: This props will be removed once we get the actual error codes

    addToWishlistInactiveProductErrorMsg?: string
    outOfStockVariantMsg?: string
    outOfStockVariantGenericMsg?: string
    curbsidePickupAvailableLabel?: string
    availableByLabel?: string
    outOfStockShipToStoreBetweenLabel?: string
    storeChangeNotificationLabel?: string
    discontinuedInStoreMsg?: string
    selectStoreLabel?: string
    availabilitySelectVariantMsg?: string
    availabilitySelectVariantGenericMsg?: string
    pickupAvailabilitySelectVariantMsg?: string
    sthAvailabilitySelectVariantMsg?: string
    maxNumberOfStores?: number
    onContinueClicked?: () => void
    maxPurchasableLimitLabel: string
    onlineOrdersNotAcceptedMsg: string
    onlineOrdersNotAcceptedBuyBoxMsg?: string
    // Financing component
    triangleLogo: string
    triangleLogoA11y: string
    triangleMessageLink?: string
    triangleTitle: string
    signUpLabel: string
    signUpLink: string
    monthlyPriceLabel?: string
    perMonthLabel: string
    numberOfMonths: number
    ctMoneyPercentageText?: string
    ctMoneyMessageLink?: string
    percentageValue: number
    availableAtLabel: string
    availableAtLink?: string
    thresholdValue: number
    exceedMaximumSellQuantityMessage?: string
    exceedMaximumPurchableMessage?: string
    sthNotEligibleText?: string
    bopisNotEligibleText?: string
    winterTirePolicyModalTitle?: string
    monthlyInstallmentsQuebec?: string
    monthlyPriceQuebecLabel?: string
    // props for vehicle selector alert in buybox
    productDoesNotFitPrompt?: string
    productDoesNotFitCopy?: string
    productPartiallyFitPrompt?: string
    productMayNotFitPrompt?: string
    productMayNotFitCopy?: string
    updateVehicleCTA?: string
    continueWithoutAdditionalInfoCTA?: string
    suggestedQuantityLabel?: string
    seeProductFitVehicleCTA?: string
    shopWithNoVehicleCTA?: string
    shopByVehicleCTA?: string
    seeTiresFitVehicleCTA?: string
    availableOnlinePurchaseLabel?: string
    inStoreAvailabilityLabel?: string
    serviceInstallationMessage?: string
    packageDefaultQuantity?: number
    availableBetweenLabel?: string
    shipToYourStoreBetweenLabel?: string
    heavyOversizeItemText: string
    heavyOversizeToolTipIcon: string
    heavyOversizeHeader: string
    heavyOversizeBody: string
    estimateFeesText?: string
    estimatedFeesText?: string
    estimatedFeesPrice?: string
    estimateFeesDescription?: string
    estimateFeesDisclaimer?: string
    a11yEstimateFeesTooltipIcon?: string
    estimateFeesSuccessMsg?: string
    stgFitmentWarningMsg?: string
    tiresSpec?: TireWheelSpecifications[]
    wheelsSpec?: TireWheelSpecifications[]
    shipsByLabel?: string
    assemblyDescription?: string
    assemblyTitleLabel?: string
    productDoesNotFitTireSizePrompt?: string
    productDoesNotFitTireSizeCopy?: string
    seeProductFitTireSizeCTA?: string
    enterVehicleInfoCopy?: string
    removeETA?: boolean
    isShippingPostalCodeHide: boolean
    // props for automotive show more/less feature
    viewMoreLessFeatureEnabled?: boolean
    visibleFitmentNotesCount?: number
    onlineOnlyPurchaseLabel?: string
    onlineOnlyAvailabilityLabel?: string
    onlineOnlyAvailabilityBOPIS?: string
    onlineOnlyAvailabilitySTH?: string
    promoEnabled?: string
    promoRestrictedBrands: PromoRestrictedBrands[]

    // props for STH block for WEST banners
    enableOOSMessageForBOPIS?: boolean
    freeShippingEnabled?: boolean
    freeShippingExclusion?: ShippingExclusion[]
    regularShippingThreshold?: number
    triangleShippingThreshold?: number
    shipToHomeFree?: string
    shipToHomeFreeOverRegular?: string
    shipToHomeFreeOverTriangle?: string
    shipToHomeEligible?: string
    shipToHomeNotFree?: string
    shipsInLabel?: string
    endlessAisleShippingLabel?: string
    shippingEstimate?: string
    enableShowOOSOnFulfillmentBlock?: boolean
    disableBopisForComboStore?: boolean

    // props for roadrated icons
    snowPeakRated?: string
    mudAndSnowRated?: string
    ratedLowResistance?: string
    acceptsStuds?: string
    snowPeakRatedTitle?: string
    mudAndSnowRatedTitle?: string
    ratedLowResistanceTitle?: string
    acceptsStudsTitle?: string
    // props for service install
    autoInstallationHelpLabel?: string
    autoInstallationHelpPopupMsg?: string
    autoServiceAvailableText?: string
    autoServiceUnavailableText?: string
    autoShoppingAtText?: string
    definitionExpressDeliveryTooltipHeaderText?: string
    definitionExpressDeliveryTooltipBodyText?: string
    expressDeliveryIsAvailableLabel?: string
    isSeparatedErrorHandling?: boolean
    isShowCurbsideAvailabilityLabelForProposedStores?: boolean
    isShowAvailableByLabel?: boolean
    isShowBopisOutOfStockLabel?: boolean
    bopisBlockOutOfStockLabel?: string
    timeLabelWhenPickUpAvailable?: string
    etaPickUpMessageHeaderText?: string
    etaPickUpAdditionalMessageText?: string
    etaLearnMoreMessageLinkLabel?: string
    etaLearnMoreMessageLink?: string

    isProductLevelSellability?: boolean
    // wishlist
    wishListNonSellableEnabled?: boolean
    a11yRemoveFromWishList?: string
    a11yAddToWishList?: string
    a11yUpdatingWishList?: string
    weathertechProps: BuyBoxWeatherTechVehicleSelectorProps

    comingSoonMessageTitleLabel?: string
    comingSoonMessageBody?: string
    isColourSwatchesActive?: boolean
    isATCErrorHandling?: boolean
    isDenyToUseStoreInventory?: boolean
    ignoreStockAtDC?: boolean
    preferredShippingOptionLabel?: string
    notEligibleExpressDeliveryLabel?: string
    estimateFeesDisclaimerExpressDelivery?: string
    standardShippingFeesText?: string
    expressShippingFeesText?: string
    notAvailableShippingFeesText?: string
    deliveryEligibilityLabel?: string
    outOfStockAtStoreLabel?: string
    notEligibleStandardDeliveryLabel?: string
    shippingFeeText?: string
    estimatedShippingFeeText?: string
    estimateExpressShippingFeesTooltipHeaderText?: string
    estimateExpressShippingFeesTooltipBodyText?: string
    selectNearbyStoreLabel?: string
    tdisplay?: boolean
    displaySelectMembershipUpsell?: boolean
    tdisplayMasterCardandTSUser?: boolean
    estimateStandardShippingFeesTooltipHeaderText?: string
    estimateStandardShippingFeesTooltipBodyText?: string
    spendAndGetLabel?: string
    enableMPNForAll?: boolean
    enableMPNForAutomotive?: boolean
    eligibilityAndShippingInfoLabel?: string
    freeStandardDeliveryWithPriceLimit?: string
    freeStandardDeliveryLabel?: string
    vehicleAddSuccessMsg?: string
    enableHidingFirstVariantWhereAllSkusOOS?: boolean
    enableBOPISStore?: boolean
    enableOnlyAvailableOnline?: boolean
    onlyAvailableOnlineAtLabel?: string
    hidingAllFirstVariantOOSMessage?: string
    productDoesNotFitNoVehicleCopy?: string
    filterTitle?: string
    searchPlaceholder?: string
    noResultsMessage?: string
    shopByPartNumberCTA?: string
    cancelCTA?: string
    clearCTA?: string
    availablePartsLabel?: string
    updateVehicleToastMessage?: string
    searchPlaceholderForWheel?: string
    wheelSizeFilterLabel?: string
    availableWheelSizeTabLabel?: string
    shopByWheelSizeCTA?: string
    fullyDiscontinuedTitle?: string
    setOfTwoLabel?: string
    setOfTwoNowLabel?: string
    // props for automotive sku pills
    displayVehiclePillsAccordion: boolean
    displayTireSizePillsSection: boolean
    vehiclePillsTitle?: string
    // prop for info tooltip in buybox
    a11yStoreInfoTooltip?: string
    enablePromoBadgesForBoxesModel?: boolean
    // Toggle to enable/disable A/B Test on PDP's
    enableABTestForProducts?: boolean
    // prop for Auto Parts SKU PDPs
    disableSkuForVehicleCallOnAutoPartsPdp?: boolean
    disableDisplayingOfVehicleFormsForAutoPartsSkuPdps?: boolean

    enableFulfillmentSimplificationBox?: boolean
    enableGenMerchSingleSkuInUrl?: boolean
    buyOnlineHeaderText?: string
    alsoFindItInStoreHeader?: string
    availableLabel?: string
    unavailableLabel?: string
    checkOtherStoresLabel?: string
    selectAllOptionsLabel?: string
    freeShippingOverLabel?: string
    inStockAtStore: string
    outOfStockAtStoreff: string

    seeOtherTiresFitCTA: string
    fulfillmentTooltip?: string
    shippingFeeCalculation?: string
    shipToHomeUnavailable?: string
    prodOOSErrMsg?: string
    selectNearByStoreLabel?: string
    inStorePurchaseOnly?: string
    checkStoreAvailability?: string
    pickUpAtStoreLabel?: string
    getItByLabelff?: string
    getItTomorrowLabelff?: string
    getItTodayLabelff?: string
    expressDeliveryUnavailable?: string
    expressDeliveryUnavailableOOS?: string
    seeWheelsFitVehicleCTA: string
    // props specific to add to sales alerts
    addToSalesAlerts: string
    addedToSalesAlerts: string
    showSalesAlertMessages: boolean
    shipsBetween?: string
    postalCodeForEligFeesLabel?: string
    deliveryLabel?: string
    deliveryFeeLabel?: string
    deliverToLabel?: string
    needMoreLabel?: string
    signInSalesAlertMessage: string
    // props for helium inflation
    heliumTemporarilyUnavailableAtThisStoreLabel: string
    // fulfillment props
    pickUpBetweenLabel?: string
    needItTodayLabel?: string
    availableAtNearbyStores?: string
    needMoreItemsDescLabel?: string
    ltdStockUrgencyMsg: string
    aOAServiceUnvailableForTiresMsg?: string
    aOAServiceUnvailableForWheelsMsg?: string
    itemRemovedFromWishlistMsg?: string
    showPartialVehicleBanner?: boolean
    skuPartialFitsToVehicle?: string
    offerRewardSetMessageTemplate?: string
    offerRewardMessageTemplate?: string
    offerMultiplierRewardSetMessageTemplate?: string
    ptePreviewTextForAuthUser?: string
    fulfillmentBoxesOrder: string[]
}

export interface ProductSkuForABTest {
    pCode?: string
    minPrice?: string
    maxPrice?: string
}

export interface ProductListForABTest {
    productList?: ProductSkuForABTest[]
}

/**
 * Interface to get product list for A/B test on PDP's
 */
export interface ProductDataForABTest {
    data?: ProductListForABTest
}
export interface PriceVariant {
    feeTitle?: string
    feeValue?: number
    originalPrice?: Price
    currentPrice: Price
    displayWasLabel?: boolean
    discount?: Price
    badges?: string[]
    feeDisclaimerType?: string
    feeDisclaimerMessage?: string
    feeDisclaimerTitle?: string
    isTireCyclingFee?: boolean
    priceMessage?: PriceMessage[]
    feeMessages?: FeeMessage[]
    saleCut?: SaleCut
    loyalty?: loyaltyBadge
}
export interface ProductOption {
    descriptor: string
    display: string
    values: ProductOptionValue[]
}
export interface ProductOptionValue {
    value: string
    id: string
    skuCodes: string[]
    mediaSet: HybrisMedia[]
    currentPrice?: Price
    originalPrice?: Price
    saleCut?: SaleCut
    isDefaultColour?: boolean
    colourSwatchImageUrl?: string
    badges?: string[]
    displayNameForVariants?: string
    isUnavailableDueToStock?: boolean
}

export interface TriangleBenefits {
    tsRewardsBaseLoyalty: number
    tsRewardsBaseLoyaltyValue: number
    tsTriangleMastercardBenefits: number
    tsTriangleMastercardBenefitsValue: number
}

export interface TriangleSelectBenefits {
    tsBrandDiscount: number
    tsBrandDiscountValue: number
    tsEverydayDiscount: number
    tsEverydayDiscountValue: number
    tsBrand: boolean
}

export interface ProductSku {
    code: string
    active: boolean
    sellable: boolean
    orderable: boolean
    isBulk: boolean
    displayWasLabel: boolean
    ecoFee: number | null
    badges: string[]
    url: string
    optionIds: number[]
    maxPurchasableQty?: number
    storeShelfLocation: string
    triangleBenefits?: TriangleBenefits
    triangleSelectBenefits?: TriangleSelectBenefits
    loyalty?: loyaltyBadge
}

/**
 * Interface for loyalty badge
 */
export interface loyaltyBadge {
    Bonus?: {
        OfferRewardValue: string
        OfferRule: string
        RewardPercentage: string
    }
}

/**
 * Interface for Variants
 */
export interface VariantType {
    name: string
    skuCodes: string[]
    id: string
    isUnavailable: boolean
    firstVariant?: VariantType[]
    secondVariant?: VariantType[]
    thirdVariant?: VariantType[]
    mediaSet: MediaImages[]
    displayNameForVariants?: string
}

/**
 * Interface for VariantDetails
 */
export interface VariantDetails {
    firstVariant: FilteredVariantType
    secondVariant: FilteredVariantType
    thirdVariant: FilteredVariantType
    [index: string]: FilteredVariantType
}

/**
 *Interface for ColorType
 */
export interface FilteredVariantType {
    values: VariantType[]
    display: string
    descriptor: string
    sortStatus: string
    displayNameForVariants?: string
    isUnavailableDueToStock?: boolean
}
export interface PreviousSKUAndStoreLoactionAndMaxRadiusType {
    selectedProductCode?: string
    selectedPreferredStoreId?: string
    geoPoint?: StoreGeoPoint
    maxRadiusToSearchStore?: number
    productDataCode?: string
}

export interface EventType {
    label: string
    id: string
    link?: string
    selected?: boolean
}

export interface SortType {
    data: DescriptorSortType[]
    sortBy: string[]
    sortField: string
}
export interface DescriptorSortType {
    [key: string]: string | boolean | number | VariantType[] | undefined
    sortStatus: string
    values: VariantType[]
    display: string
    descriptor: string
    displayNameForVariants?: string
    isUnavailableDueToStock?: boolean
}

export type KnownVehicleAttribute = {
    id: VehicleTypeIds | string
    value: string
}

export type SuggestedVehicleAttribute = {
    id: VehicleTypeIds | string
    values: Array<string>
}

export type VehicleAttribute = {
    id: VehicleTypeIds | string
    values: Array<string>
}

export type VehicleAttributes = {
    known: KnownVehicleAttribute[]
    suggested: SuggestedVehicleAttribute[]
    indexed?: VehicleAttribute[]
}
export interface AddVehicleDataResponse {
    attributes: VehicleAttributes
    vehicle: {
        vehicleId: string
        baseVehicleId: string
    }
}

/**
 *Interface for Threshold Values
 */
export interface ThresholdValuesType {
    hotDealThreshold?: number
    limitedTimeThreshold?: number
    lowStockThreshold?: number
}

/**
 * Interface for SaleCut
 */
export interface SaleCut {
    percentage?: number | null
    value?: number | null
}

export interface SaleCutDTO {
    percentage: number
    value: number
}
export enum SellableCodes {
    SELLABLE_NOT_STARTED = 'SELLABLE_NOT_STARTED',
    SELLABLE_FINISHED = 'SELLABLE_FINISHED',
}

export enum STHCodes {
    STH_ONLY = 'STH_ONLY',
    ENDLESSAISLE = 'ENDLESSAISLE',
}

export interface IPrevValueCache {
    current?: {
        selectedProductCode?: string
        selectedPreferredStoreId?: string
        geoPoint?: StoreGeoPoint
        maxRadiusToSearchStore?: number
        productDataCode?: string
    }
}

export interface BazaarVoiceCallback {
    ['rating_summary']: {
        on: (event: string, eventCallback: (node: Node) => void) => void
    }
    ['swat_reviews']: {
        on: (event: string, eventCallback: (node: HTMLElement) => void) => void
    }
}

export interface HowToReadTireSizesProps {
    displayHowToReadTireSizePopup: string | boolean
    howToReadTireSizePopupTitle: string
    howToReadTireSizePopupButtonLinkName: string
    howToReadTireSizePopupImage: string
    howToReadTireSizePopupImageAltText: string
    typeOfSection?: HowToImageSectionProps
}

export interface VehiclePayload {
    baseVehicleId?: string
    type?: string
    make?: string
    model?: string
    body?: string
    options?: string
    year?: string
    engineConfigId?: string
}

export interface FbtProductWithAvailability {
    fulfillment?: FulfillmentDTOP
}

export interface BreadCrumbLink {
    url: string
    label: string
}

export interface SkuCode {
    list: ProductSku[]
    code: string
}

export interface ProductLevel {
    isAllVariantSelected: boolean
    data: ProductResponseData
}
