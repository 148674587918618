import { getLanguage, magicNumber } from '../../utils'
import { getCurrentDay } from '../../utils/getCurrentDay'
import { GetStoreTimingsParamsType, OpeningHours, WeekDayOpeningList } from './StoreSelectorModal.type'
import { formatInTimeZone } from 'date-fns-tz'

/**
 * Function to get weekday opening timings from store data based on current day
 * @param {StoreWithAvailability} store - store data
 * @returns {WeekDayOpeningList}
 */
export const getStoreTimings = (params: GetStoreTimingsParamsType): WeekDayOpeningList => {
    const currentDay = getCurrentDay(getLanguage())
    const { openingHours } = params || {}
    const weekdayHours = openingHours?.weekDayOpeningList?.find(
        (day: Record<string, unknown>) => (day.weekDay as string)?.toLowerCase() === currentDay,
    )
    const weekdayHoursUpdate = checkNeedUpdateWeekdayHours(params, weekdayHours)
    return weekdayHoursUpdate || {}
}

const calculateClosedClosesInStore = (
    store: { storeTimezone?: string },
    weekdayHours: WeekDayOpeningList,
): Record<string, boolean | number | string> => {
    const currentDay = getCurrentDay(getLanguage())
    const isCurrentDay = weekdayHours?.weekDay?.toLowerCase() === currentDay
    const storeTimezone = store.storeTimezone || ''
    const currentTime = storeTimezone && formatInTimeZone(new Date(), storeTimezone, 'HH:mm')
    const formattedCloseTime = formattedTime(weekdayHours?.closingTime?.hour, weekdayHours?.closingTime?.minute)
    const formattedOpenTime = formattedTime(weekdayHours?.openingTime?.hour, weekdayHours?.openingTime?.minute)
    const isClosed =
        isCurrentDay && currentTime
            ? isClosedStore(currentTime, formattedOpenTime, formattedCloseTime)
            : weekdayHours?.closed
    const closesIn =
        // eslint-disable-next-line no-unsafe-optional-chaining
        isCurrentDay && currentTime ? weekdayHours?.closingTime?.hour - timeStringToNumber(currentTime) : null
    return { currentTime, isClosed, closesIn }
}

const formattedTime = (hour: number, minute: number): string => {
    const hourAsString = hour ? hour.toString() : ''
    let minuteAsString = '00'
    if (minute !== undefined && minute > magicNumber.ZERO) {
        minuteAsString = `${minute}`
    }
    const checkValue = (value: string): string => {
        return value.length === magicNumber.ONE ? `0${value}` : value
    }
    return `${checkValue(hourAsString)}:${checkValue(minuteAsString)}`
}

const isClosedStore = (currentTime: string, formattedOpenTime: string, formattedCloseTime: string): boolean => {
    // eslint-disable-next-line sonar/strings-comparison
    return currentTime < formattedOpenTime || currentTime > formattedCloseTime
}

const timeStringToNumber = (time: string): number => {
    const hoursMinutes = time.split(/[.:]/)
    const hours = parseInt(hoursMinutes[0], 10)
    const minutes = hoursMinutes[magicNumber.ONE] ? parseInt(hoursMinutes[magicNumber.ONE], 10) : 0
    return Math.ceil(hours + minutes / magicNumber.SIXTY)
}

export const checkNeedUpdateWeekdayHours = (
    store: { storeTimezone?: string },
    weekdayHours: WeekDayOpeningList,
): WeekDayOpeningList => {
    const calcProperties = calculateClosedClosesInStore(store, weekdayHours)
    if (calcProperties.currentTime) {
        weekdayHours = {
            ...weekdayHours,
            closed: calcProperties.isClosed as boolean,
            closesIn: calcProperties.closesIn as number,
        }
    }
    return weekdayHours
}

/**
 * Function to get next weekday opening timings from store data based on current day
 * @param {StoreWithAvailability} store - store data
 * @returns {WeekDayOpeningList}
 */
export const getNextDayStoreTimings = (store: { openingHours: OpeningHours }): WeekDayOpeningList => {
    const currentDay = getCurrentDay(getLanguage())
    const { openingHours } = store || {}
    const weekdayHoursIndex = openingHours?.weekDayOpeningList?.findIndex(
        (day: Record<string, unknown>) => (day.weekDay as string)?.toLowerCase() === currentDay,
    )
    const nextIndex =
        // eslint-disable-next-line no-unsafe-optional-chaining
        weekdayHoursIndex === openingHours?.weekDayOpeningList?.length - magicNumber.ONE
            ? magicNumber.ZERO
            : weekdayHoursIndex + magicNumber.ONE
    const nextDayHours = openingHours?.weekDayOpeningList?.[nextIndex]
    return nextDayHours || {}
}
