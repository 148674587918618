import { MagicNumber } from '../../../analytics/analytics.type'

/**
 * Returns false , if the object passed is empty , otherwise returns true
 * @param {unknown} obj - max limit of the text.
 * @return {boolean}
 */
export const checkDataLength = (obj: any): boolean => {
    return !!obj && Object.values(obj).length > MagicNumber.ZERO
}

/**
 * Returns ZERO , if the object passed is empty , otherwise returns ONE
 * @param {unknown} obj - some object to check that it is not empty
 * @return {number}
 */
export const checkDataLengthToNumber = (obj: unknown): number => {
    return !!obj && Object.values(obj).length > MagicNumber.ZERO ? MagicNumber.ONE : MagicNumber.ZERO
}

/**
 * function to check if atleast one property of the object has non null value and not empty string in case of string value for the key
 * @param {unknown}obj
 * @return {boolean}
 */
export const checkValidData = (obj: any): boolean => {
    return !!obj && Object.values(obj).filter(item => !!item).length > MagicNumber.ZERO
}

/**
 * function to check if all properties of the object has non null value and not empty string in case of string value for the key
 * @param {unknown}obj
 * @return {boolean}
 */
export const checkAllObjectFieldsValid = (obj: any): boolean => {
    return !!obj && Object.values(obj).filter(item => !!item).length === Object.values(obj).length
}
