export const storeLocatorList = [
    {
        baseStoreId: 'CTR',
        type: 'test',
        name: 'Yonge & Dundas Long Store Name',
        id: 1,
        url: '/test',
        address: {
            cellphone: '1234567890',
            companyName: 'CTR',
            defaultAddress: false,
            district: 'Toronto',
            email: 'test1@mailinator.com',
            formattedAddress: '61 Dundas St West2, Toronto',
            id: '1',
            line1: '61 Dundas St West2, Toronto',
            line2: '',
            phone: '1234567890',
            postalCode: 'A2A2A2',
            shippingAddress: false,
            town: '',
            visibleInAddressBook: false,
            lastName: '',
            firstName: '',
            fax: '',
            city: { isocode: 'CA', name: 'Toronto' },
            cityDistrict: { isocode: 'CA', name: 'Toronto' },
            country: { isocode: 'CA', name: 'Canada' },
            region: { isocode: 'CA', name: 'Toronto' },
        },
        description: 'Yonge & Dundas Long Store Name Description',
        displayName: 'Yonge & Dundas long Store name',
        geoPoint: {
            latitude: 60.5086699,
            longitude: -73.5539925,
        },
        inventory: [
            {
                Corporate: {},
                IsOnline: {},
                Quantity: 4,
                sku: '18725001',
                Store: 'ABC',
            },
        ],
        openingHours: {
            code: '',
            name: '',
            specialDayOpeningList: [],
            weekDayOpeningList: [
                {
                    closed: false,
                    closingTime: {
                        formattedHour: '9 PM',
                        hour: 9,
                        minute: 4,
                    },
                    openingTime: {
                        formattedHour: '9 AM',
                        hour: 9,
                        minute: 4,
                    },
                    weekDay: 'mon',
                },
            ],
        },
        fulfillment: {
            storePickUp: {
                enabled: true,
                source: {},
                availability: {
                    quantity: 3,
                    storeShelfLocation: '',
                },
            },
            shipToHome: {
                enabled: false,
                quantity: 1,
                etaEarliest: 'May 25',
                etaLatest: 'June 10',
            },
        },
        onlineOrdering: true,
    },
    {
        baseStoreId: 'CTR',
        type: 'test',
        name: 'Leslie & Lakeshore Long Store Name',
        id: 2,
        url: '/test2',
        address: {
            cellphone: '1234567890',
            companyName: 'CTR',
            defaultAddress: false,
            district: 'Toronto',
            email: 'test@mailinator.com',
            formattedAddress: '62 Dundas St West2, Toronto',
            id: '1',
            line1: '62 Dundas St West2, Toronto',
            line2: '',
            phone: '1234567890',
            postalCode: 'A2A2A2',
            shippingAddress: false,
            town: '',
            visibleInAddressBook: false,
            lastName: '',
            firstName: '',
            fax: '',
            city: { isocode: 'CA', name: 'Toronto' },
            cityDistrict: { isocode: 'CA', name: 'Toronto' },
            country: { isocode: 'CA', name: 'Canada' },
            region: { isocode: 'CA', name: 'Toronto' },
        },
        description: 'Leslie & Lakeshore Long Store Name Description',
        displayName: 'Leslie & Lakeshore Store',
        geoPoint: {
            latitude: 13.0711552,
            longitude: 80.2193408,
        },
        inventory: [
            {
                Corporate: {},
                IsOnline: {},
                Quantity: 3,
                sku: '18725001',
                Store: 'ABC',
            },
        ],
        openingHours: {
            code: '',
            name: '',
            specialDayOpeningList: [],
            weekDayOpeningList: [
                {
                    closed: false,
                    closingTime: {
                        formattedHour: '9 PM',
                        hour: 9,
                        minute: 4,
                    },
                    openingTime: {
                        formattedHour: '9 AM',
                        hour: 9,
                        minute: 4,
                    },
                    weekDay: 'mon',
                },
            ],
        },
        fulfillment: {
            storePickUp: {
                enabled: true,
                source: {},
                availability: {
                    quantity: 3,
                    storeShelfLocation: '',
                },
            },
            shipToHome: {
                enabled: false,
                quantity: 0,
                etaEarliest: 'May 25',
                etaLatest: 'June 10',
            },
        },
        onlineOrdering: false,
    },
    {
        baseStoreId: 'CTR',
        type: 'test',
        name: 'Eglinton & Laird',
        id: 3,
        url: '/test3',
        address: {
            cellphone: '1234567890',
            companyName: 'CTR',
            defaultAddress: false,
            district: 'Toronto',
            email: 'test@mailinator.com',
            formattedAddress: '65 Dundas St West2, Toronto',
            id: '1',
            line1: '65 Dundas St West2, Toronto',
            line2: '',
            phone: '1234567890',
            postalCode: 'A2A2A2',
            shippingAddress: false,
            town: '',
            visibleInAddressBook: false,
            lastName: '',
            firstName: '',
            fax: '',
            city: { isocode: 'CA', name: 'Toronto' },
            cityDistrict: { isocode: 'CA', name: 'Toronto' },
            country: { isocode: 'CA', name: 'Canada' },
            region: { isocode: 'CA', name: 'Toronto' },
        },
        description: 'Eglinton & Laird Description',
        displayName: 'Eglinton & Laird Store',
        geoPoint: {
            latitude: 45.5086699,
            longitude: -80.5539925,
        },
        inventory: [
            {
                Corporate: {},
                IsOnline: {},
                Quantity: 2,
                sku: '18725001',
                Store: 'ABC',
            },
        ],
        openingHours: {
            code: '',
            name: '',
            specialDayOpeningList: [],
            weekDayOpeningList: [
                {
                    closed: true,
                    closingTime: {
                        formattedHour: '9 PM',
                        hour: 9,
                        minute: 4,
                    },
                    openingTime: {
                        formattedHour: '9 AM',
                        hour: 9,
                        minute: 4,
                    },
                    weekDay: 'mon',
                },
            ],
        },
        fulfillment: {
            storePickUp: {
                enabled: true,
                source: {},
                availability: {
                    quantity: 3,
                    storeShelfLocation: '',
                },
            },
            shipToHome: {
                enabled: false,
                quantity: 0,
                etaEarliest: 'May 25',
                etaLatest: 'June 10',
            },
        },
        onlineOrdering: true,
    },
]
export const noOfOtherStoresToShow = 3
