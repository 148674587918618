import { AUTO_SERVICE_INSTALLATION_ID } from '../globalConstants/global.constant'

/**
 * Check if store has auto service.
 *
 * @param {Store} store - store to check
 * @return {boolean}
 */
interface RequiredStoreProperties {
    storeServices?: { name: string }[]
}

export const isAutoServiceAvailableInStore = <StoreType extends RequiredStoreProperties>(store: StoreType): boolean => {
    return !!store?.storeServices?.find(service => service.name === AUTO_SERVICE_INSTALLATION_ID)
}
