// / <reference path="../externals.ts" />

import { AnalyticsEvent } from '../global.type'

/**
 * This file has been depreciated.
 * Please continue with provider based approach from `analytics` folder
 */

const initializeDataLayer = (): boolean => typeof window.analyticsLayer !== 'undefined'

// This Util is for Analytics layer
const analyticsService = (() => {
    const _pushData = (data: AnalyticsEvent) => {
        if (initializeDataLayer()) {
            ;(window.analyticsLayer as AnalyticsEvent[]).push(data)
        }
    }
    return {
        pushData: (data: AnalyticsEvent) => {
            _pushData(data)
        },
    }
})()

export default analyticsService
