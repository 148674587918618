/* eslint-disable max-lines */
/* eslint-disable complexity */
import { AnyAction, Dispatch } from 'redux'
import getFilteredCartItems from '../../utils/getFilteredCartItems'
import { isCheckoutPage, isShoppingCartPage } from '../../utils/checkPageType'
import { getParamValue, PaymentFlowType } from '@nl/lib'

import {
    addToCartGuestSuccessAction,
    addToCartGuestFailureAction,
    resetCartGUIDCreatedFlagAction,
    addServiceToCartSuccessAction,
    addServiceToCartFailureAction,
    getCartItemsSuccessAction,
    getMiniCartItemsSuccessAction,
    getCheckoutCartItemsSuccessAction,
    getCartItemsFailureAction,
    updateCartDataSuccessAction,
    updateCartDataFailureAction,
    reservationRequestInProgress,
    reservationRequestFinished,
    setSelectedServiceAction,
    resetSelectedServiceAction,
    removeCartItemSuccessAction,
    removeCartItemFailureAction,
    removeCartBalloonPackageItemSuccessAction,
    updateDeliveryMethodSuccessAction,
    updateDeliveryMethodFailureAction,
    changeCartDeliveryModeFailure,
    changeCartDeliveryModeReset,
    updatePostalCodeSuccessAction,
    setSelectedFulFillmentOption,
    resetCartValidationsAction,
    updateAnalyticsInfo,
    setCheckoutPickup,
    setCheckoutContact,
    setCheckoutShipping,
    setCheckoutAppointment,
    setShowSpinner,
    setXhrInfoForGetCart,
    mergeCartSuccessAction,
    getCartItemsForAuthForMergeSuccessAction,
    setCheckoutPayment,
    updatePostalCodeDispatchStartedAction,
    updateStoreInitiatedCart,
    updateStoreInitiatedCartPostalCode,
    updateStoreInitiatedCartPostalCodeFail,
    updatePostalCodeFailureAction,
    shippingEstimationInProgress,
    shippingEstimationSuccess,
    shippingEstimationError,
    resetShippingEstimation,
    setSflToastVariables,
    applyPromoCodeSuccessAction,
    applyPromoCodeErrorAction,
    clearAddToCartErrorDataAction,
    setCartFlyoutOpenAction,
    initCheckoutFailure,
    setExpressDeliveryPostalCodeOutOfRange,
    resetReservationVariables,
    setShoppingCartLimitItemsFailure,
    clearShoppingCartLimitItemsFailure,
    resetAutoPackagesDataAction,
    setCheckoutAuthCartLoaded,
    addToCartBundleSuccess,
    addToCartBundleFailure,
    updateAutoPackageAction,
    updateUserInitializedPackageFlowAction,
    updateCurrentPackageIdAction,
    resetCheckOutVariablesAction,
    setCreatePackageClickAction,
    setHeliumInflationStoreToggleAction,
    setHeliumInflationGlobalToggleAction,
    setHeliumInflationSelectedAction,
    setInitiatePlaceOrderSequence,
    setShouldInvokeInitPayment,
    setInitPaymentStatusAction,
    getMiniCartPromoDataSuccessAction,
    getCartPTESuccess,
    getCartPTEError,
    setPaymentFlowType,
} from '../actionCreators'

import httpClient from '../utils/httpClient'
import { API_ENDPOINTS } from '../../environments/apiConfig'
import {
    AddProductToCartGuestRequestDTO,
    CartItemsData,
    ServiceDTO,
    UpdateDeliveryRequest,
    FilteredCartData,
    CartModificationsDTO,
    CartResponseErrorDTO,
    VehicleInformation,
    AddProductToCartGuestResponseDTO,
    MiniCartData,
    MiniCartStorageData,
    CheckoutCartItemsData,
    IAddToCartBundleRequest,
    AxiosCartResponseErrorDTO,
    MiniCartPromoData,
    CartPotentialToEarnDTO,
    ProcessCartData,
} from '../models/cart.interface'
import { HttpRequestState } from '../../httpClient/client.type'
import { CartUpdateType } from '../../components/ShoppingCart/ShoppingCart.type'
import { cartService } from '../../services'
import { RootState } from '../reducers'
import appCacheService from '../../utils/appCacheService'
import sessionStorageService from '../../utils/sessionStorageService'
import {
    AutoPackages,
    PackageLandingInitialState,
} from '../../components/AutomotivePackage/PackageLanding/PackageLanding.type'
import { MagicNumber } from '../../analytics/analytics.type'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import { productWheelTypes, AutoPackageKeys } from '../../globalConstants'
import { filterAutoPackageProductOnload, updateSessionDataForPackageFlow } from '../../helpers/autoPackage.helper'
import { errorAnalytics } from '../../analytics/components/errorAnalytics'
import fireAnalyticsForMerge from '../../analytics/components/mergeCartAnalytics'
import { CertonaInitialization } from '../../certona/certona.service'
import { certonaEventConst } from '../../certona/certona.constant'
import { pageTypes, STORE_INITIATED_CART_KEY } from '../../config'
import getPageType from '../../utils/getPageType'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { isOneTimeCartForAuthUser } from '../../utils/isOneTimeCartForAuthUser.utils'
import { AutoPackageModalData } from '../../components/ProductGridView/ProductCard.types'
import { getAutoPackageList, incompletePackageList } from '../../components/ProductGridView/ProductCard.helper'
import { isDeliveryModeForciblyChanged } from '../../components/ShoppingCart/CartItemSTH.helper'
import { AxiosError, AxiosResponse } from 'axios'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import convertToCartItemsDataForAuth from '../../utils/convertToCartItemsDataForAuth'
import { convertToMiniCartStorageData } from '../../utils/convertToMiniCartData'
import { currencyFormat, httpStatusCodes } from '../../globalConstants/global.constant'
import { deliveryOptionsService } from '../../services/deliveryOptionsService/deliveryOptionsService'
import { SuccessResponse } from '../models/deliveryOptions.interface'
import { cartLimitItemsErrorCode } from '../../globalConstants/cdsErrorCodes'
import customizeCertonaInitialization from '../../hooks/customizeCertonaInitialization.hook'
import { getMiniCartDefault } from '../../services/cartService/cart.service.constants'
import { isRedirectedAfterClickToPaySTHValidation } from '../../components/Checkout/Checkout.helper'
import { isBalloonAddonOptionsAvailable } from '../../components/CartFlyout/CartFlyoutComp.helper'
import { CountryType } from '../../components/Checkout/ShippingAddress/ShippingAddress.types'
import {
    heliumInflationGlobalToggleOffErrorCode,
    heliumInflationStoreToggleOffErrorCode,
} from '../../components/BuyBox/BuyBox.constant'
import { handleCartEvent } from '../../helpers/monetateFomoEvents.helper'
import { getFomoPriceData } from '@nl/lib/src/helpers/Price.helper'

/**
 * Function for Error logging
 * @template Type - type of error obj
 * @param {Type} err
 * @param {string} message
 * @returns {void}
 */
export const logError = <Type extends { message?: string; error?: string; statusCode?: number }>(
    err: Type,
    message: string,
): void => {
    // eslint-disable-next-line no-console
    console.group(message)
    // eslint-disable-next-line no-console
    console.log(`error Message: ${err?.message as string}`)
    // eslint-disable-next-line no-console
    console.log(`errorType: ${err?.error as string}`)
    // eslint-disable-next-line no-console
    console.log(`statusCode: ${err?.statusCode as number}`)
    // eslint-disable-next-line no-console
    console.groupEnd()
}

/**
 * This function is used to update the session storage package flow data based on the selected package
 * @param {string | undefined} selectedPackage
 * @param {CartModificationsDTO} cartModificationDTO
 * @param {AutoPackages} autoPackages
 * @param {boolean} isCreatePackage
 * @returns {void}
 */
export const updateSessionStorageData =
    (
        selectedPackage: string | undefined,
        cartModificationDTO: CartModificationsDTO,
        autoPackages: AutoPackages[],
        isCreatePackage?: boolean,
    ): ((dispatch: Dispatch) => void) =>
    (dispatch: Dispatch): void => {
        if (selectedPackage === productWheelTypes.Tire || selectedPackage === productWheelTypes.WinterTire) {
            const updateAutoPackage = updateSessionDataForPackageFlow(
                AutoPackageKeys.tireData,
                cartModificationDTO,
                autoPackages,
                isCreatePackage,
            )
            dispatch(updateAutoPackageAction(updateAutoPackage))
            dispatch(updateUserInitializedPackageFlowAction(true))
            dispatch(
                updateCurrentPackageIdAction(
                    updateAutoPackage[updateAutoPackage.length - MagicNumber.ONE].packageId || '',
                ),
            )
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (selectedPackage === productWheelTypes.Wheel) {
            const updateAutoPackage = updateSessionDataForPackageFlow(
                AutoPackageKeys.wheelData,
                cartModificationDTO,
                autoPackages,
                isCreatePackage,
            )
            dispatch(updateAutoPackageAction(updateAutoPackage))
            dispatch(updateUserInitializedPackageFlowAction(true))
            dispatch(
                updateCurrentPackageIdAction(
                    updateAutoPackage[updateAutoPackage.length - MagicNumber.ONE].packageId || '',
                ),
            )
        }
    }

/**
 * Adds a product to the cart.
 * @param {AddProductToCartGuestRequestDTO} requestPayload
 * @param {boolean} addServiceToCart
 * @param {boolean} showSpinner
 * @param {boolean} isBuybox
 * @param {string} postalCodeVal
 * @param {VehicleInformation} vehicleInformation
 * @param {boolean} isBulk
 * @param {boolean} isPackageFlow
 * @param {AutoPackages[]} autoPackages
 * @param {boolean} isStaggered
 * @param {boolean} isCreatePackage
 * @param {boolean} isShippingPostalCodeHide
 * @param {boolean} isCertonaRecItemAdded
 * @param {boolean} enableFOMO
 * @returns {Promise<void>}
 */
export const addProductToCartGuest =
    (
        requestPayload: AddProductToCartGuestRequestDTO,
        // eslint-disable-next-line default-param-last
        addServiceToCart = false,
        // eslint-disable-next-line default-param-last
        showSpinner = false,
        // eslint-disable-next-line default-param-last
        isBuybox = false,
        postalCodeVal?: string,
        vehicleInformation?: VehicleInformation,
        isBulk?: boolean,
        isPackageFlow?: boolean,
        autoPackages?: AutoPackages[],
        isStaggered?: boolean,
        isCreatePackage?: boolean,
        isShippingPostalCodeHide?: boolean,
        isCertonaRecItemAdded = false,
        enableFOMO = false,
        // eslint-disable-next-line max-params
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { selectedPreferredStoreId, preferredStoreDetails } = getState().storeDetails
        const { isAuthFlowExecuted, userProfileData } = getState().userProfile
        const lastCartStoreId = getState().cart?.cartItemsData?.cart?.store?.id
        const lastCartDeliveryMode =
            getState().cart?.cartItemsData?.cart?.deliveryMode || appCacheService?.cartData?.get()?.cart?.deliveryMode
        // eslint-disable-next-line sonar/expression-complexity
        const postalCode = isShippingPostalCodeHide
            ? userProfileData?.addresses?.postalCode || ''
            : postalCodeVal || preferredStoreDetails?.address?.postalCode || ''

        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required

        /**
         * TODO - this mock data is for pdp error messages. to be removed once we api sends error codes
         */
        const isMockData = getParamValue(window.location.search, 'isMockData', '?')

        if (isMockData) {
            return httpClient.apiGet(API_ENDPOINTS.addToCartError, {}, 'AEM_MOCK_JSON').then(data => {
                dispatch(addToCartGuestFailureAction(data.data))
            })
        }

        const isPackageFlowFunc = (data: AddProductToCartGuestResponseDTO) => {
            if (!autoPackages) return
            dispatch(resetSelectedServiceAction())

            if (isPackageFlow) {
                const lastAutoPackageItem = autoPackages[autoPackages.length - MagicNumber.ONE]
                if (!lastAutoPackageItem?.nextSelectedContainer) {
                    updateSessionStorageData(
                        lastAutoPackageItem?.currentSelectedContainer,
                        data.cartModifications[0],
                        autoPackages,
                        isCreatePackage,
                    )(dispatch)
                } else {
                    updateSessionStorageData(
                        lastAutoPackageItem?.nextSelectedContainer,
                        data.cartModifications[0],
                        autoPackages,
                        isCreatePackage,
                    )(dispatch)
                }
                dispatch(updateCurrentPackageIdAction(data.cartModifications[0].entry.packageId || ''))
            }
        }

        dispatch(setXhrInfoForGetCart(HttpRequestState.inProgress))

        return (
            cartService
                .addItemToCart(
                    requestPayload.guid,
                    requestPayload.entries.orderEntries,
                    selectedPreferredStoreId,
                    postalCode,
                    isStaggered,
                    isBulk,
                )
                // eslint-disable-next-line sonar/cyclomatic-complexity
                .then((data: AxiosResponse<AddProductToCartGuestResponseDTO>) => {
                    replaceEmptyImagesWithDefault(
                        data?.data?.cartModifications?.map(cartModification => cartModification?.entry),
                        'images',
                    )
                    isBuybox && dispatch(setCartFlyoutOpenAction(true))
                    const guid = data.data.guid
                    // OCCP-19905 store cart guid only for guest user in local storage
                    // OCCP-24280 removed guid check, as FED should replace guid always from ATC response
                    const isGuestUser = (): boolean => {
                        const isLoggedIn = isAuthFlowExecuted && Boolean(checkDataLength(userProfileData))
                        return !isLoggedIn
                    }
                    isGuestUser() && appCacheService.setCartGuid(guid)
                    isShoppingCartPage()
                        ? getCartItemsData(guid, '', true)(dispatch, getState)
                        : getMiniCartItemsData(guid, '')(dispatch, getState)
                    const cartEntriesData = { ...data.data }
                    cartEntriesData.cartModifications[0].numberOfItems = cartEntriesData?.numberOfItems || 0

                    if (lastCartStoreId && String(lastCartStoreId) !== selectedPreferredStoreId) {
                        appCacheService.cartStoreChanged.set(selectedPreferredStoreId)
                    }

                    if (isDeliveryModeForciblyChanged(requestPayload, lastCartDeliveryMode)) {
                        appCacheService.isDeliveryModeConflict.set('yes')
                    }

                    if (addServiceToCart) {
                        // OCCP-6273: vehicle information is not returned in cartModification for hardware addon but required for analytics purposes
                        cartEntriesData.cartModifications[0].entry.vehicleInformation = vehicleInformation || null
                    }

                    dispatch(addToCartGuestSuccessAction(cartEntriesData))
                    if (addServiceToCart) {
                        dispatch(
                            addServiceToCartSuccessAction({
                                success: true,
                                showErrorToast: false,
                                selectedService: data.data.serviceList[0],
                            }),
                        )
                    } else {
                        const filteredProductCodes = cartEntriesData.cartModifications
                            .map(({ entry }) => entry.baseProduct)
                            .join(';')

                        const isBalloonAddonAvailable = isBalloonAddonOptionsAvailable(
                            cartEntriesData.entryGroups,
                            cartEntriesData.cartModifications[0],
                        )
                        !isBalloonAddonAvailable &&
                            customizeCertonaInitialization(
                                certonaEventConst.addToCart,
                                isCertonaRecItemAdded,
                                filteredProductCodes,
                            )
                    }

                    isPackageFlowFunc(cartEntriesData)

                    if (enableFOMO && checkDataLength(cartEntriesData)) {
                        const cartData = cartEntriesData?.cartModifications?.map(cartItemData => ({
                            productId: cartItemData?.entry?.baseProduct,
                            sku: cartItemData?.entry?.code,
                            quantity: cartItemData?.entry?.quantity,
                            unitPrice: getFomoPriceData(cartItemData?.entry),
                            currency: currencyFormat,
                        }))
                        handleCartEvent(cartData)
                    }
                })
                .catch((err: AxiosCartResponseErrorDTO) => {
                    if (err.response) {
                        logError(err.response.data, 'Reason for add to cart api failure')
                        const errorResponse = err.response
                        const errCode = errorResponse?.data?.errCode
                        const statusCode = errorResponse?.data?.statusCode

                        if (errCode === heliumInflationStoreToggleOffErrorCode) {
                            dispatch(setHeliumInflationStoreToggleAction(false))
                            dispatch(setHeliumInflationSelectedAction(false))
                            // eslint-disable-next-line sonarjs/elseif-without-else
                        } else if (errCode === heliumInflationGlobalToggleOffErrorCode) {
                            dispatch(setHeliumInflationGlobalToggleAction())
                            dispatch(setHeliumInflationSelectedAction(false))
                        }

                        if (errCode === cartLimitItemsErrorCode) {
                            dispatch(setShoppingCartLimitItemsFailure(errorResponse))
                        } else if (statusCode === httpStatusCodes.gone) {
                            appCacheService.removeCartGuid()
                            appCacheService.cartData.delete()
                        } else {
                            addServiceToCart
                                ? dispatch(addServiceToCartFailureAction({ success: false, showErrorToast: true }))
                                : dispatch(addToCartGuestFailureAction(errorResponse))
                        }
                    }
                })
                .finally(() => {
                    dispatch(setShowSpinner(false))
                    dispatch(setXhrInfoForGetCart(HttpRequestState.done))
                })
        )
    }
/**
 * setting flag to call redux action to set SFL ID in local storage.
 * @param {string | undefined} sflId
 * @returns {void}
 */
export const setSflIdToLocalStorage = (sflId: string | undefined): void => {
    const slfguid = appCacheService.getSflGuid() || sflId
    !!slfguid && appCacheService.setSflGuid(slfguid)
}
export const resetCartGUIDCreatedFlag =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetCartGUIDCreatedFlagAction())
    }

export const setATCService =
    (services: ServiceDTO[]) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedServiceAction(services))
    }

export const resetATCService =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetSelectedServiceAction())
    }

/**
 * Fills checkout reducer with cart items
 * @param {CartItemsData} cartData
 * @param {Dispatch} dispatch
 */
export const fillCheckoutDataFromAPI = (cartData: CartItemsData, dispatch: Dispatch): void => {
    if (checkDataLength(cartData)) {
        if (cartData.contactInfo) {
            dispatch(setCheckoutContact(cartData.contactInfo))
        }
        if (checkDataLength(cartData.deliveryAddress?.address)) {
            const shippingAddressFromAPI = cartData.deliveryAddress
            const updateData = {
                firstName: shippingAddressFromAPI?.firstName,
                lastName: shippingAddressFromAPI?.lastName,
                addressLineOne: shippingAddressFromAPI?.address?.address1,
                addressLineTwo: shippingAddressFromAPI?.address?.address2,
                city: shippingAddressFromAPI?.address?.city,
                province: shippingAddressFromAPI?.address?.province,
                country: '',
                postalCode: shippingAddressFromAPI?.address?.postalCode,
            }
            dispatch(setCheckoutShipping(updateData))
        }
        if (cartData.pickupLocation) {
            dispatch(
                setCheckoutPickup({
                    orderPickupInPerson: cartData.orderPickupInPerson,
                    pickupLocation: cartData.pickupLocation,
                    vin: cartData.vin,
                    requestCallback: cartData.customerCallbackRequested,
                }),
            )
        }
        if (cartData.bookedAppointments) {
            dispatch(setCheckoutAppointment(cartData.bookedAppointments[MagicNumber.ZERO]))
        }
        if (cartData.paymentInfo?.billingAddress?.firstName) {
            dispatch(
                setCheckoutPayment({
                    billingAddress: {
                        ...cartData.paymentInfo?.billingAddress,
                        country: (cartData.paymentInfo?.billingAddress.country as CountryType)?.isocode,
                    },
                    newCreditCard: !isRedirectedAfterClickToPaySTHValidation(),
                    maskedCardNumber: cartData.paymentInfo?.maskedCardNumber,
                    cardType: cartData.paymentInfo?.cardType,
                }),
            )
        }
    }
}

/**
 * function to come out of package flow once complete package is available
 * @param {boolean} isCartFlyoutOpen
 * @param {PackageLandingInitialState} packages
 * @param {AutoPackages} currentPackage
 * @param {dispatch} dispatch
 */
const discardPackageFlow = (
    isCartFlyoutOpen: boolean,
    packages: PackageLandingInitialState,
    dispatch: Dispatch,
    currentPackage?: AutoPackages,
) => {
    if (
        // eslint-disable-next-line sonar/expression-complexity
        !isCartFlyoutOpen &&
        packages &&
        currentPackage &&
        checkDataLength(currentPackage?.vehicleInfo?.wheelData) &&
        checkDataLength(currentPackage?.vehicleInfo?.tireData) &&
        !currentPackage?.changeSelectedLink &&
        !currentPackage?.cartFlyoutChangeSelectedLink
    ) {
        dispatch(resetAutoPackagesDataAction())
        sessionStorageService.removeItem('packageFlow')
    }
}

/**
 * function to process received cart data
 * @param {ProcessCartData} processCartProps
 */
const processCartData = (
    processCartProps: ProcessCartData,
    // eslint-disable-next-line sonar/cyclomatic-complexity
) => {
    const {
        cartData,
        authenticatedUser,
        isOneTimeCart,
        isCartFlyoutOpen,
        isGetCartAfterUpdatingPaymentInfo,
        dispatch,
        saveLastVisitedDate,
        flowType = PaymentFlowType.NORMAL,
    } = processCartProps
    replaceEmptyImagesWithDefault(cartData?.orderEntries, 'images')
    const updatedGuid = cartData?.cartId
    !authenticatedUser && appCacheService.setCartGuid(updatedGuid)
    setSflIdToLocalStorage('saveForLaterId' in cartData ? cartData.saveForLaterId : '')
    const cartFilteredData = getFilteredCartItems(cartData)
    cartFilteredData.xhrInfo = {
        getCartInfo: HttpRequestState.done,
    }
    if (saveLastVisitedDate) appCacheService.cartLastVisitedDate.set(new Date().toLocaleDateString())
    filterAutoPackageProductOnload(cartFilteredData.cart)
    const packages = (sessionStorageService.getItem('packageFlow') &&
        JSON.parse(sessionStorageService.getItem('packageFlow') || '')) as PackageLandingInitialState
    const currentPackage = packages?.autoPackages?.find(item => item.packageId === packages.currentPackageId)
    discardPackageFlow(isCartFlyoutOpen, packages, dispatch, currentPackage)
    const pageType = getPageType()
    !(pageType === pageTypes.orderConfirmation || pageType === pageTypes.orderDetails) &&
        !isGetCartAfterUpdatingPaymentInfo &&
        fillCheckoutDataFromAPI(cartFilteredData.cart, dispatch)
    if (isOneTimeCart) {
        dispatch(updateStoreInitiatedCart(cartFilteredData))
    } else {
        isCheckoutPage()
            ? dispatch(getCheckoutCartItemsSuccessAction(cartFilteredData))
            : dispatch(getCartItemsSuccessAction(cartFilteredData))
    }
    isGetCartAfterUpdatingPaymentInfo &&
        flowType === PaymentFlowType.PLACE_ORDER &&
        dispatch(setInitiatePlaceOrderSequence(true))
    isOneTimeCart && sessionStorageService.setItem(STORE_INITIATED_CART_KEY, JSON.stringify(cartFilteredData))
}

const getPromiseResult = (
    guid: string,
    option: string,
    storeId: string | number,
    authenticatedUser = false,
    isOneTimeCart = false,
): Promise<{ data: CheckoutCartItemsData | CartItemsData; status: number }> => {
    return isCheckoutPage()
        ? cartService.getCheckoutCartItems(guid, option, storeId.toString(), authenticatedUser && isOneTimeCart)
        : cartService.getCartItems(guid, option, storeId.toString(), authenticatedUser && isOneTimeCart)
}

/**
 * function to receive and process cart items data
 * @param {string} guid
 * @param {string} option
 * @param {string | number} storeId
 * @param {boolean} authenticatedUser
 * @param {boolean} isOneTimeCart
 * @param {boolean} isCartFlyoutOpen
 * @param {boolean} isGetCartAfterUpdatingPaymentInfo
 * @param {Dispatch} dispatch
 * @param {boolean} isHideSpinnerAtExit
 * @param {boolean} saveLastVisitedDate
 * @param {PaymentFlowType} flowType
 */
const processCartCall = (
    guid: string,
    option: string,
    storeId: string | number,
    // eslint-disable-next-line default-param-last
    authenticatedUser = false,
    // eslint-disable-next-line default-param-last
    isOneTimeCart = false,
    isCartFlyoutOpen: boolean,
    isGetCartAfterUpdatingPaymentInfo: boolean,
    dispatch: Dispatch,
    // eslint-disable-next-line default-param-last
    isHideSpinnerAtExit = true,
    saveLastVisitedDate: boolean,
    flowType = PaymentFlowType.NORMAL,
    // eslint-disable-next-line max-params
) => {
    getPromiseResult(guid, option, storeId, authenticatedUser, isOneTimeCart)
        .then(cartData => {
            const processCartProps: ProcessCartData = {
                cartData: cartData?.data,
                authenticatedUser,
                isOneTimeCart,
                isCartFlyoutOpen,
                isGetCartAfterUpdatingPaymentInfo,
                dispatch,
                saveLastVisitedDate,
                flowType,
            }
            processCartData(processCartProps)
        })
        .catch((err: AxiosCartResponseErrorDTO) => {
            if (err.response) {
                logError(err.response.data, 'Reason for Get Cart Error')
                const errorResponse = err.response
                dispatch(getCartItemsFailureAction(errorResponse))
            }
            dispatch(setXhrInfoForGetCart(HttpRequestState.failed))
        })
        .finally(() => {
            isHideSpinnerAtExit && dispatch(setShowSpinner(false))
            flowType === PaymentFlowType.BILLING_ADDRESS && dispatch(setPaymentFlowType(PaymentFlowType.NORMAL))
        })
}

/**
 * Function to get cart items for shopping cart
 * @param {string} guid
 * @param {string} option
 * @param {boolean} showSpinner
 * @param {boolean} isOneTimeCart
 * @param {authenticatedUser} authenticatedUser
 * @param {boolean} isGetCartAfterUpdatingPaymentInfo
 * @param {boolean} isHideSpinnerAtExit
 * @param {boolean} saveLastVisitedDate
 * @param {PaymentFlowType} flowType
 * @returns {Promise}
 */
export const getCartItemsData =
    (
        guid: string,
        option: string,
        showSpinner = false,
        isOneTimeCart = false,
        authenticatedUser = false,
        isGetCartAfterUpdatingPaymentInfo = false,
        isHideSpinnerAtExit = true,
        saveLastVisitedDate = false,
        flowType = PaymentFlowType.NORMAL,
        // eslint-disable-next-line max-params
    ) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        const { oneTimeCartStore } = enableDestructOnUndefinedData(
            getState().sharedCart?.cartConsuming?.storeSharedCart,
        )
        const { isCartFlyoutOpen } = getState().product
        const storeId = isOneTimeCart ? oneTimeCartStore.id : selectedPreferredStoreId
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        dispatch(setXhrInfoForGetCart(HttpRequestState.inProgress))

        const { prefetchedCartPromise } = window

        if (!prefetchedCartPromise) {
            processCartCall(
                guid,
                option,
                storeId,
                authenticatedUser,
                isOneTimeCart,
                isCartFlyoutOpen,
                isGetCartAfterUpdatingPaymentInfo,
                dispatch,
                isHideSpinnerAtExit,
                saveLastVisitedDate,
                flowType,
            )
        } else {
            prefetchedCartPromise
                .then(response => {
                    if (response.ok) return response.json()
                    throw new Error('Prefetch request fall')
                })
                .then(cartData => {
                    cartService.updateMiniCartData(cartData)
                    const processCartProps: ProcessCartData = {
                        cartData,
                        authenticatedUser,
                        isOneTimeCart,
                        isCartFlyoutOpen,
                        isGetCartAfterUpdatingPaymentInfo,
                        dispatch,
                        saveLastVisitedDate,
                        flowType,
                    }
                    processCartData(processCartProps)
                })
                .catch(() => {
                    processCartCall(
                        guid,
                        option,
                        storeId,
                        authenticatedUser,
                        isOneTimeCart,
                        isCartFlyoutOpen,
                        isGetCartAfterUpdatingPaymentInfo,
                        dispatch,
                        isHideSpinnerAtExit,
                        saveLastVisitedDate,
                        flowType,
                    )
                })
                .finally(() => {
                    isHideSpinnerAtExit && dispatch(setShowSpinner(false))
                    window.prefetchedCartPromise = undefined
                    flowType === PaymentFlowType.BILLING_ADDRESS && dispatch(setPaymentFlowType(PaymentFlowType.NORMAL))
                })
        }
    }

/**
 * Function to get mini cart items for shopping cart
 * @param {string} guid
 * @param {string} option
 * @param {boolean} showSpinner
 * @param {boolean} enableCache
 * @returns {Promise}
 */
export const getMiniCartItemsData =
    (guid: string, option: string, showSpinner = false, enableCache = false) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const storeId = getState().storeDetails.selectedPreferredStoreId
        const isUserAuthorized = checkDataLength(getState().userProfile?.userProfileData)
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        dispatch(setXhrInfoForGetCart(HttpRequestState.inProgress))
        cartService
            .getMiniCartItems(guid, option, storeId, enableCache)
            .then(cartData => {
                const updatedGuid = cartData?.data?.cartId
                updatedGuid && appCacheService.setCartGuid(updatedGuid)
                dispatch(getMiniCartItemsSuccessAction(cartData.data))
                if (cartData?.data?.onSaleItems) {
                    dispatch(getMiniCartPromoDataSuccessAction(cartData.data.onSaleItems))
                } else {
                    const miniCartPromoData = JSON.parse(appCacheService.miniCartPromoData.get()) as MiniCartPromoData
                    dispatch(getMiniCartPromoDataSuccessAction(miniCartPromoData))
                }
                dispatch(setXhrInfoForGetCart(HttpRequestState.done))
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorResponse = err.response || ({} as CartResponseErrorDTO)

                if (errorResponse?.data?.statusCode === httpStatusCodes.gone) {
                    if (!appCacheService.getCartGuid() && isUserAuthorized) {
                        const miniCartStorageData: MiniCartStorageData =
                            convertToMiniCartStorageData(getMiniCartDefault)
                        appCacheService.miniCartData.set(JSON.stringify(miniCartStorageData))
                        dispatch(getMiniCartItemsSuccessAction(getMiniCartDefault))
                        dispatch(setXhrInfoForGetCart(HttpRequestState.done))
                    }
                    appCacheService.removeCartGuid()
                } else {
                    logError(errorResponse?.data, 'Reason for Get Mini Cart Error')
                    dispatch(setXhrInfoForGetCart(HttpRequestState.failed))
                    dispatch(getCartItemsFailureAction(errorResponse))
                }
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Function to call initCheckout Api to reserve the cart items for the current user
 * @param {string} guid
 * @param {boolean} showSpinner
 * @returns {Promise}
 */

export const reserveCartItems =
    (guid: string, showSpinner = false) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        const { storeInitiatedCart, isStoreSharedCart } = enableDestructOnUndefinedData(
            getState().sharedCart?.cartConsuming?.storeSharedCart,
        )
        const storeId = isStoreSharedCart ? storeInitiatedCart?.storeData?.id : selectedPreferredStoreId
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        dispatch(reservationRequestInProgress())
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        cartService
            .initCheckout(guid, storeId?.toString(), isOneTimeCartFlag)
            .then(data => {
                replaceEmptyImagesWithDefault(data?.data?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                cartFilteredData.xhrInfo = {
                    getCartInfo: HttpRequestState.done,
                }
                if (isStoreSharedCart) {
                    dispatch(updateStoreInitiatedCart(cartFilteredData))
                    dispatch(setShouldInvokeInitPayment(true))
                    dispatch(setInitPaymentStatusAction(false))
                } else {
                    dispatch(updateCartDataSuccessAction(cartFilteredData))
                }
            })
            .then(() => {
                dispatch(reservationRequestFinished())
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorResponse = err.response
                if (errorResponse) {
                    dispatch(getCartItemsFailureAction(errorResponse))
                }

                dispatch(initCheckoutFailure())
                dispatch(resetCheckOutVariablesAction())
                dispatch(resetReservationVariables())
                dispatch(setShowSpinner(false))
            })
    }

// eslint-disable-next-line no-warning-comments
// TODO: mockFileName param will be removed once the actual api is integrated.
export const removeCartItem =
    (
        guid: string,
        mockFileName?: string,
        showSpinner = false,
        entryNumberArray: number[] = [],
        productIds: string[] = [],
        isFromSFL = false,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { selectedPreferredStoreId } = getState().storeDetails
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        return cartService
            .updateCartEntry(guid, 0, true, mockFileName, selectedPreferredStoreId, entryNumberArray)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                isFromSFL &&
                    dispatch(
                        setSflToastVariables({
                            addedToSflSuccess: true,
                        }),
                    )
                productIds.forEach(product => {
                    CertonaInitialization.triggerCertona({
                        itemid: product,
                        event: certonaEventConst.removeFromCart,
                        recommendations: false,
                    })
                })
                const cartFilteredData = getFilteredCartItems(data.data)
                dispatch(removeCartItemSuccessAction(cartFilteredData))
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorMessage = err.response?.data.errors?.[0].message as string
                dispatch(
                    removeCartItemFailureAction({
                        errorFlag: true,
                        errorMessage: errorMessage,
                    }),
                )
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

// Action for updating the cart data when clicked on quantity selector
// eslint-disable-next-line no-warning-comments
// TODO: this action should have a payload for a PUT call.
export const updateCartData =
    (
        guid: string,
        quantity: number,
        isAdd: boolean,
        selectedPreferredStoreId: string,
        entryNumberArray: number[],
        inputRef?: React.MutableRefObject<HTMLElement>,
    ) =>
    // (guid: string, entryNumber: number, quantity: number, isAdd: boolean, selectedPreferredStoreId: string) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(setShowSpinner(true))
        return cartService
            .updateCartEntry(guid, quantity, false, '', selectedPreferredStoreId, entryNumberArray)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data, quantity)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
                dispatch(
                    updateAnalyticsInfo({
                        updateType: isAdd ? CartUpdateType.ADD_QUANTITY : CartUpdateType.REMOVE_QUANTITY,
                    }),
                )
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorResponse = err.response

                if (errorResponse) dispatch(updateCartDataFailureAction(errorResponse))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
                inputRef?.current?.focus()
            })
    }

// OCCP-18331: action to create an auto package. changed from calling add-to-cart to calling update-cart-entry
/**
 * create auto package
 * @param {string} guid - guid for the user
 * @param {string} storeId
 * @param {number[]} entryNumberArray
 * @param {AutoPackages[] } autoPackages
 * @param { AutoPackageModalData } selectedCard
 * @param { string } redirectURL
 * @returns {AxiosPromise}
 */
export const createAutoPackage =
    (
        guid: string,
        redirectURL: string,
        autoPackages: AutoPackages[],
        storeId?: string,
        entryNumberArray?: number[],
        selectedCard?: AutoPackageModalData,
    ) =>
    (dispatch: Dispatch): Promise<void> => {
        return cartService
            .createAutoPackage(guid, storeId, entryNumberArray)
            .then((data: AxiosResponse<CartItemsData>) => {
                replaceEmptyImagesWithDefault(data?.data?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
                const lastAutoPackageItem = autoPackages?.[autoPackages.length - MagicNumber.ONE]
                const unfinishedAutoPackageArray = incompletePackageList(getAutoPackageList(data?.data?.orderEntries))
                const updatingProduct =
                    unfinishedAutoPackageArray?.find(item => item.code === selectedCard?.code) ||
                    ({} as AutoPackageModalData)
                const modifiedData = {
                    entry: updatingProduct,
                    errorCode: updatingProduct?.errorCode || '',
                    errorMessage: data?.data?.errorMessage,
                    quantityAdded: updatingProduct?.quantity,
                }
                dispatch(setCreatePackageClickAction(true))
                if (!lastAutoPackageItem?.nextSelectedContainer) {
                    updateSessionStorageData(
                        lastAutoPackageItem?.currentSelectedContainer,
                        modifiedData,
                        autoPackages,
                        true,
                    )(dispatch)
                } else {
                    updateSessionStorageData(
                        lastAutoPackageItem?.nextSelectedContainer,
                        modifiedData,
                        autoPackages,
                        true,
                    )(dispatch)
                }
                window.location.href = encodeURI(redirectURL)
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                if (err.response) {
                    dispatch(updateCartDataFailureAction(err.response))
                }
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Function to call update cart Api to update pickup location for BOPIS products
 * @param {string} guid
 * @param {string} storeId
 * @param {boolean} showSpinner
 * @returns {Promise}
 */
export const updateCartPickupLocation =
    (guid: string, storeId: string, showSpinner = false) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { cartItemsData } = getState().cart
        showSpinner && dispatch(setShowSpinner(true))
        return cartService
            .updateCart(guid, storeId, cartItemsData.cart?.orderEntries?.length)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                // eslint-disable-next-line no-warning-comments
                // TODO: code commented which can be used to handle 206 error
                // if (data.status === httpStatusCodes.successCode) {
                dispatch(updateCartDataSuccessAction(cartFilteredData))
                // }
                // // added for OCCP-18509 cds api change for postalcode change response
                // if (data.status === httpStatusCodes.partialSuccessCode) {
                //     dispatch(updatePostalCodePartialSuccessAction(cartFilteredData))
                // }
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorResponse = err.response

                if (errorResponse) {
                    dispatch(updateCartDataFailureAction(errorResponse))
                }
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

// Action for updating the cart data when delivery method for the bulk item changed
export const updateBulkDeliveryMethod =
    (requestPayload: UpdateDeliveryRequest) =>
    (dispatch: Dispatch): Promise<void> => {
        const guid = appCacheService.getCartGuid()
        return cartService
            .updateBulkDeliveryOption(guid, requestPayload)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                if (requestPayload.bulkDeliveryOptionType) {
                    // Analytics info updated
                    dispatch(updateAnalyticsInfo({ updateType: CartUpdateType.BULK_DELIVERY_OPTION }))
                }
                dispatch(updateDeliveryMethodSuccessAction(cartFilteredData))
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorResponse = err.response
                if (errorResponse) {
                    dispatch(updateDeliveryMethodFailureAction(errorResponse))
                }
            })
    }

/**
 * action to update postal code in cart
 * @param {string} postalCode - given postal code
 * @param {string} cartGUID - list of postal codes available
 * @param {boolean} showSpinner
 * @param {boolean} isBulk - for bulk items
 * @param {string} context - from which page the api is getting called
 * @param {string} outOfRangeErrorCode
 * @param {boolean} hideSpinnerOnError
 * @returns {Dispatch}
 */
export const updatePostalCodeForCart =
    (
        postalCode: string,
        cartGUID: string,
        // eslint-disable-next-line default-param-last
        showSpinner = false,
        isBulk?: boolean,
        context?: string,
        outOfRangeErrorCode?: string,
        hideSpinnerOnError = false,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        dispatch(updatePostalCodeDispatchStartedAction(true))
        dispatch(resetCartValidationsAction())
        const { selectedPreferredStoreId, preferredStoreDetails } = getState().storeDetails
        const { oneTimeCartStore, isStoreSharedCart } = enableDestructOnUndefinedData(
            getState().sharedCart?.cartConsuming?.storeSharedCart,
        )
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        return cartService
            .updatePostalCodeToCart(
                postalCode,
                cartGUID,
                isStoreSharedCart ? oneTimeCartStore?.id.toString() : selectedPreferredStoreId,
                isOneTimeCartFlag,
                isBulk,
                context,
                preferredStoreDetails?.isExpressDeliveryEligible,
            )
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                cartFilteredData.isPostalCodePartialSuccess = Boolean(
                    cartFilteredData.cart?.errorCode && cartFilteredData.cart.errorCode === outOfRangeErrorCode,
                )
                // eslint-disable-next-line no-warning-comments
                // TODO: code commented which can be used to handle 206 error for future reference when all APIs return status code as 206
                // if (data.status === httpStatusCodes.successCode) {
                // intentional revert for OCCP 20426 to address the primary issue
                isStoreSharedCart
                    ? dispatch(updateStoreInitiatedCartPostalCode(cartFilteredData))
                    : dispatch(updatePostalCodeSuccessAction(cartFilteredData, isCheckoutPage()))
                // }
                // // added for OCCP-18509 cds api change for postalcode change response
                if (data.status === httpStatusCodes.partialSuccessCode) {
                    //  dispatch(updatePostalCodePartialSuccessAction(cartFilteredData))
                }
                if (isCheckoutPage()) {
                    // invoke initPayment api if gift card used
                    const totalGiftCardAmount = cartFilteredData.cart.paymentInfo.totalGiftCardAmount?.value
                    if (totalGiftCardAmount && totalGiftCardAmount > 0) {
                        dispatch(setShouldInvokeInitPayment(true))
                        dispatch(setInitPaymentStatusAction(false))
                    }
                    hideSpinnerOnError && dispatch(setShowSpinner(false))
                }
                !isCheckoutPage() && dispatch(setShowSpinner(false))
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                if (err.response) {
                    isStoreSharedCart
                        ? dispatch(updateStoreInitiatedCartPostalCodeFail(err.response))
                        : dispatch(updatePostalCodeFailureAction(err.response))
                }
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Action to update postal code in cart when postal code is express delivery elligible
 *
 * @param {string} postalCode - given postal code
 * @param {string} selectedPreferredStore - given postal code
 * @param {string} selectedSku - product sku elligible for express delivery
 * @param {boolean} isBulk - for bulk items
 * @param {boolean} isProductAndStoreAvailableForED
 * @param {string} cartGUID - list of postal codes available
 * @param {string} outOfRangeErrorCode
 * @returns {void}
 */
export const getDeliveryOptionsForCartPage =
    (
        postalCode: string,
        selectedPreferredStore: string | number,
        selectedSku: string | undefined,
        // eslint-disable-next-line default-param-last
        isBulk = false,
        // eslint-disable-next-line default-param-last
        isProductAndStoreAvailableForED = false,
        cartGUID: string,
        outOfRangeErrorCode?: string,
    ) =>
    (dispatch: Dispatch<any>): void => {
        deliveryOptionsService
            .fetchDeliveryOptions(
                postalCode,
                selectedPreferredStore,
                selectedSku,
                isBulk,
                isProductAndStoreAvailableForED,
            )
            .then((deliveryOptionsData: Record<string, SuccessResponse>) => {
                const response = deliveryOptionsData.data
                if (response.isExpressDeliveryEligible) {
                    dispatch(setExpressDeliveryPostalCodeOutOfRange(false))
                    dispatch(updatePostalCodeForCart(postalCode, cartGUID, true, isBulk, '', outOfRangeErrorCode))
                } else {
                    dispatch(setExpressDeliveryPostalCodeOutOfRange(true))
                }
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                if (err.response) dispatch(updatePostalCodeFailureAction(err.response))
            })
    }

/**
 * action to estimate shipping cost without updating cart
 * @param {string} postalCode - given postal code
 * @param {string} cartGUID - list of postal codes available
 * @param {boolean} showSpinner
 * @returns {Dispatch}
 */
export const estimateShippingCost =
    (postalCode: string, cartGUID: string, showSpinner = false) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        dispatch(resetShippingEstimation())
        dispatch(shippingEstimationInProgress(true))
        const { selectedPreferredStoreId, preferredStoreDetails } = getState().storeDetails
        return cartService
            .estimateShippingCost(
                postalCode,
                cartGUID,
                selectedPreferredStoreId,
                preferredStoreDetails?.isExpressDeliveryEligible,
            )
            .then(data => {
                dispatch(shippingEstimationSuccess(data.data))
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                if (err.response) dispatch(shippingEstimationError(err.response))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Action to change cart delivery mode
 * @param {string} deliveryMode - delivery mode value
 * @returns {Promise<void>} void promise
 */
export const changeCartDeliveryMode =
    (deliveryMode: string) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        dispatch(setShowSpinner(true))
        dispatch(changeCartDeliveryModeReset())
        const cartId = getState().cart.cartItemsData.cart.cartId
        return cartService
            .changeCartDeliveryMode(cartId, deliveryMode)
            .then(() => {
                getCartItemsData(cartId, '', true)(dispatch, getState)
                dispatch(
                    updateAnalyticsInfo({
                        updateType: CartUpdateType.DELIVERY_OPTIONS_CHANGE,
                        option: { deliveryModeType: deliveryMode },
                    }),
                )
            })
            .catch((error: AxiosCartResponseErrorDTO) => {
                if (error.response) dispatch(changeCartDeliveryModeFailure(error.response))
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Action to apply promo code to the cart
 * @param {string} promoCode - promo code
 * @returns {Dispatch}
 */
export const applyPromoCode =
    (promoCode: string) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        dispatch(setShowSpinner(true))

        const cartId = getState().cart.cartItemsData?.cart?.cartId
        const { selectedPreferredStoreId } = getState().storeDetails
        return cartService
            .applyPromoCode(cartId, promoCode, selectedPreferredStoreId)
            .then(response => {
                const cartFilteredData = getFilteredCartItems(response.data)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
                dispatch(applyPromoCodeSuccessAction(promoCode))
            })
            .catch((error: AxiosCartResponseErrorDTO) => {
                if (error.response) dispatch(applyPromoCodeErrorAction(error.response.data))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Action to delete promo code from the cart
 * @param {string} promoCode - promo code
 * @returns {Dispatch}
 */
export const deletePromoCode =
    (promoCode: string) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        dispatch(setShowSpinner(true))

        const cartId = getState().cart.cartItemsData?.cart?.cartId
        const { selectedPreferredStoreId } = getState().storeDetails
        return cartService
            .deletePromoCode(cartId, promoCode, selectedPreferredStoreId)
            .then(response => {
                const cartFilteredData = getFilteredCartItems(response.data)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
            })
            .catch((error: AxiosCartResponseErrorDTO) => {
                logError(error?.response?.data || {}, 'Reason for Delete Promo Code error')
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * function to track selected fulfillment option
 * @param {string} value - selected fulfillment option
 * @param {number} entryNumber
 * @returns {Dispatch}
 */
export const changeFulFillmentOption =
    (value: string, entryNumber: number) =>
    (dispatch: Dispatch): void => {
        dispatch(
            setSelectedFulFillmentOption({
                mode: value,
                entryNumber,
            }),
        )
    }

/**
 * function to track selected fulfillment option
 * @param {string} value - selected fulfillment option
 * @param {string} guid cart id
 * @param {number[]} entryNumberArray entry number array
 * @param {boolean} showSpinner show spinner flag
 * @param {boolean} isSTHDeliveryOptionChange if update is from STH delivery option change
 * @returns {Promise<void>} void promise
 */
export const updateDeliveryMethodAction =
    (value: string, guid: string, entryNumberArray: number[], showSpinner = false, isSTHDeliveryOptionChange = false) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        const postalCode = getState().cart.cartItemsData.deliveryAddress?.address?.postalCode || ''
        const { selectedPreferredStoreId } = getState().storeDetails
        return cartService
            .updateDeliveryOption(value, guid, selectedPreferredStoreId, postalCode, entryNumberArray)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
                // update information for analytics
                dispatch(
                    updateAnalyticsInfo({
                        updateType: CartUpdateType.FULFILLMENT_CHANGE,
                        option: { deliveryModeType: value, entryNumber: entryNumberArray[0] },
                    }),
                )
                isSTHDeliveryOptionChange &&
                    dispatch(
                        updateAnalyticsInfo({
                            updateType: CartUpdateType.DELIVERY_OPTIONS_CHANGE,
                        }),
                    )
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorResponse = err.response
                if (errorResponse) dispatch(updateCartDataFailureAction(errorResponse))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * function to reset cart validation error messages
 * @returns {Dispatch}
 */
export const resetCartValidations =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetCartValidationsAction())
    }

/**
 * gets cart items for auth users
 * @param {string} guid
 * @returns {void}
 */
export const getCartItemsForAuthForMerge =
    (guid: string) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        cartService
            .getCartItems(guid, '', selectedPreferredStoreId)
            .then((cartData: { data: CartItemsData }) => {
                replaceEmptyImagesWithDefault(cartData?.data?.orderEntries, 'images')
                setSflIdToLocalStorage(cartData?.data?.saveForLaterId)
                const cartFilteredData = getFilteredCartItems(cartData.data)
                cartFilteredData.xhrInfo = {
                    getCartInfo: HttpRequestState.done,
                }
                fillCheckoutDataFromAPI(cartFilteredData.cart, dispatch)
                dispatch(getCartItemsSuccessAction(cartFilteredData))
                dispatch(getCartItemsForAuthForMergeSuccessAction(cartFilteredData))
            })
            .catch((err: AxiosError<Record<string, unknown>>) => {
                logError(err?.response?.data || {}, 'Reason for Get Cart Error')
                dispatch(getCartItemsForAuthForMergeSuccessAction({} as FilteredCartData))
            })
    }

/**
 * gets mini cart items for auth users
 * @param {string} guid
 * @returns {void}
 */
export const getMiniCartItemsForAuthForMerge =
    (guid: string) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        cartService
            .getMiniCartItems(guid, '', selectedPreferredStoreId)
            .then((cartData: { data: MiniCartData }) => {
                dispatch(getMiniCartItemsSuccessAction(cartData.data))
                dispatch(getCartItemsForAuthForMergeSuccessAction(convertToCartItemsDataForAuth(cartData.data)))
                if (cartData?.data?.onSaleItems) {
                    dispatch(getMiniCartPromoDataSuccessAction(cartData.data.onSaleItems))
                } else {
                    const miniCartPromoData = JSON.parse(appCacheService.miniCartPromoData.get()) as MiniCartPromoData
                    dispatch(getMiniCartPromoDataSuccessAction(miniCartPromoData))
                }
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorResponse = err.response
                if (errorResponse) {
                    if (errorResponse.data.statusCode === httpStatusCodes.gone) {
                        const guestCartId = appCacheService.getCartGuid()
                        const isUserAuthorized = checkDataLength(getState().userProfile?.userProfileData)
                        const pageType = getPageType()
                        if (
                            !!guestCartId &&
                            isUserAuthorized &&
                            !(pageType === pageTypes.orderConfirmation || pageType === pageTypes.orderDetails)
                        ) {
                            dispatch(mergeAuthCartWithGuest(guestCartId, '') as unknown as AnyAction)
                        } else {
                            appCacheService.removeCartGuid()
                        }
                    } else {
                        logError(errorResponse.data, 'Reason for Get Mini Cart Error')
                    }
                }
                dispatch(getCartItemsForAuthForMergeSuccessAction({} as FilteredCartData))
            })
    }

/**
 * gets checkout cart items for auth users
 * @param {string} guid
 * @returns {void}
 */
export const getCheckoutCartItemsForAuthForMerge =
    (guid: string) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        cartService
            .getCheckoutCartItems(guid, '', selectedPreferredStoreId)
            .then((cartData: { data: CheckoutCartItemsData | CartItemsData }) => {
                replaceEmptyImagesWithDefault(cartData?.data?.orderEntries, 'images')
                setSflIdToLocalStorage('saveForLaterId' in cartData.data ? cartData.data.saveForLaterId : '')
                const cartFilteredData = getFilteredCartItems(cartData.data)
                cartFilteredData.xhrInfo = {
                    getCartInfo: HttpRequestState.done,
                }
                fillCheckoutDataFromAPI(cartFilteredData.cart, dispatch)
                dispatch(getCheckoutCartItemsSuccessAction(cartFilteredData))
                dispatch(getCartItemsForAuthForMergeSuccessAction(cartFilteredData))
                dispatch(setCheckoutAuthCartLoaded(true))
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorResponse = err?.response?.data
                if (errorResponse?.statusCode === httpStatusCodes.gone) {
                    appCacheService.removeCartGuid()
                } else {
                    logError(errorResponse || {}, 'Reason for Get Checkout Cart Error')
                }
                dispatch(getCartItemsForAuthForMergeSuccessAction({} as FilteredCartData))
                dispatch(setXhrInfoForGetCart(HttpRequestState.failed))
            })
    }

/**
 * Calls merge cart API
 * @param {string} guestCartId
 * @param {string} authCartId
 * @param {number[]} orderEntries
 * @param {boolean} fromLogin
 * @param {boolean} showSpinner
 * @param { Function } successCallback
 * @param {boolean} isBuyNow
 * @returns {Promise<void>}
 */
export const mergeAuthCartWithGuest =
    (
        guestCartId: string,
        authCartId: string,
        orderEntries?: number[],
        fromLogin?: boolean,
        // eslint-disable-next-line default-param-last
        showSpinner = false,
        successCallback?: () => void,
        isBuyNow?: boolean,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { selectedPreferredStoreId } = getState().storeDetails
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        return cartService
            .mergeCartItems(guestCartId, authCartId, selectedPreferredStoreId, orderEntries, isBuyNow)
            .then((data: AxiosResponse<CartItemsData>) => {
                const updatedGuid = data?.data?.cartId
                appCacheService.setCartGuid(updatedGuid)
                replaceEmptyImagesWithDefault(data?.data?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                cartFilteredData.xhrInfo = {
                    getCartInfo: HttpRequestState.done,
                }
                fillCheckoutDataFromAPI(cartFilteredData.cart, dispatch)
                isCheckoutPage()
                    ? dispatch(getCheckoutCartItemsSuccessAction(cartFilteredData))
                    : dispatch(getCartItemsSuccessAction(cartFilteredData))
                fireAnalyticsForMerge(guestCartId, authCartId, cartFilteredData, orderEntries, fromLogin)
                dispatch(mergeCartSuccessAction(true))
                successCallback && successCallback()
                !isCheckoutPage() && getMiniCartItemsData(updatedGuid, '')(dispatch, getState)
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorResponse = err.response
                dispatch(mergeCartSuccessAction(false))
                dispatch(setXhrInfoForGetCart(HttpRequestState.failed))

                if (errorResponse) dispatch(getCartItemsFailureAction(errorResponse))

                errorAnalytics(errorResponse?.data.message, errorResponse?.data.errOrigin)
            })
            .finally(() => {
                showSpinner && dispatch(setShowSpinner(false))
            })
    }

/**
 * Function to remove cart error data
 * @returns {Promise<void>}
 */
export const clearAddToCartErrorData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearAddToCartErrorDataAction())
    }

/**
 * Function to add or remove reusable bag to cart
 * @param {boolean} optInOut
 * @param {string} guid
 * @param {string} selectedPreferredStoreId
 * @param {boolean} showSpinner
 * @returns {Promise<void>}
 */
export const updateValueBagsOptIn =
    (optInOut: boolean, guid: string, selectedPreferredStoreId: string, showSpinner = false) =>
    (dispatch: Dispatch): Promise<void> => {
        showSpinner && dispatch(setShowSpinner(true))

        return cartService
            .updateReusableBagsOptIn(optInOut, guid, selectedPreferredStoreId)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                const newCartFilteredData = getFilteredCartItems(data.data)
                dispatch(updateCartDataSuccessAction(newCartFilteredData))
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorResponse = err.response
                if (errorResponse) dispatch(updateCartDataFailureAction(errorResponse))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Clear shopping cart limit failure value
 * @returns {void}
 */
export const clearCartLimitItemsFailure =
    () =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const cartLimitItemsFailure = getState().cart.cartLimitItemsFailure
        if (checkDataLength(cartLimitItemsFailure)) {
            dispatch(clearShoppingCartLimitItemsFailure())
        }
    }

/**
 * Clear shipping fees data if store eligible for express - reset shippingEstimationsStandardAndExpress if not - reset shippingEstimation
 * @returns {void}
 */
export const clearShippingFeesData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetShippingEstimation())
    }

/**
 * Function to set add to cart bundle
 * @param {IAddToCartBundleRequest} requestPayload request body for API call
 * @returns {Promise<void>} void
 */
export const addToCartBundle =
    (requestPayload: IAddToCartBundleRequest) =>
    (dispatch: Dispatch): Promise<void> => {
        return cartService
            .addToCartBundle(requestPayload)
            .then(() => {
                dispatch(addToCartBundleSuccess())
            })
            .catch((error: AxiosError<string>) => {
                dispatch(
                    addToCartBundleFailure({
                        data: error?.response?.data ?? null,
                        status: error?.response?.status ?? null,
                    }),
                )
            })
    }

/**
 * Function remove item from Balloon package (weight or bag)
 * @param requestPayload
 * @param showSpinner
 */
export const deleteItemFromBalloonPackage =
    (requestPayload: IAddToCartBundleRequest, showSpinner = false) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { selectedPreferredStoreId } = getState().storeDetails
        showSpinner && dispatch(setShowSpinner(true))
        return cartService
            .deleteItemFromBalloonPackage(requestPayload, selectedPreferredStoreId)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')

                const cartFilteredData = getFilteredCartItems(data.data)
                dispatch(removeCartBalloonPackageItemSuccessAction(cartFilteredData))
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                const errorMessage = err.response?.data.errors?.[0].message as string
                dispatch(
                    removeCartItemFailureAction({
                        errorFlag: true,
                        errorMessage: errorMessage,
                    }),
                )
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Function to get potential to earn on cart
 * @returns {Promise<void>} - A promise that resolves when the operation is complete
 */
export const getCartPTE =
    (storeId: string | number) =>
    (dispatch: Dispatch): Promise<void> => {
        return cartService
            .createCartPTEUrl(storeId.toString())
            .then((data: CartPotentialToEarnDTO) => {
                dispatch(getCartPTESuccess(data))
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                logError(err?.response?.data || {}, 'Reason for Get Cart PTE Error')
                if (err?.response) {
                    dispatch(getCartPTEError())
                }
            })
    }
