/* eslint no-invalid-this: 0 */
// eslint-disable-next-line no-warning-comments
// TODO: need to investigate above eslint issue further
import { AnalyticsComponentInterface, TriggerEventType } from './component.type'
import { AnalyticsRecordInterface, BaseProvider, PromotionRecord } from '../providers/provider.type'
/**
 * Analytics base class implementation
 */
export abstract class AnalyticsComponent implements AnalyticsComponentInterface {
    /**
     * Base constructor for Analytics component
     * @param  {HTMLElement} domElement
     * @param  {TriggerEventType[]} triggerEvents
     * @param  {PromotionRecord}  analyticsData
     * @param  {BaseProvider} analyticsProvider
     */
    constructor(
        public domElement: HTMLElement,
        public triggerEvents: TriggerEventType[],
        public analyticsData: PromotionRecord,
        public analyticsProvider: BaseProvider,
    ) {
        this.domElement = domElement
        this.triggerEvents = triggerEvents
        this.analyticsData = analyticsData
        this.analyticsProvider = analyticsProvider
    }
    /**
     * push data to analytics server
     * @param  {AnalyticsRecordInterface} data
     */
    push = (data: AnalyticsRecordInterface): void => {
        this.analyticsProvider.push(data)
    }
    /**
     * Return path name from current URL.
     * @return {string}
     */
    pathname = (): string => {
        const url = new URL(window.location.href)
        return url.pathname
    }
    /**
     * Abstract function
     */
    abstract registerListeners: () => void
    abstract createData: () => AnalyticsRecordInterface
}
