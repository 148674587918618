import React from 'react'
import { connect } from 'react-redux'

import { RootState } from '../../../redux/reducers'
import { firstIndex, timeOutMilliSecond } from './GlobalPageLoadAnalytics.constant'
import { GlobalPageLoadAnalyticsService } from './GlobalPageLoadAnalytics.service'
import getPageType from '../../../utils/getPageType'
import { isPLPPageReady } from '../../../utils/checkIsAutomotivePage'

import globalAnalyticsHelper from './globalAnalyticsHelper'
import { GlobalOnloadAnalyticData, GlobalPageLoadAnalyticsHocProps } from './GlobalPageLoadAnalytics.type'

/**
 * HOC function for global page load analytics implementation
 * @param {React.FC<any>} WrappedComponent - component to wrap
 * @returns {connect} - hoc component
 */
function globalPageLoadAnalyticsHoc(WrappedComponent: React.FC<any>) {
    let onPageLoadAnalyticsIndex = 0
    /**
     * HOC component class
     */
    class GlobalPageLoadAnalyticsHoc extends React.Component<GlobalPageLoadAnalyticsHocProps, object> {
        /**
         * Check and push analytics data
         * @returns {void} - void
         */
        componentDidUpdate(): void {
            // eslint-disable-next-line no-warning-comments
            // TODO: This condition will update based on the component we will wrap to avoid multiple analytics stamps
            if (this.props.isAuthFlowExecuted && onPageLoadAnalyticsIndex === 0) {
                const pageType = getPageType()
                if (
                    isPLPPageReady(this.props.categoryData!, this.props.storeData!) ||
                    globalAnalyticsHelper(pageType, this.props.storeData, this.props.orderData)
                ) {
                    // eslint-disable-next-line no-warning-comments
                    // TODO need to wait for triangle transaction history data before firing the analytics. Currently there is no unique page type present for this
                    setTimeout(() => {
                        GlobalPageLoadAnalyticsService.setOnloadAnalytics(
                            this.props as unknown as GlobalOnloadAnalyticData,
                        )
                    }, timeOutMilliSecond)
                    onPageLoadAnalyticsIndex = firstIndex
                }
            }
        }

        /**
         * Render wrapped component
         * @returns {JSX.Element} - wrapped component
         */
        render() {
            return (
                <>
                    <WrappedComponent {...this.props} />
                </>
            )
        }
    }

    /**
     * Map state to props
     * @param {RootState} state - redux state
     * @returns {any} props
     */
    function mapStateToProps(state: RootState) {
        return {
            categoryData: state?.categoryIdData,
            errorInterceptorData: state?.errorInterceptorData?.errorInterceptorData,
            isAuthFlowExecuted: state?.userProfile?.isAuthFlowExecuted,
            profileData: state?.userProfile?.userProfileData,
            productData: state?.product,
            storeData: state?.storeDetails,
            preferredStoreDetails: state?.storeDetails?.preferredStoreDetails,
            triangleTransactionData: state?.triangleTransactionData?.fetchTriangleTransaction?.transactions,
            vehicleData: state?.vehicleTires?.vehicleData,
            orderData: state?.orderItemsDetails?.order,
        }
    }

    return connect(mapStateToProps)(GlobalPageLoadAnalyticsHoc)
}

export { globalPageLoadAnalyticsHoc }
