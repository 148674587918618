import React from 'react'
import {
    SanitizeStringContentWrapper,
    Tooltip,
    checkIfDateIsOverdue,
    getFormattedDateWithComparison,
    replaceStrWithDynamicVal,
    replaceMultipleStrWithDynamicVal,
    handleArrowsAccessibility,
    stringKeyCodes,
    arrowActionIdentifier,
    getFormattedDateTime,
    areAllParamsValid,
    libUtils,
} from '@nl/lib'
import { ItemAvailability, StoreWithAvailability } from '../../../redux/models/storeDetails.interface'
import StoreSelectorModalComp from '../../StoreSelectorModal'
import { PREFIX } from '../../../config'
import { getExpressDeliveryDate, getOrderInTime } from '../../../helpers/expressDelivery.helper'
import { ExpressDeliveryType } from '../SwitchFulfillmentOptions/SwitchFulfillmentOptions.type'
import { VariantWarningMessageDTO } from './FulfillmentOptions.type'
import { VariantType, VariantDetails } from '../BuyBox.type'
import { inStockString, inStockTag, lowStockTag, onlineOnlyBadgeLabel } from './FulfillmentOptions.constant'
import { fulfillmentConstants } from '../BuyBox.constant'
import {
    FulfillmentDTOP,
    ProductResponseData,
    ProductSku,
    ProductSkusData,
} from '../../../redux/models/product.interface'
import { FulfillmentMethods } from '../../ShoppingCart/ShoppingCart.type'

/**
 * Function to Render a tooltip component.
 * @param {boolean} visibility tooltip visibility
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setVisibility set visibility
 * @param {string} displayName display name
 * @param {JSX.Element} toolTipText tooltip text
 * @param {string} closeIconLabel close icon label
 * @param {React.MutableRefObject<undefined>} buttonRef button ref
 * @returns {JSX.Element | null} returns tooltip component text
 */
export const rendersTooltipComp = (
    visibility: boolean,
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>,
    displayName: string,
    toolTipText: JSX.Element,
    closeIconLabel: string,
    buttonRef: React.MutableRefObject<HTMLElement>,
    isInFlyout?: boolean,
): JSX.Element | null => {
    return !!visibility ? (
        <Tooltip
            visibility={visibility}
            setVisibility={setVisibility}
            iconID="ct-close"
            headerText={displayName}
            bodyText={toolTipText}
            coords={buttonRef.current}
            a11yCloseIconLabel={closeIconLabel}
            isModalPresent={isInFlyout}
        />
    ) : null
}

/**
 * Renders a store selector flyout component.
 * @param {boolean} shouldOpen should open
 * @param {void} closeFlyout close flyout
 * @param {StoreWithAvailability[]} nearByStoreListForSelectedSku nearby store list for selected sku
 * @param {boolean} isProductLevel is product level
 * @param {boolean | undefined} isShowNotInStock is show not in stock
 * @param {boolean | undefined} isNearbyStoreListApiDone is nearby store list api done
 * @param {boolean | undefined} isInMiniPDPFlyout is nearby store list api done
 * @returns {JSX.Element | null} returns store selector flyout component
 */
export const rendersStoreSelectorFlyoutComp = (
    shouldOpen: boolean,
    closeFlyout: () => void,
    nearByStoreListForSelectedSku: StoreWithAvailability[],
    isProductLevel: boolean,
    isShowNotInStock: boolean | undefined,
    isNearbyStoreListApiDone: boolean | undefined,
    isInMiniPDPFlyout?: boolean,
): JSX.Element | null => {
    return (
        <StoreSelectorModalComp
            closeFlyout={closeFlyout}
            isShowAvailability={true}
            storeList={nearByStoreListForSelectedSku}
            isProductLevel={isProductLevel}
            isShowEmptyStoresToggle={isShowNotInStock}
            isOpen={shouldOpen}
            isNearbyStoreListApiDone={isNearbyStoreListApiDone}
            isInMiniPDPFlyout={isInMiniPDPFlyout}
        />
    )
}

/**
 * Returns variant message object based on fulfillment option selected
 * @param {number} secondVariantLength length of second variant
 * @param {number} isDynamicVariantAvailable dynamic variant available
 * @param {string} selectedSecondVariant selected second variant
 * @param {string} selectedThirdVariant selected third variant
 * @param {string} availabilitySelectVariantGenericMsg availability select variant generic message
 * @param {variantDetails} variantDetails variant details
 * @param {string} availabilitySelectVariantMsg availability select variant message
 * @param {boolean} isShipToHome is ship to home
 * @param {string} sthAvailabilitySelectVariantMsg ship to home availability select variant message
 * @param {string} pickupAvailabilitySelectVariantMsg pickup availability select variant message
 * @returns {VariantWarningMessageDTO | undefined} Returns variant message object based on fulfillment option selected
 */
const getVariantMsgOnFulfillmentOption = (
    secondVariantLength: number,
    isDynamicVariantAvailable: number,
    selectedSecondVariant: string,
    selectedThirdVariant: string,
    availabilitySelectVariantGenericMsg: string,
    variantDetails: VariantDetails,
    availabilitySelectVariantMsg: string,
    isShipToHome: boolean,
    sthAvailabilitySelectVariantMsg: string,
    pickupAvailabilitySelectVariantMsg: string,
    // eslint-disable-next-line max-params
): VariantWarningMessageDTO | undefined => {
    let variantMessage = ''
    let fulfillmentVariantMessage = ''

    if (secondVariantLength && !selectedSecondVariant && isDynamicVariantAvailable && !selectedThirdVariant) {
        variantMessage = availabilitySelectVariantGenericMsg
        fulfillmentVariantMessage = variantMessage
    } else if (secondVariantLength && !selectedSecondVariant) {
        const display = variantDetails?.secondVariant?.display
        variantMessage = replaceStrWithDynamicVal(availabilitySelectVariantMsg, display)
        fulfillmentVariantMessage = replaceStrWithDynamicVal(
            isShipToHome ? sthAvailabilitySelectVariantMsg : pickupAvailabilitySelectVariantMsg,
            display,
        )
    } else {
        const display = variantDetails?.thirdVariant?.display || variantDetails?.firstVariant?.display
        variantMessage = replaceStrWithDynamicVal(availabilitySelectVariantMsg, display)
        fulfillmentVariantMessage = replaceStrWithDynamicVal(
            isShipToHome ? sthAvailabilitySelectVariantMsg : pickupAvailabilitySelectVariantMsg,
            display,
        )
    }

    return {
        variantMessage,
        fulfillmentVariantMessage,
    }
}

/**
 * Returns variant message if all variants not selected
 * @param {string} selectedFirstVariant selected first variant
 * @param {string} selectedSecondVariant selected second variant
 * @param {string} selectedThirdVariant selected third variant
 * @param {string} availabilitySelectVariantGenericMsg availability select variant generic message
 * @param {variantDetails} variantDetails variant details
 * @param {string} availabilitySelectVariantMsg availability select variant message
 * @param {boolean} isShipToHome is ship to home
 * @param {string} sthAvailabilitySelectVariantMsg ship to home availability select variant message
 * @param {string} pickupAvailabilitySelectVariantMsg pickup availability select variant message
 * @returns {VariantWarningMessageDTO | undefined} Returns variant message if not selected
 */

export const getVariantMessage = (
    selectedFirstVariant: string,
    selectedSecondVariant: string,
    selectedThirdVariant: string,
    availabilitySelectVariantGenericMsg: string,
    variantDetails: VariantDetails,
    availabilitySelectVariantMsg: string,
    isShipToHome: boolean,
    sthAvailabilitySelectVariantMsg: string,
    pickupAvailabilitySelectVariantMsg: string,
    // eslint-disable-next-line max-params
): VariantWarningMessageDTO | undefined => {
    const selectedColorObj =
        variantDetails &&
        variantDetails?.firstVariant.values.filter((variant: VariantType) => variant.name === selectedFirstVariant)[0]
    const isSizeAvailable = selectedColorObj?.secondVariant?.length || 0
    const isDynamicVariantAvailable = selectedColorObj?.thirdVariant?.length || 0

    // renders msg when all variants are not selected & ATC clicked or at product level
    return getVariantMsgOnFulfillmentOption(
        isSizeAvailable,
        isDynamicVariantAvailable,
        selectedSecondVariant,
        selectedThirdVariant,
        availabilitySelectVariantGenericMsg,
        variantDetails,
        availabilitySelectVariantMsg,
        isShipToHome,
        sthAvailabilitySelectVariantMsg,
        pickupAvailabilitySelectVariantMsg,
    )
}
const componentClassName = `${PREFIX}-buy-online`

/**
 * Function to render Express Delivery information
 * @param {ExpressDeliveryType} skuExpressDeliveryFulfillmentData express delivery fulfillment data
 * @param {StoreWithAvailability[]} preferredStoreDetails preferred store details
 * @param {string} todayExpressDeliveryDateLabel today express delivery date label
 * @param {string} tomorrowExpressDeliveryDateLabel tomorrow express delivery date label
 * @param {string} generalExpressDeliveryDateLabel general express delivery date label
 * @returns {JSX.Element} return JSX element
 */
export const renderExpressDeliveryAvailabilityDate = (
    skuExpressDeliveryFulfillmentData: ExpressDeliveryType,
    preferredStoreDetails: StoreWithAvailability,
    todayExpressDeliveryDateLabel: string,
    tomorrowExpressDeliveryDateLabel: string,
    generalExpressDeliveryDateLabel: string,
): JSX.Element => {
    const expressDelivery = skuExpressDeliveryFulfillmentData
    const estimatedTimeOfSending = expressDelivery?.orderIn || ''
    const storeTimeZone = preferredStoreDetails?.storeTimezone || ''
    const isOrderInOverdue = checkIfDateIsOverdue(estimatedTimeOfSending, storeTimeZone)
    const expressDeliveryDate = getExpressDeliveryDate(isOrderInOverdue, expressDelivery?.etaEarliest as string)

    const expressDeliveryFormattedDate = getFormattedDateWithComparison(expressDeliveryDate, storeTimeZone)
    const availabilityDateLabel = getAvailabilityDateLabel(
        expressDeliveryFormattedDate?.isToday,
        todayExpressDeliveryDateLabel,
        expressDeliveryFormattedDate?.isTomorrow,
        tomorrowExpressDeliveryDateLabel,
        generalExpressDeliveryDateLabel,
    )

    const orderIn = getOrderInTime(isOrderInOverdue, estimatedTimeOfSending, storeTimeZone)

    return (
        <>
            <SanitizeStringContentWrapper
                stringContent={replaceMultipleStrWithDynamicVal(availabilityDateLabel, [
                    ...orderIn,
                    expressDeliveryFormattedDate.date,
                ])}>
                {memoizedStringContent => (
                    <div
                        className={`${componentClassName}__express-delivery-container--delivery-info`}
                        dangerouslySetInnerHTML={{ __html: memoizedStringContent }}
                    />
                )}
            </SanitizeStringContentWrapper>
        </>
    )
}

/**
 * Function return availability Date label
 * @param {boolean} expressDeliveryDateToday express delivery date today
 * @param {string} todayExpressDeliveryDateLabel today express delivery date label
 * @param {boolean} expressDeliveryDateTomorrow express delivery date tomorrow
 * @param {string} tomorrowExpressDeliveryDateLabel tomorrow express delivery date label
 * @param {string} generalExpressDeliveryDateLabel general express delivery date label
 * @returns {string} availability date label
 */
export const getAvailabilityDateLabel = (
    expressDeliveryDateToday: boolean,
    todayExpressDeliveryDateLabel: string,
    expressDeliveryDateTomorrow: boolean,
    tomorrowExpressDeliveryDateLabel: string,
    generalExpressDeliveryDateLabel: string,
): string => {
    return expressDeliveryDateToday
        ? todayExpressDeliveryDateLabel
        : // eslint-disable-next-line sonar/no-nested-conditional
        expressDeliveryDateTomorrow
        ? tomorrowExpressDeliveryDateLabel
        : generalExpressDeliveryDateLabel
}

/**
 * This function checks if warning message is needed
 * @param {string} selectedStoreName selected store name
 * @param {boolean} isAddToCartClicked check add to cart clicked
 * @param {boolean} inStockInSelectedStore in stock at selected store
 * @param {boolean} inStockAtNearbyStores in stock at near by store
 * @param {boolean} inStockAtDC in stock at DC
 * @param {boolean} onlineOrdering whether product is for online ordering
 * @returns {boolean} returns true if warning message is needed, else false
 */

export const isNeededStoreWarningMessage = (
    selectedStoreName: string,
    isAddToCartClicked: boolean,
    inStockInSelectedStore: boolean,
    inStockAtNearbyStores: boolean,
    inStockAtDC: boolean,
    onlineOrdering: boolean,
): boolean => {
    return (
        // eslint-disable-next-line sonar/expression-complexity
        !selectedStoreName &&
        isAddToCartClicked &&
        ((!inStockInSelectedStore && inStockAtNearbyStores) ||
            (!inStockInSelectedStore && inStockAtNearbyStores && inStockAtDC && onlineOrdering))
    )
}

/**
 * Function return tag type string when STH is first fulfillment as per UI
 * @param {boolean} freeShippingEnabled true for western when we want to show STH first
 * @param {boolean} productNotForSth true when STH is unavailable
 * @returns {string} tag type value
 */
export const tagTypeForSTHFirstFulfillment = (freeShippingEnabled: boolean, productNotForSth: boolean): string => {
    return freeShippingEnabled && productNotForSth ? fulfillmentConstants.unavailable : inStockString
}

/**
 * Function return label tag type string when STH is first fulfillment as per UI
 * @param {boolean} freeShippingEnabled true for western when we want to show STH first
 * @param {boolean} productNotForSth true when STH is unavailable
 * @param {string} availableLabel available label
 * @param {string} unavailableLabel unavailableLabel label
 * @returns {string} label tag
 */
export const labelTagSTHFirstFulfillment = (
    freeShippingEnabled: boolean,
    productNotForSth: boolean,
    availableLabel: string,
    unavailableLabel: string,
): string => {
    return freeShippingEnabled && productNotForSth ? unavailableLabel : availableLabel
}

/**
 * Function to return bopis fulfillment tag values
 * @param {boolean} freeShippingEnabled true for western when we want to show STH first
 * @param {boolean} productNotForSth true when STH is unavailable
 * @param {string} availableLabel available label
 * @param {string} unavailableLabel unavailableLabel label
 * @param {boolean} onlineOnly true when product is available only online
 * @param {number} selectedStoreQty available quantity
 * @param {string} message inline message to show
 * @param {boolean} bopisUnavailable unavilable label for bopis
 * @param {boolean} checkIfAllVariantsSelected true when all variant are selected
 * @param {boolean} isOutOfStockAtStoreAndDC - checks if the product is not orderable, selected Store Qty and selected Store DC Qty equal to zero.
 * @returns {string} fulfillment tag values
 */
export const getFulfillmentTagValueForThirdBox = (
    freeShippingEnabled: boolean,
    productNotForSth: boolean,
    availableLabel: string,
    unavailableLabel: string,
    onlineOnly: boolean,
    selectedStoreQty: number,
    message: string,
    bopisUnavailable: boolean,
    checkIfAllVariantsSelected: boolean,
    isOutOfStockAtStoreAndDC: boolean,
    // eslint-disable-next-line max-params
): string => {
    if (freeShippingEnabled) {
        // eslint-disable-next-line sonar/expression-complexity
        return onlineOnly || (checkIfAllVariantsSelected && bopisUnavailable)
            ? unavailableLabel
            : // eslint-disable-next-line sonar/no-nested-conditional
            selectedStoreQty === 0
            ? message
            : availableLabel
    } else {
        // eslint-disable-next-line sonar/no-nested-conditional
        return productNotForSth ? unavailableLabel : isOutOfStockAtStoreAndDC ? message : availableLabel
    }
}

/**
 * This function handles keydown event for tab
 * @param { React.KeyboardEvent<HTMLDivElement> } event keyboard event
 * @param { number } currentIndex current index
 * @param { string } label fulfillment label
 * @param { React.MutableRefObject<null> } tablistRef element ref
 * @param { Function } handleClickOrEnter onclick function
 * @returns {void} returns nothing
 */
export const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    currentIndex: number,
    label: string,
    tablistRef: React.MutableRefObject<HTMLUListElement>,
    handleClickOrEnter: (
        event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
        currentIndex: number,
        label: string,
    ) => void,
): void => {
    if (event.key === stringKeyCodes.rightArrow) {
        handleArrowsAccessibility(event, arrowActionIdentifier.NEXT, currentIndex, tablistRef.current)
    } else if (event.key === stringKeyCodes.leftArrow) {
        handleArrowsAccessibility(event, arrowActionIdentifier.PREVIOUS, currentIndex, tablistRef.current)
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (event.key === stringKeyCodes.enter || event.key === stringKeyCodes.space) {
        handleClickOrEnter(event, currentIndex, label)
    }
}

/**
 * Function to return bopis last fulfillment tag string values when STH is last fulfillment as per UI
 * @param {boolean} freeShippingEnabled true for western when we want to show STH first
 * @param {boolean} productNotForSth true when STH is unavailable
 * @param {string} availableLabel available label
 * @param {string} unavailableLabel unavailableLabel label
 * @param {boolean} onlineOnly true when product is available only online
 * @param {string} selectedStoreQty store quantity
 * @param {string} message show message
 * @returns {string} fulfillment tag values
 */
export const bopisLastFulfillmentTagValues = (
    freeShippingEnabled: boolean,
    productNotForSth: boolean,
    availableLabel: string,
    unavailableLabel: string,
    onlineOnly: boolean,
    selectedStoreQty: number,
    message: string,
): string => {
    if (freeShippingEnabled) {
        // eslint-disable-next-line sonar/no-nested-conditional
        return onlineOnly ? unavailableLabel : selectedStoreQty <= 0 ? message : availableLabel
    } else {
        return productNotForSth ? unavailableLabel : availableLabel
    }
}
/**
 * Function to return the tag Values for express delivery fulfillment.
 * @param {string} availableLabel available label
 * @param {string} unavailableLabel unavailableLabel label
 * @param {boolean | undefined} onlineOnly - Indicates if the product is available online only.
 * @param {boolean} bopisUnavailable - check product is eligible for bopis
 * @param {boolean} checkIfAllVariantsSelected - whether all variants are selected.
 * @returns {string} Returns the tag values for express delivery fulfillment.
 */
export const expressDeliveryFulfillmentTagValues = (
    availableLabel: string,
    unavailableLabel: string,
    onlineOnly: boolean | undefined,
    bopisUnavailable: boolean,
    checkIfAllVariantsSelected: boolean,
): string => {
    return onlineOnly || (checkIfAllVariantsSelected && bopisUnavailable) ? unavailableLabel : availableLabel
}

/**
 * Function to return show fulfillment options
 * @param {boolean} isOutOfStockInAllStore Indicates if the product is out of stock in all stores.
 * @param {boolean} isProductLevel Indicates if selected product is at ProductLevel
 * @param {boolean | undefined} isVariantsHidden Indicates if product variants are hidden.
 * @param {boolean} checkIfAllVariantsSelected Indicates whether all variants are selected.
 * @returns {boolean} returns true if fulfillment options should be displayed, else false or undefined.
 */
export const showFulfillmentOptions = (
    isOutOfStockInAllStore: boolean,
    isProductLevel: boolean,
    isVariantsHidden: boolean | undefined,
    checkIfAllVariantsSelected: boolean,
): boolean | undefined => {
    return (!isOutOfStockInAllStore || isProductLevel || !checkIfAllVariantsSelected) && !isVariantsHidden
}

/*
 * Function to return whether to dispaly curbside label.
 * @param {boolean} isShowCurbsideAvailabilityLabelForProposedStores
 * @param {boolean} isShowAvailableByLabel
 * @returns {boolean} showCurbsideLabel
 */
export const showCurbsideLabel = (
    isShowCurbsideAvailabilityLabelForProposedStores: boolean,
    isShowAvailableByLabel: boolean,
): boolean => {
    return isShowCurbsideAvailabilityLabelForProposedStores && isShowAvailableByLabel
}

/**
 * Function to return tag type
 * @param {boolean} checkIfAllVariantsSelected Indicates if all variants are selected.
 * @param {boolean | undefined} expressDeliveryEnabled Indicates if express delivery is enabled.
 * @param {boolean} isQtyAvailable Indicates if qty is available.
 * @returns {string} returns tag type string.
 */
const tagTypeValue = (
    checkIfAllVariantsSelected: boolean,
    expressDeliveryEnabled: boolean | undefined,
    isQtyAvailable: boolean,
): string => {
    if (checkIfAllVariantsSelected) {
        if (expressDeliveryEnabled) {
            return isQtyAvailable ? inStockTag : lowStockTag
        } else return fulfillmentConstants.unavailable
    } else return ''
}

/**
 * Function to return the tag Values for express delivery fulfillment.
 * @param {FulfillmentDTOP | undefined} fulfillment - The fulfillment details of the product.
 * @param {string} outOfStockLabel - Label to use when the product is out of stock.
 * @param {string} availableLabel - Label to use when the product is available.
 * @param {string} unavailableLabel - Label to use when the product is unavailable.
 * @param {boolean} checkIfAllVariantsSelected - checks if all variants have been selected.
 * @param {boolean} isEDEnabled - checks if the express delivery is enabled for the product.
 * @returns {string[]} Returns a string array with tagType, tagLabel for express delivery fulfillment.
 */
export const expressDeliveryFulfillmentTagDetails = (
    fulfillment: FulfillmentDTOP | undefined,
    outOfStockLabel: string,
    availableLabel: string,
    unavailableLabel: string,
    checkIfAllVariantsSelected: boolean,
    isEDEnabled: boolean,
): string[] => {
    const { availability } = fulfillment || {}
    const { quantity } = availability || { quantity: 0 }
    const isQtyAvailable = quantity > 0

    const tagType = tagTypeValue(checkIfAllVariantsSelected, isEDEnabled, isQtyAvailable)
    // eslint-disable-next-line sonar/no-nested-conditional
    const tagLabel = isEDEnabled ? (isQtyAvailable ? availableLabel : outOfStockLabel) : unavailableLabel

    return [tagType, tagLabel]
}

/*
 * Function to get the FulfillmentMethod.
 * @param {boolean} freeShippingEnabled
 * @returns {string} Returns the FulfillmentMethod.
 */
export const getFulfillmentMethod = (freeShippingEnabled: boolean): string => {
    return freeShippingEnabled ? FulfillmentMethods.STH : FulfillmentMethods.BOPIS
}

/**
 * Determines whether to fetch the estimated delivery date API based on various conditions.
 * @param {boolean} isOnlineOnly - Flag indicating whether the product is available online only.
 * @param {boolean} isBulk - Flag indicating whether the product is purchased in bulk.
 * @param {boolean} isRepairOrderNeeded - Flag indicating whether a repair order is required for the product.
 * @param {boolean} isHomeService - Flag indicating whether home service is selected.
 * @param {boolean} inStockInSelectedStore - Flag indicating whether the product is in stock in the selected store.
 * @param {number} instoreQty - Quantity of the product available in-store.
 * @param {boolean} estimatedDeliveryDateEnabled - Flag indicating whether estimated delivery date feature is enabled.
 * @returns {Boolean} returns true if all the conditions are validated and fetches Estimated Delivery Date
 */
export const shouldFetchEstimateDeliveryDate = (
    isOnlineOnly: boolean,
    isBulk: boolean,
    isRepairOrderNeeded: boolean,
    isHomeService: boolean,
    inStockInSelectedStore: boolean,
    instoreQty: number,
    estimatedDeliveryDateEnabled: boolean,
): boolean => {
    return (
        // eslint-disable-next-line sonar/expression-complexity
        !isOnlineOnly &&
        !isBulk &&
        !isRepairOrderNeeded &&
        !isHomeService &&
        inStockInSelectedStore &&
        instoreQty > 0 &&
        estimatedDeliveryDateEnabled
    )
}

/**
 * Renders the label for shipping estimates.
 * @param {number} selectedStoreQty - The quantity selected in the store.
 * @param {boolean} orderable - Flag indicating if the product is orderable.
 * @param {boolean} isOutOfStockInAllStore - Flag indicating if the product is out of stock in all stores.
 * @param {JSX.Element | null} getShipsBetweenEstimateLabel - JSX element representing estimated shipping between dates.
 * @param {string | undefined} postalCodeVal - The postal code value.
 * @param {boolean} isEstimatedDeliveryDateAvailable - Flag indicating if estimated delivery date is available.
 * @param {JSX.Element | null} rendersSTHEstimatedDeliveryDate - JSX element representing estimated delivery date.
 * @param {JSX.Element | null} getShipByEstimateLabel - JSX element representing estimated ship by date.
 * @returns {JSX.Element | null} The JSX element representing the rendered label or null.
 */
export const renderSTHEstimateLabel = (
    selectedStoreQty: number,
    orderable: boolean,
    isOutOfStockInAllStore: boolean,
    getShipsBetweenEstimateLabel: JSX.Element | null,
    postalCodeVal: string | undefined,
    isEstimatedDeliveryDateAvailable: boolean,
    rendersSTHEstimatedDeliveryDate: JSX.Element | null,
    getShipByEstimateLabel: JSX.Element | null,
    // eslint-disable-next-line max-params
): JSX.Element | null => {
    // eslint-disable-next-line sonar/expression-complexity
    return (selectedStoreQty === 0 && orderable) || isOutOfStockInAllStore
        ? getShipsBetweenEstimateLabel
        : // eslint-disable-next-line sonar/no-nested-conditional
        postalCodeVal && isEstimatedDeliveryDateAvailable
        ? rendersSTHEstimatedDeliveryDate
        : getShipByEstimateLabel
}

/**
 * function to check express delivery eligibility
 * @param {boolean} freeShippingEnabled
 * @param {StoreWithAvailability} preferredStoreDetails
 * @returns {boolean}
 */
export const isExpressDeliveryEligible = (
    freeShippingEnabled: boolean,
    preferredStoreDetails: StoreWithAvailability,
): boolean => {
    return freeShippingEnabled
        ? Boolean(preferredStoreDetails.isExpressDeliveryEligible && preferredStoreDetails.bopisAvailable)
        : Boolean(preferredStoreDetails.isExpressDeliveryEligible)
}

/**
 * Checks if any badge in the given array indicates an online-only product.
 * @param {string[]} badges - An array of badge labels.
 * @returns {boolean} Returns true if any badge label indicates an ONLINEONLY product, otherwise false.
 */
export const isOnlineOnlyProduct = (badges: string[]): boolean => {
    return badges ? badges.some(badge => badge.toLowerCase() === onlineOnlyBadgeLabel.toLowerCase()) : false
}

/**
 * checks if a repair order is required for the product.
 * @param {boolean} repairOrderRequired - Indicates if a repair order is required for the product.
 * @param {ProductResponseData} productData - The product information.
 * @returns {boolean | undefined} - Returns true if a repair order is required, if not false
 */
export const isRepairOrderRequired = (
    repairOrderRequired: boolean,
    productData: ProductResponseData,
): boolean | undefined => {
    return repairOrderRequired ? repairOrderRequired : productData?.repairOrderRequired
}

/**
 * Formats the start and end date/time into a friendly display format.
 * @param {string} start - The start date/time string.
 * @param {string} end - The end date/time string.
 * @returns {string} - A formatted string representing the friendly display of the date/time range.
 */
export const getFriendlyDateTime = (start: string, end: string): string => {
    return `${getFormattedDateTime(start)[0]} - ${getFormattedDateTime(end)[0]}`
}

/**
 * Gets the formatted earliest date of ship to home.
 * @param {string | null} etaEarliest - The ship to home ETA earliest.
 * @param {string} formattedDateTime - The friendly formattted date string of ship to home ETA earliest.
 * @returns {string} - The formatted earliest ship to home date,
 */
export const getFormattedSTHEarliestDate = (etaEarliest: string | null, formattedDateTime: string): string => {
    return etaEarliest !== null ? formattedDateTime : ''
}

/**
 * gets the formatted estimated fee.
 * @param {boolean} isShipToHome - Indicates if the fee is for ship to home (true) or express delivery (false).
 * @param {string} formattedSTHEstimatedFee - The formatted estimated fee for ship to home.
 * @param {string} formattedExpressEstimatedFee - The formatted estimated fee for express delivery.
 * @returns {string} The formatted estimated fee based on ship to home or express delivery.
 */
export const getFormattedEstimatedFee = (
    isShipToHome: boolean,
    formattedSTHEstimatedFee: string,
    formattedExpressEstimatedFee: string,
): string => {
    return isShipToHome ? formattedSTHEstimatedFee : formattedExpressEstimatedFee
}

/**
 * Extracts MinETA and MaxETA values from the provided ItemAvailability object.
 * If sthETA is present, returns its MinETA and MaxETA values as strings.
 * If sthETA is not present or null, returns { MinETA: null, MaxETA: null }.
 * @param {ItemAvailability} availabilityObj - The ItemAvailability object containing sthETA.
 * @returns {{ MinETA: string, MaxETA: string }} - An object with MinETA and MaxETA values as strings.
 */
export const extractMinAndMaxSTHETA = (availabilityObj: ItemAvailability): { MinETA: string; MaxETA: string } => {
    const { MinETA, MaxETA } = availabilityObj?.Corporate?.sthETA || {}
    return { MinETA, MaxETA }
}

/**
 * Extracts badges and orderable from product SKU data.
 * @param {ProductSkusData} productSkuData The product SKU data containing badges and orderable.
 * @returns {{ badges: string[]; orderable: boolean }} An object containing extracted badges and orderable.
 */
export const extractBadgesAndOrderable = (
    productSkuData: ProductSkusData,
): { badges: string[]; orderable: boolean } => {
    const { badges, orderable } = productSkuData?.skus?.[0] || {}
    return { badges, orderable }
}

/**
 * Extracts badges from product data.
 * @param {productData} productData The product data containing badges
 * @returns {string[]} An object containing extracted badges
 */
export const extractBadges = (productData: ProductResponseData): string[] => {
    const { badges } = productData || {}
    return badges
}
/**
 * Function to get the availability label.
 * @param {boolean} inStockInSelectedStore - checks if the item is in stock in the selected store.
 * @param {boolean} isNearbyStoresListEmpty - checks if the nearby stores list is empty.
 * @param {string} checkOtherStoresLabel - Label for checking other stores.
 * @param {string} outOfStockLabel - Label for out of stock.
 * @returns {string} Returns the availability tag label
 */
export const getAvailabilityTagLabel = (
    inStockInSelectedStore: boolean,
    isNearbyStoresListEmpty: boolean,
    checkOtherStoresLabel: string,
    outOfStockLabel: string,
): string => {
    return !inStockInSelectedStore && isNearbyStoresListEmpty ? outOfStockLabel : checkOtherStoresLabel
}

/**
 * Function returns true if split ETA condition satisfies
 * @param {boolean} orderable boolean which returns true when object is orderable
 * @param {number} selectedStoreQty in-store qty
 * @param {boolean} isProductDiscontinued true for FD, SD, TD products
 * @param {number} dCQuantity corporate quantity
 * @returns {boolean} Returns true/false
 */
const isSplitEtaTrue = (
    orderable: boolean,
    selectedStoreQty: number,
    isProductDiscontinued: boolean,
    dCQuantity: number,
): boolean => {
    return areAllParamsValid(orderable, selectedStoreQty > 0, !isProductDiscontinued, dCQuantity > 0)
}

/**
 * Function returns true if show more is visible on screen
 * @param {boolean} orderable boolean which returns true when object is orderable
 * @param {number} selectedStoreQty in-store qty
 * @param {number} qtySelectorInput input selector qty
 * @param {boolean} isProductDiscontinued true for FD, SD, TD products
 * @param {boolean} freeShippingEnabled true for western banner where we show STH first on fulfillment box.
 * @param {number} dCQuantity corporate quantity
 * @param {boolean} isStaggeredFitmentProduct isStaggeredFitmentProduct
 * @returns {boolean} Returns true/false
 */
export const showNeedMore = (
    orderable: boolean,
    selectedStoreQty: number,
    qtySelectorInput: number,
    isProductDiscontinued: boolean,
    freeShippingEnabled: boolean,
    dCQuantity: number,
    isStaggeredFitmentProduct: boolean,
): boolean => {
    return (
        !isStaggeredFitmentProduct &&
        !freeShippingEnabled &&
        isSplitEtaTrue(orderable, selectedStoreQty, isProductDiscontinued, dCQuantity) &&
        libUtils.getNumberOrDefault(qtySelectorInput) <= selectedStoreQty
    )
}

/**
 * Function returns true if condition to show spit ETA data after qty increase in STH, satisfies
 * @param {boolean} orderable boolean which returns true when object is orderable
 * @param {number} selectedStoreQty in-store quantity
 * @param {number} qtySelectorInput qty selected by user
 * @param {number} dCQuantity corporate quantity
 * @param {boolean} isAutomotiveProduct true for automotive products
 * @param {boolean} isProductDiscontinued true for FD, SD, TD products
 * @param {boolean} isSTH true if split eta for sth
 * @returns {boolean} Returns true/false
 */
export const showSplitEtaDatesForSTH = (
    orderable: boolean,
    selectedStoreQty: number,
    qtySelectorInput: number,
    dCQuantity: number,
    isAutomotiveProduct: boolean,
    isProductDiscontinued: boolean,
    isSTH: boolean,
): boolean => {
    const selectedProductDCQuantity = dCQuantity + selectedStoreQty
    const includeAutomotive = isSTH ? !isAutomotiveProduct : true
    return (
        includeAutomotive &&
        isSplitEtaTrue(orderable, selectedStoreQty, isProductDiscontinued, dCQuantity) &&
        selectedStoreQty < libUtils.getNumberOrDefault(qtySelectorInput) &&
        libUtils.getNumberOrDefault(qtySelectorInput) <= selectedProductDCQuantity
    )
}

/**
 * Function returns free pick up label class name for split case
 * @param {boolean} isSplitEta true when split ETA
 * @returns {string} Returns string
 */
export const freePickUpSplitLabelClass = (isSplitEta: boolean) => {
    return isSplitEta ? `${componentClassName}__free-pickup-container--label-split` : ''
}

/**
 * Function to return true if express delivery is enabled.
 * @param {FulfillmentDTOP | undefined} fulfillment - The fulfillment details of the product.
 * @param {boolean} isEDEligible - checks if the item is eligible for express delivery.
 * @param {boolean} freeShippingEnabled - checks if free shipping is enabled for the product.
 * @param {boolean} isEDEnabledForPostalCodeProduct - checks if the express delivery is enabled for postal code product.
 * @returns {boolean} - Returns true if express delivery is enabled based on the evaluated criteria, otherwise false.
 */
export const isExpressDeliveryEnabled = (
    fulfillment: FulfillmentDTOP | undefined,
    isEDEligible: boolean,
    freeShippingEnabled: boolean,
    isEDEnabledForPostalCodeProduct: boolean,
): boolean => {
    const { expressDelivery, storePickUp } = fulfillment || {}
    return freeShippingEnabled
        ? areAllParamsValid(Boolean(expressDelivery?.enabled), Boolean(storePickUp?.enabled), isEDEligible)
        : isEDEnabledForPostalCodeProduct
}

/**
 * Function returns the label by checking if a postal code is eligible for express delivery.
 * @param {boolean} isExpressDeliveryEligibleForPostalCode - checks if postal code is eligible for express delivery.
 * @param {string} expressDeliveryUnavailable - The label to use when express delivery is unavailable.
 * @param {string} postalCodeNotEligibleExpressDeliveryLabel - The label to use when the postal code is not eligible for express delivery.
 * @returns {string} - Returns the label based on postal code eligibility for express delivery.
 */
const getPostalCodeNotEligibleForEDLabel = (
    isExpressDeliveryEligibleForPostalCode: boolean,
    expressDeliveryUnavailable: string,
    postalCodeNotEligibleExpressDeliveryLabel: string,
): string => {
    return isExpressDeliveryEligibleForPostalCode
        ? expressDeliveryUnavailable
        : postalCodeNotEligibleExpressDeliveryLabel
}

/**
 * Function returns the label by checking stock availability.
 * @param {boolean} inStockInSelectedStore - checks if the item is in stock at the selected store.
 * @param {string} expressDeliveryUnavailableOOS - The label to use when express delivery is unavailable and product is out of stock.
 * @param {boolean} isExpressDeliveryEligibleForPostalCode - checks if postal code is eligible for express delivery.
 * @param {string} expressDeliveryUnavailable - The label to use when express delivery is unavailable.
 * @param {string} postalCodeNotEligibleExpressDeliveryLabel - The label to use when the postal code is not eligible for express delivery.
 * @returns {string} - Returns the label based on stock availability.
 */
export const getEDUnavailableLabel = (
    inStockInSelectedStore: boolean,
    expressDeliveryUnavailableOOS: string,
    isExpressDeliveryEligibleForPostalCode: boolean,
    expressDeliveryUnavailable: string,
    postalCodeNotEligibleExpressDeliveryLabel: string,
): string => {
    return !inStockInSelectedStore
        ? expressDeliveryUnavailableOOS
        : getPostalCodeNotEligibleForEDLabel(
              isExpressDeliveryEligibleForPostalCode,
              expressDeliveryUnavailable,
              postalCodeNotEligibleExpressDeliveryLabel,
          )
}

/**
 * Function to check if sku is OOS
 * @param {ProductSku} productSku productSku
 * @returns {boolean} return isSkuOutOfStock
 */
export const isSkuOutOfStock = (productSku: ProductSku): boolean => {
    const dcQuantity = productSku?.fulfillment?.availability?.Corporate?.Quantity || 0
    return productSku?.fulfillment?.availability?.quantity === 0 && (dcQuantity === 0 || !productSku?.orderable)
}

/**
 * Function to check if skus are available at store
 * @param {FulfillmentDTOP} frontFulfillment - fulfillment object
 * @param {FulfillmentDTOP} rearFulfillment - fulfillment object
 * @param {number} qtySelectorFront - front quantity
 * @param {number} qtySelectortRear - rear quantity
 * @returns {boolean} returns boolean
 */
export const isAvailableAtStore = (
    frontFulfillment: FulfillmentDTOP,
    rearFulfillment: FulfillmentDTOP,
    qtySelectorFront: number,
    qtySelectortRear: number,
): boolean => {
    return (
        frontFulfillment?.availability?.quantity >= libUtils.getNumberOrDefault(qtySelectorFront) &&
        rearFulfillment?.availability?.quantity >= libUtils.getNumberOrDefault(qtySelectortRear)
    )
}

/**
 * Function to check if skus are available only at DC
 * @param {FulfillmentDTOP} fulfillmentObj - fulfillment object
 * @param {boolean} isOrderable isOrderable
 * @returns {boolean} returns boolean
 */
export const isOOSAtStoreAndDC = (fulfillmentObj: FulfillmentDTOP, isOrderable: boolean): boolean => {
    return (
        fulfillmentObj?.availability?.quantity === 0 &&
        !(isOrderable && (fulfillmentObj?.availability?.Corporate?.Quantity as number) > 0)
    )
}
const sellableClass = `${componentClassName}__fulfillment__sellable-label`

/**
 * Function to render the restriction message.
 * @param {string | undefined} restrictionMessage - The restriction message to be rendered.
 * @returns {JSX.Element | null} Returns a JSX element if the restriction message is provided, otherwise null.
 */
export const renderRestrictionMsg = (restrictionMessage: string | undefined): JSX.Element | null => {
    return (
        <div className={`${sellableClass}`}>
            {!!restrictionMessage ? (
                <div className={`${componentClassName}__fulfillment__in-store-available`}>
                    <span className={`${sellableClass}__restriction-message`}>{restrictionMessage}</span>
                </div>
            ) : null}
        </div>
    )
}

/**
 * Function to render the in-store purchase message.
 * @param {boolean | undefined} isSellableProduct - Indicates if the product is sellable.
 * @param {boolean | undefined} isBopisOnlySkuWithStoreBopisUnavailable - Indicates if the SKU is BOPIS only and if the store BOPIS is unavailable.
 * @param {number} selectedStoreQty - The quantity of the selected store.
 * @param {string | undefined} inStorePurchase - The in-store purchase message.
 * @param {string | undefined} inStorePurchaseOnly - The in-store purchase only message.
 * @param {string | undefined} checkStoreAvailability - The check store availability message.
 * @returns {JSX.Element} Returns a JSX element based on the provided parameters.
 */
const renderInstorePurchaseMsg = (
    isSellableProduct: boolean | undefined,
    isBopisOnlySkuWithStoreBopisUnavailable: boolean | undefined,
    selectedStoreQty: number,
    inStorePurchase: string | undefined,
    inStorePurchaseOnly: string | undefined,
    checkStoreAvailability: string | undefined,
): JSX.Element => {
    return (
        <div className={`${sellableClass}`}>
            {(!isSellableProduct || isBopisOnlySkuWithStoreBopisUnavailable) && selectedStoreQty > 0 ? (
                <div className={`${componentClassName}__fulfillment__in-store-available`}>
                    <span className={`${sellableClass}__title`}>{inStorePurchase}</span>
                </div>
            ) : (
                <div className={`${componentClassName}__fulfillment__check-store-availablility`}>
                    <div className={`${sellableClass}__title`}>{inStorePurchaseOnly}</div>
                    <div className={`${sellableClass}__description`}>{checkStoreAvailability}</div>
                </div>
            )}
        </div>
    )
}

/**
 * Function to render either the restriction message or the in-store purchase message.
 * @param {string | undefined} isRestrictionMessageAvailableAtSkuLevel - The restriction message available at SKU level.
 * @param {boolean | undefined} isSellableProduct - Indicates if the product is sellable.
 * @param {boolean | undefined} isBopisOnlySkuWithStoreBopisUnavailable - Indicates if the SKU is BOPIS only and if the store BOPIS is unavailable.
 * @param {number} selectedStoreQty - The quantity of the selected store.
 * @param {string | undefined} inStorePurchase - The in-store purchase message.
 * @param {string | undefined} inStorePurchaseOnly - The in-store purchase only message.
 * @param {string | undefined} checkStoreAvailability - The check store availability message.
 * @returns {JSX.Element} Returns a JSX element based on the provided conditions.
 */
export const renderRestrictionMsgOrInStorePurchase = (
    isRestrictionMessageAvailableAtSkuLevel: string | undefined,
    isSellableProduct: boolean | undefined,
    isBopisOnlySkuWithStoreBopisUnavailable: boolean | undefined,
    selectedStoreQty: number,
    inStorePurchase: string | undefined,
    inStorePurchaseOnly: string | undefined,
    checkStoreAvailability: string | undefined,
): JSX.Element => {
    if (!!isRestrictionMessageAvailableAtSkuLevel) {
        return <>{!isSellableProduct && renderRestrictionMsg(isRestrictionMessageAvailableAtSkuLevel)}</>
    } else {
        return renderInstorePurchaseMsg(
            isSellableProduct,
            isBopisOnlySkuWithStoreBopisUnavailable,
            selectedStoreQty,
            inStorePurchase,
            inStorePurchaseOnly,
            checkStoreAvailability,
        )
    }
}
