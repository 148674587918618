import React, { useMemo, Validator } from 'react'
import PropTypes from 'prop-types'

import { AutoPartsType } from './VehicleProductCard.type'
import Price from '../Price'
import { Price as PriceType } from '../Price/Price.types'
import { PREFIX } from '../config'
import Badges from '../Badges'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import {
    replaceStrWithDynamicVal,
    addDynamicToken,
    replaceStrWithDynamicElement,
} from '../../utils/replaceStrWithDynamicVal'
import { getFormattedPriceValue } from '../ProductReusableCard/instanceCheckingUtil'
import { getAccessibilityPriceId } from '../../utils/getAccessibilityId'
import Icon from '../Icon'
import { autoPartSkuFitmentTypes } from '../ProductGridView/ProductGrid.types'
import { FitmentTypeEnum } from '../../globalConstants/global.constant'

/**
 * Product Vehicle information component displays per tire, front, rare and setOfFourTire details
 * @param {AutoPartsType} props
 * @returns {JSX.Element} returns Product vehicle information component
 */
const AutoPartsProductInformation: React.FC<AutoPartsType> = props => {
    const productCardVehicleClassPrefix = `${PREFIX}-product-card-vehicle`
    const feeMessage = props.feeMessages?.[0]
    /**
     * return badges detail for product
     * @returns {JSX.Element}
     */
    const productBadges = (): JSX.Element => {
        return (
            isArrayNotEmpty(props.badges) && (
                <div className={`${PREFIX}-plp-badges`}>
                    <Badges
                        badges={props.vehicleInformationPresent ? props.selectedSKUData?.badges : props.badges}
                        badgesAndPriorities={props.badgePriorities}
                        hideDisclaimer={props.hideDisclaimer}
                    />
                </div>
            )
        )
    }

    /**
     * function to get fee message
     * @returns { string }
     */
    const getFeeMessage = (): string => {
        return props.vehicleInformationPresent ? feeMessage?.feeTitle : props.feeTitle
    }

    /**
     * function to get fee title
     * @returns {string}
     */
    const getFeeTitle = (): string => {
        const feeValue = props.vehicleInformationPresent ? props.selectedSKUData?.feeValue : props.feeValue
        return feeValue
            ? replaceStrWithDynamicVal(
                  addDynamicToken(getFeeMessage(), '$x'),
                  getFormattedPriceValue(props.language, feeValue),
              )
            : ''
    }
    /**
     * functionto check if selected sku data present or not
     * @returns { boolean }
     */
    const checkIfSelectedSKUDataAvailable = (): boolean => {
        return !!(props.vehicleInformationPresent && props.selectedSKUData)
    }

    /**
     * Function to return isOnSale flag
     * @returns {boolean}
     */
    const onSaleValue = useMemo(
        () => (props.skus?.length === 1 ? props.skus[0].isOnSale : props.isOnSale),
        [props.isOnSale, props.skus],
    )

    /**
     * Memorizes the built fitment message with a correct chosen text and inserted vehicle information if a placeholder is required.
     * @returns {JSX.Element}
     */
    const legacyFitmentMessage = useMemo(() => {
        if (
            props.fitmentMessageToggle &&
            props.fitmentTypeCode === FitmentTypeEnum.DIRECT_FIT_02 &&
            props.skus?.[0]?.fitment?.autoPartsFit !== autoPartSkuFitmentTypes.UNIVERSAL
        ) {
            const fitmentMessageClass = `${productCardVehicleClassPrefix}--fitment-container`
            const isFit = props.skus?.[0]?.fitment?.autoPartsFit === autoPartSkuFitmentTypes.FIT
            const dynamicMessageElement = replaceStrWithDynamicElement(
                isFit ? props.fitMessageOnCard : props.partialFitMessageOnCard,
                <span className={`${PREFIX}-vehicle-information`}>{props.vehicleString}</span>,
            )

            return (
                <div className={fitmentMessageClass}>
                    <Icon
                        type={isFit ? 'ct-notification-success-green' : 'ct-notification-caution-orange'}
                        size="sm"
                        decorative={false}
                    />
                    <div id={`fitment__${props.accessibilityId}`} className={`${fitmentMessageClass}__fitment-message`}>
                        {isFit ? (
                            dynamicMessageElement
                        ) : (
                            <span className={`${PREFIX}-not-fit-message`}>{dynamicMessageElement}</span>
                        )}
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }, [
        productCardVehicleClassPrefix,
        props.fitMessageOnCard,
        props.fitmentMessageToggle,
        props.partialFitMessageOnCard,
        props.skus,
        props.vehicleString,
        props.fitmentTypeCode,
        props.accessibilityId,
    ])

    /**
     * Memorizes the built fitment message with a correct chosen text and inserted vehicle information if a placeholder is required.
     * @returns {JSX.Element}
     */
    const fitmentMessage = useMemo(() => {
        if (props.fitmentMessageToggle || props.fitmentMessageToggleUniversal) {
            const fitmentMessageClass = `${productCardVehicleClassPrefix}--fitment-container`
            const {
                confirmFitMessageUniversalOnCard,
                fitMessageUniversalOnCard,
                partialFitMessageOnCard,
                fitMessageOnCard,
            } = props
            /**
             * Gets template message for fitment message by SAPI key with considering of toggles
             * @param {string} key
             * @returns {string}
             */
            const getFitmentMessagePlaceHolder = (key: string): string =>
                (({
                    confirmFitMessageUniversalOnCard:
                        props.fitmentMessageToggleUniversal && confirmFitMessageUniversalOnCard,
                    fitMessageUniversalOnCard: props.fitmentMessageToggleUniversal && fitMessageUniversalOnCard,
                    partialFitMessageOnCard: props.fitmentMessageToggle && partialFitMessageOnCard,
                    fitMessageOnCard: props.fitmentMessageToggle && fitMessageOnCard,
                }[key] || '') as unknown as string)
            const messagePlaceholder = getFitmentMessagePlaceHolder(props.product.fitmentCompatibilityKey || '')
            const dynamicMessageElement =
                messagePlaceholder &&
                replaceStrWithDynamicElement(
                    messagePlaceholder,
                    <span className={`${PREFIX}-vehicle-information`}>{props.vehicleString}</span>,
                )
            const iconTypes: Record<string, string> = {
                fitmentIcon001: 'ct-notification-success-green',
                fitmentIcon002: 'ct-notification-caution-orange',
                fitmentIcon003: 'ct-information-blue',
            }
            const iconType = iconTypes[props.product.fitmentMarkKey || '']
            const fitmentClassMap: Record<string, string> = {
                confirmFitMessageUniversalOnCard: ` ${fitmentMessageClass}__fitment-message--bold`,
                fitMessageUniversalOnCard: ` ${fitmentMessageClass}__fitment-message--bold`,
                partialFitMessageOnCard: ` ${fitmentMessageClass}__fitment-message--bold`,
                fitMessageOnCard: '',
            }
            const extraFitmentClass = fitmentClassMap[props.product.fitmentCompatibilityKey]
            return dynamicMessageElement ? (
                <div className={fitmentMessageClass}>
                    {iconType && <Icon type={iconType} size="sm" decorative={false} />}
                    <div
                        id={`fitment__${props.accessibilityId}`}
                        className={`${fitmentMessageClass}__fitment-message ${extraFitmentClass}`}>
                        {dynamicMessageElement}
                    </div>
                </div>
            ) : (
                legacyFitmentMessage
            )
        } else {
            return <></>
        }
    }, [props, productCardVehicleClassPrefix, legacyFitmentMessage])

    return (
        <div className={`${productCardVehicleClassPrefix}`}>
            <div className={`${productCardVehicleClassPrefix}--per-tire`}>
                <div
                    className={`${productCardVehicleClassPrefix}--per-tire__price-code`}
                    id={getAccessibilityPriceId(props.accessibilityId)}>
                    <Price
                        isRangeView={props.isPriceRangeViewEnabled}
                        unitPriceLabel={props.productProps?.unitPriceLabel}
                        clearancePriceLabel={props.productProps?.clearancePriceLabel}
                        a11yPriceRangeFrom={props.productProps?.a11yPriceRangeFrom}
                        a11yPriceRangeTo={props.productProps?.a11yPriceRangeTo}
                        a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                        a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                        a11yTooltipIcon={props.a11yTooltipIcon}
                        a11yCloseIconLabel={props.a11yCloseIconLabel}
                        feeTitle={getFeeTitle()}
                        feeDisclaimerTitle={
                            checkIfSelectedSKUDataAvailable()
                                ? feeMessage?.feeDisclaimerTitle
                                : props.feeDisclaimerTitle
                        }
                        feeDisclaimerMessage={
                            checkIfSelectedSKUDataAvailable()
                                ? feeMessage?.feeDisclaimerMessage
                                : props.feeDisclaimerMessage
                        }
                        currentPrice={
                            checkIfSelectedSKUDataAvailable() ? props.selectedSKUData?.currentPrice : props.currentPrice
                        }
                        originalPrice={
                            checkIfSelectedSKUDataAvailable()
                                ? props.selectedSKUData?.originalPrice
                                : props.originalPrice
                        }
                        displayWasLabel={
                            checkIfSelectedSKUDataAvailable()
                                ? props.selectedSKUData?.displayWasLabel
                                : props.displayWasLabel
                        }
                        language={props.language}
                        priceMessage={
                            checkIfSelectedSKUDataAvailable() ? props.selectedSKUData?.priceMessage : props.priceMessage
                        }
                        nowFromLabel={props.nowFromLabel}
                        saveFromLabel={props.saveFromLabel}
                        wasFromLabel={props.wasFromLabel}
                        fromLabel={props.fromLabel}
                        overridePriceHeight={props.overridePriceHeight}
                        plusMinusSymbol={props.plusMinusSymbol}
                        isOnSaleOrClearance={onSaleValue}
                    />
                    {productBadges()}
                    {fitmentMessage}
                </div>
            </div>
        </div>
    )
}

AutoPartsProductInformation.propTypes = {
    productProps: PropTypes.any,
    currentPrice: PropTypes.shape({
        value: PropTypes.number.isRequired,
        maxPrice: PropTypes.number.isRequired,
        minPrice: PropTypes.number.isRequired,
    }) as Validator<PriceType>,
    originalPrice: PropTypes.shape({
        value: PropTypes.number.isRequired,
        maxPrice: PropTypes.number.isRequired,
        minPrice: PropTypes.number.isRequired,
    }) as Validator<PriceType>,
    language: PropTypes.string,
    feeTitle: PropTypes.string,
    badgePriorities: PropTypes.any,
    badges: PropTypes.array,
    hideDisclaimer: PropTypes.bool,
    priceMessage: PropTypes.array,
    feeDisclaimerMessage: PropTypes.string,
    displayWasLabel: PropTypes.bool,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    feeDisclaimerTitle: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    feeValue: PropTypes.number,
    vehicleInformationPresent: PropTypes.bool,
    selectedSKUData: PropTypes.any,
    overridePriceHeight: PropTypes.bool,
    feeMessages: PropTypes.array,
}

export default AutoPartsProductInformation
