export const editConstant = {
    passwordForm: 'password',
    tfaPhoneNumberForm: 'tfaPhoneNumber',
}
export const securityEditContainer = 'security-edit-container'
export const tfaPhoneNumberDataRegEx = /^\+1\d{10}$/

export enum PhoneVerificationErrorType {
    PROCESSING_ERROR = 'processingError',
}

export const phoneVerificationPhoneErrorCodes = {
    [PhoneVerificationErrorType.PROCESSING_ERROR]: {
        revokedOrInvalidToken: '400006',
        maxAttemptsOnCodeReached: '400124',
    },
}

export enum Mode {
    EDIT = 'edit',
    ADD = 'add',
}

export const maskedPhoneNumberFormat = '(***) *** ****'
