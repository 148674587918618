/**
 * Enum for sizes
 */
export enum Size {
    MINI = 'mini',
    XS = 'xs',
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
    XL = 'xl',
    XXL = 'xxl',
    XXXL = 'xxxl',
    XXXXL = 'xxxxl',
}

/**
 * Enum for reverse Sizes used in charts
 */
export enum SizeReverse {
    MINI = 'minir',
    XS = 'xsr',
    SMALL = 'smr',
    MEDIUM = 'mdr',
    LARGE = 'lgr',
    XL = 'xlr',
    XXL = 'xxlr',
}

/**
 * Enum for variations
 */
export enum Variation {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
}

/**
 * Enum for directions
 */
export enum Direction {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
}

/**
 * Enum for position
 */
export enum Position {
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
    ALL = 'all',
    CENTER = 'center',
    X_AXIS = 'x', // left & right
    Y_AXIS = 'y', // top & bottom
}

/**
 * Enum for status
 */
export enum Status {
    PROGRESS = 'In Progress',
    DONE = 'Done',
    FAILED = 'failed',
    SUCCESS = 'success',
    ERROR = 'error',
    START = 'start',
    RESET = 'reset',
}

/**
 * Enum for state
 */
export enum State {
    SUCCESS = 'success',
    CAUTION = 'caution',
    ERROR = 'error',
    INFO = 'information',
    DEFAULT = 'default',
    ACTIVE = 'active',
}

/**
 * Enum for animation type
 */
export enum Animation {
    PULSE = 'pulse',
    BOUNCE = 'bounce',
    PING = 'ping',
    BLINK = 'blink',
    NONE = 'none',
}

/**
 * Enum for shapes
 */
export enum Shapes {
    CIRCLE = 'circle',
}

/**
 * Enum for Link Targets
 * _blank - opens a new tab
 * _self - opens on same tab/window
 * _parent - parent browsing context of the current one. If no parent, behaves as _self
 * _top - The topmost browsing context. If no ancestors, behaves as _self
 * _framename - opens in named iframe
 */
export enum Target {
    BLANK = '_blank',
    SELF = '_self',
    PARENT = '_parent',
    TOP = '_top',
    FRAME = 'framename',
}

export enum ExternalTarget {
    EXTERNAL = 'external',
    OPENER = 'opener',
    NOOPENER = 'noopener',
    NOREFERRER = 'noreferrer',
    NOFOLLOW = 'nofollow',
    NOREFOPEN = 'noreferrer noopener',
    NOREFOPENFOLLOW = 'noreferrer noopener nofollow',
}

/**
 * Enum for Radius
 * Radius enum sizes is default sizes are (2px, 4px, 8px, 10px, 14px)
 * Sizes can be adjusted via css
 */
export enum Radius {
    NONE = 'none',
    ROUNDED_XS = 'rounded-xs', // 2px
    ROUNDED_SM = 'rounded-sm', // 4px
    ROUNDED_MD = 'rounded-md', // 8px
    ROUNDED_LG = 'rounded-lg', // 10px
    ROUNDED_XL = 'rounded-xl', // 14px
    CIRCLE = 'circle',
}

/**
 * @typedef { Object } IRadius
 * @property { Radius } size
 * @property { Position } position
 */
export interface IRadius {
    size: Radius
    position: Position
}

/**
 * @typedef { Object } IValueType
 * @property { string } type
 * @property { string } value
 */
export interface IValueType {
    type: string
    value: string
}

/**
 * Swiper carousel breakpoint keys and settings
 */
export type BreakpointKeys = Size.XS | Size.SMALL | Size.MEDIUM | Size.LARGE
export interface BreakpointSettings {
    slidesToShow: number
    slidesPerGroup: number
    spacing?: number
}

/**
 * @typedef { Object } IAnimation
 * @property { boolean } infinite
 * @property { number } timer - in ms if infinite is false
 * @property { Animation } animation - type of animation
 */
export interface IAnimation {
    type: Animation
    infinite?: boolean
}

export interface ITitleDesc {
    title?: string
    description: string | string[] | Record<string, string> | React.ReactNode
}

export interface MultiField {
    component: string
    value: string
}

export interface EnvironmentConfig {
    serviceClient: string
    serviceVersion: string
    ocpApimSubscriptionKey: string
    baseSiteId: string
}
export interface IImage {
    url: string
    alt?: string
}

export interface ILink {
    url: string
    target?: Target | string
    rel?: ExternalTarget | string
    title?: string
    label?: JSX.Element | string | string[] | Record<string, string> | React.ReactNode
}
