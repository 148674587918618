import { FeatureBulletsDTO, HrefLangLinkData, ProductResponseData } from '../../../redux/models/product.interface'
import { CategoryResponseById } from '../../../redux/models/category.interface'
import { MediaImages, Vehicle } from '@nl/lib'
import { StoreWithAvailability } from '../../../redux/models/storeDetails.interface'
import { TireType } from '../../../redux/models/tireVehicle.interface'
import { ProductDataState } from '../../../redux/reducers/productData.reducer'
import PropTypes from 'prop-types'
import { Validator } from 'react'

export interface SeoStructure {
    title?: string
    metaName?: [Record<string, string>]
    metaProp?: [Record<string, string>]
    links?: [Record<string, string>]
    isAlternateTagRenderEnabled?: boolean
}

export interface SeoLink {
    path: string
    href: string
    hrefLang?: string
}

export interface SeoCopyType {
    viewMoreLabel: string
    viewMoreA11yDescription?: string
    viewLessLabel: string
    viewLessA11yDescription?: string
    viewMoreAfter: number
}

export interface SeoHocType {
    product: ProductResponseData
    plp: CategoryResponseById
    defaultVehicle?: Vehicle
    isFitmentRequired?: boolean
    storeDetails: StoreWithAvailability
    shopByTireSize: TireType | null
    productCardData: ProductDataState
    urlDefaultVehicle?: Vehicle
    isPreselectedFromURL: boolean
}

export interface ReduxStateType {
    canonicalUrl: string
    url?: string
    hreflangLinkData?: HrefLangLinkData[]
    noFollow?: boolean
    noIndex?: boolean
    featureBullets?: FeatureBulletsDTO[]
    longDescription?: string
    seodescription?: string
    seoDescription?: string
    seoTitle?: string
    name?: string
    displayName?: string
    primarySellingChannel: string
    images?: MediaImages[]
    productWheelType?: string
}

export const SeoHocPropTypes = {
    product: PropTypes.object.isRequired as Validator<ProductResponseData>,
    plp: PropTypes.object.isRequired as Validator<CategoryResponseById>,
    defaultVehicle: PropTypes.object as Validator<Vehicle>,
    isFitmentRequired: PropTypes.bool,
    storeDetails: PropTypes.object.isRequired as Validator<StoreWithAvailability>,
    shopByTireSize: PropTypes.object as Validator<TireType>,
    productCardData: PropTypes.object.isRequired as Validator<ProductDataState>,
    urlDefaultVehicle: PropTypes.object as Validator<Vehicle>,
    isPreselectedFromURL: PropTypes.bool.isRequired,
}
