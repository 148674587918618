import getPageTitle from '../../utils/getPageTitle'
import { onLoadEvent } from '../../components/Accounts/OrderHistory/GlobalPageLoadAnalytics.constant'
import { getProvider } from '../providers'
import { SpaPageAnalytics, SpaPageEventAnalytics } from '../analytics.type'
import { AnalyticsRecordInterface } from '../providers/provider.type'
import { analyticsAttributes } from '../../globalConstants'
import { GlobalPropsHelper } from '../helpers/globalProps'

const provider = getProvider()

const globalProps = new GlobalPropsHelper()
const dataConfig = globalProps.readDataConfig()

const spaAnalytics = (() => {
    const {
        eventParameters: { location },
    } = analyticsAttributes

    /**
     * Prepare analytics data for onload page
     * @param {string} pageName
     * @returns {SpaPageAnalytics}
     */
    const prepareOnloadPageData = (pageName?: string): SpaPageAnalytics => {
        return {
            name: pageName ? pageName : getPageTitle(),
            url: window.location.href,
        }
    }

    /**
     * This function is to trigger analytics for single page
     * @param { string } pageName
     * @returns { SpaPageEventAnalytics }
     */
    const _spaAnalyticsData = (pageName?: string): SpaPageEventAnalytics => {
        return {
            event: onLoadEvent,
            page: prepareOnloadPageData(pageName),
        }
    }

    const _spaLoginAnalyticsData = (successLogin: boolean, eventType: string): AnalyticsRecordInterface => {
        return {
            event: onLoadEvent,
            centralLogin: [
                {
                    eventType,
                    successLogin,
                },
            ],
        }
    }

    /**
     * Prepare registration onload data
     * @param {string} step - The current step of the registration process
     * @param {string} linkOption - The link option used during registration
     * @param {string} action - The action taken during registration
     * @param {boolean} [isSuccess] - true if registration is successful, false if has error, undefined if has not result
     * @returns {RegistrationOnLoadAnalytics} - The onload data for registration
     */
    const _spaRegistrationOnLoadData = (
        step: string,
        linkOption: string,
        action: string,
        isSuccess?: boolean,
    ): AnalyticsRecordInterface => {
        let actionResult

        if (isSuccess === undefined) {
            actionResult = {}
        } else {
            actionResult = isSuccess ? { success: true } : { error: true }
        }

        return {
            event: onLoadEvent,
            centralRegistration: [
                {
                    source: dataConfig?.bannerId,
                    step,
                    linkOption,
                    action,
                    ...actionResult,
                },
            ],
        }
    }

    return {
        setAnalytics: (pageName?: string): void => {
            provider.push(_spaAnalyticsData(pageName))
        },
        setSSOLoginAnalytics: (successLogin: boolean): void => {
            provider.push(_spaLoginAnalyticsData(successLogin, location.ssoLogin))
        },
        setLoginFromCLPAnalytics: (successLogin: boolean): void => {
            provider.push(_spaLoginAnalyticsData(successLogin, location.finishLoginFromCLP))
        },
        setRewardsRegistrationData: (step: string, linkOption: string, action: string, isSuccess?: boolean): void => {
            provider.push(_spaRegistrationOnLoadData(step, linkOption, action, isSuccess), true)
        },
    }
})()

export default spaAnalytics
