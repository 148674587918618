import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators/security.actionCreators'
import { SecurityDataState } from '../models/security.interface'
import { Status } from '@nl/lib'
import {
    successUpdatePassword,
    failUpdatePassword,
    successUpdateTfaPhoneNumber,
    failUpdateTfaPhoneNumber,
    initPhoneVerificationFlow,
    failPhoneVerificationFlow,
    clearUpdateTfaPhoneNumberStatus,
    resetPhoneVerificationFlow,
    successPhoneVerificationFlow,
    setPhoneVerificationErrCode,
    clearPhoneVerificationData,
    setTFANumber,
} from '../actionCreators/security.actionCreators'

type Action = ActionType<typeof actions>

export const initialState: SecurityDataState = {
    passwordUpdateStatus: '',
    passwordErrCode: '',
    tfaPhoneNumberUpdateStatus: '',
    tfaPhoneNumberUpdateErrCode: '',
    tfaNumber: '',
    phoneVerificationData: {
        status: '',
        phvToken: '',
        gigyaAssertion: '',
        errCode: '',
        phoneNumber: '',
    },
}

// eslint-disable-next-line complexity, sonar/cyclomatic-complexity
export const securityReducer = (state: SecurityDataState = initialState, action: Action): SecurityDataState => {
    switch (action.type) {
        case getType(successUpdatePassword): {
            return {
                ...state,
                passwordUpdateStatus: Status.SUCCESS,
            }
        }
        case getType(failUpdatePassword): {
            return {
                ...state,
                passwordUpdateStatus: Status.FAILED,
                passwordErrCode: action.payload,
            }
        }
        case getType(successUpdateTfaPhoneNumber): {
            return {
                ...state,
                tfaPhoneNumberUpdateStatus: Status.SUCCESS,
            }
        }
        case getType(clearUpdateTfaPhoneNumberStatus): {
            return {
                ...state,
                tfaPhoneNumberUpdateStatus: '',
                tfaPhoneNumberUpdateErrCode: '',
            }
        }
        case getType(failUpdateTfaPhoneNumber): {
            return {
                ...state,
                tfaPhoneNumberUpdateStatus: Status.FAILED,
                tfaPhoneNumberUpdateErrCode: action.payload,
            }
        }
        case getType(initPhoneVerificationFlow): {
            return {
                ...state,
                phoneVerificationData: {
                    status: Status.START,
                    phvToken: action.payload.phvToken,
                    gigyaAssertion: action.payload.gigyaAssertion,
                    errCode: '',
                    phoneNumber: action.payload.phone ?? '',
                },
            }
        }
        case getType(failPhoneVerificationFlow): {
            return {
                ...state,
                phoneVerificationData: {
                    status: Status.FAILED,
                    phvToken: '',
                    gigyaAssertion: '',
                    errCode: '',
                    phoneNumber: '',
                },
            }
        }
        case getType(resetPhoneVerificationFlow): {
            return {
                ...state,
                phoneVerificationData: {
                    status: Status.RESET,
                    phvToken: '',
                    gigyaAssertion: '',
                    errCode: '',
                    phoneNumber: '',
                },
            }
        }
        case getType(successPhoneVerificationFlow): {
            return {
                ...state,
                phoneVerificationData: {
                    status: Status.SUCCESS,
                    phvToken: '',
                    gigyaAssertion: '',
                    errCode: '',
                    phoneNumber: '',
                },
            }
        }
        case getType(setPhoneVerificationErrCode): {
            return {
                ...state,
                phoneVerificationData: {
                    ...state.phoneVerificationData,
                    errCode: action.payload,
                    status: Status.START,
                },
            }
        }
        case getType(clearPhoneVerificationData): {
            return {
                ...state,
                phoneVerificationData: {
                    status: '',
                    phvToken: '',
                    gigyaAssertion: '',
                    errCode: '',
                    phoneNumber: '',
                },
            }
        }
        case getType(setTFANumber): {
            return {
                ...state,
                tfaNumber: action.payload.phones[0]?.phone,
            }
        }
        default:
            return state
    }
}
