import React, { useRef, useEffect } from 'react'

import FlyoutModalComponent from '../FlyoutModalComponent'
import TransactionColumnLogo from '../TransactionColumnLogo'
import LineSummary from '../LineSummary'
import PreviewDetail from '../PreviewDetail'
import { TransactionDetailProps, TransactionAmountType } from './TransactionDetail.type'
import Icon from '../Icon'
import Button from '../Button'
import { PREFIX, MODAL_OPEN_CLASS, previousElementName } from '../config'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import Heading from '../Heading'
import { modalAccessibilityHandler } from '../../utils'

const TransactionDetail: React.FC<TransactionDetailProps> = props => {
    const {
        isOpen,
        closeFlyout,
        transactionDetailLabel,
        transaction,
        bannerImageList,
        storeNameLabel,
        datePlacedLabel,
        transactionDate,
        a11yCloseIconLabel,
        transactionAmountList,
    } = props

    const contentRef = useRef(null)

    const componentClass = `${PREFIX}-transaction-detail`
    const modalClassName = `${PREFIX}-previous-element`
    const previousElement: HTMLElement | null = document.querySelector(`button[${previousElementName}]`)

    /**
     * function calls on the click of close button and closes the modal
     * @returns {void}
     */
    const closeModalHandler = (): void => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName })
        closeFlyout()
        if (previousElement) {
            setTimeout(() => {
                previousElement?.focus() // Highlight the initiated button
                previousElement?.removeAttribute(previousElementName)
            }, 0)
        }
    }

    const flyoutModalProps = {
        isOpen,
        closeFlyout,
        isStoreSelectorModalUsed: false,
    }

    const { storeName, banner } = transaction

    // stop page scroll when flyOut is open
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add(MODAL_OPEN_CLASS)
        } else {
            document.body.classList.remove(MODAL_OPEN_CLASS)
        }

        /**
         * Remove body `modalOpen Class` on Flyout unmount, is necessary for the case
         * when Flyout unmount happens by not changing `isOpen` prop to `false`.
         */
        return () => {
            document.body.classList.remove(MODAL_OPEN_CLASS)
        }
    }, [isOpen])

    useClickOutsideClose(contentRef, () => closeModalHandler(), isOpen, true)

    /**
     * render transaction detail modal header
     * @return {JSX.Element}
     */
    const getModalHeader = (): JSX.Element => (
        <div className={`${componentClass}__header`}>
            <Heading variant={'h2'} componentClass={`${componentClass}__title`}>
                {transactionDetailLabel}
            </Heading>
            <Button
                data-testid="close-button"
                type="icon_button"
                onClick={closeModalHandler}
                ariaLabel={a11yCloseIconLabel}>
                <Icon type="ct-close" size="lg" />
            </Button>
        </div>
    )

    /**
     * render transaction modal body
     * @return {JSX.Element}
     */
    const getModalDescription = (): JSX.Element => (
        <section className={`${PREFIX}-xs-flex ${componentClass}__body`}>
            <TransactionColumnLogo
                banner={banner}
                bannerImageList={bannerImageList}
                customClass={`${PREFIX}-xs-none ${PREFIX}-sm-flex`}
            />
            <div className={`${PREFIX}-row`}>
                <PreviewDetail title={storeNameLabel} detail={storeName} />
                <PreviewDetail title={datePlacedLabel} detail={transactionDate} />
            </div>
        </section>
    )

    return isOpen ? (
        <FlyoutModalComponent {...flyoutModalProps}>
            <div className={`${componentClass}`}>
                {getModalHeader()}
                {getModalDescription()}
                {transactionAmountList.map((item: TransactionAmountType, index: number) => (
                    <LineSummary
                        key={index}
                        className={`${PREFIX}-xs-flex ${PREFIX}-xs-justify-space-between ${PREFIX}-line-summary${
                            item.isCtMoneyDetailView ? '__expand' : ''
                        }`}
                        title={item?.title}
                        value={item?.value}
                    />
                ))}
            </div>
        </FlyoutModalComponent>
    ) : null
}

export default TransactionDetail
