import React from 'react'
import Checkbox from '../Checkbox'
import { ProductCardCompareProps } from '../ProductGridView/ProductGrid.types'
import { stringKeyCodes } from '../../utils'
import { PREFIX } from '../config'

/**
 * Compare checkbox component for plp/srp
 * @param {ProductCardCompareProps} props - Compare props
 * @returns {JSX.Element} returns compare checkbox component
 */
const CompareCheckbox: React.FC<ProductCardCompareProps> = (props: ProductCardCompareProps): JSX.Element => {
    const {
        addProductItemToCompare,
        product,
        idx,
        isCompareProductChecked,
        compareProductsLabel,
        showCompareCheckbox,
    } = props

    /**
     * Function to call on compare checkbox keyboard event
     * @param {React.KeyboardEvent<HTMLElement>} event keyboard event
     * @returns {void}
     */
    const handleCompareKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
        if (event.key === stringKeyCodes.enter || event.key === stringKeyCodes.spacing) {
            addProductItemToCompare(event, product, idx, isCompareProductChecked)
        }
    }

    return (
        showCompareCheckbox && (
            <div
                className={`${PREFIX}-product-card__compare-checkbox`}
                role="checkbox"
                aria-checked={false}
                tabIndex={0}
                onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => handleCompareKeyDown(event)}
                onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                    addProductItemToCompare(event, product, idx, isCompareProductChecked)
                }>
                <Checkbox
                    id={'checkbox'}
                    label={compareProductsLabel}
                    checked={isCompareProductChecked ? true : false}
                    ariaLabel={compareProductsLabel}
                />
            </div>
        )
    )
}

export default CompareCheckbox
