/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState, Validator, useMemo } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { cardPropTypes, FeatureBullets } from './product.types'
import ProductInfo from '../ProductInfo'
import Price from '../Price'
import Badges from '../Badges'
import Rebate from '../Rebate'
import { ProductCardType as GridProductCardType, ProductOptionValue } from '../ProductGridView/ProductGrid.types'
import { grid, minOptionsArrayLength, pagesForScrollRestoration, plpUrl } from './productCard.constant'
import { scrollToFooter } from '../../utils/scrollToFooter'
import { checkDataLength } from '../../utils/checkDataLength'
import { magicNumber, stringKeyCodes, replaceStrWithDynamicVal } from '../../utils'
import Icon from '../Icon'
import { productCardScrollToTop } from '../../utils/ProductCardScrollToTop/productCardScrollToTop'
import { ProductCardScrollEvent } from '../../utils/ProductCardScrollToTop/productCardScrollToTop.type'
import PromoMessages from '../PromoMessages'
import ProductCardColorSwatches from '../ProductCardColorSwatches'
import { COLOR } from '../Variants/Variants.constant'
import { useGenerateSaveMessage } from '../Price/Price.helper'
import { useScrollRestoration } from './ProductCard.hooks'
import {
    getAccessibilityAvailabilityId,
    getAccessibilityPriceId,
    getAccessibilityPromoId,
    getAccessibilityTitleId,
} from '../../utils/getAccessibilityId'
import ProductCardATC from '../VehicleProductCard/ProductCardATC'
import { getPageType } from '../../utils/getPageType'
import ProductCardWishlist from '../ProductCardWishlist/ProductCardWishlist'
import CompareCheckbox from '../CompareCheckbox/CompareCheckbox'
import Button from '../Button'

/**
 * ProductCard component
 * @return {JSX.Element} returns ProductCardVariants,ProductInfo,Price,Badges
 */

const ProductCard: React.FC<cardPropTypes> = ({
    productProps: {
        ratingsAndReview,
        featureHeaderLabel,
        variantAvailableMsg,
        a11yPriceRangeFrom,
        a11yPriceRangeTo,
        fulfillment,
        sellable,
        orderable,
        enableAddToCartOptions,
        addButton,
        optionsButton,
        hideSaleEndDaySoonMessage,
    },
    product,
    idx,
    cardType,
    title,
    brand,
    code,
    inImageBadgePriorities,
    badgePriorities,
    badges,
    showBadge = true,
    discount,
    hideDisclaimer,
    hideDescription,
    productCardClick,
    hideSaveLabel,
    a11yStrikeOutPrice,
    a11yStrikeOutPriceRange,
    a11yCloseIconLabel,
    a11yTooltipIcon,
    images,
    featureBullets,
    options,
    currentPrice,
    originalPrice,
    displayWasLabel,
    language,
    feeTitle,
    feeDisclaimerMessage,
    feeDisclaimerTitle,
    showFBTComponent,
    priceMessage,
    rebateIcon,
    rebate,
    nowFromLabel,
    saveFromLabel,
    wasFromLabel,
    fromLabel,
    feeDisclaimerType,
    imageDataComponentName,
    returnPolicy,
    isMultiSku,
    inStockOnlineLabel,
    outOfStockLabel,
    inStorePurchaseLabel,
    inStockAisleLabel,
    checkAvailabilityLabel,
    checkOtherStoresLabel,
    overridePriceHeight,
    unitPriceLabel,
    clearancePriceLabel,
    promotionalPriceLabel,
    thresholdValue,
    enableSaveStory,
    enableMiniPdpFlyoutSupport,
    enableATCForSingleSkuMiniPdp,
    url,
    isUrgentLowStock,
    isUrgentLowStockLabel,
    isWheelOrTirePDP,
    eachLabel,
    isAutomotiveProduct,
    isScrollingRequired,
    isLazyRequire,
    productDataId,
    saleEndDaySoonMessage,
    isColourSwatchesActive,
    saleMessagesRules,
    saleEndDisableShift,
    a11yVariantSelected,
    a11yVariantUnSelected,
    a11yClickToReadFootnote,
    accessibilityId,
    plusMinusSymbol,
    boldBrand,
    isOnSale,
    enableBadges,
    showSaleClearanceBadge,
    addToCartBtnClick,
    showAtcBtnSpinner,
    addToCartLabel,
    sectionTitle,
    componentName,
    swiperLazyLoading,
    sponsoredLabel,
    selectedSchemaId,
    addProductItemToWishlist,
    isWishlistItemActive,
    isWishlistEligible,
    showWishlistBtnSpinner,
    a11yWishlistIcon,
    quickLookLabel,
    isWishlistToShow,
    addProductItemToCompare,
    isCompareProductChecked,
    compareProductsLabel,
    isWestPriceLazyLoadEnabled,
    showCloseBtn,
    removeCompareProductCard,
    closeLabel,
    isPriceRangeViewEnabled = false,
    showCompareCheckbox,
}) => {
    const GridView = cardType === grid

    const colorVariant = useMemo(() => {
        return options?.find(variant => {
            return variant.descriptor === COLOR
        })
    }, [options])

    const defaultColorSwatch = colorVariant?.values?.find(items => items?.defaultSelect)

    const [currentColorVariantData, setCurrentColorVariantData] = useState<ProductOptionValue>(
        defaultColorSwatch || colorVariant?.values?.[0],
    )
    const [isColorChanged, setIsColorChanged] = useState(false)
    const compareCheckboxClass = showCompareCheckbox ? `${PREFIX}-product-card__compare-check-box` : ''

    const currentLevelData = useMemo(() => {
        if (colorVariant) {
            const selectedSku = product?.skus?.find(sku => sku.code === currentColorVariantData.skuCodes[0])
            const result = currentColorVariantData
            isWestPriceLazyLoadEnabled &&
                !!selectedSku &&
                Object.assign(result, {
                    ...selectedSku,
                })
            return result
        }
        return {
            ...product,
            originalPrice,
            currentPrice,
            badges,
            displayWasLabel,
            priceMessage,
            isOnSale,
        }
    }, [
        colorVariant,
        product,
        originalPrice,
        currentPrice,
        badges,
        displayWasLabel,
        priceMessage,
        isOnSale,
        currentColorVariantData,
        isWestPriceLazyLoadEnabled,
    ])

    const saveMessage = useGenerateSaveMessage(
        currentLevelData?.saleCut,
        currentLevelData?.originalPrice,
        saleMessagesRules,
        currentLevelData?.currentPrice,
    )

    const {
        restorationRef,
        getScrollRestorationId,
        removeScrollRestorationId,
        restoreScroll,
        saveDataAsScrollRestorationId,
    } = useScrollRestoration()

    const onProductClick = (
        event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        selectedColorId,
    ) => {
        if (GridView) {
            saveDataAsScrollRestorationId(productDataId)
        }
        productCardClick(event, product, idx, null, selectedColorId)
    }

    const createUrlWithPolicy = (source: string, policyType: string) => {
        return policyType
            ? `${source?.toString()?.split('?', magicNumber.ONE).toString()}${returnPolicy(policyType)}`
            : `${source}`
    }

    /**
     * Function to check pagetype for which scrollRestoration should use
     * @returns {boolean | undefined} returns boolean or undefined
     */
    const isAcceptablePage = useCallback((): boolean | undefined => {
        return pagesForScrollRestoration?.includes(getPageType())
    }, [pagesForScrollRestoration])

    useEffect(() => {
        const savedDataAsScrollRestorationId = getScrollRestorationId()

        // eslint-disable-next-line sonar/different-types-comparison
        const isIdsMatched = productDataId === savedDataAsScrollRestorationId

        if (isIdsMatched && isAcceptablePage()) {
            restoreScroll()
            removeScrollRestorationId()
        }
    }, [
        restorationRef,
        productDataId,
        getScrollRestorationId,
        removeScrollRestorationId,
        restoreScroll,
        isAcceptablePage,
    ])

    /**
     * @method handleMouseEvent  : Function invoked on mouse enter/leave event
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e
     * @param {string} productImage
     */

    const handleMouseEvent = (_e: React.MouseEvent<HTMLDivElement, MouseEvent>, productImage?: string) => {
        if (
            (_e.target as HTMLElement)?.parentElement?.className === `${PREFIX}-product-card__image-wrap` &&
            productImage
        ) {
            _e.target['src'] = `${createUrlWithPolicy(productImage, imageDataComponentName)}`
        }
    }

    /**
     * Availability statement component
     * @return {JSX.Element} returns availability statement to show
     */
    const renderAvailabilityStatement = (): JSX.Element => {
        if (fulfillment) {
            const quantityFlag = fulfillment.availability.quantity
            const corporateQuantityFlag = fulfillment.availability.Corporate.Quantity
            const sellableFlag = sellable
            const orderableFlag = orderable
            return getAvailabilityMessage(quantityFlag, corporateQuantityFlag, sellableFlag, orderableFlag)
        }
        return null
    }

    const renderUrgencyLowStockMessage = (quantityFlag: number): JSX.Element => {
        const urgencyMsg = isUrgentLowStockLabel
            ? replaceStrWithDynamicVal(isUrgentLowStockLabel, quantityFlag?.toString())
            : ''
        return (
            <span>
                <span className={`${PREFIX}-product-card__urgentLowStock`}>{urgencyMsg}</span>
            </span>
        )
    }

    // eslint-disable-next-line complexity
    const getAvailabilityMessage = (
        quantityFlag: number,
        corporateQuantityFlag: number,
        isSellableFlag: boolean,
        isOrderableFlag: boolean,
        // eslint-disable-next-line sonar/cyclomatic-complexity
    ): JSX.Element => {
        let availabilityLabel: string | JSX.Element = replaceStrWithDynamicVal(
            inStockAisleLabel,
            quantityFlag?.toString() ?? '',
        )
        if (isSellableAndInStock(quantityFlag, isSellableFlag)) {
            if (isUrgentLowStock) {
                availabilityLabel = renderUrgencyLowStockMessage(quantityFlag)
            }
            return (
                <div
                    className={`${PREFIX}-product-card__availability-message`}
                    id={getAccessibilityAvailabilityId(accessibilityId)}>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-checkmark" size="sm" />
                        </span>
                        {availabilityLabel}
                    </div>
                </div>
            )
        } else if (isNotSellableAndInStock(quantityFlag, isSellableFlag)) {
            if (isUrgentLowStock) {
                availabilityLabel = renderUrgencyLowStockMessage(quantityFlag)
            }
            return (
                <div
                    className={`${PREFIX}-product-card__availability-message`}
                    id={getAccessibilityAvailabilityId(accessibilityId)}>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-checkmark" size="sm" />
                        </span>
                        {availabilityLabel}
                    </div>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-checkmark" size="sm" />
                        </span>
                        {inStorePurchaseLabel}
                    </div>
                </div>
            )
        } else if (isSellableAndOrderable(quantityFlag, corporateQuantityFlag, isSellableFlag, isOrderableFlag)) {
            return (
                <div
                    className={`${PREFIX}-product-card__availability-message`}
                    id={getAccessibilityAvailabilityId(accessibilityId)}>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-checkmark" size="sm" />
                        </span>
                        {inStockOnlineLabel}
                    </div>
                </div>
            )
        } else if (checkOtherStores(quantityFlag, corporateQuantityFlag, isSellableFlag, isOrderableFlag)) {
            return (
                <div
                    className={`${PREFIX}-product-card__availability-message`}
                    id={getAccessibilityAvailabilityId(accessibilityId)}>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-prohibited" size="sm" />
                        </span>
                        {availabilityLabel}
                    </div>
                    {corporateQuantityFlag > 0 && (
                        <div>
                            <span className={`${PREFIX}-product-card__availability-icon`}>
                                <Icon type="ct-checkmark" size="sm" />
                            </span>
                            <span className={`${PREFIX}-product-card__link-text`}>{checkOtherStoresLabel}</span>
                        </div>
                    )}
                </div>
            )
        } else if (isMultiSku) {
            return (
                <div
                    className={`${PREFIX}-product-card__availability-message`}
                    id={getAccessibilityAvailabilityId(accessibilityId)}>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-checkmark" size="sm" />
                        </span>
                        <span className={`${PREFIX}-product-card__link-text`}>{checkAvailabilityLabel}</span>
                    </div>
                </div>
            )
        } else if (isOutOfStock(quantityFlag, corporateQuantityFlag, isSellableFlag, isOrderableFlag)) {
            return (
                <div
                    className={`${PREFIX}-product-card__availability-message`}
                    id={getAccessibilityAvailabilityId(accessibilityId)}>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-prohibited" size="sm" />
                        </span>
                        {outOfStockLabel}
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    const isSellableAndInStock = (quantityFlag: number, isSellableFlag: boolean): boolean => {
        return !isMultiSku && quantityFlag > 0 && isSellableFlag === true
    }

    const isNotSellableAndInStock = (quantityFlag: number, isSellableFlag: boolean): boolean => {
        return !isMultiSku && quantityFlag > 0 && isSellableFlag === false
    }

    const isSellableAndOrderable = (
        quantityFlag: number,
        corporateQuantityFlag: number,
        isSellableFlag: boolean,
        isOrderableFlag: boolean,
    ): boolean => {
        const isQtyValid = quantityFlag === 0 && corporateQuantityFlag > 0
        return !isMultiSku && isQtyValid && isSellableFlag === true && isOrderableFlag === true
    }

    const checkOtherStores = (
        quantityFlag: number,
        corporateQuantityFlag: number,
        isSellableFlag: boolean,
        isOrderableFlag: boolean,
    ): boolean => {
        return (
            !isMultiSku &&
            (isInStockCheckOtherStores(quantityFlag, corporateQuantityFlag, isSellableFlag, isOrderableFlag) ||
                isNotInStockCheckOtherStores(quantityFlag, corporateQuantityFlag, isSellableFlag, isOrderableFlag))
        )
    }

    const isInStockCheckOtherStores = (
        quantityFlag: number,
        corporateQuantityFlag: number,
        isSellableFlag: boolean,
        isOrderableFlag: boolean,
    ): boolean => {
        return (
            // eslint-disable-next-line sonar/expression-complexity
            quantityFlag === 0 &&
            corporateQuantityFlag > 0 &&
            ((isSellableFlag === true && isOrderableFlag === false) ||
                (isSellableFlag === false && isOrderableFlag === true) ||
                (isSellableFlag === false && isOrderableFlag === false))
        )
    }

    const isNotInStockCheckOtherStores = (
        quantityFlag: number,
        corporateQuantityFlag: number,
        isSellableFlag: boolean,
        isOrderableFlag: boolean,
    ): boolean => {
        return (
            // eslint-disable-next-line sonar/expression-complexity
            quantityFlag === 0 &&
            !corporateQuantityFlag &&
            ((isSellableFlag === true && isOrderableFlag === true) ||
                (isSellableFlag === true && isOrderableFlag === false))
        )
    }

    const isOutOfStock = (
        quantityFlag: number,
        corporateQuantityFlag: number,
        isSellableFlag: boolean,
        isOrderableFlag: boolean,
    ): boolean => {
        return (
            // eslint-disable-next-line sonar/expression-complexity
            isMultiSku &&
            quantityFlag === 0 &&
            corporateQuantityFlag > 0 &&
            isSellableFlag === true &&
            isOrderableFlag === false
        )
    }

    const ratingsView = () => {
        // eslint-disable-next-line no-warning-comments
        // TODO: if cds sends rating as an object i.e. averageRating and number of reviews need to pass the object keys as the props.
        return ratingsAndReview ? (
            <div
                className={`${PREFIX}-reviews__list`}
                data-bv-show="inline_rating"
                data-bv-product-id={productDataId}
                data-bv-seo="false"
                aria-hidden={true}
            />
        ) : null
    }

    /**
     * function to render price component
     *
     * @return {JSX.Element} returns Price Component
     */
    const renderPriceComponent = () => {
        return (
            <Price
                isRangeView={isPriceRangeViewEnabled}
                discountValue={discount}
                promotionalPriceLabel={discount ? promotionalPriceLabel : null}
                thresholdValue={thresholdValue}
                unitPriceLabel={unitPriceLabel}
                clearancePriceLabel={clearancePriceLabel}
                a11yPriceRangeFrom={a11yPriceRangeFrom}
                a11yPriceRangeTo={a11yPriceRangeTo}
                hideSaveLabel={hideSaveLabel}
                a11yStrikeOutPrice={a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                a11yTooltipIcon={a11yTooltipIcon}
                a11yCloseIconLabel={a11yCloseIconLabel}
                feeTitle={feeTitle}
                feeDisclaimerTitle={feeDisclaimerTitle}
                feeDisclaimerMessage={feeDisclaimerMessage}
                currentPrice={currentLevelData?.currentPrice}
                originalPrice={currentLevelData?.originalPrice}
                displayWasLabel={currentLevelData?.displayWasLabel}
                language={language}
                priceMessage={currentLevelData?.priceMessage}
                scrollToFooter={scrollToFooter}
                nowFromLabel={nowFromLabel}
                saveFromLabel={saveFromLabel}
                wasFromLabel={wasFromLabel}
                fromLabel={fromLabel}
                feeDisclaimerType={feeDisclaimerType}
                overridePriceHeight={overridePriceHeight}
                showEachTextForSaveLabel={enableSaveStory}
                isAutomotiveEachLabel={isWheelOrTirePDP}
                eachLabel={eachLabel}
                saveMessage={saveMessage}
                a11yClickToReadFootnote={a11yClickToReadFootnote}
                accessibilityPriceId={getAccessibilityPriceId(accessibilityId)}
                ariaHidden={true}
                highlightDiscountedPrice={true}
                plusMinusSymbol={plusMinusSymbol}
                isOnSaleOrClearance={currentLevelData?.isOnSale}
            />
        )
    }

    const renderColorSwatches = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-product-card__color-swatches`}>
                {colorVariant && (
                    <ProductCardColorSwatches
                        colors={colorVariant.values}
                        onColorChange={onColorChange}
                        selectedValue={currentColorVariantData?.id}
                        a11yVariantSelected={a11yVariantSelected}
                        a11yVariantUnSelected={a11yVariantUnSelected}
                        display={colorVariant.display}
                    />
                )}
            </div>
        )
    }

    const onColorChange = useCallback(currentVariant => {
        setCurrentColorVariantData({ ...currentVariant })
        setIsColorChanged(true)
    }, [])

    /**
     * function to display productInfo  price and badges
     *
     * @return {JSX.Element} returns productInfo,price and badges components
     */

    const { message, greyCopyMsg, pdfLink } = rebate || {}
    /**
     * @return {JSX.Element} returns productInfo Component
     */

    /**
     * function to display Promo Messages  and tooltip
     *
     * @return {JSX.Element} returns Promo Messages  and tooltip from PromoMessages components
     */
    const renderPromoMessages = (): JSX.Element => {
        const promoMessages = currentLevelData?.promoMessages
        const restrictedPromoMessages = currentLevelData?.restrictedPromoMessages
        const isRenderPromoMessages = checkDataLength(promoMessages) || checkDataLength(restrictedPromoMessages)
        return isRenderPromoMessages ? (
            <div className={`${PREFIX}-promo-messages-wrapper`} id={getAccessibilityPromoId(accessibilityId)}>
                <PromoMessages
                    isProductLevel={true}
                    productPromoMessages={promoMessages}
                    productRestrictedPromoMessages={restrictedPromoMessages}
                    a11yTooltipIcon={a11yTooltipIcon}
                    a11yCloseIconLabel={a11yCloseIconLabel}
                />
            </div>
        ) : null
    }

    /**
     * @method getDisclaimerData  : Function return Disclaimer data
     */

    const getDisclaimerData = (): boolean => !colorVariant?.showDisclaimer || hideDisclaimer

    /**
     * Function to return productcode
     * @return {JSX.Element}
     */
    const productCodeDisplay = (): JSX.Element => {
        return (
            code && (
                <p className={`${PREFIX}-product__code`} aria-hidden={true}>
                    #{code}
                </p>
            )
        )
    }

    /**
     *  render price and badges in the same line based on conditions
     * @return {JSX.Element}
     */
    const renderPriceBadge = (): JSX.Element => {
        const saleEndDate = currentColorVariantData?.saleEndDate || currentLevelData?.saleEndDate
        const badgesToDisplay = currentLevelData?.badges
        // eslint-disable-next-line sonar/expression-complexity
        return enableBadges && showSaleClearanceBadge ? (
            <div className={`${PREFIX}-price-badge-wrapper`}>
                <div className={`${PREFIX}-carousel-price`}>{renderPriceComponent()}</div>
                {renderPromoMessages()}
                {showBadge && checkDataLength(badgesToDisplay) && (
                    <div className={`${PREFIX}-plp-badges`} aria-hidden={true}>
                        <Badges
                            badges={badgesToDisplay}
                            badgesAndPriorities={badgePriorities}
                            hideDisclaimer={getDisclaimerData()}
                            saleEndDaySoonMessage={saleEndDaySoonMessage}
                            salePriceEndDate={saleEndDate}
                            saleEndDisableShift={saleEndDisableShift}
                            hideSaleEndDaySoonMessage={hideSaleEndDaySoonMessage}
                        />
                    </div>
                )}
            </div>
        ) : (
            <>
                {renderPriceComponent()}
                {renderPromoMessages()}
                {showBadge && checkDataLength(badgesToDisplay) && (
                    <div className={`${PREFIX}-plp-badges`} aria-hidden={true}>
                        <Badges
                            badges={badgesToDisplay}
                            badgesAndPriorities={badgePriorities}
                            hideDisclaimer={getDisclaimerData()}
                            saleEndDaySoonMessage={saleEndDaySoonMessage}
                            salePriceEndDate={saleEndDate}
                            saleEndDisableShift={saleEndDisableShift}
                            hideSaleEndDaySoonMessage={hideSaleEndDaySoonMessage}
                        />
                    </div>
                )}
            </>
        )
    }

    /**
     * renders product information
     * @returns {JSX.Element} returns JSX.Element
     */
    const productInfoComponent = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-product-card__product-information ${compareCheckboxClass}`}>
                <ProductInfo
                    brand={brand}
                    title={title}
                    hideDescription={hideDescription}
                    titleStyle={`${PREFIX}-product-card__title ${PREFIX}-product--trim`}
                    titleContainerStyle={`${PREFIX}-product-card__title-container`}
                    accessibilityTitleId={getAccessibilityTitleId(accessibilityId)}
                    boldBrand={boldBrand}
                />
                {ratingsView()}
                {!GridView && (
                    <div className={`${PREFIX}-product-card__wishlist-section`}>
                        <ProductCardATC
                            showAtcBtnSpinner={showAtcBtnSpinner}
                            product={product}
                            addToCartBtnClick={addToCartBtnClick}
                            idx={idx}
                            addButton={addButton}
                            optionsButton={optionsButton}
                            enableAddToCartOptions={enableAddToCartOptions}
                            enableMiniPdpFlyoutSupport={enableMiniPdpFlyoutSupport}
                            enableATCForSingleSkuMiniPdp={enableATCForSingleSkuMiniPdp}
                            addToCartLabel={addToCartLabel}
                            selectedSchemaId={selectedSchemaId}
                            selectedColorVariantData={currentColorVariantData}
                            quickLookLabel={quickLookLabel}
                        />
                        <ProductCardWishlist
                            isWishlistEligible={isWishlistEligible}
                            a11yWishlistIcon={a11yWishlistIcon}
                            showWishlistBtnSpinner={showWishlistBtnSpinner}
                            addProductItemToWishlist={addProductItemToWishlist}
                            isWishlistItemActive={Boolean(isWishlistItemActive)}
                            product={product}
                            idx={idx}
                            isWishlistToShow={isWishlistToShow}
                        />
                    </div>
                )}
                {renderPriceBadge()}
                {productCodeDisplay()}
                {rebate && Object.keys(rebate).length && (
                    <Rebate
                        title={message}
                        rebateIcon={rebateIcon}
                        greyCopyMsg={greyCopyMsg}
                        pdfLink={pdfLink}
                        ariaHidden={true}
                    />
                )}
                {renderAvailabilityStatement()}
                {
                    // eslint-disable-next-line sonar/expression-complexity
                    variantAvailableMsg &&
                        Array.isArray(options) &&
                        options.length > minOptionsArrayLength &&
                        !isAutomotiveProduct && (
                            <div
                                className={`${PREFIX}-product-card__colors ${PREFIX}-product-card__colour-label`}
                                aria-hidden={true}>
                                <span className={`${PREFIX}-product-card__show-colour`}>{variantAvailableMsg}</span>
                            </div>
                        )
                }
            </div>
        )
    }

    /**
     * function to display featureSection
     * @return {JSX.Element}
     */

    const featureSection = () => {
        return (
            <>
                {!GridView && featureBullets && (
                    <div className={`${PREFIX}-product-card__features`} data-testid={'product-card-features'}>
                        <h4 className={`${PREFIX}-product-card__features-heading`}>{featureHeaderLabel}</h4>
                        <ul className={` ${PREFIX}-product-card__features-deck`}>
                            {featureBullets.slice(0, magicNumber.THREE).map((value: FeatureBullets, index: number) => {
                                return <li key={index.toString() + value.description}>{value.description}</li>
                            })}
                        </ul>
                        <CompareCheckbox
                            addProductItemToCompare={addProductItemToCompare}
                            product={product}
                            idx={idx}
                            isCompareProductChecked={isCompareProductChecked}
                            compareProductsLabel={compareProductsLabel}
                            showCompareCheckbox={showCompareCheckbox}
                        />
                    </div>
                )}
            </>
        )
    }

    type imgAttribute = {
        'data-src'?: string
        src?: string
        className?: string
        'data-component-name': string
    }

    /**
     * @param {string} path
     * @param {string} dataComponentName
     * @return {imgAttribute}
     */
    const imageAttributes = (path: string, dataComponentName: string) => {
        const attributeObject: imgAttribute = {
            'data-component-name': dataComponentName,
        }
        if (isLazyRequire && !isColorChanged) {
            attributeObject['data-src'] = path

            attributeObject.className = 'lazyload'
        } else {
            attributeObject.src = path
        }
        return attributeObject
    }

    /**
     * Function to return the primary image
     * @returns {string} : returns the primary image from mediaSet or image at product level
     */
    const getPrimaryProductImage = (): string => {
        return currentColorVariantData?.mediaSet?.[0]?.url
            ? currentColorVariantData?.mediaSet?.[0]?.url
            : images?.[0]?.url
    }

    /**
     * Function to return the secondary image
     * @returns {string} : returns the secondary image from mediaSet or image at product level
     */
    const getSecondaryProductImage = (): string => {
        return currentColorVariantData?.mediaSet?.[1]?.url
            ? currentColorVariantData?.mediaSet?.[1]?.url
            : images?.[1]?.url
    }

    /**
     * Function to return the image rendered on PLP/SRP
     * @returns {JSX.Element}
     */
    const renderImage = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-product-card__image-wrap`} aria-hidden={true}>
                <img
                    alt={title}
                    onMouseEnter={e => handleMouseEvent(e, getSecondaryProductImage())}
                    onMouseLeave={e => handleMouseEvent(e, getPrimaryProductImage())}
                    {...imageAttributes(
                        `${createUrlWithPolicy(getPrimaryProductImage(), imageDataComponentName)}`,
                        imageDataComponentName,
                    )}
                    loading={swiperLazyLoading ? 'lazy' : null}
                />
            </div>
        )
    }

    const colorVariantUrl = url?.includes('?')
        ? `${url}&colorCode=${currentColorVariantData?.id}${plpUrl}`
        : `${url}?colorCode=${currentColorVariantData?.id}${plpUrl}`
    const modifiedUrl = currentColorVariantData?.id ? colorVariantUrl : url

    /**
     * @param {Event} event
     * @param {string} key
     * @param {boolean} scrollingRequired
     */
    const handleKeyUp = (event, key, scrollingRequired) => {
        if (key === stringKeyCodes.tab && scrollingRequired) {
            productCardScrollToTop(event as ProductCardScrollEvent)
        }
    }
    /**
     * @param {Event} event
     * * @param {string} key
     * @param {string} selectedColorId
     */
    const handleKeyDown = (event, key, selectedColorId) => {
        if (key === stringKeyCodes.enter) {
            onProductClick(event, selectedColorId)
        }
    }

    /**
     * Key down event for remove compare product card
     * @param {Event} event
     * @returns {void}
     */
    const handleRemoveCompareProductKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
        if (event.key === stringKeyCodes.enter || event.key === stringKeyCodes.spacing) {
            removeCompareProductCard(event, product)
        }
    }

    const renderProductCardLink = () => {
        const selectedColorId = currentColorVariantData?.id ?? null
        const sponsoredA11yId = product?.isSponsored ? `sponsored_${product?.code}` : ''
        const badgesToDisplay = currentLevelData?.badges
        const priorityAndName = inImageBadgePriorities?.priorityAndName
        const priorityAndColourWithIconLabel = priorityAndName?.map(badge => ({
            ...badge,
            badgeLabel: <img src={badge?.badgeIconUrl} alt={badge?.badgeId} />,
            colorId: '',
        }))

        return (
            <a
                href={`${modifiedUrl}`}
                ref={restorationRef}
                aria-labelledby={`title__${accessibilityId} ${sponsoredA11yId} price__${accessibilityId} promo__${accessibilityId} availability__${accessibilityId} `}
                className={`${PREFIX}-product-card__no-button prod-link`}
                onClick={event => onProductClick(event, selectedColorId)}
                onKeyUp={(event: React.KeyboardEvent<HTMLElement>) =>
                    handleKeyUp(event, event.key, isScrollingRequired)
                }
                onKeyPress={(event: React.KeyboardEvent<HTMLElement>) =>
                    handleKeyDown(event, event.key, selectedColorId)
                }
                dap-wac-link-section={sectionTitle}
                dap-wac-value={title}>
                <div data-testid={`product-card-container${idx}`} className={`${PREFIX}-product-card__content`}>
                    <div className={`${PREFIX}-plp-in-image-badges`}>
                        <Badges
                            badges={badgesToDisplay}
                            badgesAndPriorities={{
                                ...inImageBadgePriorities,
                                priorityAndColour: priorityAndColourWithIconLabel,
                            }}
                            hideDisclaimer={getDisclaimerData()}
                        />
                    </div>
                    {GridView && (
                        <ProductCardWishlist
                            isWishlistEligible={isWishlistEligible}
                            a11yWishlistIcon={a11yWishlistIcon}
                            showWishlistBtnSpinner={showWishlistBtnSpinner}
                            addProductItemToWishlist={addProductItemToWishlist}
                            isWishlistItemActive={isWishlistItemActive}
                            product={product}
                            idx={idx}
                            isWishlistToShow={isWishlistToShow}
                        />
                    )}
                    <div className={`${PREFIX}-product-card__primary-details`}>
                        {renderImage()}

                        <div className={`${PREFIX}-product-card__cta`}>
                            {GridView && (
                                <ProductCardATC
                                    showAtcBtnSpinner={showAtcBtnSpinner}
                                    product={product}
                                    addToCartBtnClick={addToCartBtnClick}
                                    idx={idx}
                                    addButton={addButton}
                                    optionsButton={optionsButton}
                                    enableAddToCartOptions={enableAddToCartOptions}
                                    enableMiniPdpFlyoutSupport={enableMiniPdpFlyoutSupport}
                                    enableATCForSingleSkuMiniPdp={enableATCForSingleSkuMiniPdp}
                                    addToCartLabel={addToCartLabel}
                                    selectedSchemaId={selectedSchemaId}
                                    selectedColorVariantData={currentColorVariantData}
                                    quickLookLabel={quickLookLabel}
                                    componentName={componentName}
                                />
                            )}
                            {isColourSwatchesActive && renderColorSwatches()}
                            {productInfoComponent()}
                        </div>
                        {showCloseBtn && (
                            <div className={`${PREFIX}-product-card__close-btn-container`}>
                                <Button
                                    modifierClass={`${PREFIX}-product-card__close-btn`}
                                    ariaLabel={closeLabel}
                                    onKeyDown={handleRemoveCompareProductKeyDown}
                                    onClick={(event: React.MouseEvent<Element>) =>
                                        removeCompareProductCard(event, product)
                                    }>
                                    {' '}
                                    <Icon type="ct-close" size="md" />
                                </Button>
                            </div>
                        )}
                    </div>
                    {GridView && product?.isSponsored && (
                        <small
                            id={sponsoredA11yId}
                            aria-label={sponsoredLabel}
                            aria-hidden="true"
                            className={`${PREFIX}-product-card__sponsored`}>
                            {sponsoredLabel}
                        </small>
                    )}
                    {featureSection()}
                    {GridView && (
                        <CompareCheckbox
                            addProductItemToCompare={addProductItemToCompare}
                            product={product}
                            idx={idx}
                            isCompareProductChecked={isCompareProductChecked}
                            compareProductsLabel={compareProductsLabel}
                            showCompareCheckbox={showCompareCheckbox}
                        />
                    )}
                </div>
            </a>
        )
    }

    return showFBTComponent ? (
        <div
            data-testid={`product-card-container-fbt${idx}`}
            className={`${PREFIX}-product-card__content`}
            aria-labelledby={`title__${accessibilityId} price__${accessibilityId} availability__${accessibilityId}`}>
            <div className={`${PREFIX}-product-card__image-wrap`}>
                <img
                    aria-hidden={true}
                    onMouseEnter={e => handleMouseEvent(e, images[1]?.url)}
                    onMouseLeave={e => handleMouseEvent(e, images[0]?.url)}
                    alt={title}
                    {...imageAttributes(
                        `${createUrlWithPolicy(getPrimaryProductImage(), imageDataComponentName)}`,
                        imageDataComponentName,
                    )}
                    loading={swiperLazyLoading ? 'lazy' : null}
                />
            </div>
            {productInfoComponent()}
        </div>
    ) : (
        renderProductCardLink()
    )
}

ProductCard.defaultProps = {
    showFBTComponent: false,
}

ProductCard.propTypes = {
    path: PropTypes.string,
    productProps: PropTypes.any,
    idx: PropTypes.number.isRequired,
    cardType: PropTypes.string.isRequired,
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    code: PropTypes.string,
    brand: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.exact({
            label: PropTypes.string,
            url: PropTypes.string,
        }),
    ]).isRequired,
    rating: PropTypes.number.isRequired,
    ratingsCount: PropTypes.number.isRequired,
    priceShortMessage: PropTypes.string,
    priceLongMessage: PropTypes.string,
    longDescription: PropTypes.string,
    timeTestedPrice: PropTypes.any,
    permanentMarkdown: PropTypes.bool,
    sellable: PropTypes.bool,
    assemblyRequired: PropTypes.bool,
    inStock: PropTypes.bool,
    onlineOnly: PropTypes.bool,
    onFloor: PropTypes.bool,
    bopisOnly: PropTypes.bool,
    productCardClick: PropTypes.func,
    redirectAfterProductCardClick: PropTypes.func,
    badgePriorities: PropTypes.any,
    badges: PropTypes.array,
    showBadge: PropTypes.bool,
    discount: PropTypes.any,
    hideDisclaimer: PropTypes.bool,
    hideDescription: PropTypes.bool,
    hideSaveLabel: PropTypes.bool,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    images: PropTypes.array,
    featureBullets: PropTypes.array,
    a11yCloseIconLabel: PropTypes.string,
    ecoFeeTitleChange: PropTypes.func,
    options: PropTypes.array,
    skus: PropTypes.array,
    currentPrice: PropTypes.any,
    originalPrice: PropTypes.any,
    displayWasLabel: PropTypes.bool,
    language: PropTypes.string,
    feeTitle: PropTypes.string,
    feeDisclaimerMessage: PropTypes.string,
    feeDisclaimerTitle: PropTypes.string,
    selectedProduct: PropTypes.bool,
    showFBTComponent: PropTypes.bool,
    priceMessage: PropTypes.any,
    rebate: PropTypes.any,
    rebateIcon: PropTypes.string,
    scrollToFooter: PropTypes.func,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    feeDisclaimerType: PropTypes.string,
    imageDataComponentName: PropTypes.string,
    returnPolicy: PropTypes.func,
    inStockLabel: PropTypes.string,
    inStockOnlineLabel: PropTypes.string,
    outOfStockLabel: PropTypes.string,
    inStorePurchaseLabel: PropTypes.string,
    inStockAisleLabel: PropTypes.string,
    checkAvailabilityLabel: PropTypes.string,
    checkOtherStoresLabel: PropTypes.string,
    overridePriceHeight: PropTypes.bool,
    unitPriceLabel: PropTypes.string,
    clearancePriceLabel: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    thresholdValue: PropTypes.number,
    enableSaveStory: PropTypes.bool,
    a11yReviewRating: PropTypes.string,
    url: PropTypes.string,
    isUrgentLowStock: PropTypes.bool,
    isUrgentLowStockLabel: PropTypes.string,
    productWheelType: PropTypes.string,
    isWheelOrTirePDP: PropTypes.bool,
    eachLabel: PropTypes.string,
    isAutomotiveProduct: PropTypes.bool,
    isScrollingRequired: PropTypes.bool,
    isLazyRequire: PropTypes.bool,
    product: PropTypes.object.isRequired as Validator<GridProductCardType>,
    productDataId: PropTypes.string,
    saleEndDaySoonMessage: PropTypes.string,
    a11yClickToReadFootnote: PropTypes.string,
    addToCartBtnClick: PropTypes.func,
    showAtcBtnSpinner: PropTypes.bool,
    sponsoredLabel: PropTypes.string,
}

ProductCard.displayName = 'ProductCard'

export default ProductCard
