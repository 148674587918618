import React, { useEffect } from 'react'
import { BuyNowModalComponentProps } from './BuyNowModal.type'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'
import { commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'
import { IFeatureFlag } from '../../redux/models/commonContent.interface'
import { setBuyNowAEMProps } from '../../redux/actionCreators'

export const BuyNowModalComponent: React.FC<BuyNowModalComponentProps> = props => {
    const dispatch = useAppDispatch()
    const commonContentAvailable = useAppSelector(commonContentAvailableSelector)
    const { featureFlag = {} as IFeatureFlag } = commonContentAvailable
    const { enableBuyNowOnBopisProd } = featureFlag

    useEffect(() => {
        if (enableBuyNowOnBopisProd) {
            dispatch(setBuyNowAEMProps({ ...props, enableBuyNowOnBopisProd: enableBuyNowOnBopisProd }))
        }
    }, [dispatch, props, enableBuyNowOnBopisProd])

    return null
}
