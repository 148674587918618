import { ssoLoginHandler, enableSingleRegistrationAsync } from '../helpers/ciam.helper'
import GigyaService from '../services/gigyaService/gigya.service'
import { commonContentMessages } from '../redux/models/commonContent.interface'
import { SingleRegistration } from '../helpers/ciam.type'

type OptionType = 'newLoyalty' | 'linkLoyalty' | 'linkMasterCard' | 'none'

/**
 * Handles redirection based on URL search parameters.
 * This function checks for specific search parameters to determine
 * the type of redirect and whether additional context needs to be passed.
 * @returns {void}
 */
const handleRedirectionWithSessionVerification = (): void => {
    const urlParams = new URLSearchParams(window.location.search)
    const redirectCLP = urlParams.get('redirectToCLP')
    const redirectCRP = urlParams.get('redirectToCRP')
    const option = urlParams.get('option')

    if (redirectCLP) {
        // Hide body logic is also duplicated on AEM side to hide static content
        ;(document.querySelector('body') as HTMLBodyElement).style.display = 'none'
        redirectWithSessionVerify(() => {
            ssoLoginHandler(window.location.origin + window.location.pathname)
        })
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (redirectCRP) {
        // Hide body logic is also duplicated on AEM side to hide static content
        ;(document.querySelector('body') as HTMLBodyElement).style.display = 'none'
        enableSingleRegistrationAsync()
            .then((enablersSingleRegistration: SingleRegistration) => {
                const { enableCRP, enableLoyaltyFlowCRP } = enablersSingleRegistration
                if (enableCRP) {
                    redirectWithSessionVerify(() => {
                        const redirectURL = `${window.location.origin}${getOptionRedirectURL(
                            enableLoyaltyFlowCRP as boolean,
                            option as OptionType,
                        )}`
                        ssoLoginHandler(
                            redirectURL,
                            getAdditionalContext(enableLoyaltyFlowCRP as boolean, option as OptionType),
                        )
                    })
                } else {
                    window.location.href = encodeURI(window?.ODP?.globalLinks.registrationPageLink)
                }
            })
            .catch(() => console.error(commonContentMessages.LoadFailed))
    }
}

/**
 * Performs redirection after verifying the user session via Gigya service.
 * If the session is verified, redirects to a specific URL and handles
 * single sign-on (SSO) process with optional additional context.
 * @param {Record<string, unknown>} [additionalContext] - Optional additional data
 * to pass to the SSO login handler, e.g., loyalty information.
 * @returns {void}
 */
const redirectWithSessionVerify = (onRedirect: () => void): void => {
    const gigyaService = new GigyaService()
    gigyaService
        .verifySession()
        .then((isVerified: boolean) => {
            if (!isVerified) {
                onRedirect()
            }
        })
        .catch(err => console.error(err))
}

/**
 * Gets the redirect URL based on the loyalty flow and option type.
 * @param {boolean} enableLoyaltyFlowCRP - Flag indicating if loyalty flow is enabled.
 * @param {OptionType} option - The type of option for redirection.
 * @returns {string} - The URL to redirect to.
 */
const getOptionRedirectURL = (enableLoyaltyFlowCRP: boolean, option: OptionType): string => {
    if (!enableLoyaltyFlowCRP) {
        return window.ODP.globalLinks?.emailActivationPageLink as string
    }

    if (!option) {
        return window.location.pathname
    }

    const optionsRedirectURLMap = {
        newLoyalty: window.ODP.globalLinks?.requestNewCard,
        linkLoyalty: window.ODP.globalLinks?.linkExisingCard,
        linkMasterCard: window.ODP.globalLinks?.linkMasterCard,
        none: window.ODP.globalLinks?.emailActivationPageLink as string,
    }

    return optionsRedirectURLMap[option]
}

/**
 * Gets additional context for the SSO registration handler.
 * @param {boolean} enableLoyaltyFlowCRP - Flag indicating if loyalty flow is enabled.
 * @param {string} option - The type of option for redirection.
 * @returns {Record<string, unknown>} - The additional context to pass to the SSO registration handler.
 */
const getAdditionalContext = (enableLoyaltyFlowCRP: boolean, option: string): Record<string, unknown> => {
    const additionalContext: { flow: string; sourceURL: string; loyalty?: string } = {
        flow: 'registration',
        sourceURL: window.location.origin + window.location.pathname,
    }
    if (enableLoyaltyFlowCRP) {
        if (option) {
            additionalContext.loyalty = option
        }
    } else {
        additionalContext.loyalty = 'none'
    }
    return additionalContext
}

export default handleRedirectionWithSessionVerification
