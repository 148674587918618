import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { PREFIX } from '../config'
import GridCard from '../ProductReusableCard'
import VehicleProductCard from '../VehicleProductCard'
import { ProductCardType, ProductOption, RoadRatingSkeletonMap, propTypes } from './ProductGrid.types'
import SkeletonComponent from '../Skeleton/SkeletonComponent'
import { replaceStrWithDynamicVal, addDynamicToken } from '../../utils/replaceStrWithDynamicVal'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { getFormattedPriceValue } from '../ProductReusableCard/instanceCheckingUtil'
import { magicNumber } from '../../utils'
import { getAccessibilityId } from '../../utils/getAccessibilityId'
import { ProductSku } from '../VariantsWrapper/VariantsWrapper.type'
import { areAllParamsValid, isAtleastOneParamValid } from '../../utils/validParams'
import { getProductPerRowCount } from '../../helpers/getProductPerRowCount.helper'
import useRoadRatingsRef from './useRoadRatingsRef'
import { isArrayEmpty } from '../../utils/isArrayEmpty'
import { useIsMobile } from '../../hooks/useIsMobile.hook'

const productGridWrapperClassName = `${PREFIX}-product`
const roadRatingSkeletonClassName = 'with-road-rating-skeleton'

/**
 * ProductGridView component
 * @returns {JSX.Element} returns GridCard and Grid view components
 */
const GridView: React.FC<propTypes> = ({
    path,
    productProps,
    ProductCardData,
    productCardClick,
    redirectAfterProductCardClick,
    viewType,
    badgePriority,
    inImageBadgePriority,
    a11yStrikeOutPrice,
    a11yStrikeOutPriceRange,
    inclCoreChargesLbl,
    plusCoreChargesLbl,
    coreChargesTooltipTitle,
    coreChargesTooltipParagraph,
    a11yTooltipIcon,
    language,
    a11yCloseIconLabel,
    isProductCardLoading,
    skeletonItems,
    rebateIcon,
    isFitmentRequired,
    setOfFourLabel,
    perTireLabel,
    vehicleFrontLabel,
    vehicleRearLabel,
    roadRatedLabel,
    vehicleFitType,
    eachLabel,
    nowFromLabel,
    saveFromLabel,
    wasFromLabel,
    fromLabel,
    isAutoPartPlp,
    vehicleList = null,
    productWheelType,
    promotionalPriceLabel,
    unitPriceLabel,
    clearancePriceLabel,
    isSRPPage,
    vehicleInformationPresent,
    observeProducts,
    tireInformationPresent,
    scrollToFooter,
    isWheelOrTirePDP,
    partNumberLabel,
    inStockLabel,
    inStockOnlineLabel,
    outOfStockLabel,
    inStorePurchaseLabel,
    inStockAisleLabel,
    checkAvailabilityLabel,
    checkOtherStoresLabel,
    isUseLegacyLogicOfVehicleCheckAvailability,
    exclude,
    thresholdValue,
    overridePriceHeight,
    a11yReviewRating,
    productCardSpecifications,
    isUrgentLowStockLabel,
    isWheelOrTireFunc,
    isAutomotiveProduct,
    imageDataComponentName,
    returnPolicy,
    attributePositionLabel,
    suggestedQuantityLabel,
    attributeUsageLabel,
    isLazyRequire,
    saleEndDaySoonMessage,
    isColourSwatchesActive,
    saleMessageRules,
    saleEndDisableShift,
    a11yVariantSelected,
    a11yVariantUnSelected,
    a11yClickToReadFootnote,
    pageType,
    plusMinusSymbol,
    enableBoldedBrandName = false,
    enableMiniPdpFlyoutSupport,
    enableATCForSingleSkuMiniPdp,
    isTireDataExist,
    fitmentMessageOnProductLevel,
    fitmentIcon,
    fitmentMessageToggle,
    fitmentMessageToggleUniversal,
    fitMessageOnCard,
    partialFitMessageOnCard,
    vehicleString,
    addToCartBtnClick,
    showAtcBtnSpinner,
    clickedATCCardIdx,
    addToCartLabel,
    sponsoredLabel,
    confirmFitMessageUniversalOnCard,
    fitMessageUniversalOnCard,
    addProductItemToWishlist,
    wishlistItems,
    isWishlistToShow,
    showWishlistBtnSpinner,
    clickedWishlistIdx,
    a11yWishlistIcon,
    quickLookLabel,
    addProductItemToCompare,
    compareProductsList,
    enableComparisonOfProducts,
    enableComparisonTool,
    compareProductsLabel,
    isBrowseOnlyMode,
    isWestPriceLazyLoadEnabled,
    isPriceRangeViewEnabled = false,
}) => {
    const isMobile = useIsMobile()

    /**
     * renderProductCardSkeleton returns  react loading skeleton for ProductCart
     * @param { number } skeletonCount gives number of skeleton grid
     * @return {JSX.Element}
     */

    const renderProductCardSkeleton = (skeletonCount: number): JSX.Element => {
        return (
            <>
                {[...Array<unknown>(skeletonCount)].map((index: number) => (
                    <li className={`${PREFIX}-product__content`} key={index}>
                        <SkeletonComponent skeletonClass={`${PREFIX}-product-card__grid-card-skeleton`} />
                    </li>
                ))}
            </>
        )
    }

    /**
     * Function to check skus availability
     * @returns {boolean}
     */
    const checkSkusAvailability = (): boolean => {
        return Boolean(ProductCardData?.filter(item => item.skus?.length > magicNumber.ZERO).length)
    }

    /**
     * generateAccessibilityId. When skuId and skus[] is empty then we are using pcode for accessibilityId - auto without vehicle info.
     * @param { ProducSkus[] } skus
     * @param { skuId } string
     * @param { code } string
     * @return {string}
     */

    const generateAccessibilityId = (skus: ProductSku[], skuId: string, code: string): string => {
        if (skus?.[0]?.code) return getAccessibilityId(pageType, skus?.[0].code)
        else if (skuId) return getAccessibilityId(pageType, skuId)
        else return getAccessibilityId(pageType, code)
    }

    /**
     * Function returns true when we need to show spinner on ATC/Option btn
     * @param { number } idx index of product card
     * @returns {boolean} returns boolean
     */
    const showUpdatedATCSpinner = (idx: number): boolean => {
        return clickedATCCardIdx === idx ? showAtcBtnSpinner : false
    }

    /**
     * Function returns true when we need to show spinner on wishlist btn
     * @param { number } idx index of product card
     * @returns {boolean} returns true/false
     */
    const showUpdatedWishlistSpinner = (idx: number): boolean => {
        return clickedWishlistIdx === idx ? showWishlistBtnSpinner : false
    }

    /**
     * function returns sku code base on banners
     * @param {ProductSku[]} skus array of products
     * @param {string} code product code
     * @returns {string} returns skucode
     */
    const getProductSkuCode = (skus: ProductSku[], code: string): string => {
        return skus?.length === 1 ? skus?.[0]?.code : code.toUpperCase().replace('P', '')
    }

    /**
     * function to check single sku product
     * @param {ProductSku[]} skus array of products
     * @param {ProductOption[]} options array of product options
     * @returns {boolean} returns true/false
     */
    const isSingleSkuProduct = (skus: ProductSku[], options: ProductOption[]): boolean => {
        return skus?.length === 1 || (isArrayEmpty(skus) && isArrayEmpty(options))
    }

    /**
     * function to check product is wishlisted
     * @param {ProductSku[]} skus array of products
     * @param {string} code product code
     * @returns {boolean} returns true/false
     */
    const isWishlistActive = (skus: ProductSku[], code: string): boolean => {
        return wishlistItems?.products?.some(wishlist => wishlist.code === getProductSkuCode(skus, code))
    }

    /**
     * function to check product is checked for compare
     * @param {string} code product code
     * @returns {boolean} returns true/false
     */
    const isProductChecked = (code: string): boolean => {
        return compareProductsList?.some(compareProduct => compareProduct.code === code)
    }

    const productPerRowInGrid = getProductPerRowCount()

    const { updateRef, getMap } = useRoadRatingsRef()

    const roadRatingSkeletonProductIndexes = useMemo<RoadRatingSkeletonMap>(() => {
        if (isFitmentRequired && isArrayNotEmpty(ProductCardData)) {
            const enableSkeletonForIndexes = new Map() as RoadRatingSkeletonMap
            let isThereRoadRatingInRow = false

            let currentRowNumber = 1
            let skeletonHeight = 0
            const tempIndexes = new Set<number>()

            const roadRatingNodes = getMap()

            for (let i = 0; i < ProductCardData.length; i++) {
                enableSkeletonForIndexes.set(i, { height: 0 })

                if (ProductCardData[i]?.roadRating?.aggregateValue) {
                    isThereRoadRatingInRow = true
                    skeletonHeight = Math.max(roadRatingNodes.get(i)?.offsetHeight || 0, skeletonHeight)
                } else tempIndexes.add(i)
                // if last row or last tile in row
                if (i === productPerRowInGrid * currentRowNumber - 1 || i === ProductCardData.length - 1) {
                    if (isThereRoadRatingInRow) {
                        // eslint-disable-next-line no-loop-func
                        tempIndexes.forEach(index => enableSkeletonForIndexes.set(index, { height: skeletonHeight }))
                    }

                    tempIndexes.clear()
                    currentRowNumber++
                    isThereRoadRatingInRow = false
                    skeletonHeight = 0
                }
            }
            return enableSkeletonForIndexes
        } else return new Map() as RoadRatingSkeletonMap
    }, [ProductCardData, isFitmentRequired, productPerRowInGrid, getMap])

    return (
        <ul className={`${PREFIX}-product__${viewType}-view ${PREFIX}-product__${viewType}-items`}>
            <>
                {ProductCardData.map((value: ProductCardType, idx: number) => {
                    const singleSkuCurrentPrice = value.skus?.[magicNumber.ZERO]?.currentPrice
                    const singleSkuOriginalPrice = value.skus?.[magicNumber.ZERO]?.originalPrice
                    const isSingleSkuPricesAvailable = () =>
                        value.skus?.length === magicNumber.ONE &&
                        isAtleastOneParamValid(!!singleSkuCurrentPrice, !!singleSkuOriginalPrice)
                    const isNoOptionsProduct = areAllParamsValid(isWestPriceLazyLoadEnabled, !value.options?.length)
                    const singleSkuPrices = isAtleastOneParamValid(isSingleSkuPricesAvailable(), isNoOptionsProduct)
                        ? {
                              currentPrice: singleSkuCurrentPrice,
                              originalPrice: singleSkuOriginalPrice,
                          }
                        : {}

                    const {
                        type,
                        title,
                        code,
                        brand,
                        options,
                        rating,
                        ratingsCount,
                        timeTestedPrice,
                        priceShortMessage,
                        priceLongMessage,
                        longDescription,
                        packagePrice,
                        badges,
                        salePrice,
                        discount,
                        onlineOnly,
                        inStock,
                        coreCharge,
                        coreChargeIncluded,
                        images,
                        featureBullets,
                        skus,
                        originalPrice,
                        currentPrice,
                        displayWasLabel,
                        feeMessages,
                        priceMessage,
                        rebate,
                        categoryLevel,
                        roadRating,
                        specifications,
                        partNumber,
                        fitmentNotes,
                        fitmentTypeCode,
                        sellable,
                        orderable,
                        url,
                        isUrgentLowStock,
                        totalCurrentPrice,
                        skuId,
                        isOnSale,
                    } = { ...value, ...singleSkuPrices } as unknown as ProductCardType
                    const feeMessage = isArrayNotEmpty(feeMessages) ? feeMessages?.[0] : {}
                    const isWheelOrTire = isWheelOrTireFunc && isWheelOrTireFunc(value?.productWheelType)
                    const isAutomotiveProductFlag =
                        isAutomotiveProduct && isAutomotiveProduct(fitmentTypeCode, value.productWheelType)

                    const isWishlistItemActive = areAllParamsValid(isWishlistToShow, isWishlistActive(skus, code))

                    const isWishlistEligible = areAllParamsValid(isWishlistToShow, isSingleSkuProduct(skus, options))

                    const isCompareProductChecked = isProductChecked(code)

                    /** for tire and wheel fulfilment will be displayed from the sku level */
                    const fulfillment =
                        isWheelOrTire && vehicleInformationPresent
                            ? // eslint-disable-next-line sonar/no-nested-conditional
                              skus.length === magicNumber.TWO
                                ? null
                                : skus?.[0]?.fulfillment
                            : value?.fulfillment

                    const showCompareCheckbox = areAllParamsValid(
                        !isMobile,
                        value?.comparisonEnabled,
                        enableComparisonOfProducts,
                        enableComparisonTool,
                        !isBrowseOnlyMode,
                    )

                    return (
                        <Fragment key={`product-content-${code}`}>
                            {
                                <li
                                    id={code}
                                    ref={node => {
                                        observeProducts?.(node)
                                    }}
                                    key={code}
                                    className={`${PREFIX}-product__content`}
                                    data-testid="product-grids">
                                    {!isFitmentRequired ? (
                                        <GridCard
                                            product={value}
                                            productProps={{
                                                ...productProps,
                                                fulfillment,
                                                sellable,
                                                orderable,
                                            }}
                                            productCardClick={productCardClick}
                                            redirectAfterProductCardClick={redirectAfterProductCardClick}
                                            path={path}
                                            idx={idx}
                                            cardType={viewType}
                                            title={title}
                                            type={type}
                                            code={skuId}
                                            brand={brand}
                                            rating={rating}
                                            ratingsCount={ratingsCount}
                                            timeTestedPrice={timeTestedPrice}
                                            salePrice={salePrice}
                                            discount={discount}
                                            priceShortMessage={priceShortMessage}
                                            priceLongMessage={priceLongMessage}
                                            longDescription={longDescription}
                                            inStock={inStock}
                                            onlineOnly={onlineOnly}
                                            packagePrice={packagePrice}
                                            badgePriorities={badgePriority}
                                            inImageBadgePriorities={inImageBadgePriority}
                                            badges={badges}
                                            a11yStrikeOutPrice={a11yStrikeOutPrice}
                                            a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                                            plusCoreChargesLbl={plusCoreChargesLbl}
                                            inclCoreChargesLbl={inclCoreChargesLbl}
                                            coreCharge={coreCharge}
                                            coreChargeIncluded={coreChargeIncluded}
                                            coreChargesTooltipTitle={coreChargesTooltipTitle}
                                            coreChargesTooltipParagraph={coreChargesTooltipParagraph}
                                            a11yTooltipIcon={a11yTooltipIcon}
                                            images={images}
                                            featureBullets={featureBullets}
                                            feeTitle={
                                                feeMessage?.value
                                                    ? replaceStrWithDynamicVal(
                                                          addDynamicToken(feeMessage?.feeTitle, '$x'),
                                                          getFormattedPriceValue(language, feeMessage?.value),
                                                      )
                                                    : ''
                                            }
                                            feeDisclaimerTitle={feeMessage?.feeDisclaimerTitle}
                                            feeDisclaimerMessage={feeMessage?.feeDisclaimerMessage}
                                            options={options}
                                            skus={skus}
                                            originalPrice={originalPrice}
                                            currentPrice={currentPrice}
                                            displayWasLabel={displayWasLabel}
                                            language={language}
                                            a11yCloseIconLabel={a11yCloseIconLabel}
                                            priceMessage={priceMessage}
                                            rebateIcon={rebateIcon}
                                            rebate={rebate}
                                            scrollToFooter={scrollToFooter}
                                            nowFromLabel={nowFromLabel}
                                            saveFromLabel={saveFromLabel}
                                            wasFromLabel={wasFromLabel}
                                            fromLabel={fromLabel}
                                            hideDisclaimer={!isArrayNotEmpty(options)}
                                            feeDisclaimerType={feeMessage?.type}
                                            inStockLabel={inStockLabel}
                                            inStockOnlineLabel={inStockOnlineLabel}
                                            outOfStockLabel={outOfStockLabel}
                                            inStorePurchaseLabel={inStorePurchaseLabel}
                                            inStockAisleLabel={inStockAisleLabel}
                                            checkAvailabilityLabel={checkAvailabilityLabel}
                                            checkOtherStoresLabel={checkOtherStoresLabel}
                                            overridePriceHeight={overridePriceHeight}
                                            promotionalPriceLabel={promotionalPriceLabel}
                                            unitPriceLabel={unitPriceLabel}
                                            clearancePriceLabel={clearancePriceLabel}
                                            thresholdValue={thresholdValue}
                                            a11yReviewRating={a11yReviewRating}
                                            url={url}
                                            isUrgentLowStock={isUrgentLowStock}
                                            isUrgentLowStockLabel={isUrgentLowStockLabel}
                                            productWheelType={value.productWheelType}
                                            isWheelOrTirePDP={isWheelOrTire}
                                            eachLabel={eachLabel}
                                            imageDataComponentName={imageDataComponentName}
                                            returnPolicy={returnPolicy}
                                            isAutomotiveProduct={isAutomotiveProductFlag}
                                            isScrollingRequired={true}
                                            isLazyRequire={isLazyRequire}
                                            productDataId={code}
                                            saleEndDaySoonMessage={saleEndDaySoonMessage}
                                            isColourSwatchesActive={isColourSwatchesActive}
                                            saleMessagesRules={saleMessageRules}
                                            saleEndDisableShift={saleEndDisableShift}
                                            a11yVariantSelected={a11yVariantSelected}
                                            a11yVariantUnSelected={a11yVariantUnSelected}
                                            accessibilityId={generateAccessibilityId(skus, skuId, code)}
                                            plusMinusSymbol={plusMinusSymbol}
                                            boldBrand={enableBoldedBrandName}
                                            isOnSale={isOnSale}
                                            addToCartBtnClick={addToCartBtnClick}
                                            enableMiniPdpFlyoutSupport={enableMiniPdpFlyoutSupport}
                                            enableATCForSingleSkuMiniPdp={enableATCForSingleSkuMiniPdp}
                                            showAtcBtnSpinner={showUpdatedATCSpinner(idx)}
                                            addToCartLabel={addToCartLabel}
                                            sponsoredLabel={sponsoredLabel}
                                            addProductItemToWishlist={addProductItemToWishlist}
                                            isWishlistItemActive={isWishlistItemActive}
                                            isWishlistEligible={isWishlistEligible}
                                            isWishlistToShow={isWishlistToShow}
                                            showWishlistBtnSpinner={showUpdatedWishlistSpinner(idx)}
                                            a11yWishlistIcon={a11yWishlistIcon}
                                            quickLookLabel={quickLookLabel}
                                            addProductItemToCompare={addProductItemToCompare}
                                            isCompareProductChecked={isCompareProductChecked}
                                            compareProductsLabel={compareProductsLabel}
                                            isWestPriceLazyLoadEnabled={isWestPriceLazyLoadEnabled}
                                            isPriceRangeViewEnabled={isPriceRangeViewEnabled}
                                            showCompareCheckbox={showCompareCheckbox}
                                        />
                                    ) : (
                                        <VehicleProductCard
                                            className={classNames({
                                                [`${roadRatingSkeletonClassName}-${
                                                    roadRatingSkeletonProductIndexes.get(idx).height
                                                }`]: roadRatingSkeletonProductIndexes.has(idx),
                                            })}
                                            isPriceRangeViewEnabled={isPriceRangeViewEnabled}
                                            updateRoadRatingRef={updateRef(idx)}
                                            product={value}
                                            productProps={{
                                                ...productProps,
                                                fulfillment,
                                                sellable,
                                                orderable,
                                            }}
                                            idx={idx}
                                            productCardClick={productCardClick}
                                            redirectAfterProductCardClick={redirectAfterProductCardClick}
                                            cardType={viewType}
                                            title={title}
                                            code={code}
                                            brand={brand}
                                            rating={rating}
                                            images={images}
                                            ratingsCount={ratingsCount}
                                            showRatingSection={true}
                                            skus={skus}
                                            currentPrice={currentPrice}
                                            language={language}
                                            feeTitle={feeMessage?.feeTitle}
                                            rebateIcon={rebateIcon}
                                            rebate={rebate}
                                            tireCategory={categoryLevel}
                                            isFitmentRequired={isFitmentRequired}
                                            setOfFourLabel={setOfFourLabel}
                                            perTireLabel={perTireLabel}
                                            vehicleFrontLabel={vehicleFrontLabel}
                                            vehicleRearLabel={vehicleRearLabel}
                                            roadRatedLabel={roadRatedLabel}
                                            vehicleFitType={vehicleFitType}
                                            eachLabel={eachLabel}
                                            roadRating={roadRating}
                                            specification={specifications}
                                            badgePriorities={badgePriority}
                                            badges={badges}
                                            originalPrice={originalPrice}
                                            displayWasLabel={displayWasLabel}
                                            discount={discount}
                                            isAutoPartPlp={isAutoPartPlp}
                                            priceMessage={priceMessage}
                                            feeDisclaimerTitle={feeMessage?.feeDisclaimerTitle}
                                            feeDisclaimerMessage={feeMessage?.feeDisclaimerMessage}
                                            a11yStrikeOutPrice={a11yStrikeOutPrice}
                                            a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                                            a11yCloseIconLabel={a11yCloseIconLabel}
                                            a11yTooltipIcon={a11yTooltipIcon}
                                            fitmentTypeCode={fitmentTypeCode}
                                            vehicleList={vehicleList}
                                            options={options}
                                            partNumber={partNumber}
                                            fitmentNotes={fitmentNotes}
                                            productWheelType={productWheelType}
                                            feeValue={feeMessage?.value}
                                            nowFromLabel={nowFromLabel}
                                            saveFromLabel={saveFromLabel}
                                            wasFromLabel={wasFromLabel}
                                            fromLabel={fromLabel}
                                            promotionalPriceLabel={promotionalPriceLabel}
                                            unitPriceLabel={unitPriceLabel}
                                            clearancePriceLabel={clearancePriceLabel}
                                            feeDisclaimerType={feeMessage?.type}
                                            scrollToFooter={scrollToFooter}
                                            isSRPPage={isSRPPage}
                                            vehicleInformationPresent={vehicleInformationPresent}
                                            tireInformationPresent={tireInformationPresent}
                                            isWheelOrTirePDP={isWheelOrTirePDP}
                                            partNumberLabel={partNumberLabel}
                                            inStockLabel={inStockLabel}
                                            inStockOnlineLabel={inStockOnlineLabel}
                                            outOfStockLabel={outOfStockLabel}
                                            inStorePurchaseLabel={inStorePurchaseLabel}
                                            inStockAisleLabel={inStockAisleLabel}
                                            checkAvailabilityLabel={checkAvailabilityLabel}
                                            checkOtherStoresLabel={checkOtherStoresLabel}
                                            isUseLegacyLogicOfVehicleCheckAvailability={
                                                isUseLegacyLogicOfVehicleCheckAvailability
                                            }
                                            exclude={exclude}
                                            overridePriceHeight={overridePriceHeight}
                                            a11yReviewRating={a11yReviewRating}
                                            url={url}
                                            feeMessages={feeMessages}
                                            productCardSpecifications={productCardSpecifications}
                                            isUrgentLowStock={isUrgentLowStock}
                                            isUrgentLowStockLabel={isUrgentLowStockLabel}
                                            totalCurrentPrice={totalCurrentPrice}
                                            imageDataComponentName={imageDataComponentName}
                                            returnPolicy={returnPolicy}
                                            attributePositionLabel={attributePositionLabel}
                                            suggestedQuantityLabel={suggestedQuantityLabel}
                                            attributeUsageLabel={attributeUsageLabel}
                                            isScrollingRequired={true}
                                            isLazyRequire={isLazyRequire}
                                            productDataId={code}
                                            a11yClickToReadFootnote={a11yClickToReadFootnote}
                                            showAvailabilityMessage={checkSkusAvailability()}
                                            accessibilityId={generateAccessibilityId(skus, skuId, code)}
                                            plusMinusSymbol={plusMinusSymbol}
                                            boldBrand={enableBoldedBrandName}
                                            isTireDataExist={isTireDataExist}
                                            fitmentMessageOnProductLevel={fitmentMessageOnProductLevel}
                                            fitmentIcon={fitmentIcon}
                                            fitmentMessageToggle={fitmentMessageToggle}
                                            fitmentMessageToggleUniversal={fitmentMessageToggleUniversal}
                                            isOnSale={isOnSale}
                                            fitMessageOnCard={fitMessageOnCard}
                                            partialFitMessageOnCard={partialFitMessageOnCard}
                                            confirmFitMessageUniversalOnCard={confirmFitMessageUniversalOnCard}
                                            fitMessageUniversalOnCard={fitMessageUniversalOnCard}
                                            vehicleString={vehicleString}
                                            addToCartBtnClick={addToCartBtnClick}
                                            showAtcBtnSpinner={showUpdatedATCSpinner(idx)}
                                            sponsoredLabel={sponsoredLabel}
                                            addToCartLabel={addToCartLabel}
                                            addProductItemToWishlist={addProductItemToWishlist}
                                            isWishlistItemActive={isWishlistItemActive}
                                            isWishlistEligible={isWishlistEligible}
                                            isWishlistToShow={isWishlistToShow}
                                            showWishlistBtnSpinner={showUpdatedWishlistSpinner(idx)}
                                            a11yWishlistIcon={a11yWishlistIcon}
                                            addProductItemToCompare={addProductItemToCompare}
                                            isCompareProductChecked={isCompareProductChecked}
                                            compareProductsLabel={compareProductsLabel}
                                            showCompareCheckbox={showCompareCheckbox}
                                        />
                                    )}
                                </li>
                            }
                        </Fragment>
                    )
                })}
                {isProductCardLoading && renderProductCardSkeleton(skeletonItems)}
            </>
        </ul>
    )
}

GridView.propTypes = {
    path: PropTypes.string,
    productProps: PropTypes.any,
    ProductCardData: PropTypes.any,
    viewType: PropTypes.string,
    productCardClick: PropTypes.func,
    redirectAfterProductCardClick: PropTypes.func,
    badgePriority: PropTypes.any,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    plusCoreChargesLbl: PropTypes.string,
    inclCoreChargesLbl: PropTypes.string,
    coreChargesTooltipTitle: PropTypes.string,
    coreChargesTooltipParagraph: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    language: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    isProductCardLoading: PropTypes.bool,
    skeletonItems: PropTypes.number,
    rebateIcon: PropTypes.string,
    isFitmentRequired: PropTypes.bool,
    setOfFourLabel: PropTypes.string,
    perTireLabel: PropTypes.string,
    vehicleFrontLabel: PropTypes.string,
    vehicleRearLabel: PropTypes.string,
    roadRatedLabel: PropTypes.string,
    vehicleFitType: PropTypes.any,
    eachLabel: PropTypes.string,
    isAutoPartPlp: PropTypes.bool,
    vehicleList: PropTypes.array,
    productWheelType: PropTypes.string,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    unitPriceLabel: PropTypes.string,
    clearancePriceLabel: PropTypes.string,
    isSRPPage: PropTypes.bool,
    vehicleInformationPresent: PropTypes.bool,
    tireInformationPresent: PropTypes.bool,
    scrollToFooter: PropTypes.func,
    isWheelOrTirePDP: PropTypes.bool,
    isAutomotiveProduct: PropTypes.func,
    inStockLabel: PropTypes.string,
    inStockOnlineLabel: PropTypes.string,
    outOfStockLabel: PropTypes.string,
    inStorePurchaseLabel: PropTypes.string,
    inStockAisleLabel: PropTypes.string,
    checkAvailabilityLabel: PropTypes.string,
    checkOtherStoresLabel: PropTypes.string,
    isUseLegacyLogicOfVehicleCheckAvailability: PropTypes.bool,
    partNumberLabel: PropTypes.string,
    exclude: PropTypes.array,
    thresholdValue: PropTypes.number,
    overridePriceHeight: PropTypes.bool,
    a11yReviewRating: PropTypes.string,
    productCardSpecifications: PropTypes.string,
    isUrgentLowStockLabel: PropTypes.string,
    isWheelOrTireFunc: PropTypes.func,
    imageDataComponentName: PropTypes.string,
    returnPolicy: PropTypes.func,
    attributePositionLabel: PropTypes.string,
    suggestedQuantityLabel: PropTypes.string,
    attributeUsageLabel: PropTypes.string,
    isLazyRequire: PropTypes.bool,
    a11yClickToReadFootnote: PropTypes.string,
    pageType: PropTypes.string,
    addToCartBtnClick: PropTypes.func,
    showAtcBtnSpinner: PropTypes.bool,
    clickedATCCardIdx: PropTypes.number,
    addToCartLabel: PropTypes.string,
    sponsoredLabel: PropTypes.string,
}

GridView.displayName = 'GridView'

/**
 * ProductGridView component
 * @param {ProductGridViewProps} props
 * @returns {JSX.Element} returns GridView component
 */
const ProductGridView: React.FC<propTypes> = props => {
    return (
        <>
            <div className={`${PREFIX}-row ${PREFIX}-container`}>
                {/* for spacing purpose added style attribute */}

                <div className={productGridWrapperClassName}>
                    <GridView {...props} />
                </div>
            </div>
        </>
    )
}
ProductGridView.propTypes = {
    path: PropTypes.string,
    productProps: PropTypes.any,
    ProductCardData: PropTypes.any,
    viewType: PropTypes.string,
    productCardClick: PropTypes.func,
    redirectAfterProductCardClick: PropTypes.func,
    badgePriority: PropTypes.any,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    plusCoreChargesLbl: PropTypes.string,
    inclCoreChargesLbl: PropTypes.string,
    coreCharge: PropTypes.any,
    coreChargeIncluded: PropTypes.bool,
    coreChargesTooltipTitle: PropTypes.string,
    coreChargesTooltipParagraph: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    language: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    isProductCardLoading: PropTypes.bool,
    skeletonItems: PropTypes.number,
    rebateIcon: PropTypes.string,
    isFitmentRequired: PropTypes.bool,
    setOfFourLabel: PropTypes.string,
    perTireLabel: PropTypes.string,
    vehicleFrontLabel: PropTypes.string,
    vehicleRearLabel: PropTypes.string,
    roadRatedLabel: PropTypes.string,
    vehicleFitType: PropTypes.any,
    eachLabel: PropTypes.string,
    isAutoPartPlp: PropTypes.bool,
    fitmentTypeCode: PropTypes.any,
    vehicleList: PropTypes.array,
    productWheelType: PropTypes.string,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    unitPriceLabel: PropTypes.string,
    clearancePriceLabel: PropTypes.string,
    isSRPPage: PropTypes.bool,
    vehicleInformationPresent: PropTypes.bool,
    tireInformationPresent: PropTypes.bool,
    scrollToFooter: PropTypes.func,
    partNumberLabel: PropTypes.string,
    exclude: PropTypes.array,
    thresholdValue: PropTypes.number,
    overridePriceHeight: PropTypes.bool,
    a11yReviewRating: PropTypes.string,
    productCardSpecifications: PropTypes.string,
    isUrgentLowStockLabel: PropTypes.string,
    imageDataComponentName: PropTypes.string,
    returnPolicy: PropTypes.func,
    isLazyRequire: PropTypes.bool,
    saleEndDaySoonMessage: PropTypes.string,
    addToCartBtnClick: PropTypes.func,
    showAtcBtnSpinner: PropTypes.bool,
    clickedATCCardIdx: PropTypes.number,
    addToCartLabel: PropTypes.string,
}

ProductGridView.displayName = 'ProductGridView'

export default ProductGridView
