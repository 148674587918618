import { libUtils } from '@nl/lib'
import { BuyOnlineType } from '../../../BuyBox/FulfillmentOptions/BuyOnline.type'
import { MiniBuyBoxProps } from './MiniBuyBox.type'

/**
 * Get BuyOnline props
 * @param {MiniBuyBoxProps} props - MiniBuyBoxProps
 * @returns {Partial<BuyOnlineType>} - BuyOnlineType
 */
export const getBuyOnlineProps = (props: MiniBuyBoxProps): Partial<BuyOnlineType> => {
    const {
        selectStoreLabel,
        inStockLabel,
        outOfStockLabel,
        freeShippingEnabled,
        buyOnlineHeaderText,
        checkStoreAvailability,
        changeLabel,
        inStorePurchase,
        storePickUpIcon,
        availableLabel,
        unavailableLabel,
        availabilityLabel,
        shipToHomeLabel,
        freePickUpInStoreLabel,
        availableByLabel,
        timeLabelWhenPickUpAvailable,
        pickUpAtStoreLabel,
        getItByLabelff,
        getItTomorrowLabelff,
        getItTodayLabelff,
        checkOtherStoresLabel,
        eligibilityAndShippingInfoLabel,
        shipsByLabel,
        postalCodeForEligFeesLabel,
        deliveryLabel,
        deliveryFeeLabel,
        deliverToLabel,
        needMoreLabel,
        pickUpBetweenLabel,
        urgencyMessageLabel,
        isShowCurbsideAvailabilityLabelForProposedStores,
        isShowAvailableByLabel,
        needMoreItemsDescLabel,
        shipToHomeUnavailable,
        freeShippingOverLabel,
        shipsInLabel,
        availabilitySelectVariantMsg,
        availabilitySelectVariantGenericMsg,
        pickupAvailabilitySelectVariantMsg,
        sthAvailabilitySelectVariantMsg,
        outOfStockTitle,
        outOfStockText,
        hidingAllFirstVariantOOSMessage,
        estimateStandardShippingFeesTooltipHeaderText,
        estimateStandardShippingFeesTooltipBodyText,
        delFeesToolTipHeaderText,
        delFeesToolTipBodyText,
        sameDayDeliverySLETooltipBodyText,
        onlineOrdersNotAcceptedBuyBoxMsg,
        fullyDiscontinuedTitle,
        regularShippingThreshold,
        fulfillmentTooltip,
        shippingFeeCalculation,
        triangleShippingThreshold,
        outOfStockAtStoreff,
        freePickUpUnavailableAtSelectedStore,
        freePickUpUnavailable,
        expressDeliveryUnavailable,
        expressDeliveryUnavailableOOS,
        a11yEstimateFeesTooltipIcon,
        isShippingPostalCodeHide,
        shipsBetween,
        onlyAvailableOnlineAtLabel,
        availableOnlinePurchaseLabel,
        estimatedShippingFeeText,
        selectNearbyStoreLabel,
        estimateExpressShippingFeesTooltipHeaderText,
        estimateExpressShippingFeesTooltipBodyText,
        shippingFeeText,
        etaLearnMoreMessageLinkLabel,
        etaPickUpAdditionalMessageText,
        etaPickUpMessageHeaderText,
        bopisBlockOutOfStockLabel,
        shipToYourStoreBetweenLabel,
        onlineOrdersNotAcceptedMsg,
        notEligibleStandardDeliveryLabel,
        freeStandardDeliveryWithPriceLimit,
        freeStandardDeliveryLabel,
        a11yStoreInfoTooltip,
        onlineOnlyPurchaseLabel,
        inStoreAvailabilityLabel,
        availableBetweenLabel,
        checkNearByCTALabel,
        preferredStoreText,
        checkAllStoreAvailabilityLabel,
        visitNearbyStoreLabel,
        shippingToLabel,
        freePickUpInStoreOverLabel,
        thresholdTooltipHeaderText,
        thresholdTooltipDescription,
        thresholdTooltipThresholdConditionText,
        a11yThresholdAndHandlingFeeTooltipIcon,
        getItBetweenLabel,
        selectNearbyStorePickUpLabel,
        selectNearbyStoreShipLabel,
        postalCodeTitle,
        postalCodeUpdateCTA,
        postalCodeLabel,
        outOfStockVariantMsg,
        outOfStockVariantGenericMsg,
        curbsidePickupAvailableLabel,
        outOfStockShipToStoreBetweenLabel,
        storeChangeNotificationLabel,
        discontinuedInStoreMsg,
        sthNotEligibleText,
        bopisNotEligibleText,
        chooseAnotherStoreLabel,
        visitPreferredStoreLabel,
        nearbyStoresNotAcceptOrdersLabel,
        heavyOversizeItemText,
        heavyOversizeToolTipIcon,
        heavyOversizeHeader,
        heavyOversizeBody,
        estimateFeesText,
        estimatedFeesText,
        estimateFeesDisclaimer,
        assemblyDescription,
        assemblyTitleLabel,
        definitionExpressDeliveryTooltipHeaderText,
        definitionExpressDeliveryTooltipBodyText,
        expressDeliveryIsAvailableLabel,
        preferredShippingOptionLabel,
        notEligibleExpressDeliveryLabel,
        shipToHomeFree,
        shipToHomeFreeOverRegular,
        shipToHomeFreeOverTriangle,
        shipToHomeEligible,
        shipToHomeNotFree,
        endlessAisleShippingLabel,
        outOfStockAtStoreLabel,
        prodOOSErrMsg,
        isATCErrorHandling,
        enablePromoBadgesForBoxesModel,
    } = props

    return {
        buyOnlineHeaderText,
        availableLabel,
        unavailableLabel,
        availabilityLabel,
        shipToHomeLabel,
        freePickUpInStoreLabel,
        availableByLabel,
        timeLabelWhenPickUpAvailable,
        pickUpAtStoreLabel: libUtils.getStringOrDefault(pickUpAtStoreLabel),
        getItByLabelff: libUtils.getStringOrDefault(getItByLabelff),
        getItTomorrowLabelff: libUtils.getStringOrDefault(getItTomorrowLabelff),
        getItTodayLabelff: libUtils.getStringOrDefault(getItTodayLabelff),
        selectStoreLabel,
        inStockLabel,
        checkOtherStoresLabel,
        outOfStockLabel,
        eligibilityAndShippingInfoLabel,
        changeLabel,
        shipsByLabel,
        postalCodeForEligFeesLabel,
        deliveryLabel,
        deliveryFeeLabel,
        deliverToLabel,
        needMoreLabel,
        pickUpBetweenLabel,
        isShippingPostalCodeHide: Boolean(isShippingPostalCodeHide),
        urgencyMessageLabel,
        isShowCurbsideAvailabilityLabelForProposedStores: Boolean(isShowCurbsideAvailabilityLabelForProposedStores),
        isShowAvailableByLabel: Boolean(isShowAvailableByLabel),
        needMoreItemsDescLabel,
        shipToHomeUnavailable,
        freeShippingEnabled,
        freeShippingOverLabel,
        shipsInLabel,
        availabilitySelectVariantMsg,
        availabilitySelectVariantGenericMsg,
        pickupAvailabilitySelectVariantMsg,
        sthAvailabilitySelectVariantMsg,
        outOfStockTitle,
        outOfStockText,
        hidingAllFirstVariantOOSMessage,
        estimateStandardShippingFeesTooltipHeaderText,
        estimateStandardShippingFeesTooltipBodyText,
        delFeesToolTipHeaderText,
        delFeesToolTipBodyText,
        sameDayDeliverySLETooltipBodyText,
        onlineOrdersNotAcceptedBuyBoxMsg,
        fullyDiscontinuedTitle,
        regularShippingThreshold,
        fulfillmentTooltip,
        shippingFeeCalculation,
        triangleShippingThreshold,
        outOfStockAtStoreff,
        freePickUpUnavailableAtSelectedStore,
        freePickUpUnavailable,
        checkStoreAvailability,
        inStorePurchase,
        expressDeliveryUnavailable,
        expressDeliveryUnavailableOOS,
        a11yEstimateFeesTooltipIcon,
        storePickUpIcon,
        shipsBetween,
        onlyAvailableOnlineAtLabel,
        availableOnlinePurchaseLabel,
        estimatedShippingFeeText,
        selectNearbyStoreLabel,
        estimateExpressShippingFeesTooltipHeaderText,
        estimateExpressShippingFeesTooltipBodyText,
        shippingFeeText,
        etaLearnMoreMessageLinkLabel,
        etaPickUpAdditionalMessageText,
        etaPickUpMessageHeaderText,
        bopisBlockOutOfStockLabel,
        shipToYourStoreBetweenLabel,
        onlineOrdersNotAcceptedMsg,
        notEligibleStandardDeliveryLabel,
        freeStandardDeliveryWithPriceLimit,
        freeStandardDeliveryLabel,
        a11yStoreInfoTooltip,
        onlineOnlyPurchaseLabel,
        inStoreAvailabilityLabel,
        availableBetweenLabel,
        checkNearByCTALabel,
        preferredStoreText,
        checkAllStoreAvailabilityLabel,
        visitNearbyStoreLabel,
        shippingToLabel,
        freePickUpInStoreOverLabel,
        thresholdTooltipHeaderText,
        thresholdTooltipDescription,
        thresholdTooltipThresholdConditionText,
        a11yThresholdAndHandlingFeeTooltipIcon,
        getItBetweenLabel,
        selectNearbyStorePickUpLabel,
        selectNearbyStoreShipLabel,
        postalCodeTitle,
        postalCodeUpdateCTA,
        postalCodeLabel,
        outOfStockVariantMsg,
        outOfStockVariantGenericMsg,
        curbsidePickupAvailableLabel,
        outOfStockShipToStoreBetweenLabel,
        storeChangeNotificationLabel,
        discontinuedInStoreMsg,
        sthNotEligibleText,
        bopisNotEligibleText,
        chooseAnotherStoreLabel,
        visitPreferredStoreLabel,
        nearbyStoresNotAcceptOrdersLabel,
        heavyOversizeItemText,
        heavyOversizeToolTipIcon,
        heavyOversizeHeader,
        heavyOversizeBody,
        estimateFeesText,
        estimatedFeesText,
        estimateFeesDisclaimer,
        assemblyDescription,
        assemblyTitleLabel,
        definitionExpressDeliveryTooltipHeaderText,
        definitionExpressDeliveryTooltipBodyText,
        expressDeliveryIsAvailableLabel,
        preferredShippingOptionLabel,
        notEligibleExpressDeliveryLabel,
        shipToHomeFree,
        shipToHomeFreeOverRegular,
        shipToHomeFreeOverTriangle,
        shipToHomeEligible,
        shipToHomeNotFree,
        endlessAisleShippingLabel,
        outOfStockAtStoreLabel,
        prodOOSErrMsg,
        isATCErrorHandling,
        enablePromoBadgesForBoxesModel,
    }
}
