import React from 'react'

import { PREFIX } from '../config'
import { StyleCodesProps } from './product.types'

/**
 * Style Code component
 * @param {StyleCodesProps} props - styleCode
 * @return {JSX.Element} returns Style Code component
 */
const StyleCodes: React.FC<StyleCodesProps> = ({ styleCode, key, index }): JSX.Element => {
    return (
        <span className={`${PREFIX}-product__sku`} key={`style code ${key}`}>
            {`${index ? ', ' : ' '}#${styleCode}`}
        </span>
    )
}

export default StyleCodes
