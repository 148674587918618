import { formatPlaceholder } from '../globalConstants/global.constant'

/**
 * Formats input string according to specified pattern.
 *
 * Function replaces each placeholder symbol in format string
 * by corresponding characters from the input string.
 * @param {string} input - input string to be formatted.
 * @param {string} pattern - format string that contains placeholders
 * @returns {string} formatted input
 */
export function formatStringWithPattern(input: string, pattern: string): string {
    let inputIndex = 0
    const regexPattern = new RegExp(`\\${formatPlaceholder}`, 'g')
    const placeholderCount = (pattern.match(regexPattern) || []).length

    if (!pattern.length || !input.length || placeholderCount !== input.length) {
        return input
    }

    return pattern.replace(regexPattern, () => {
        const { [inputIndex]: char } = input
        inputIndex++
        return char
    })
}
