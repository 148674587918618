import { BrazeCardType, ContentCardType } from '@nl/lib'

/**
 * Braze Init service
 */
class BrazeService {
    /**
     * Initialize Braze Web SDK
     * @param {string} apiKey braze api key
     * @param {string} sdkEndpointUrl braze sdk endpoint url
     * @returns {void}
     */
    init = (apiKey: string, sdkEndpointUrl: string): void => {
        if (window?.braze) {
            window.braze.initialize(apiKey, {
                baseUrl: sdkEndpointUrl,
                allowUserSuppliedJavascript: true, // enable Custom Message Integration
                minimumIntervalBetweenTriggerActionsInSeconds: 20,
            })
        }
    }

    /**
     * get current user Id
     * @returns {string} returns user id
     */
    getUserId = (): string => {
        if (window?.braze) {
            window.braze?.getUser()?.getUserId((userId: string) => {
                return userId
            })
        }
        return ''
    }

    /**
     * destroy braze event
     * @returns {void}
     */
    destroy = (): void => {
        if (window?.braze && window?.braze?.isInitialized()) {
            window.braze?.destroy()
        }
    }

    /**
     * log custom event
     * @param {string} eventName custom event name
     * @param {Record<string, unknown>} eventProperties custom event properties
     * @returns {void}
     */
    logCustomEvent = (eventName: string, eventProperties?: Record<string, unknown>): void => {
        if (window?.braze && window?.braze?.isInitialized()) {
            window.braze?.logCustomEvent(eventName, eventProperties)
        }
    }

    /**
     * Subscribe to Braze Custom Event
     * @param {ContentCardType} data custom event name
     * @returns {void}
     */
    subscribeToContentCardsUpdates = (data: ContentCardType): void => {
        if (window?.braze && window?.braze?.isInitialized()) {
            window.braze?.subscribeToContentCardsUpdates(data)
        }
    }

    /**
     * Braze custom card content refresh event
     * @returns {void}
     */
    requestContentCardsRefresh = (): void => {
        if (window?.braze && window?.braze?.isInitialized()) {
            window.braze?.requestContentCardsRefresh()
        }
    }

    /**
     * Track custom content card impressions event
     * @param {BrazeCardType[]} contentCards
     * @returns {void} trigger card impression event
     */
    logContentCardImpressions = (contentCards: BrazeCardType[]): void => {
        if (window?.braze && window?.braze?.isInitialized()) {
            window.braze?.logContentCardImpressions(contentCards)
        }
    }

    /**
     * Track custom content card click event
     * @param {BrazeCardType} card
     * @returns {void} trigger card click event
     */
    logContentCardClick = (card: BrazeCardType): void => {
        if (window?.braze && window?.braze?.isInitialized()) {
            window.braze?.logContentCardClick(card)
        }
    }
}

// Creating a singleton instance of service
const brazeService = new BrazeService()

export { brazeService }
export default brazeService
