import ReactCMSComp from 'react-cms-components/index'
import { renderDynamicReactComponent } from '../helpers/cms.helper'
import { ComponentType } from '../helpers/cms.helper.type'
import { Component, InitReactComponentEventType, InitReactComponentDetailType } from '../global.type'
import { AEM_CUSTOM_EVENT } from '../globalConstants'

const targetedComponents = document.querySelectorAll('.target')
let targetHTML: HTMLElement

/**
 * Reload targeted functions on event listener via AEM
 * @returns { void }
 */
const targetingHandler = (): void => {
    const componentArray = ReactCMSComp.return({
        componentList: [
            ...(targetHTML.querySelectorAll('[data-component-prioritized]') as unknown as Array<Element>),
            ...(targetHTML.querySelectorAll(
                '[data-component]:not([data-component-prioritized])',
            ) as unknown as Array<Element>),
        ] as unknown as NodeListOf<Element>,
    })

    if (componentArray.length > 0) {
        componentArray.forEach((component: Component) => {
            renderDynamicReactComponent(component as ComponentType)
        })
    }
}

/**
 * Targeting Function to watch AEM_CUSTOM_EVENT via AEM
 * @returns { void }
 */
const fetchTargetedComponents = (): void => {
    document.addEventListener('DOMContentLoaded', targetingObserver)
    Array.from(targetedComponents).forEach((val: Element) => {
        const reactEl = val.querySelector('.reactbasecomponent')
        if (reactEl) {
            val.addEventListener(AEM_CUSTOM_EVENT, ((e: CustomEvent) => fetchTargetedElement(e, val)) as EventListener)
        }
    })
}

/**
 * Targeting Function to watch AEM_CUSTOM_EVENT via AEM
 * @param { CustomEvent } e - event values from custom event
 * @param { Element } el - Element value on where to listen to for custom events
 * @returns { void }
 */
const fetchTargetedElement = (e: CustomEvent, el: Element): void => {
    const { detail } = e as CustomEvent<InitReactComponentEventType>
    const { targetedElement } = detail as InitReactComponentDetailType
    targetHTML = targetedElement as HTMLElement

    if (e.type === AEM_CUSTOM_EVENT && targetedElement) {
        el.removeEventListener(AEM_CUSTOM_EVENT, ((evt: CustomEvent) => fetchTargetedElement(evt, el)) as EventListener)
    }
}

/**
 * Observer Function to watch targeting visibility
 * @returns { void }
 */
const targetingObserver = (): void => {
    const mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
        mutations.forEach((mutation: MutationRecord) => {
            const { target, attributeName } = mutation
            if (attributeName === 'style') {
                const { style, firstElementChild } = target as HTMLElement
                const isReactEl = firstElementChild?.classList.contains('reactbasecomponent')
                if (style.visibility) {
                    isReactEl && targetingHandler()
                    mutationObserver.disconnect()
                }
            }
        })
    })

    targetedComponents.forEach(ele => {
        mutationObserver.observe(ele, {
            attributes: true,
            childList: true,
            characterData: false,
            subtree: true,
            attributeFilter: ['style'],
        })
    })
}

export default fetchTargetedComponents
