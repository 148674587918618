/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { FlyoutModalComponent, Radio, Tag, isArrayEmpty, isArrayNotEmpty, isPrerenderOrNot, libUtils } from '@nl/lib'
import { miniPDPFlyoutDetailsSelector } from '../../../../redux/selectors/miniPDPFlyoutData.selectors'
import { closeMiniPDPFlyoutAction, setPreferredStoreDetailsAction } from '../../../../redux/actionCreators'
import { FlyoutHeader, ImageGallery, ProductTitle } from '../.'
import { MiniBuyBoxProps } from './MiniBuyBox.type'
import ATCQuantityWishlist from '../ATCQuantityWishlist/ATCQuantityWishlist'
import PriceComponent from '../../../BuyBox/PriceComponent/PriceComponent'
import BadgesWrapper from '../../../BuyBox/Badges/BadgesWrapper'
import Variants from '../../../BuyBox/Variants/Variants'
import PromoMessagesComponent from '../../../BuyBox/PromoMessagesComponent/PromoMessagesComponent'
import {
    getAvailableVariants,
    getSelectedDetailsFromSKU,
    isBopis,
    isExpressDeliveryAvailable,
    productNotForSth,
} from '../../../BuyBox/BuyBox.helper'
import { commonContentAvailableSelector } from '../../../../redux/selectors/commonContent.selectors'
import {
    BadgePriority,
    IAccessibility,
    IFeatureFlag,
    IGeneral,
    IStoreLocator,
    IProduct,
    IBreadcrumb,
} from '../../../../redux/models/commonContent.interface'
import { miniPDPFlyout } from './MiniBuyBox.constant'
import { MagicNumber } from '../../../../analytics/analytics.type'
import { checkDataLength } from '../../../Accounts/Addresses/checkDataLength'
import { enableDestructOnUndefinedData } from '../../../../utils/PDP/enableDestructOnUndefinedData.utils'

import { PREFIX } from '../../../../config'
import { areAllParamsValid } from '../../../../utils/getFilteredCartItems'
import {
    FulfillmentInfo,
    ImageGalleryInfo,
    PriceInfo,
    ProductTitleInfo,
    VariantsInfo,
} from '../../../../redux/models/miniPDPFlyout.interface'
import { useStoreData, useTrackingDataChange, useVariantsHandlers } from '../../hooks'
import {
    bopisAvailablilitySelector,
    bopisEligibilitySelector,
    storeDetailsDataSelector,
} from '../../../../redux/selectors/storeDetails.selectors'
import {
    Inventory,
    ItemAvailability,
    NearByOptions,
    StoreWithAvailability,
} from '../../../../redux/models/storeDetails.interface'
import appCacheService from '../../../../utils/appCacheService'
import {
    fetchNearbyStoreList,
    setIsNearByStoreListApiDone,
    setIsOOSCurrentStore,
    setIsOutOfStock,
    setSelectedPreferredStoreId,
    setSelectedStoreName,
} from '../../../../redux/actions'
import BuyOnline from '../../../BuyBox/FulfillmentOptions/BuyOnline'
import { getBuyOnlineProps } from './MiniBuyBox.helper'
import { dispatchToast } from '../../../ToastMessage/ToastMessage.helper'
import { ToastComponentNames } from '../../../../redux/models/toastMessage.interface'
import { availabilityBulletInStock } from '../../../BuyBox/FulfillmentOptions/FulfillmentOptions.constant'
import { rendersStoreSelectorFlyoutComp } from '../../../BuyBox/FulfillmentOptions/BuyOnline.helper'
import {
    BuyOnlineType,
    FindInStoreType,
    StoreFlyOutModalComponent,
} from '../../../BuyBox/FulfillmentOptions/BuyOnline.type'
import { SIZECHART_CROSS_BRAND } from '../../../BuyBox/BuyBox.constant'
import OOSAtAllStoreMessage from '../OOSAtAllStoreMessage/OOSAtAllStoreMessage'
import { updateScuDataByStore } from '../../../../redux/actions/miniPDPFlyout.action'
import FindInStore from '../../../BuyBox/FulfillmentOptions/FindInStore'
import { PromoRestrictedBrands, SellableCodes } from '../../../BuyBox/BuyBox.type'
import { isOOSCurrentStoreSelector } from '../../../../redux/selectors/product.selectors'
import SizeChart from '../../../BuyBox/SizeChart'
import { SizeChartDataType } from '../../../BuyBox/SizeChart/SizeChart.type'
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux.hook'

/**
 * MiniBuyBox component
 * @param {MiniBuyBoxProps} props - props
 * @returns {JSX.Element} - MiniBuyBox component
 */
export const MiniBuyBox: React.FC<MiniBuyBoxProps> = (props: MiniBuyBoxProps): JSX.Element => {
    const {
        miniPdpTitle = 'Quick view',
        closeIconLabel = 'Close mini PDP flyout',
        variantWarningLabel,
        availableOnlinePurchaseLabel,
        onlyAvailableOnlineAtLabel,
        a11yVariantsSelectedCount,
        outOfStockLabel,
        inStockLabel,
        goToPdpButtonLabel,
        variantNotSelectedMessage,
        isProductLevelSellability,
        freePickUpInStoreLabel,
        freeShippingEnabled,
        minQuantity,
        isSeparatedErrorHandling,
        inStorePurchaseOnly,
        enableBOPISStore,
        enableFulfilmentSelection,
        promoEnabled,
        promoRestrictedBrands,
        alsoFindItInStoreHeader,
        checkOtherStoresLabel,
        inStockAtStore,
        outOfStockAtStoreff,
        a11yStoreInfoTooltip,
        ltdStockUrgencyMsg,
        isShippingPostalCodeHide,
        fulfillmentBoxesOrder,
    } = props

    const dispatch = useAppDispatch()
    const commonContentAvailable = useAppSelector(commonContentAvailableSelector)
    const {
        product: commonContentProduct = {} as IProduct,
        featureFlag = {} as IFeatureFlag,
        accessibility = {} as IAccessibility,
        general = {} as IGeneral,
        storeLocator = {} as IStoreLocator,
        badges: commonContentBadges = {} as BadgePriority,
        breadcrumb = {} as IBreadcrumb,
    } = commonContentAvailable
    const {
        saleEndDaySoonMessage,
        maxPurchasableQuantity: bannerMaxPurchasableQuantity,
        howToMeasureLabel,
    } = commonContentProduct
    const {
        disableAdaptingTimeZoneForSaleEndDate,
        enableLightPdpLoading,
        enableShowInStockFilter,
        disableSelectAStoreButton,
        supportOfCustomDisplayOrderForProductVariantsEnabled: enableCustomOrder,
    } = featureFlag
    const { maxRadiusToSearchStore, maxNumberOfStores, storeSuccessfullySelectedMsg, storeChangeToastTimeOut } =
        storeLocator

    const { a11yVariantSelected, a11yVariantUnSelected, a11yTooltipIcon } = accessibility
    const { viewLessLabel, viewMoreLabel } = general
    const {
        isFlyoutVisible,
        productDataList,
        currentCode,
        initColourVariant = '',
    } = useAppSelector(miniPDPFlyoutDetailsSelector)
    const bopisEligibility = useAppSelector(bopisEligibilitySelector) ?? true

    const {
        preferredStoreDetails,
        selectedStoreName,
        selectedPreferredStoreId,
        nearByStoreListForSelectedSku,
        nearByStoreListForPostalCode,
        isNearbyStoreListApiDone,
        isNearbyStoreListSuccess,
    } = useAppSelector(storeDetailsDataSelector)
    const { onlineOrdering, geoPoint } = preferredStoreDetails
    const {
        currentStoreId,
        productTitleInfo = {} as ProductTitleInfo,
        imageGalleryInfo = {} as ImageGalleryInfo,
        priceInfo = {} as PriceInfo,
        variantsInfo = {} as VariantsInfo,
        fulfillmentInfo = {} as FulfillmentInfo,
    } = enableDestructOnUndefinedData(productDataList[currentCode])

    const { productDataBySku, hideDisclaimer, isCurrentPriceAvailable } = priceInfo
    const { badges = [] } = enableDestructOnUndefinedData(productDataBySku)
    const {
        productData,
        productSkuData,
        variantDetails,
        isProductLevel,
        selectedFirstVariant,
        selectedSecondVariant,
        selectedThirdVariant,
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
        selectedProductCode,
        saleEndDate,
        isAllVariantsSelected,
        isAllSkusOOS,
        variantDetailsOrder,
        selectedFirstVariantDisplayName,
        isSkuInStoreUpdatedBySelectedSku,
        sizeChartData,
    } = variantsInfo
    const {
        fulfillment,
        isSTHOnly,
        availability,
        isOnlineOnly,
        selectedStoreQty,
        inStockInSelectedStore,
        selectedVariantSkuData,
        isProductNotForSth,
        isOutOfStockAtStoreAndDC,
        isProductDiscontinued,
        isProductDiscontinuedEOL,
        isProductSellable,
        storePickUp,
        hasOnlyBOPISEnabled,
        isExpressDeliveryEnabledForProduct,
    } = fulfillmentInfo
    const { messageCode } = productData || {}
    const { storeShelfLocation: selectedStoreShelfLocation } = availability || {}
    const isBopisAvailableAtStore = useAppSelector(bopisAvailablilitySelector) ?? true
    const isOOSCurrentStore = useAppSelector(isOOSCurrentStoreSelector)
    const buyOnlineProps = useMemo(() => getBuyOnlineProps(props), [props])
    const bopisUnavailable = useMemo(
        () => !(!freeShippingEnabled || (isBopisAvailableAtStore && storePickUp?.enabled)),
        [freeShippingEnabled, isBopisAvailableAtStore, storePickUp],
    )
    const hasZeroSelectedStoreQuantityWithFreeShippingEnabled = useMemo(
        () => selectedStoreQty === MagicNumber.ZERO && !!freeShippingEnabled,
        [selectedStoreQty, freeShippingEnabled],
    )

    const [isVariantSelected, setIsVariantSelected] = useState(false)
    const [isAddToCartClicked, setIsAddToCartClicked] = useState(false)
    const [isAddToWishlistClicked, setIsAddToWishlistClicked] = useState(false)
    const [showErrorMsgForVariantSelection, setShowErrorMsgForVariantSelection] = useState(false)
    const [isNoSkuFindStoreClicked, setIsNoSkuFindStoreClicked] = useState(false)
    const [nearByStoresfetched, setNearByStoresfetched] = useState(false)
    const [isFirstVariantClicked, setIsFirstVariantClicked] = useState(false)
    const [isExpressDelivery, setIsExpressDelivery] = useState(false)
    const [selectedFulfillment, setSelectedFulfillment] = useState('')
    const [qtySelectorInput, setQtySelectorInput] = useState(minQuantity)
    const [qtySelectorErrorMsg, setQtySelectorErrorMsg] = useState('')
    const [isOutOfStockInAllStore, setIsOutOfStockInAllStore] = useState(false)
    const [isShipToHome, setIsShipToHome] = useState(() => (isPrerenderOrNot() ? false : !!freeShippingEnabled))
    const [isFreePickUp, setIsFreePickUp] = useState(() => isPrerenderOrNot() || !freeShippingEnabled)
    const [postalCodeVal, setPostalCodeVal] = useState(appCacheService.postalCode.get())
    const [isFulfillmentUpdated, setIsFulfillmentUpdated] = useState(false)
    const [isNearByStoresAvailable, setIsNearByStoresAvailable] = useState(true)
    const [shouldStoreFlyoutOpen, setStoreFlyoutShouldOpen] = useState<boolean>(false)
    const [showSizeChart, setShowSizeChart] = useState(false)

    const isNearbyStoresCalledRef = useRef(false)
    const preferredStoreId = appCacheService.preferredStoreId.get()

    const isAddToCartOrWishlistClicked = isAddToCartClicked || isAddToWishlistClicked

    const {
        setSelectedCode,
        setSelectedFirstVariantValue,
        setSelectedSecondVariantValue,
        setSelectedThirdVariantValue,
        setSelectedFirstVariantIdValue,
        setSelectedSecondVariantIdValue,
        setSelectedThirdVariantIdValue,
    } = useVariantsHandlers(enableDestructOnUndefinedData(variantsInfo), setShowErrorMsgForVariantSelection)
    const { isDataChanged, updatePrevCurrentValue } = useTrackingDataChange(
        productData?.code,
        selectedPreferredStoreId,
        geoPoint,
        maxRadiusToSearchStore,
        selectedProductCode,
    )
    const getStoreInventoryQtyFromSKU = useCallback(
        (store: StoreWithAvailability): number => {
            const storeProductData: Inventory[] = store?.inventory?.filter(p => p.sku === selectedProductCode) || []
            return storeProductData.length ? storeProductData[0].Quantity : 0
        },
        [selectedProductCode],
    )

    const inStockAtNearbyStores = useCallback((): boolean => {
        const storesList = isShipToHome ? nearByStoreListForPostalCode : nearByStoreListForSelectedSku
        // eslint-disable-next-line sonar/function-return-type
        const filteredStoresList = storesList?.filter((store: StoreWithAvailability) => {
            const qtyAvailable = getStoreInventoryQtyFromSKU(store)
            return String(store.id) !== selectedPreferredStoreId && qtyAvailable
        })
        return isArrayNotEmpty(filteredStoresList) && !isSTHOnly
    }, [
        nearByStoreListForSelectedSku,
        nearByStoreListForPostalCode,
        isShipToHome,
        getStoreInventoryQtyFromSKU,
        selectedPreferredStoreId,
        isSTHOnly,
    ])

    const {
        isStoreSelected,
        maxQuantityAvailable,
        inStockAtDC,
        quantityExceeded,
        availableForATC,
        limitMsgValue,
        nearByStoreLabel,
    } = useStoreData(
        props,
        fulfillmentInfo,
        variantsInfo,
        inStockAtNearbyStores(),
        isShipToHome,
        bannerMaxPurchasableQuantity,
        setQtySelectorErrorMsg,
        qtySelectorErrorMsg,
        hasOnlyBOPISEnabled,
        qtySelectorInput,
    )

    const getSkuCode = useCallback((): string => {
        return !isVariantSelected ? productData?.code : selectedProductCode
    }, [isVariantSelected, productData?.code, selectedProductCode])

    const filteredStoreList = nearByStoreListForSelectedSku?.filter((store: StoreWithAvailability) => {
        return getStoreInventoryQtyFromSKU(store)
    })
    const nearbyStoresList = filteredStoreList?.slice(MagicNumber.ZERO, MagicNumber.THREE)
    const isNearbyStoresListEmpty = isArrayEmpty(nearbyStoresList)

    useEffect(() => {
        if (isArrayNotEmpty(productSkuData?.skus) && !isProductLevel) {
            const outOfStockInAllStore = !inStockInSelectedStore && !inStockAtDC && isNearbyStoresListEmpty
            const OOSCurrentStore = (!inStockInSelectedStore && !inStockAtDC) || !isCurrentPriceAvailable
            dispatch(setIsOutOfStock(outOfStockInAllStore))
            // used for certona to check out of stock in current store
            dispatch(setIsOOSCurrentStore(OOSCurrentStore))
            setIsOutOfStockInAllStore(outOfStockInAllStore)
        } else {
            dispatch(setIsOutOfStock(false))
            dispatch(setIsOOSCurrentStore(false))
            setIsOutOfStockInAllStore(false)
        }
    }, [
        dispatch,
        isOutOfStockInAllStore,
        inStockAtDC,
        inStockAtNearbyStores,
        inStockInSelectedStore,
        productSkuData,
        isProductLevel,
        isCurrentPriceAvailable,
        isNearbyStoresListEmpty,
    ])

    const getNearbyStores = useCallback(() => {
        dispatch(
            fetchNearbyStoreList(
                {
                    maxCount: maxNumberOfStores,
                    radius: maxRadiusToSearchStore,
                    sku: selectedProductCode,
                    latitude: geoPoint.latitude,
                    longitude: geoPoint.longitude,
                    showNotInStock: enableShowInStockFilter,
                },
                NearByOptions.SKU,
            ),
        )
        isNearbyStoresCalledRef.current = true
    }, [
        dispatch,
        maxNumberOfStores,
        maxRadiusToSearchStore,
        selectedProductCode,
        geoPoint,
        enableShowInStockFilter,
        isNearbyStoresCalledRef,
    ])

    const isExpressDeliveryEligible = useCallback((): boolean => {
        return freeShippingEnabled
            ? Boolean(preferredStoreDetails?.isExpressDeliveryEligible && preferredStoreDetails?.bopisAvailable)
            : Boolean(preferredStoreDetails?.isExpressDeliveryEligible)
    }, [freeShippingEnabled, preferredStoreDetails])

    const unavailableFulfillmentSection = useCallback((): boolean => {
        return (
            // eslint-disable-next-line sonar/expression-complexity
            (isShipToHome &&
                productNotForSth(isAllVariantsSelected, fulfillment, productData, Boolean(freeShippingEnabled))) ||
            (isExpressDelivery &&
                (!isExpressDeliveryAvailable(
                    isAllVariantsSelected,
                    fulfillment,
                    isExpressDeliveryEligible(),
                    Boolean(freeShippingEnabled),
                    true,
                ) ||
                    areAllParamsValid(
                        !freeShippingEnabled,
                        (selectedStoreQty as number) > 0,
                        libUtils.getNumberOrDefault(qtySelectorInput) > (selectedStoreQty as number),
                    )) &&
                isAllVariantsSelected) ||
            (isBopis(selectedFulfillment, freePickUpInStoreLabel as string) &&
                bopisUnavailable &&
                isAllVariantsSelected)
        )
    }, [
        isExpressDelivery,
        isAllVariantsSelected,
        fulfillment,
        productData,
        freeShippingEnabled,
        isShipToHome,
        bopisUnavailable,
        selectedFulfillment,
        freePickUpInStoreLabel,
        isExpressDeliveryEligible,
        qtySelectorInput,
        selectedStoreQty,
    ])

    const isOOSAtStoreForPickUp = useCallback((): boolean => {
        return isSeparatedErrorHandling && isFreePickUp
            ? !inStockInSelectedStore
            : !inStockInSelectedStore && !inStockAtDC
    }, [inStockAtDC, inStockInSelectedStore, isFreePickUp, isSeparatedErrorHandling])

    const sendRequestByClickNearByStores = useCallback((): void => {
        if (
            // eslint-disable-next-line sonar/expression-complexity
            (inStockInSelectedStore && (!isNearbyStoresCalledRef.current || !isNearbyStoreListSuccess)) ||
            (!freeShippingEnabled && isShipToHome)
        ) {
            getNearbyStores()
        }
    }, [
        getNearbyStores,
        inStockInSelectedStore,
        isNearbyStoreListSuccess,
        isNearbyStoresCalledRef,
        isShipToHome,
        freeShippingEnabled,
    ])

    const isBopisOnlyUnavailableForPickup = useCallback((): boolean | undefined => {
        return enableBOPISStore && !bopisEligibility && hasOnlyBOPISEnabled
    }, [bopisEligibility, enableBOPISStore, hasOnlyBOPISEnabled])

    const displaySuccessToast = useCallback((): void | null => {
        const toastProps = {
            options: {
                toastSuccessMessage: storeSuccessfullySelectedMsg,
                toastSuccessIcon: 'ct-notification-success',
            },
            success: true,
            hideCTA: true,
            enableTimer: true,
            toastTimeOutValue: parseInt(storeChangeToastTimeOut, 10),
        }
        dispatchToast(true, toastProps, ToastComponentNames.NONE, dispatch)
    }, [dispatch, storeChangeToastTimeOut, storeSuccessfullySelectedMsg])

    const isBopisOnlySkuWithStoreBopisUnavailable = useMemo((): boolean => {
        return Boolean(selectedVariantSkuData?.isBopisOnly && !preferredStoreDetails?.bopisAvailable)
    }, [selectedVariantSkuData, preferredStoreDetails])
    const isBopisOnlyUnavailableForPickupInStock = useMemo(
        () => isBopisOnlyUnavailableForPickup() && inStockInSelectedStore,
        [isBopisOnlyUnavailableForPickup, inStockInSelectedStore],
    )

    const isProductNotEligibleForSTH = useMemo(
        () => isShipToHome && isProductNotForSth,
        [isProductNotForSth, isShipToHome],
    )

    const hasBuyOnlineSection = useMemo(
        () =>
            enableFulfilmentSelection &&
            ((checkDataLength(fulfillment) && !isProductLevel) ||
                !areAllParamsValid(isProductLevel, isVariantSelected)),
        [fulfillment, isProductLevel, enableFulfilmentSelection, isVariantSelected],
    )

    const isProductBulk = useMemo(
        () =>
            Boolean(
                isProductLevel ? productData?.isBulk : getSelectedDetailsFromSKU(productData, getSkuCode())?.isBulk,
            ),
        [isProductLevel, productData, getSkuCode],
    )

    const isProductUrgentLowStock = useMemo<boolean>(() => {
        if (enableLightPdpLoading) {
            return !!productSkuData?.skus?.[0]?.isUrgentLowStock
        } else if (isProductLevel) {
            return !!productData?.isUrgentLowStock
        } else {
            return !!getSelectedDetailsFromSKU(productData, getSkuCode())?.isUrgentLowStock
        }
    }, [productData, productSkuData, isProductLevel, getSkuCode, enableLightPdpLoading])

    const isComingSoonProduct = useMemo((): boolean => {
        return messageCode === SellableCodes.SELLABLE_NOT_STARTED && !isProductLevel && isProductSellable
    }, [messageCode, isProductLevel, isProductSellable])

    const showFindinStore = useMemo(() => {
        return (
            // eslint-disable-next-line sonar/expression-complexity
            isAllVariantsSelected &&
            !isOnlineOnly &&
            (!isOutOfStockInAllStore || isProductLevel) &&
            !isComingSoonProduct
        )
    }, [isAllVariantsSelected, isOnlineOnly, isOutOfStockInAllStore, isProductLevel, isComingSoonProduct])

    /**
     * This function to show select a store button
     * @returns {boolean} true/false
     */
    const isSelectAStoreButtonAvailable = useMemo(() => {
        if (isProductNotEligibleForSTH) {
            return false
        } else {
            return Boolean(
                // eslint-disable-next-line sonar/expression-complexity
                (isOOSCurrentStore &&
                    ((!disableSelectAStoreButton && !isExpressDelivery && isAllVariantsSelected) ||
                        !!isBopisOnlyUnavailableForPickup())) ||
                    (selectedFulfillment.toLowerCase() === freePickUpInStoreLabel?.toLowerCase() &&
                        !bopisUnavailable &&
                        hasZeroSelectedStoreQuantityWithFreeShippingEnabled &&
                        inStockAtNearbyStores()),
            )
        }
    }, [
        isProductNotEligibleForSTH,
        isOOSCurrentStore,
        disableSelectAStoreButton,
        isExpressDelivery,
        isAllVariantsSelected,
        isBopisOnlyUnavailableForPickup,
        selectedFulfillment,
        freePickUpInStoreLabel,
        bopisUnavailable,
        hasZeroSelectedStoreQuantityWithFreeShippingEnabled,
        inStockAtNearbyStores,
    ])

    const closeMiniPDPFlyout = useCallback(() => {
        dispatch(closeMiniPDPFlyoutAction())
    }, [dispatch])

    useEffect(() => {
        setIsFulfillmentUpdated(true)
    }, [fulfillment])

    useEffect(() => {
        setQtySelectorErrorMsg('')
    }, [isProductLevel, isProductLevelSellability, productData, productSkuData])

    const onStoreChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, store: StoreWithAvailability): void => {
        setNearByStoresfetched(true)
        dispatch(setSelectedStoreName(e.target.value))
        dispatch(setSelectedPreferredStoreId(String(store?.id)))
        dispatch(setPreferredStoreDetailsAction(store))
        dispatch(setIsNearByStoreListApiDone(false))
        displaySuccessToast()
    }

    /**
     * This function to show the size chart
     * @returns {JSX.Element} - Size chart component
     */
    const renderNearByStoresList = (): JSX.Element | null => {
        const componentClassName = `${PREFIX}-fulfillment`
        return isArrayNotEmpty(nearbyStoresList) ? (
            <div className={`${componentClassName}__nearbystores`}>
                <div className={`${componentClassName}__nearbystores-label`}>
                    {isArrayNotEmpty(nearbyStoresList) && nearByStoreLabel}
                </div>
                <ul className={`${componentClassName}__nearbystores-list`} role="radiogroup">
                    {nearbyStoresList &&
                        nearbyStoresList.map(nearByStore => {
                            return (
                                <li
                                    key={nearByStore.id}
                                    className={`${componentClassName}__nearbystores-list__item`}
                                    role="presentation">
                                    <div className={`${componentClassName}__nearbystores-list__item__label`}>
                                        <div className={`${componentClassName}__nearbystores-list__item__name`}>
                                            <Radio
                                                id={String(nearByStore.id)}
                                                label={nearByStore.displayName}
                                                value={nearByStore.displayName}
                                                checked={nearByStore.displayName === selectedStoreName}
                                                aria-label={nearByStore.displayName}
                                                aria-checked={
                                                    nearByStore.displayName === selectedStoreName ? true : false
                                                }
                                                name="stores"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    onStoreChangeHandler(e, nearByStore)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <Tag type="availability-bullet" subType={availabilityBulletInStock}>
                                        {nearByStore.inventory?.[0]?.Quantity} {props.inStockLabel}
                                    </Tag>
                                </li>
                            )
                        })}
                </ul>
            </div>
        ) : null
    }

    useEffect(() => {
        if (!enableFulfilmentSelection) {
            return
        }

        dispatch(setIsNearByStoreListApiDone(true))

        const checkProductInfo = () =>
            // eslint-disable-next-line sonar/expression-complexity
            checkDataLength(productData) &&
            checkDataLength(productSkuData) &&
            isDataChanged() &&
            !!selectedProductCode &&
            selectedProductCode !== productData?.code
        if (
            // eslint-disable-next-line sonar/expression-complexity
            !!maxRadiusToSearchStore &&
            !!geoPoint &&
            isSkuInStoreUpdatedBySelectedSku &&
            checkProductInfo() &&
            isFulfillmentUpdated
        ) {
            updatePrevCurrentValue()
            isNearbyStoresCalledRef.current = false
            setIsFulfillmentUpdated(false)

            if (!inStockInSelectedStore) {
                getNearbyStores()
            }
        }
    }, [
        selectedProductCode,
        dispatch,
        enableFulfilmentSelection,
        maxRadiusToSearchStore,
        geoPoint,
        maxNumberOfStores,
        productSkuData,
        isSkuInStoreUpdatedBySelectedSku,
        inStockInSelectedStore,
        selectedPreferredStoreId,
        isDataChanged,
        getNearbyStores,
        productData,
        isFulfillmentUpdated,
        updatePrevCurrentValue,
    ])

    useEffect(() => {
        if (!enableFulfilmentSelection) {
            return
        }

        if (preferredStoreId !== currentStoreId) {
            dispatch(updateScuDataByStore(productData, preferredStoreId))
        }
    }, [dispatch, enableFulfilmentSelection, productData, currentStoreId, preferredStoreId])

    /**
     * Below use effect to stop background scrolling when the flyout is open
     */
    useEffect(() => {
        const overFlowHiddenClass = 'modal-open-not-scroll'
        document.body.classList.add(overFlowHiddenClass)
        return () => {
            document.body.classList.remove(overFlowHiddenClass)
        }
    }, [])

    if (checkDataLength(variantDetails)) {
        const { firstVariant, secondVariant, thirdVariant } = variantDetails
        variantDetails.firstVariant = getAvailableVariants(firstVariant, secondVariant, thirdVariant)
    }

    const closeFlyout = (): void => {
        setStoreFlyoutShouldOpen(!shouldStoreFlyoutOpen)
    }

    // Props required for flyout modal
    const flyOutModalComponentProps: StoreFlyOutModalComponent = {
        isOpen: shouldStoreFlyoutOpen,
        closeFlyout,
        isStoreSelectorModalUsed: true,
    }

    const findInStoreProps = {
        alsoFindItInStoreHeader,
        checkOtherStoresLabel,
        sendRequestByClickNearByStores,
        isProductLevel,
        isShowNotInStock: enableShowInStockFilter,
        isNearbyStoreListApiDone,
        selectedStoreQty,
        inStockAtStore,
        outOfStockAtStoreff,
        a11yStoreInfoTooltip,
        selectedStoreShelfLocation,
        isProductUrgentLowStock,
        isAutomotive: false,
        isAutoPartsPdpPage: false,
        isSKUPresentInUrl: false,
        isOutOfStockInAllStore,
        inStockInSelectedStore: () => inStockInSelectedStore,
        ltdStockUrgencyMsg,
        isShippingPostalCodeHide,
        setIsNearByStoresAvailable,
        isNearbyStoresListEmpty,
        isModalPresent: true,
        isInMiniPDPFlyout: true,
    }

    const rendersStoreFlyoutComp = (): JSX.Element | null => {
        return shouldStoreFlyoutOpen ? (
            <FlyoutModalComponent {...flyOutModalComponentProps}>
                {rendersStoreSelectorFlyoutComp(
                    shouldStoreFlyoutOpen,
                    closeFlyout,
                    nearByStoreListForSelectedSku,
                    isProductLevel,
                    enableShowInStockFilter,
                    isNearbyStoreListApiDone,
                    true,
                )}
            </FlyoutModalComponent>
        ) : null
    }

    const renderSizeChartComponent = (): JSX.Element | null => {
        return showSizeChart ? (
            <SizeChart
                setShowSizeChart={setShowSizeChart}
                isSizeChartOpen={showSizeChart}
                sizeChartData={sizeChartData as SizeChartDataType}
                howToMeasureLabel={howToMeasureLabel}
                isInMiniPDPFlyout={true}
                backBtnLabel={breadcrumb?.backLabel}
            />
        ) : null
    }

    return (
        <div className={`${PREFIX}-mini-pdp`}>
            <FlyoutModalComponent
                {...{
                    title: miniPDPFlyout,
                    isOpen: isFlyoutVisible,
                    closeFlyout: closeMiniPDPFlyout,
                    disableCloseByEscOrOutsideClick: isAddToCartOrWishlistClicked,
                }}>
                <div className="mini-pdp-flyout-wrapper">
                    <FlyoutHeader
                        label={miniPdpTitle}
                        closeIconLabel={closeIconLabel}
                        closeFlyout={closeMiniPDPFlyout}
                        showCloseBtn
                    />
                    <ProductTitle productTitleInfo={productTitleInfo} />
                    <ImageGallery imageGalleryInfo={imageGalleryInfo} />
                    <div className="mini-pdp-buy-box-wrapper">
                        <PriceComponent
                            isStaggered={false}
                            isProductLevel={isProductLevel}
                            staggeredFulfillmentSkus={[]}
                            staggeredFulfillmentSkusFiltered={[]}
                            productDataBySku={productDataBySku}
                            setOfTwoLabel=""
                            setOfTwoNowLabel=""
                            isModalPresent
                        />
                        <PromoMessagesComponent
                            variantDetails={variantDetails}
                            promoEnabled={promoEnabled as unknown as string}
                            promoRestrictedBrands={promoRestrictedBrands as PromoRestrictedBrands[]}
                            sizeChartBrand={productData.brand?.label || SIZECHART_CROSS_BRAND}
                            isProductLevel={isProductLevel}
                            flyoutVariantsInfoData={variantsInfo}
                        />
                        <BadgesWrapper
                            isStaggered={false}
                            badges={badges}
                            hideDisclaimer={hideDisclaimer}
                            badgesDisplayOptions={commonContentBadges}
                            saleEndDaySoonMessage={saleEndDaySoonMessage}
                            productSaleEndDate={saleEndDate}
                            saleEndDisableShift={disableAdaptingTimeZoneForSaleEndDate}
                        />
                        <Variants
                            isLimitedStock={isProductUrgentLowStock}
                            onlyAvailableOnlineAtLabel={onlyAvailableOnlineAtLabel}
                            enableOnlyAvailableOnline={false}
                            availableOnlinePurchaseLabel={availableOnlinePurchaseLabel}
                            checkIfAllVariantsSelected={isAllVariantsSelected}
                            outOfStockLabel={outOfStockLabel}
                            inStockLabel={inStockLabel}
                            a11yVariantsSelectedCount={a11yVariantsSelectedCount}
                            isAutomotive={false}
                            isSKUPresentInUrl={false}
                            isNoSkuFindStoreClicked={isNoSkuFindStoreClicked}
                            productData={productData}
                            commonContentAvailable={commonContentAvailable}
                            selectedFirstVariant={selectedFirstVariant}
                            selectedFirstVariantDisplayName={selectedFirstVariantDisplayName}
                            selectedSecondVariant={selectedSecondVariant}
                            selectedThirdVariant={selectedThirdVariant}
                            getVariantsForMultipleSKUfromFitment={() => []}
                            selectedFirstVariantId={selectedFirstVariantId}
                            selectedSecondVariantId={selectedSecondVariantId}
                            selectedThirdVariantId={selectedThirdVariantId}
                            setSelectedFirstVariantIdValue={setSelectedFirstVariantIdValue}
                            setSelectedSecondVariantIdValue={setSelectedSecondVariantIdValue}
                            setSelectedThirdVariantIdValue={setSelectedThirdVariantIdValue}
                            setSelectedFirstVariantValue={setSelectedFirstVariantValue}
                            setSelectedSecondVariantValue={setSelectedSecondVariantValue}
                            setSelectedThirdVariantValue={setSelectedThirdVariantValue}
                            isAddToCartOrWishlistClicked={isAddToCartOrWishlistClicked}
                            variantDetails={variantDetails}
                            isVariantSelected={isVariantSelected}
                            setIsVariantSelected={setIsVariantSelected}
                            setSelectedCode={
                                setSelectedCode as (selectedCode: string, staggeredCodes?: string[] | undefined) => void
                            }
                            variantWarningLabel={variantWarningLabel}
                            viewMoreLabel={viewMoreLabel as string}
                            viewLessLabel={viewLessLabel as string}
                            a11yVariantSelected={a11yVariantSelected}
                            a11yVariantUnSelected={a11yVariantUnSelected}
                            list={[]}
                            criticalFitment={undefined}
                            skuCode=""
                            selectedProductCode={selectedProductCode}
                            isFirstVariantClicked={isFirstVariantClicked}
                            setIsFirstVariantClicked={setIsFirstVariantClicked}
                            selectedColorCode={initColourVariant}
                            setShowSizeChart={setShowSizeChart}
                            showSizeChart={false}
                            isAllSkusOOS={isAllSkusOOS}
                            enableHidingFirstVariantWhereAllSkusOOS
                            enableCustomOrder={enableCustomOrder}
                            variantDetailsOrder={variantDetailsOrder}
                            noUrlUpdates
                            flyoutVariantsInfoData={variantsInfo}
                        />
                        {hasBuyOnlineSection ? (
                            <BuyOnline
                                selectedStoreQty={selectedStoreQty}
                                isShowNotInStock={enableShowInStockFilter}
                                bopisUnavailable={bopisUnavailable}
                                isBopisOnlySkuWithStoreBopisUnavailable={isBopisOnlySkuWithStoreBopisUnavailable}
                                {...({
                                    variantDetails: variantDetails,
                                    sendRequestByClickNearByStores: sendRequestByClickNearByStores,
                                    setSelectedFulfillment: setSelectedFulfillment,
                                    nearByStoresfetched: nearByStoresfetched,
                                    setNearByStoresfetched: setNearByStoresfetched,
                                    setIsShipToHome: setIsShipToHome,
                                    setIsFreePickUp: setIsFreePickUp,
                                    setIsExpressDelivery: setIsExpressDelivery,
                                    isAddToCartClicked: isAddToCartClicked,
                                    inStockAtNearbyStores: inStockAtNearbyStores(),
                                    productNotForSth: isProductNotForSth,
                                    availabilityObj: availability as ItemAvailability,
                                    sku: selectedProductCode,
                                    ...buyOnlineProps,
                                } as unknown as BuyOnlineType)}
                                inStockInSelectedStore={inStockInSelectedStore}
                                inStockAtDC={inStockAtDC}
                                isVariantsHidden={isAllSkusOOS}
                                checkIfAllVariantsSelected={isAllVariantsSelected}
                                isShipToHome={isShipToHome}
                                fulfillment={fulfillment}
                                isProductLevel={isProductLevel}
                                isOutOfStockInAllStore={isOutOfStockInAllStore}
                                isAllSkusOOS={isAllSkusOOS}
                                isOnlineOnly={isOnlineOnly}
                                isSellableProduct={isProductSellable}
                                inStorePurchaseOnly={inStorePurchaseOnly as unknown as string}
                                shouldStoreFlyoutOpen={shouldStoreFlyoutOpen}
                                rendersStoreFlyoutComp={rendersStoreFlyoutComp}
                                showEstimatedFees={false}
                                isPostalCode={false}
                                postalCodeVal={postalCodeVal}
                                setPostalCodeVal={setPostalCodeVal}
                                qtySelectorInput={qtySelectorInput}
                                isProductBulk={isProductBulk}
                                isNearbyStoresListEmpty={isNearbyStoresListEmpty}
                                isOutOfStockAtStoreAndDC={isOutOfStockAtStoreAndDC}
                                isProductNotEligibleForSTH={isProductNotEligibleForSTH}
                                isExpressDeliveryEligibleForPostalCode
                                a11yTooltipIcon={a11yTooltipIcon}
                                isProductDiscontinued={isProductDiscontinued}
                                isSeparatedErrorHandling={isSeparatedErrorHandling}
                                isFreePickUp={isFreePickUp}
                                isProductFullyDiscontinued={isProductDiscontinuedEOL}
                                nearByStoresList={renderNearByStoresList() as JSX.Element}
                                isNearByStoresAvailable={isNearByStoresAvailable}
                                setIsNearByStoresAvailable={setIsNearByStoresAvailable}
                                estimatedExpressDeliveryFeesPriceText={undefined as unknown as string}
                                estimatedFeesPriceText={undefined as unknown as string}
                                showEstimateFeesModal={false}
                                setEstimateFeesModal={() => null}
                                isAutomotiveProduct={false}
                                setShowEstimatedFeesEFModal={() => null}
                                showAutoServiceUnavailableMsg={(() => null) as unknown as JSX.Element}
                                closeFlyout={closeFlyout}
                                flyoutVariantsInfoData={variantsInfo}
                                fulfillmentBoxesOrder={fulfillmentBoxesOrder}
                            />
                        ) : (
                            <OOSAtAllStoreMessage
                                isOutOfStockInAllStore={isOutOfStockInAllStore}
                                isAllVariantsSelected={isAllVariantsSelected}
                                isProductLevel={isProductLevel}
                                isProductFullyDiscontinued={isProductDiscontinuedEOL}
                                isVariantsHidden={isAllSkusOOS}
                                fullyDiscontinuedTitle={props.fullyDiscontinuedTitle}
                                outOfStockTitle={props.outOfStockTitle}
                                outOfStockText={props.outOfStockText}
                                hidingAllFirstVariantOOSMessage={props.hidingAllFirstVariantOOSMessage}
                            />
                        )}
                        {showFindinStore && <FindInStore {...(findInStoreProps as unknown as FindInStoreType)} />}
                    </div>
                    <ATCQuantityWishlist
                        isShipToHome={isShipToHome}
                        goToPdpButtonLabel={goToPdpButtonLabel as string}
                        closeFlyout={closeMiniPDPFlyout}
                        variantNotSelectedMessage={variantNotSelectedMessage}
                        isAddToCartClicked={isAddToCartClicked}
                        setIsAddToCartClicked={setIsAddToCartClicked}
                        isAddToWishlistClicked={isAddToWishlistClicked}
                        setIsAddToWishlistClicked={setIsAddToWishlistClicked}
                        setIsVariantSelected={setIsVariantSelected}
                        isStoreSelected={isStoreSelected}
                        maxQuantityAvailable={maxQuantityAvailable as number}
                        inStockAtDC={inStockAtDC}
                        quantityExceeded={quantityExceeded}
                        availableForATC={availableForATC}
                        limitMsgValue={limitMsgValue}
                        qtySelectorErrorMsg={qtySelectorErrorMsg}
                        setQtySelectorErrorMsg={setQtySelectorErrorMsg}
                        inStockAtNearbyStores={inStockAtNearbyStores}
                        isSelectAStoreButtonAvailable={isSelectAStoreButtonAvailable}
                        unavailableFulfillmentSection={unavailableFulfillmentSection}
                        isOOSAtStoreForPickUp={isOOSAtStoreForPickUp}
                        selectedFulfillment={selectedFulfillment}
                        bopisUnavailable={bopisUnavailable}
                        isExpressDelivery={isExpressDelivery}
                        isExpressDeliveryEligible={isExpressDeliveryEligible}
                        isExpressDeliveryEnabledForProduct={isExpressDeliveryEnabledForProduct}
                        onlineOrdering={onlineOrdering}
                        isBopisOnlySkuWithStoreBopisUnavailable={isBopisOnlySkuWithStoreBopisUnavailable}
                        isBopisOnlyUnavailableForPickupInStock={isBopisOnlyUnavailableForPickupInStock}
                        isProductDiscontinued={isProductDiscontinued}
                        isOutOfStockInAllStore={isOutOfStockInAllStore}
                        showErrorMsgForVariantSelection={showErrorMsgForVariantSelection}
                        setShowErrorMsgForVariantSelection={setShowErrorMsgForVariantSelection}
                        isNoSkuFindStoreClicked={isNoSkuFindStoreClicked}
                        setIsNoSkuFindStoreClicked={setIsNoSkuFindStoreClicked}
                        qtySelectorInput={qtySelectorInput}
                        setQtySelectorInput={setQtySelectorInput}
                        enableFulfilmentSelection={enableFulfilmentSelection}
                        freeShippingEnabled={false}
                        wishListNonSellableEnabled={'false'}
                        minQuantity={props.minQuantity as number}
                        isSeparatedErrorHandling={isSeparatedErrorHandling as boolean}
                        prodOOSErrMsg={props.prodOOSErrMsg}
                        isATCErrorHandling={props.isATCErrorHandling}
                        freePickUpInStoreLabel={props.freePickUpInStoreLabel as string}
                        a11yUpdatingWishList={props.a11yUpdatingWishList}
                        a11yRemoveFromWishList={props.a11yRemoveFromWishList}
                        a11yAddToWishList={props.a11yAddToWishList}
                        addToWishListLabel={props.addToWishListLabel}
                        addedToWishListLabel={props.addedToWishListLabel}
                        exceedMaximumSellQuantityMessage={props.exceedMaximumSellQuantityMessage}
                        exceedMaximumPurchableMessage={props.exceedMaximumPurchableMessage}
                        wishlistMaxLimitMessage={props.wishlistMaxLimitMessage}
                        buyboxButtonLabel={props.buyboxButtonLabel}
                        selectAllOptionsLabel={props.selectAllOptionsLabel}
                        addToWishlistMessage={props.addToWishlistMessage}
                        addToWishlistMessageGuest={props.addToWishlistMessageGuest}
                        itemRemovedFromWishlistMsg={props.itemRemovedFromWishlistMsg}
                        failureCloseLabel={props.failureCloseLabel}
                    />
                    {renderSizeChartComponent()}
                </div>
            </FlyoutModalComponent>
        </div>
    )
}
