export const FAIL_UPDATE_PASSWORD = 'FAIL_UPDATE_PASSWORD'
export const SUCCESS_UPDATE_PASSWORD = 'SUCCESS_UPDATE_PASSWORD'
export const FAIL_UPDATE_TFA_PHONE_NUMBER = 'FAIL_UPDATE_TFA_PHONE_NUMBER'
export const SUCCESS_UPDATE_TFA_PHONE_NUMBER = 'SUCCESS_UPDATE_TFA_PHONE_NUMBER'
export const CLEAR_UPDATE_TFA_PHONE_NUMBER_DATA = 'CLEAR_UPDATE_TFA_PHONE_NUMBER_DATA'
export const INIT_PHONE_VERIFICATION_FLOW = 'INIT_PHONE_VERIFICATION_FLOW'
export const FAIL_PHONE_VERIFICATION_FLOW = 'FAIL_PHONE_VERIFICATION_FLOW'
export const RESET_PHONE_VERIFICATION_FLOW = 'RESET_PHONE_VERIFICATION_FLOW'
export const SUCCESS_PHONE_VERIFICATION_FLOW = 'SUCCESS_PHONE_VERIFICATION_FLOW'
export const SET_PHONE_VERIFICATION_ERR_CODE = 'SET_PHONE_VERIFICATION_ERR_CODE'
export const CLEAR_PHONE_VERIFICATION_DATA = 'CLEAR_PHONE_VERIFICATION_DATA'
export const SET_TFA_NUMBER = 'SET_TFA_NUMBER'
