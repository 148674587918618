import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PREFIX } from '../config'
import { CheckboxProps } from './Checkbox.types'
import Icon from '../Icon'

/**
 * Checkbox component
 * @param {CheckboxProps} props
 * @return {JSX.Element} returns Checkbox component
 */
const Checkbox: React.FC<CheckboxProps> = ({ ...props }) => {
    const {
        facetId,
        disabled,
        checked,
        label,
        value,
        onChange,
        path,
        ariaLabel,
        setAutofocus,
        assistiveText,
        displaySuccessIcon,
        errorIcon,
        error,
        successIcon,
        componentClassName,
    } = props
    const facteId = facetId !== undefined ? `-${facetId}` : ''
    const id = props.id ? `${PREFIX}-checkbox__${props.id}${facteId}` : ''
    const erroClass = error ? `${componentClassName}__check--error` : ''
    const hover = props.hover ? `${props.hover}` : ''
    const checkboxRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (setAutofocus) {
            checkboxRef.current?.focus()
        }
    }, [setAutofocus])

    const successRender = (): JSX.Element => {
        return (
            !error &&
            assistiveText && (
                <div className={`${componentClassName}__assistive-text`}>
                    {displaySuccessIcon && <Icon type={successIcon} size="lg" path={path} />}
                    {assistiveText}
                </div>
            )
        )
    }

    const errorRender = (): JSX.Element => {
        return (
            error && (
                <div className={`${componentClassName}__error`} role="alert">
                    <Icon type={errorIcon} size="sm" path={path} />
                    <span className={`${componentClassName}__error-text`}>{error}</span>
                </div>
            )
        )
    }
    return (
        <>
            <div className={`${PREFIX}-checkbox ${hover}`}>
                <input
                    id={`${id}`}
                    type="checkbox"
                    className={`${PREFIX}-checkbox__input`}
                    disabled={disabled}
                    defaultChecked={checked}
                    value={value}
                    onChange={onChange}
                    data-testid={id}
                    aria-label={ariaLabel}
                    ref={checkboxRef}
                    aria-checked={checked}
                />
                <label htmlFor={`${id}`} className={`${PREFIX}-checkbox__label-container`}>
                    <span className={`${componentClassName}__check ${erroClass}`}>
                        {checked && <Icon size="xs" type="ct-checkmark" path={path} />}
                    </span>

                    <span className={`${PREFIX}-checkbox__text ${disabled ? 'disabled' : ''}`}>{label}</span>
                </label>
            </div>
            {successRender()}
            {errorRender()}
        </>
    )
}

Checkbox.defaultProps = {
    label: 'Checkbox Label',
    id: 'checkbox_label',
    errorIcon: 'ct-warning-triangle-sm',
    successIcon: 'ct-checkmark',
    displaySuccessIcon: false,
    componentClassName: `${PREFIX}-checkbox`,
}

Checkbox.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    hover: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    path: PropTypes.string,
    ariaLabel: PropTypes.string,
    setAutofocus: PropTypes.bool,
    errorIcon: PropTypes.string,
    successIcon: PropTypes.string,
    displaySuccessIcon: PropTypes.bool,
    assistiveText: PropTypes.string,
    componentClassName: PropTypes.string,
}

export default Checkbox
