import React from 'react'
import { string, array, exact } from 'prop-types'

import { PREFIX } from '../config'
import { CTATypes, ErrorScreenProps } from './ErrorScreen.type'
import BrandFooter from '../BrandFooter'
import { Variation } from '../../types'
import { SpinnerButton } from '../ButtonV2'
import { ButtonColors } from '../ButtonV2/Button.types'

/**
 * Error Screen
 * @param {ErrorCardProps} props
 * @returns {JSX.Element} returns Error screen
 */
const ErrorScreen: React.FC<ErrorScreenProps> = ({ ...props }) => {
    const { data } = props
    const { headerLogo, errorMessageHeader, errorMessageSubHeader, footer, errorDescription, ctaList } = data
    const componentName = `${PREFIX}-error-card`

    /**
     * renders cta
     * @param {CTATypes} cta cta
     * @param {number} i index
     * @returns {JSX.Element}
     */
    const renderCta = (cta: CTATypes, i: number): JSX.Element => {
        return cta.url ? (
            <a
                href={cta.url}
                key={i}
                className={`${PREFIX}-button2
                     ${PREFIX}-button2--${cta.variant || Variation.PRIMARY}
                     ${PREFIX}-button2--${cta.variant || Variation.PRIMARY}-${cta.color || ButtonColors.DEFAULT}
                     ${componentName}__link}`}
                data-link-value={cta.children}>
                {cta.children}
            </a>
        ) : (
            <SpinnerButton
                variant={cta.variant || Variation.PRIMARY}
                id={cta.id}
                color={cta.color}
                size={cta.size}
                ref={cta.ref}
                onClick={cta.onClick}
                key={i}
                showSpinner={Boolean(cta.showSpinner)}
                a11y={{
                    label: cta.a11y?.label,
                    describedBy: cta.a11y?.describedBy,
                }}>
                {cta.children}
            </SpinnerButton>
        )
    }

    /**
     * renders ctaList based on button version
     * @returns {JSX.Element[] | null}
     */
    const renderCtaList = (): JSX.Element[] | null => {
        return ctaList?.map((cta: CTATypes, i: number): JSX.Element => renderCta(cta, i))
    }

    return (
        <div className={`${componentName}`}>
            <div className={`${componentName}__error-img`}>
                <img src={`${headerLogo?.image}`} alt={`${headerLogo?.imageAlt}`} />
            </div>
            <div className={`${componentName}__content`}>
                {errorMessageHeader && (
                    <p className={`${componentName}__title`} dangerouslySetInnerHTML={{ __html: errorMessageHeader }} />
                )}
                {errorMessageSubHeader && (
                    <p
                        className={`${componentName}__subtitle`}
                        dangerouslySetInnerHTML={{ __html: errorMessageSubHeader }}
                    />
                )}
            </div>
            {errorDescription && (
                <div
                    className={`${componentName}__sub-content`}
                    dangerouslySetInnerHTML={{ __html: errorDescription }}
                />
            )}
            {ctaList?.length > 1 ? <div className={`${componentName}__cta`}>{renderCtaList()}</div> : renderCtaList()}
            {footer?.bannerImage && (
                <BrandFooter
                    image={footer.bannerImage}
                    altText={footer.bannerImageAltText}
                    showDivider={footer.showDivider}
                />
            )}
        </div>
    )
}

ErrorScreen.propTypes = {
    data: exact({
        headerLogo: exact({ image: string, imageAlt: string }),
        errorMessageHeader: string,
        errorMessageSubHeader: string,
        ctaList: array,
    }).isRequired,
}

export default ErrorScreen
