import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { SearchTitleProps } from './SearchTitle.type'
import Button from '../Button/Button'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'

/**
 * Search Title component
 * @return {JSX.Element} returns Search Title component
 */
const SearchTitle: React.FC<SearchTitleProps> = ({ ...props }): JSX.Element => {
    const {
        searchTitle,
        searchString,
        suggestiveMessage,
        didYouMean,
        suggestiveQueryClickCallback,
        lastSelectedCategory,
        similarProductsMessage,
        showCorrectedText,
        correctedMessage,
        isSrpPromoIcon,
        noResultSubTitle,
        isProductDataAvailable,
        isPromoSearchBar,
    } = props

    const searchQuery = searchString ? `${searchTitle} "${searchString}" ` : ''

    /**
     * Determines whether to show a message based on the promo search bar status and ProductData Availability.
     * @param {boolean} isPromoSearchBarEnabled - Indicates if the promo search bar is enabled.
     * @param {boolean} isMessageAvailable - Indicates if the message exists.
     * @param {boolean} hasProductData - Indicates if the ProductData Available.
     * @returns {boolean} - Returns true if the message should be shown, otherwise false.
     */
    const shouldShowMessage = (
        isPromoSearchBarEnabled: boolean,
        isMessageAvailable: boolean,
        hasProductData: boolean,
    ): boolean => {
        return isPromoSearchBarEnabled ? isMessageAvailable && hasProductData : isMessageAvailable
    }

    //  Determines whether to show the similar products message based on the promo search bar status and product data availability.
    const showSimiliarProductMsg = shouldShowMessage(isPromoSearchBar, !!similarProductsMessage, isProductDataAvailable)

    // Determines whether to show the did you mean message based on the promo search bar status and product data availability.
    const showDidYouMeanMsg = shouldShowMessage(isPromoSearchBar, isArrayNotEmpty(didYouMean), isProductDataAvailable)

    /*
     * getSuggestiveSearchQueries function returns suggestive search link
     */
    const getSuggestiveSearchQueries = (): JSX.Element[] => {
        return didYouMean.map((suggestion: string, index: number) => {
            return (
                <Button
                    key={index}
                    type="tertiary"
                    onClick={() => {
                        suggestiveQueryClickCallback(suggestion)
                    }}>
                    {suggestion}
                </Button>
            )
        })
    }

    return (
        <div className={`${PREFIX}-srp`} data-testid="search-title">
            {showCorrectedText && <p className={`${PREFIX}-srp__noproduct-msg`}>{correctedMessage}</p>}
            <h2 className={`${PREFIX}-srp__title`}>
                {searchQuery && <div>{searchQuery}</div>}
                {lastSelectedCategory && <div className={`${PREFIX}-srp__category`}>{lastSelectedCategory}</div>}
            </h2>
            {showSimiliarProductMsg && (
                <div className={`${PREFIX}-srp__similar-products-message`}>{similarProductsMessage}</div>
            )}
            {showDidYouMeanMsg && (
                <div className={`${PREFIX}-srp__suggestive-message`}>
                    {suggestiveMessage}
                    <span className={`${PREFIX}-srp__suggestive-option`}>{getSuggestiveSearchQueries()}</span>
                </div>
            )}
            {isSrpPromoIcon && noResultSubTitle && <p className={`${PREFIX}-srp__sub-msg`}>{noResultSubTitle}</p>}
        </div>
    )
}

SearchTitle.propTypes = {
    searchTitle: PropTypes.string,
    searchString: PropTypes.string,
    suggestiveMessage: PropTypes.string,
    didYouMean: PropTypes.array,
    suggestiveQueryClickCallback: PropTypes.func,
    lastSelectedCategory: PropTypes.string,
    similarProductsMessage: PropTypes.string,
}

export default SearchTitle
