import React, { useEffect } from 'react'
import { useAppSelector } from '../../hooks/react-redux.hook'
import { orderSelector } from '../../redux/selectors/orderItemsDetails.selectors'
import { isOrderHasAutomotiveLines } from '../OrderDetails/OrderDetailItems/OrderDetailItems.helper'

/**
 * DynamicChangesOnOrderPageInit to update the order data specific full page manipulations:
 *  - Hide Triangle banner on the automotive orders when the baneer is upper than the order products details.
 * @returns {JSX.Element} DynamicChangesOnOrderPageInit component
 */
const DynamicChangesOnOrderPageInit: React.FC = () => {
    const order = useAppSelector(orderSelector)
    const isAutoAppointanble = Boolean(isOrderHasAutomotiveLines(order))
    useEffect(() => {
        if (isAutoAppointanble) {
            const triangleBanners = document.querySelectorAll('.loyaltyofferscarousel')
            const productDetials = document.querySelector('.orderConfirmation')
            if (productDetials) {
                const htmlProductDetials = productDetials as HTMLElement
                triangleBanners.forEach(banner => {
                    const htmlBanner = banner as HTMLElement
                    if (htmlProductDetials.offsetTop > htmlBanner.offsetTop) {
                        htmlBanner.style.display = 'none'
                    }
                })
            }
        }
    }, [isAutoAppointanble])
    return <></>
}

export default DynamicChangesOnOrderPageInit
